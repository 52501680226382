import { makeAutoObservable } from 'mobx';

import { IOrganizationDto } from 'Shared/Interfaces/Api/Organizations';
import Utils from "../../Utils";

export default class OrganizationFormModel {
  public id = '';

  public name = '';

  public isValidationActivated = false;

  public nameValidState = '';

  constructor(organization?: IOrganizationDto) {
    makeAutoObservable(this, undefined, { autoBind: true });
    if (!organization) return;

    this.id = organization.id;
    this.name = organization.name;
  }

  public getDto() {
    if (this.isNotValid()) return null;

    return {
      id: this.id,
      name: this.name,
    };
  }

  public updateName(name: string) {
    this.name = name;

    if (this.isValidationActivated) {
      this.nameValidState = this.checkForValid(this.name);
    }
  }

  public updateFromDto(organization: IOrganizationDto) {
    this.id = organization.id;
    this.name = organization?.name;
  }

  public deInit() {
    this.id = '';
    this.name = '';
    this.nameValidState = '';
  }

  public isNotValid(): boolean {
    this.nameValidState = this.checkForValid(this.name);

    return !!this.nameValidState;
  }

  private checkForValid(value: string): string {
    return Utils.checkForValid(value);
  }
}
