import { makeAutoObservable } from 'mobx';

import { IExamGetDto, IExamPostDto } from 'Shared/Interfaces/Api/ExamTemplate';
import { CoursesId } from 'Shared/Enums';
import Utils from '../../Utils';

const DEFAULT_THICKNESS_BRIEF =
  'Для одного образца:\n' +
  'Выполнить ультразвуковую толщинометрию (УТ).\n' +
  'Оформить таблицу по результатам контроля.\n' +
  'Сравнить результаты с требованиями нормативных документов (НД).\n' +
  '\n' +
  'Экзаменационный образец ...\n' +
  'Тип образца: ...\n' +
  'Марка материала: ...\n' +
  'Размеры образца: ...\n' +
  'Шероховатость: не хуже Rz40.\n' +
  '\n' +
  'НД на технологию контроля: ГОСТ Р ИСО 16809-2015\n' +
  'НД на нормы оценки: ...\n' +
  '\n' +
  'При необходимости в разъяснении вопросов по нормативным документам обращайтесь к методисту.';

const DEFAULT_FLAW_BRIEF =
  'Для одного образца:\n' +
  'Выполнить ультразвуковую дефектоскопию (УД).\n' +
  'Оформить таблицу по результатам контроля.\n' +
  'Сравнить результаты с требованиями нормативных документов (НД).\n' +
  '\n' +
  'Экзаменационный образец ...\n' +
  'Тип образца: ...\n' +
  'Марка материала: ...\n' +
  'Размеры образца: ...\n' +
  'Шероховатость: не хуже Rz40\n' +
  'Способ сварки, разделка кромок: ...\n' +
  '\n' +
  'НД на технологию контроля: ГОСТ Р 55724-2013\n' +
  'НД на нормы оценки: ...\n' +
  'Уровень качества: ...\n' +
  '\n' +
  'При необходимости в разъяснении вопросов по нормативным документам обращайтесь к методисту.';

interface IExamFormModelDef {
  id: string;
  name: string;
  courseId: CoursesId;
  sampleId: string;
  sampleTypeId: string;
  materialId: string;
  departmentId: string;
  rejectionRateId: string;
  qualityLevelId: string;
  brief: string;

  getDto(): IExamPostDto | null;
  updateFromDto(dto: IExamGetDto): void;
  clear(): boolean;
  onChangeName(name: string): void;
  onChangeCourse(courseId: CoursesId): void;
  onChangeSample(sampleId: string): void;
  onChangeRejectionRate(rejectionRateId: string): void;
  onChangeQualityLevel(qualityLevelId: string): void;
  onChangeBrief(briefId: string): void;
}

class ExamFormModel implements IExamFormModelDef {
  public id = '';

  public name = '';

  public courseId: CoursesId = CoursesId.ThicknessMeasurement;

  public sampleId = '';

  public sampleTypeId = '';

  public tuningSampleId = '';

  public materialId = '';

  public departmentId = '';

  public rejectionRateId = '';

  public qualityLevelId = '';

  public brief = DEFAULT_THICKNESS_BRIEF;

  private isValidationActivated = false;

  public nameValidState = '';

  public courseIdValidState = '';

  public sampleIdValidState = '';

  public sampleTypeIdValidState = '';

  public materialIdValidState = '';

  public departmentIdValidState = '';

  public rejectionIdValidState = '';

  public qualityLevelIdValidState = '';

  public briefValidState = '';

  public constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public getDto(): IExamPostDto | null {
    if (this.isNotValid()) return null;
    return {
      name: this.name,
      description: this.brief,
      sampleId: this.sampleId,
      qualityLevelId: this.qualityLevelId,
      materialId: this.materialId,
      sampleTypeId: this.sampleTypeId,
    };
  }

  public updateId(examId: string) {
    this.id = examId;
  }

  public setTuningSample(sampleId: string) {
    this.tuningSampleId = sampleId;
  }

  public updateFromDto(dto: IExamGetDto): void {
    this.id = dto.id;
    this.name = dto.name;
    this.brief = dto.description;
    this.sampleId = dto.sampleId;
    this.qualityLevelId = dto.qualityLevelId;
    this.courseId = dto.deviceTypeId as CoursesId;
    this.materialId = dto.materialId;
    this.sampleTypeId = dto.sampleTypeId;
  }

  public clear(): boolean {
    this.id = '';
    this.name = '';
    this.courseId = CoursesId.ThicknessMeasurement;
    this.sampleId = '';
    this.sampleTypeId = '';
    this.tuningSampleId = '';
    this.materialId = '';
    this.departmentId = '';
    this.rejectionRateId = '';
    this.qualityLevelId = '';
    this.brief = '';

    this.isValidationActivated = false;
    this.nameValidState = '';
    this.courseIdValidState = '';
    this.sampleIdValidState = '';
    this.sampleTypeIdValidState = '';
    this.materialIdValidState = '';
    this.departmentIdValidState = '';
    this.rejectionIdValidState = '';
    this.qualityLevelIdValidState = '';
    this.briefValidState = '';

    return true;
  }

  public onChangeName(value: string) {
    this.name = value;

    if (this.isValidationActivated) {
      this.nameValidState = this.checkForValid(this.name);
    }
  }

  public onChangeCourse(courseId: CoursesId): void {
    this.courseId = courseId;

    if (courseId === CoursesId.FlawDetection) {
      this.brief = DEFAULT_FLAW_BRIEF;
    }

    if (courseId === CoursesId.ThicknessMeasurement) {
      this.brief = DEFAULT_THICKNESS_BRIEF;
    }

    this.materialId = '';
    this.sampleTypeId = '';
    this.sampleId = '';
  }

  public onChangeSample(sampleId: string): void {
    this.sampleId = sampleId;

    if (this.isValidationActivated) {
      this.sampleIdValidState = this.checkForValid(this.sampleId);
    }
  }

  public onChangeSampleType(type: string): void {
    this.sampleTypeId = type;

    if (this.isValidationActivated) {
      this.sampleTypeIdValidState = this.checkForValid(this.sampleTypeId);
    }
  }

  public onChangeMaterialName(materialId: string): void {
    this.materialId = materialId;

    if (this.isValidationActivated) {
      this.materialIdValidState = this.checkForValid(this.materialId);
    }
  }

  public onChangeDepartment(departmentId: string): void {
    this.departmentId = departmentId;

    if (this.isValidationActivated) {
      this.departmentIdValidState = this.checkForValid(this.departmentId);
    }
  }

  public onChangeRejectionRate(rejectionRateId: string): void {
    this.rejectionRateId = rejectionRateId;

    if (this.isValidationActivated) {
      this.rejectionIdValidState = this.checkForValid(this.rejectionRateId);
    }
  }

  public onChangeQualityLevel(qualityLevelId: string): void {
    this.qualityLevelId = qualityLevelId;

    if (this.isValidationActivated) {
      this.qualityLevelIdValidState = this.checkForValid(this.qualityLevelId);
    }
  }

  public onChangeBrief(value: string): void {
    this.brief = value;

    if (this.isValidationActivated) {
      this.briefValidState = this.checkForValid(this.brief, 2048);
    }
  }

  private isNotValid(): boolean {
    this.isValidationActivated = true;
    this.nameValidState = this.checkForValid(this.name);
    this.courseIdValidState = this.checkForValid(this.courseId);
    this.sampleIdValidState = this.checkForValid(this.sampleId);
    this.sampleTypeIdValidState = this.checkForValid(this.sampleTypeId);
    this.materialIdValidState = this.checkForValid(this.materialId);
    this.departmentIdValidState = this.checkForValid(this.departmentId, 2048);
    this.rejectionIdValidState = this.checkForValid(this.rejectionRateId, 2048);
    this.qualityLevelIdValidState = this.checkForValid(this.qualityLevelId, 2048);
    this.briefValidState = this.checkForValid(this.brief, 2048);

    return (
      !!this.nameValidState ||
      !!this.courseIdValidState ||
      !!this.sampleIdValidState ||
      !!this.sampleTypeIdValidState ||
      !!this.materialIdValidState ||
      !!this.departmentIdValidState ||
      !!this.rejectionIdValidState ||
      !!this.qualityLevelIdValidState ||
      !!this.briefValidState
    );

    return false;
  }

  private checkForValid(value: string, maxLength?: number): string {
    return Utils.checkForValid(value, maxLength);
  }
}

export default ExamFormModel;
