import { makeAutoObservable, runInAction } from 'mobx';

import { samplesApiService, sensorsApiService } from 'services/apiService';

import { appStore, examsStore, measurementStore, studyCoursesStore } from './index';

import SensorModel from 'Shared/Models/Devices/SensorModel';

import Chart from 'Shared/Models/Simulation/Chart';

import { CourseModulesIds, CoursesId, OptionsTabCode, SampleMode, StudyFocusEl } from 'Shared/Enums';
import {
  IDefectDefectData,
  IDefectNoteData,
  IPoint,
  ISampleDefectData,
  ISampleDepthData,
  ISelectItem,
  IUserDefectDefects,
} from 'Shared/Interfaces/App';

import ThickDeviceModel from 'Shared/Models/Devices/ThickDeviceModel';
import {
  defectSamplesExam,
  defectSamplesStudy,
  depthSamplesExam,
  depthSamplesStudy,
  emptySampleData,
  userDepthData,
} from 'Shared/Constants/Simulation';
import SampleDefect from 'Shared/Models/Simulation/Sample/SampleDefect';
import SampleAbstract from 'Shared/Models/Simulation/Sample/SampleAbstract';
import SampleDepth from 'Shared/Models/Simulation/Sample/SampleDepth';
import { TuningSample } from '../Shared/Models/Samples/TuningSample';
import { DefectNote } from 'Shared/Models/ForkFlow/DefectNote';

import Utils from 'Shared/Utils';
import { MouseEvent } from 'react';

export const NO_SENSOR = new SensorModel({
  id: '',
  name: 'Без датчика',
  frequency: 0,
  maxThickness: 0,
  minThickness: 0,
  deviceTypeId: CoursesId.FlawDetection,
});

class MeasurementStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isPending = false;

  public pipAngle = 0;

  public thickDevice = new ThickDeviceModel();

  public sensors: SensorModel[] = [];

  public allSensors: ISelectItem[] = [];

  public flawSamples: ISampleDefectData[] = [];

  public thicknessSamples: ISampleDepthData[] = [];

  public selectedSensor: SensorModel = NO_SENSOR;

  public selectedSampleId = emptySampleData.id;

  public selectedSampleMode: SampleMode | null = null;

  public currentDeviceTab = OptionsTabCode.common;

  public chart: Chart | null = null;

  public sample: SampleAbstract | null = null;

  public tuningSamplesIds: string[] = [];

  public mouseDownElement: StudyFocusEl | null = null;

  public userDefects: IUserDefectDefects[] = [];

  public defectNotes: DefectNote[] = [];

  public defectNoteIdToRemove: number | null = null;

  public get disabledNoteAdding() {
    if (studyCoursesStore.selectedCourse.selectedModule?.id === CourseModulesIds.FlawWorkWithTool) {
      return this.defectNotes.length > 0 && studyCoursesStore.selectedCourse.selectedModule?.currentStep?.id! < 56;
    }

    if (studyCoursesStore.selectedCourse.selectedModule?.id === CourseModulesIds.ThickWorkWithTool) {
      if (studyCoursesStore.selectedCourse.selectedModule?.currentStep?.id! < 19) {
        return true;
      }

      if (
        studyCoursesStore.selectedCourse.selectedModule?.currentStep?.id! >= 19 &&
        studyCoursesStore.selectedCourse.selectedModule?.currentStep?.id! < 26
      ) {
        return true;
      }
    }

    return false;
  }

  public get samplesList(): ISelectItem[] {
    return studyCoursesStore.selectedCourse.code === CoursesId.FlawDetection
      ? this.flawSamples.map((item) => ({ id: item.id.toString(), name: item.name }))
      : this.thicknessSamples.map((item) => ({ id: item.id.toString(), name: item.name }));
  }

  public get selectSensorsList(): ISelectItem[] {
    return this.sensors.map((item) => item.getSelectItem());
  }

  public initChart(canvasChartTag: HTMLCanvasElement): void {
    const sample = this.flawSamples.find((item) => this.selectedSampleId === item.id);
    if (!sample) return;

    if (this.chart) {
      this.chart.stop();
      this.chart = null;
    }

    this.chart = new Chart(canvasChartTag, sample, appStore.defectDeviceParam);
    this.chart.start();
  }

  public initDepthSample(canvasTag: HTMLCanvasElement): void {
    const sample = this.thicknessSamples.find((item) => this.selectedSampleId === item.id);
    if (!sample) return;

    this.sample = new SampleDepth(canvasTag, sample);

    this.sample?.coordLines?.clear();
    this.sample.setUserDefects(userDepthData);
  }

  public initDefectSample(canvasTag: HTMLCanvasElement): void {
    const sample = this.flawSamples.find((item) => this.selectedSampleId === item.id);
    if (!sample) return;

    this.sample = new SampleDefect(canvasTag, sample, this.setPipPosition);
    this.selectedSampleMode = sample.mode;

    this.sample?.coordLines?.clear();
  }

  private setPipPosition(x: number, y: number) {
    this.selectedSensor.updatePosition(Utils.roundDigit(x, 2), Utils.roundDigit(y, 2));
    this.chart?.setCoord(x, y);
  }

  public getSamples() {
    if (studyCoursesStore.selectedCourse.selectedModule?.id === CourseModulesIds.FlawExam) {
      this.flawSamples.push(emptySampleData);
    }

    if (studyCoursesStore.selectedCourse.selectedModule?.id === CourseModulesIds.ThickWorkWithTool) {
      this.thicknessSamples = depthSamplesStudy;
    }

    if (studyCoursesStore.selectedCourse.selectedModule?.id === CourseModulesIds.FlawWorkWithTool) {
      this.flawSamples = defectSamplesStudy;
    }
  }

  public async getTuningSamples() {
    this.isPending = true;
    try {
      this.flawSamples = [];
      const tuningSamples = await samplesApiService.getTuningSamples();
      const tuningSamplesData = TuningSample.getSampleFromData(tuningSamples);
      this.tuningSamplesIds = tuningSamples.map((item) => item.id);
      this.flawSamples = [...tuningSamplesData];
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public async getSampleForExam(sampleId: string): Promise<void> {
    this.isPending = true;
    this.thicknessSamples = [];

    try {
      const examSample = await samplesApiService.getSampleById(sampleId);

      if (!examSample) return;

      const json = examSample.data.replaceAll(`'`, `"`);
      const parsed = JSON.parse(json);

      if (examSample.deviceTypeId === CoursesId.FlawDetection) {
        this.flawSamples = [...this.flawSamples, ...defectSamplesExam];

        const defectSampleData: ISampleDefectData = {
          id: examSample.id,
          name: examSample.name,
          width: parsed.width,
          depth: examSample.depth,
          height: parsed.height,
          mode: parsed.mode,
          defects: parsed.defects,
          weld: { x: 150, width: 15 },
        };

        this.flawSamples.push(defectSampleData);
      }

      if (examSample.deviceTypeId === CoursesId.ThicknessMeasurement) {
        this.thicknessSamples = depthSamplesExam;

        const thickSampleData: ISampleDepthData = {
          id: examSample.id,
          name: examSample.name,
          width: parsed.width,
          depth: examSample.depth,
          height: parsed.height,
          mode: parsed.mode,
          defects: parsed.defects,
        };

        this.thicknessSamples.push(thickSampleData);
      }
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public async getSensors(deviceId: CoursesId): Promise<void> {
    this.isPending = true;

    try {
      const dtos = await sensorsApiService.getSensors(deviceId);
      if (!dtos) return;

      this.sensors = dtos.map((dto) => new SensorModel(dto));
      this.sensors.push(NO_SENSOR);
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public async getAllSensors(): Promise<void> {
    this.isPending = true;

    try {
      const thickSensors = await sensorsApiService.getSensors(CoursesId.ThicknessMeasurement);
      const flawSensors = await sensorsApiService.getSensors(CoursesId.FlawDetection);

      this.allSensors = [...thickSensors, ...flawSensors];
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public selectSensor(sensorId: string): void {
    const sensor = this.sensors.find((item) => item.id === sensorId);
    if (!sensor) return;

    this.selectedSensor = sensor;
    this.chart?.params?.sensor.setSelectedSensorData(sensor);

    const defectsNotesLength = this.defectNotes.length;
    if (defectsNotesLength < 1) return;

    this.defectNotes[defectsNotesLength - 1].onSensorNameChange(sensor.name);
  }

  public dropSensorOnSample(e: MouseEvent | null): void {
    const point = <IPoint>{ x: 10, y: 10 }; // Значение по умоланию
    if (e) {
      point.x = e.nativeEvent.offsetX;
      point.y = e.nativeEvent.offsetY;
    }
    this.sample?.pip?.initPip(point);

    this.setDirection();
  }

  public selectSample(sampleId: string): void {
    // Сохраняет настройки ВРЧ при смене образца на экзамене
    if (studyCoursesStore.selectedCourse.selectedModule?.isExam) {
      appStore.putDeviceEnvParamsToLocalStorage();
    }

    this.selectedSampleId = sampleId;
    if (this.tuningSamplesIds.includes(sampleId)) {
      examsStore.examToPass.setTuningSample(sampleId);
    }
    appStore.initOnlyFlawDeviceParamFromLS();
    this.selectedSensor.onSurface(false);
  }

  public calcCO3(angle: number, defects: IDefectDefectData[]) {
    angle = ((90 - angle) * Math.PI) / 180;

    const L = 55; // Радиус СО3 для 1 дефекта
    const L2 = L * 3; // Радиус СО3 для 2 дефекта
    const sinA = Math.sin(angle);
    const cosA = Math.cos(angle);

    const depthL = L * sinA;
    const depthL2 = L2 * sinA;

    // 1 дефект
    const x1 = L - L * cosA;
    const x2 = L - L2 * cosA;
    const depth1 = depthL;
    const depth2 = depthL2;

    // 2 дефект (моделирование 2-го отражения)
    const x3 = L + L * cosA;
    const x4 = L + L2 * cosA;
    const depth3 = depthL;
    const depth4 = depthL2;

    defects[0].x = x1;
    defects[0].depth = depth1;
    defects[1].x = x2;
    defects[1].depth = depth2;
    defects[2].x = x3;
    defects[2].depth = depth3;
    defects[3].x = x4;
    defects[3].depth = depth4;

    (measurementStore.sample as SampleDefect).setDefects();
  }

  public pipDirectionToggle(): void {
    this.pipAngle = this.pipAngle === 180 ? 0 : 180;

    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.check();
    appStore.putDeviceEnvParamsToLocalStorage();

    this.setDirection();
  }

  public updateMouseDownElement(elemId: StudyFocusEl | null) {
    this.mouseDownElement = elemId;
  }

  public showDefectNoteRemoveDialog(noteId: number | null): void {
    this.defectNoteIdToRemove = noteId;
  }

  public defectNoteCallBack(defectData: IUserDefectDefects) {
    this.userDefects = this.userDefects.filter((item) => item.counter !== defectData.counter);
    this.userDefects.push(defectData);
    this.sample?.setUserDefects(this.userDefects);
  }

  public addDefectNote(): void {
    const note = new DefectNote(this.defectNoteCallBack, this.defectNotes.length + 1);
    note.onSensorNameChange(this.selectedSensor.name);
    this.defectNotes.push(note);
  }

  public removeDefectNote(): void {
    this.defectNotes = this.defectNotes.filter((item) => item.defectNumber !== this.defectNoteIdToRemove);
    this.defectNotes.forEach((item, index) => {
      item.initDefectNumber(index + 1);
    });

    this.userDefects = this.userDefects.filter((item) => item.counter !== this.defectNoteIdToRemove);
    this.defectNoteIdToRemove = null;

    this.initUserDefect();
  }

  public removeDefectNotes(): void {
    this.defectNotes = [];
    this.userDefects = [];
    // window.localStorage.removeItem(LocalStorageParam.DefectNotes);
  }

  public initDefectNoted(defectNoteDtos: IDefectNoteData[]) {
    this.defectNotes = defectNoteDtos.map((dto) => new DefectNote(this.defectNoteCallBack, null, dto));
    const userDefects = this.defectNotes.map((item) => item.getUserDefectData());
    if (!userDefects) return;

    this.userDefects = userDefects;
  }

  public initUserDefect() {
    this.sample?.setUserDefects(this.userDefects);
  }

  public setDeviceTab(value: OptionsTabCode) {
    this.currentDeviceTab = value;
  }

  public deInit(): void {
    this.selectedSampleId = emptySampleData.id;
    this.selectedSensor = NO_SENSOR;
    this.chart?.params.deInit();
    this.isPending = false;
    this.currentDeviceTab = OptionsTabCode.common;
    this.flawSamples = [];
    this.thicknessSamples = [];
    this.selectedSampleMode = null;
    this.tuningSamplesIds = [];
  }

  private setDirection() {
    this.sample?.pip?.setRotation(this.pipAngle);
    this.chart?.params.sensor.onSetDirection(this.pipAngle === 0);
  }
}

export default new MeasurementStore();
