import HTTPService from './HTTPService';

import { apiPaths } from 'Shared/Constants/Navigation/apiPaths';

import { IGroupDto, IGroupPostDto, IGroupRowDto } from 'Shared/Interfaces/Api/Groups';
import { CourseModulesIds } from 'Shared/Enums';

class GroupsApiService extends HTTPService {
  constructor() {
    super(apiPaths.groups);
  }

  public getGroups(isArchive?: boolean): Promise<IGroupRowDto[]> {
    return this.GET(`?isDeleted=${!!isArchive}`);
  }

  public postGroup(group: IGroupPostDto): Promise<string> {
    return this.POST(``, group);
  }

  public putGroup(groupId: string, group: IGroupPostDto): Promise<string> {
    return this.PUT(`${groupId}`, group);
  }

  public restoreGroup(groupId: string): Promise<string> {
    return this.PUT(`restore/${groupId}`);
  }

  public deleteGroup(groupId: string): Promise<string> {
    return this.DELETE(`/${groupId}`);
  }

  public getGroupById(groupId: string): Promise<IGroupDto> {
    return this.GET(`${groupId}`);
  }

  public joinStudentsToGroup(groupId: string, studentIds: string[]): Promise<any> {
    return this.POST(`${groupId}/students`, studentIds);
  }

  public getStudentsByGroup(groupId: string): Promise<string[]> {
    return this.GET(`${groupId}/students`);
  }

  public getGroupsByStudent(studentId: string): Promise<IGroupDto[]> {
    return this.GET(`by-student-id?studentId=${studentId}`);
  }

  public getProgress(groupId: string, studentId: string): Promise<number> {
    return this.GET(`progress?groupId=${groupId}&studentId=${studentId}`);
  }

  public putProgress(groupId: string, studentId: string, currentModule: CourseModulesIds): Promise<number> {
    return this.PUT(`progress?groupId=${groupId}&studentId=${studentId}&currentModule=${currentModule}`);
  }
}

export default new GroupsApiService();
