import HTTPService from './HTTPService';

import { apiPaths } from 'Shared/Constants/Navigation/apiPaths';

import { IExamDto, ISaveExamDto, IVerifyExamDto } from 'Shared/Interfaces/Api/Exam';

class ExamApiService extends HTTPService {
  constructor() {
    super(apiPaths.exam);
  }

  public startExam(studentId: string, groupId: string): Promise<IExamDto> {
    return this.POST(``, { studentId, groupId });
  }

  public removeExam(examId: string): Promise<string> {
    return this.DELETE(`?id=${examId}`);
  }

  public getExamByStudentId(studentId: string, groupId: string): Promise<IExamDto> {
    return this.GET(`student?studentId=${studentId}&groupId=${groupId}`);
  }

  public finishExam(examId: string) {
    return this.POST(`${examId}/finish`);
  }

  public saveExam(examId: string, examData: ISaveExamDto) {
    return this.PUT(`/${examId}/save`, examData);
  }

  public loadExam(examId: string): Promise<ISaveExamDto> {
    return this.GET(`${examId}/load`);
  }

  public verify(examId: string, verifyData: IVerifyExamDto) {
    return this.PUT(`${examId}/verify`, verifyData);
  }
}

export default new ExamApiService();
