import { IOrganizationDto } from 'Shared/Interfaces/Api/Organizations';

interface IOrganizationRowModelDef {
  id: string;
  name: string;
}

class OrganizationRowModel implements IOrganizationRowModelDef {
  public id = '';

  public name = '';

  constructor(dto: IOrganizationDto) {
    this.id = dto.id;
    this.name = dto.name;
  }
}

export default OrganizationRowModel;
