import HTTPService from './HTTPService';

import { apiPaths } from 'Shared/Constants/Navigation/apiPaths';

import { ISensorDto } from 'Shared/Interfaces/Api/Sensors';
import { CoursesId } from 'Shared/Enums';

class SamplesApiService extends HTTPService {
  constructor() {
    super(apiPaths.sensors);
  }

  public getSensors(deviceTypeId: CoursesId): Promise<ISensorDto[]> {
    return this.GET(`?deviceTypeId=${deviceTypeId}&searchPattern=`);
  }
}

export default new SamplesApiService();
