import { makeAutoObservable } from 'mobx';

export default class ThickDeviceModel {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  private calibratedLevel = '6.0';

  public isWasCalibrated = false;

  public isSwitchedOn = false;

  public isAboveSampleSimulator = false;

  // положение курсора по clientX
  public x: number | null = null;

  // положение курсора по clientY
  public y: number | null = null;

  public depthValue: string = '';

  public instDepthValue: string | null = null; // Мгновенное значение

  private timerId: any = null;
  
  public isSampleImitatorWet = false;

  public switchToggle() {
    if (!this.isSwitchedOn) {
      this.isSwitchedOn = true;
      this.instDepthValue = '0.0';
    }
  }

  public dropLiquidOnImitator() {
    this.isSampleImitatorWet = true;
  }

  public setMousePosition(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  public setDepthValue(depth: string) {
    if (!this.isSwitchedOn || !depth) return;

    this.instDepthValue = depth;

    if (!this.isWasCalibrated) {
      this.isWasCalibrated = depth === this.calibratedLevel;
    }
  }

  public setSensorAboveSimulator(isAbove: boolean) {
    this.isAboveSampleSimulator = isAbove;
    if (!isAbove) this.setDepthValue('0.0');

    if (this.timerId) clearTimeout(this.timerId);
    
    this.timerId = setInterval(() => {
      if (!this.instDepthValue) return;
      this.depthValue = this.instDepthValue
    }, 200);
  }

  public deInitThickDevice() {
    this.depthValue = '';
    this.instDepthValue = null;
    this.isSwitchedOn = false;
    this.isAboveSampleSimulator = false;
    this.isSampleImitatorWet = false;
    this.x = null;
    this.y = null;

    clearTimeout(this.timerId);
  }
}
