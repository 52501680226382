import { makeStyles } from '@material-ui/core';
import { indents } from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  studyModulesWrapper: {
    marginTop: indents.thirtyTwo,
    marginLeft: indents.fortyEight,
    marginRight: indents.fortyEight,
    height: 'calc(100% - 80px)',
  },
}));

export default useStyles;
