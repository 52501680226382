import { makeStyles } from '@material-ui/core';
import { colors, indents, inputBorderRadius } from '../theme';

const useStyles = makeStyles({
  dateInputWrapper: {
    '& .MuiInputBase-root': {
      height: '40px',
      minWidth: '64px',
      borderRadius: inputBorderRadius,
    },
    '& fieldset': {
      borderColor: colors.primary.mainBlue,
    },
    '&:hover fieldset': {
      borderColor: colors.primary.darkBlue,
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.primary.darkBlue,
    },
  },
});

export default useStyles;
