import { makeStyles } from '@material-ui/core';
import { colors, indents } from '../../uiKitElems';

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: `${indents.sixteen}px ${indents.thirtyTwo}px`,
    height: 60,
    backgroundColor: colors.grayColor.gray,
  },
}));

export default useStyles;
