import HTTPService from './HTTPService';

import { apiPaths } from 'Shared/Constants/Navigation/apiPaths';

import { IAuthDto, ICredentialsDto, IUserPostDto, IUserPutDto, IUserRowDto } from 'Shared/Interfaces/Api/Useres';
import { UserRole } from 'Shared/Enums';

class UsersApiService extends HTTPService {
  constructor() {
    super(apiPaths.users);
  }

  public getUsers(roleId: UserRole, isArchive?: boolean): Promise<IUserRowDto[]> {
    return this.GET(`?roleId=${roleId}&isDeleted=${!!isArchive}`);
  }

  public postUser(user: IUserPostDto): Promise<string> {
    return this.POST(``, user);
  }

  public putUser(userId: string, user: IUserPutDto): Promise<string> {
    return this.PUT(`${userId}`, user);
  }

  public restoreUser(userId: string): Promise<string> {
    return this.PUT(`/restore/${userId}`);
  }

  public updatePassword(userId: string, password: string): Promise<string> {
    return this.PUT(`/password/${userId}?password=${password}`);
  }

  public deleteUser(userId: string): Promise<string> {
    return this.DELETE(`/${userId}`);
  }

  public getUserById(userId: string): Promise<IUserPostDto> {
    return this.GET(`${userId}`);
  }

  public authUser(authData: ICredentialsDto): Promise<IAuthDto> {
    return this.POST('auth', authData);
  }
}

export default new UsersApiService();
