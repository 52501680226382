import React from 'react';

import clsx from 'clsx';

import { Typography } from '@material-ui/core';

import { ITextWrapperProps } from '../ITextStyleProps';

import useStyles from './styles';

const H5Text = (props: ITextWrapperProps) => {
  const { children, className, color, id, fontWeight, isLight, noWrap, text } = props;

  const c = useStyles({ isLight, fontWeight, color, noWrap });

  return (
    <Typography id={id} className={className ? clsx(className, c.h5Text) : c.h5Text}>
      {children || text}
    </Typography>
  );
};

export default H5Text;
