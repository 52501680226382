import { makeAutoObservable, runInAction } from 'mobx';

import { appStore, studyCoursesStore, userAuthStore } from './index';

import { groupsApiService } from 'services/apiService';

import { flawDetection, thickness } from 'Shared/Constants/Education';
import { CourseModel } from 'Shared/Models/Courses';
import { CourseModulesIds, CoursesId, LocalStorageParam, ModuleProgress } from 'Shared/Enums';

interface IStudyCoursesStoreDef {
  thicknessCourse: CourseModel;
  flawDetection: CourseModel;
  selectedCourse: CourseModel;

  onSelectCourse(selectedCourse: CoursesId): void;
}

class StudyCoursesStore implements IStudyCoursesStoreDef {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isPending = false;

  public thicknessCourse = new CourseModel();

  public flawDetection = new CourseModel();

  public selectedCourse: CourseModel = new CourseModel();

  public init() {
    if (this.selectedCourse.code === this.thicknessCourse.code) {
      this.thicknessCourse.init(thickness);
    }

    if (this.selectedCourse.code === this.flawDetection.code) {
      this.flawDetection.init(flawDetection);
    }
  }

  public onSelectCourse(selectedCourse: CoursesId): void {
    switch (selectedCourse) {
      case CoursesId.FlawDetection: {
        this.selectedCourse = this.flawDetection;
        break;
      }

      case CoursesId.ThicknessMeasurement: {
        this.selectedCourse = this.thicknessCourse;
        break;
      }
    }
  }

  public async getProgress(): Promise<void> {
    try {
      this.isPending = true;

      const userGroupByDevice = userAuthStore.authUser.userGroups.find(
        (item) => item.deviceTypeId === studyCoursesStore.selectedCourse.code
      );

      if (!userGroupByDevice) return;

      const result = await groupsApiService.getProgress(userGroupByDevice.groupId, userAuthStore.authUser.id);
      if (!result) return;

      this.buildProgress(result);
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public async getProgressForTeacher(groupId: string, studentId: string): Promise<void> {
    try {
      this.isPending = true;

      const result = await groupsApiService.getProgress(groupId, studentId);
      this.buildProgress(result);
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public async saveProgress(): Promise<void> {
    try {
      this.isPending = true;

      const userGroupByDevice = userAuthStore.authUser.userGroups.find(
        (item) => item.deviceTypeId === studyCoursesStore.selectedCourse.code
      );

      if (!userGroupByDevice) return;

      await groupsApiService.putProgress(
        userGroupByDevice.groupId,
        userAuthStore.authUser.id,
        studyCoursesStore.selectedCourse?.selectedModule?.id!
      );
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public deInit() {
    this.selectedCourse = new CourseModel();
    this.flawDetection.deInitCourseModel();
    this.thicknessCourse.deInitCourseModel();
  }

  private buildProgress(result: number) {
    const lastCompletedModuleId = result.toString() as CourseModulesIds;

    const lastCompletedIndex = studyCoursesStore.selectedCourse.modules.findIndex(
      (item) => item.id === lastCompletedModuleId
    );
    if (!lastCompletedIndex.toString()) return;

    for (let i = 0; i < studyCoursesStore.selectedCourse.modules.length; i++) {
      if (result > 3 && studyCoursesStore.selectedCourse.code === CoursesId.ThicknessMeasurement) continue;

      if (i <= lastCompletedIndex) {
        studyCoursesStore.selectedCourse.modules[i].state = ModuleProgress.Completed;
      }

      if (i === lastCompletedIndex + 1) {
        studyCoursesStore.selectedCourse.modules[i].state = ModuleProgress.Current;
      }
    }
  }
}

export default new StudyCoursesStore();
