import { makeAutoObservable, runInAction } from 'mobx';

import { examApiService, examTemplatesApiService, samplesApiService } from 'services/apiService';

import { examsStore, studentsInGroupStore, studyCoursesStore, userAuthStore, usersStore } from './index';

import { UserRowModel } from 'Shared/Models/Users';
import { DefectCheckTableModel } from 'Shared/Models/Checking';

import { colors } from 'Components/uiKitElems';
import dayjs from 'dayjs';
import { CoursesId, UserRole } from 'Shared/Enums';
import { IDefectDefectData, IDefectDepthData } from '../Shared/Interfaces/App';

enum ExamState {
  HaveToCheck = 'Требует оценки',
  Checked = 'Оценено',
}

class StudentProgressStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isPending = false;

  public isShowExamBrief = false;

  public examBrief = '';

  public isShowCheckTable = false;

  public isShowReExamDialog = false;

  public isShowSaveConfirmDialog = false;

  public disableExamToPass = false;

  public finalMark: number | null = null;

  public lastVerifierId = '';

  public selectedStudent: UserRowModel | null = null;

  public examId = '';

  public finishedAt = '';

  public examState = '';

  public defectCheckTable: DefectCheckTableModel | null = null;

  public get passedAt() {
    return `Дата сдачи ${dayjs(this.finishedAt).format('DD.MM.YYYY')}`;
  }

  public get currentExamState(): string {
    const teacher = usersStore.users.find((item) => item.id === this.lastVerifierId);

    this.examState = this.finalMark
      ? `${ExamState.Checked}. ${teacher?.fullName!}: ${this.finalMark}`
      : ExamState.HaveToCheck;

    return this.examState;
  }

  public get isShowFlawCheckingTable() {
    return (
      studyCoursesStore.selectedCourse.code === CoursesId.FlawDetection &&
      this.defectCheckTable?.courseId === CoursesId.FlawDetection &&
      this.isShowCheckTable
    );
  }

  public get isShowThicknessCheckingTable() {
    return (
      studyCoursesStore.selectedCourse.code === CoursesId.ThicknessMeasurement &&
      this.defectCheckTable?.courseId === CoursesId.ThicknessMeasurement &&
      this.isShowCheckTable
    );
  }

  public get finalMarkColor(): string {
    let finalMarkColor = '';

    if (this.finalMark! < 50) {
      finalMarkColor = colors.statusColor.dangerRed;
    }

    if (this.finalMark! > 50 && this.finalMark! < 75) {
      finalMarkColor = colors.statusColor.warningYello;
    }

    if (this.finalMark! > 75) {
      finalMarkColor = colors.statusColor.successGreen;
    }

    return finalMarkColor;
  }

  public onShowBrief(isShow: boolean) {
    this.isShowExamBrief = isShow;
  }

  public async getStudentExamData(): Promise<void> {
    if (!this.selectedStudent) return;

    try {
      this.isPending = true;
      const exam = await examApiService.getExamByStudentId(
        this.selectedStudent.id,
        studentsInGroupStore.selectedGroupId
      );

      this.finalMark = exam.scoreTeacher;
      this.lastVerifierId = exam.lastVerifierId;
      this.finishedAt = exam.finishedAt;

      if (userAuthStore.authUser.role === UserRole.Student) return;

      const examData = await examApiService.loadExam(exam.id);
      this.examId = exam.id;
      const examTemplate = await examTemplatesApiService.getExamById(exam.examTemplateId);
      this.examBrief = examTemplate.description;
      const dto = await samplesApiService.getSampleById(examTemplate.sampleId);

      if (!dto) return;

      const json = dto.data.replaceAll(`'`, `"`);
      const parsed = JSON.parse(json);

      const courseId = dto.deviceTypeId as CoursesId;
      let flawDefects: IDefectDefectData[] = [];
      let depthDefects: IDefectDepthData[] = [];

      if (courseId === CoursesId.FlawDetection) {
        flawDefects = parsed.defects;
      }

      if (courseId === CoursesId.ThicknessMeasurement) {
        depthDefects = parsed.defects;
      }

      this.defectCheckTable = new DefectCheckTableModel(courseId, examData, flawDefects, depthDefects, parsed.tuningSampleId, parsed.sensorId);
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public async checkForPassedExam(studentId: string, groupId: string): Promise<void> {
    try {
      this.isPending = true;
      const exam = await examApiService.getExamByStudentId(studentId, groupId);
      this.finalMark = exam.scoreTeacher;
      this.lastVerifierId = exam.lastVerifierId;

      if (exam && exam.finishedAt) {
        this.disableExamToPass = true;
      }

      if (!exam) {
        this.disableExamToPass = false;
      }

      if (exam && !exam.finishedAt) {
        this.disableExamToPass = false;
        await examsStore.continueExam(exam.id, exam.examTemplateId);
      }
    } catch (e) {
      this.disableExamToPass = false;
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public async saveVerify() {
    if (!this.selectedStudent) return;

    try {
      this.isPending = true;
      await examApiService.verify(this.examId, {
        lastVerifierId: userAuthStore.authUser.id,
        scoreCalculate: this.defectCheckTable?.calculatedMark!,
        scoreTeacher: this.finalMark!,
      });

      this.closeSaveConfirmDialog();
      this.deInit();
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public showStudentProgress(studentId: string | null): void {
    if (studentId === null) this.selectedStudent = null;

    this.selectedStudent = studentsInGroupStore.studentsByGroup.find((item) => item.id === studentId) || null;
  }

  public setFinalMark(value?: string) {
    if (!this.finalMark) {
      this.finalMark = this.defectCheckTable?.calculatedMark || null;
    }

    if (!value) {
      this.finalMark = null;
      return;
    }
    const parsed = parseInt(value);

    if (parsed < 0) this.finalMark = 0;
    if (parsed > 100) this.finalMark = 100;

    if (parsed > -1 && parsed < 101) {
      this.finalMark = parsed;
    }
  }

  public showReExamDialog() {
    this.isShowReExamDialog = true;
  }

  public closeReExamDialog() {
    this.isShowReExamDialog = false;
  }

  public showSaveConfirmDialog() {
    this.isShowSaveConfirmDialog = true;
  }

  public closeSaveConfirmDialog() {
    this.isShowSaveConfirmDialog = false;
  }

  public showCheckTable() {
    this.isShowCheckTable = true;
  }

  public closeCheckTable() {
    this.isShowCheckTable = false;
  }

  public deInit() {
    this.isShowCheckTable = false;
    this.isPending = false;
    this.isShowReExamDialog = false;
    this.isShowSaveConfirmDialog = false;
    this.finalMark = null;
    this.finishedAt = '';
    this.lastVerifierId = '';
    this.selectedStudent = null;
    this.examId = '';
    this.examState = ``;
    this.defectCheckTable = null;
    this.disableExamToPass = false;
    this.examBrief = '';
  }

  public async removePassedExam() {
    this.isPending = true;

    try {
      await examApiService.removeExam(this.examId);
      this.deInit();
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }

    this.closeReExamDialog();
    return true;
  }
}

export default new StudentProgressStore();
