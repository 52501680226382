import React from 'react';

import { Route } from 'react-router-dom';

import { AdminCabinet } from 'Modules';

import { ApplicationRoutes } from 'Shared/Constants/Navigation';

const TeacherRoutes = () => {
  return (
    <Route exact={true} path={ApplicationRoutes.Cabinet}>
      <AdminCabinet />
    </Route>
  );
};

export default TeacherRoutes;
