import { Graphics, Sprite, Texture } from 'pixi.js';
import SampleDefect from '../Sample/SampleDefect';
import PipAbstract from './pipAbstract';
import {IPoint} from "../../../Interfaces/App";

/**
 * ПИП дефектоскопа
 */
export default class setPipDefect extends PipAbstract {
  pipDefectImage = 'images/sampleSurface/pip.png';

  /**
   * Угол поворота
   */
  rotation = 0;

  /**
   * Calback изменения координат
   */
  setCoord: any;

  width: number;
  height: number;

  constructor(sample: SampleDefect, width: number, height: number, x: number, y: number, setCoord: any) {
    super(sample);

    this.width = width;
    this.height = height;
    
    this.setCoord = setCoord;
  }

  /**
   * Инитит ПИП при выборе датчика
   */
  public initPip(point: IPoint) {
    super.initPip(point);

    this.buildPip(this.sample.toPx(this.width), this.sample.toPx(this.height), this.sample.relativeX, this.sample.relativeY);
    this.AddPip(this.onDragMoveHandler);
  }

  /**
   * Построение ПИП
   * @param width
   * @param height
   * @param x
   * @param y
   * @private
   */
  private buildPip(width: number, height: number, x: number, y: number) {
    const sensor = Sprite.from(Texture.from(this.pipDefectImage));
    sensor.width = width;
    sensor.height = height;
    sensor.anchor.set(0.5);
    
    // Nick
    const nick = new Graphics();
    nick.lineStyle(3, 0x000000, 1);
    nick.moveTo(0, height / 4);
    nick.lineTo(0, height / 2);
    nick.moveTo(0, - height / 4);
    nick.lineTo(0, - height / 2);
    
    this.pip.addChild(sensor)
    this.pip.addChild(nick)

    this.pip.position.set(x, y);
  }

  /**
   * Поворот ПИП
   * @param angle
   */
  setRotation(angle: number) {
    this.rotation = angle;
    
    if (angle === 0) this.pip.scale.x = Math.abs(this.pip.scale.x);
    else this.pip.scale.x = -this.pip.scale.x;
  }

  /**
   * Перемещение
   * @param xMetric - метрический х
   * @param yMetric - метрический y
   */
  onDragMoveHandler = (xMetric: number, yMetric: number) => {
    // Ограничение перемещения по шву
    const { weld } = (this.sample as SampleDefect).sampleData;
    
    // Находимся в шве
    const inWeld =
        weld !== undefined &&
        (xMetric > (weld.x - weld.width / 2 - this.width / 2) && (xMetric < weld.x + weld.width));

    // Значение Х, на границе со швом
    if (inWeld) {
      // Приблизились слева
      if (xMetric < weld.x) 
        this.xValueStopMeasure = this.sample.sampleX + this.sample.toPx(weld.x - weld.width / 2 - this.width / 2);
      // Приблизились справа
      else
        this.xValueStopMeasure = this.sample.sampleX + this.sample.toPx(weld.x + weld.width / 2 + this.width / 2);
      return;
    }
    
    this.setCoord(xMetric, yMetric);
  };
}
