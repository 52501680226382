import React, { ChangeEvent, MouseEvent, ReactNode, useState } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import { EnhancedTableHeader } from './components';
import TablePagination, { LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination';
import Popover, { PopoverPosition } from '@material-ui/core/Popover';

import { Spinner, StudyFocusWrapper } from 'Components/uiKitElems';

import { ITableColumn } from './index';

import { StudyFocusEl } from 'Shared/Enums';

import useStyles from './styles';

const DEFAULT_PAGINATION_OPTIONS = [5, 10, 15, 20];

const labelDisplayedRows = ({ from, to, count }: LabelDisplayedRowsArgs) => {
  return `${from}-${to === -1 ? count : to} из ${count !== -1 ? count : `больше ${to}`}`;
};

interface IProps {
  lastRowRef?: any;
  className?: string;
  columns: ITableColumn[];
  customClassCell?: string;
  customHeaderCell?: { el: JSX.Element; columnKey: string };
  data: any[];
  dataKey: string;
  totalCount?: number;
  rowsPerPage?: number;
  page?: number;
  rowsPerPageOptions?: number[];
  loading?: boolean;
  hover?: boolean;
  selectedItemKey?: any;
  showPopoverOnSelect?: boolean;
  popoverContent?: ReactNode;
  onChangePage?: (page: number) => void;
  onChangeRowsPerPage?: (rowsPerPage: number) => void;
  onRowClick?: (row: any, e: MouseEvent<Element>) => void;
  onPopoverClose?: () => void;
  onSort?: (order: 'asc' | 'desc' | undefined, orderBy: string | undefined) => void;
}

const CustomTable = (props: IProps) => {
  const {
    lastRowRef,
    className,
    columns,
    customHeaderCell,
    customClassCell,
    data,
    dataKey,
    totalCount,
    rowsPerPage,
    page,
    rowsPerPageOptions,
    loading,
    hover,
    selectedItemKey,
    showPopoverOnSelect,
    popoverContent,
    onChangePage,
    onChangeRowsPerPage,
    onRowClick,
    onPopoverClose,
    onSort,
  } = props;

  const c = useStyles();

  const [open, setOpen] = useState(false);
  const [anchorPosition, setAnchorPosition] = useState<PopoverPosition | undefined>();

  const [order, setOrder] = useState<'asc' | 'desc' | undefined>();
  const [orderBy, setOrderBy] = useState<string | undefined>();

  const renderRow = (row: any, cols: ITableColumn[], ref: any) => {

    return cols.map((column) => {
      let cellId: StudyFocusEl = StudyFocusEl.UnKnown;

      if (column.key === 'levelOver') {
        cellId = StudyFocusEl.LevelOver;
      }

      if (column.key === 'oY') {
        cellId = StudyFocusEl.oYTableInput;
      }

      if (column.key === 'y') {
        cellId = StudyFocusEl.Y;
      }

      if (column.key === 'defectLength') {
        cellId = StudyFocusEl.DefectLengthTableInput;
      }

      if (column.key === 'defectNumber') {
        cellId = StudyFocusEl.DefectNoteTableNumber;
      }

      if (column.key === 'shortNote') {
        cellId = StudyFocusEl.ShortTableNote;
      }

      if (column.key === 'marking') {
        cellId = StudyFocusEl.Normality;
      }

      if (column.key === 'marking') {
        cellId = StudyFocusEl.Normality;
      }

      if (column.key === 'defectDepth') {
        cellId = StudyFocusEl.TableDepthValue;
      }

      return (
        <TableCell
          ref={ref}
          variant="body"
          key={column.key}
          align={column.align}
          className={customClassCell ? customClassCell : ''}
          style={{ maxWidth: column.maxWidth, width: column.width }}>
          {cellId === StudyFocusEl.UnKnown ? (
            <>{column.cell ? column.cell(row) : row[column.key]}</>
          ) : (
            <StudyFocusWrapper isNotDisabled={true} fullWidth={true} isLightBg={true} id={cellId}>
              {column.cell ? column.cell(row) : row[column.key]}
            </StudyFocusWrapper>
          )}
        </TableCell>
      );
    });
  };

  const renderRows = (tableRows: any[]) => {
    return tableRows.map((row, index) => {
      const handleClick = (e: MouseEvent<Element>) => {
        if (!onRowClick) return;

        if (showPopoverOnSelect) {
          setAnchorPosition({ top: e.clientY, left: e.clientX });
          setOpen(true);
        }

        onRowClick(row, e);
      };

      return (
        <TableRow selected={selectedItemKey === row[dataKey]} hover={hover} key={row[dataKey]} onClick={handleClick}>
          {renderRow(row, columns, tableRows.length - 1 === index ? lastRowRef : null)}
        </TableRow>
      );
    });
  };

  const handlePageChange = (e: MouseEvent<HTMLButtonElement> | null, value: number) => {
    if (!onChangePage) return;

    onChangePage(value);
  };

  const handleChangeRowsPerPage = (e: ChangeEvent<HTMLInputElement>) => {
    if (!onChangeRowsPerPage) return;

    onChangeRowsPerPage(Number.parseInt(e.target.value, 10));
  };

  const handlePopoverClose = () => {
    setOpen(false);

    if (onPopoverClose) {
      onPopoverClose();
    }
  };

  const handleSort = (event: MouseEvent<unknown>, property: string | undefined) => {
    setOrderBy(property);

    let newOrder;

    if (property) {
      if (orderBy === property) {
        newOrder = order === 'asc' ? 'desc' : 'asc';
      } else {
        newOrder = 'asc';
      }
    }

    setOrder(newOrder as any);

    if (onSort) {
      onSort(newOrder as any, property);
    }
  };

  const renderPagination = () => {
    if (totalCount === undefined || page === undefined || rowsPerPage === undefined) return null;

    return (
      <TablePagination
        labelRowsPerPage="Строк на странице:"
        className={c.pagination}
        count={totalCount}
        component="div"
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions || DEFAULT_PAGINATION_OPTIONS}
        onPageChange={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelDisplayedRows={labelDisplayedRows}
      />
    );
  };

  return (
    <div className={className ? clsx(className, c.wrapper) : c.wrapper}>
      <TableContainer className={c.tableContainer}>
        <Table stickyHeader={true}>
          <EnhancedTableHeader
            className={c.head}
            columns={columns}
            customCell={customHeaderCell}
            order={order}
            orderBy={orderBy}
            onSort={handleSort}
          />

          <TableBody>{renderRows(data)}</TableBody>
        </Table>
      </TableContainer>

      <Spinner isLoading={!!loading} zIndex={3} />

      {showPopoverOnSelect && (
        <Popover
          open={open}
          anchorReference="anchorPosition"
          anchorPosition={anchorPosition}
          onClose={handlePopoverClose}>
          {popoverContent}
        </Popover>
      )}

      {renderPagination()}
    </div>
  );
};

export default observer(CustomTable);
