import React from 'react';

import clsx from 'clsx';

import { H5Text } from '../textWrappers';
import { LabelPosition } from './types';

import useStyles from './styles';

interface IProps {
  className?: string;
  labelWrapperClassName?: string;
  inputCell?: JSX.Element;
  validationMessage?: string;
  inputWidthInPercents?: number;
  labelPosition?: LabelPosition;
  labelCell?: JSX.Element;
  spaceBetweenSize?: number;
  required?: boolean;
}

const InputWrapper = (props: IProps) => {
  const {
    className,
    inputCell,
    inputWidthInPercents,
    labelCell,
    validationMessage,
    labelPosition,
    labelWrapperClassName,
    required,
    spaceBetweenSize,
  } = props;
  const c = useStyles({ spaceBetweenSize, inputWidthInPercents });

  let styledByPosition = '';
  let indentStyle = '';

  switch (labelPosition) {
    case LabelPosition.Left: {
      styledByPosition = c.inputElemsInRow;
      indentStyle = c.inputLabelOnLeft;
      break;
    }
    case LabelPosition.TopLeft: {
      styledByPosition = c.inputElemsInColumnSlipLeft;
      indentStyle = c.inputLabelOnAbove;
      break;
    }
    case LabelPosition.TopCenter: {
      styledByPosition = c.inputElemsInColumnCentered;
      indentStyle = c.inputLabelOnAbove;
      break;
    }
    case LabelPosition.TopRight: {
      styledByPosition = c.inputElemsInColumnSlipRight;
      indentStyle = c.inputLabelOnAbove;
      break;
    }
    default: {
      styledByPosition = c.inputElemsInRow;
      indentStyle = c.inputLabelOnLeft;
    }
  }

  const label = (
    <div className={labelWrapperClassName || indentStyle}>
      {labelCell} {required && <span className={c.requiredSign}>*</span>}
    </div>
  );

  return (
    <div className={className ? clsx(styledByPosition, className) : styledByPosition}>
      {labelPosition !== LabelPosition.Right && label}
      <div className={c.inputWrapper}>
        <div className={c.controlWrapper}>
          {validationMessage && <H5Text className={c.validationNotice} text={validationMessage} />}
          {inputCell}
        </div>
      </div>
      {labelPosition === LabelPosition.Right && label}
    </div>
  );
};

export default InputWrapper;
