import { makeStyles } from '@material-ui/core';
import {
  bgColors,
  borderRadius,
  colors,
  indents,
  inputBorderRadius,
  smallestBorderRadius,
  typoColors
} from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  displayAndOptionsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    color: 'white',
    height: '90%',
    width: '100%',
    minWidth: 'fit-content',
    minHeight: 'fit-content',
  },
  displayWrapper: {
    border: `solid ${bgColors.deviceBorders} 2px`,
    borderRadius: smallestBorderRadius,
    position: 'relative',
    color: 'white',
    height: '100%',
    width: '90%',
  },
  sampleAndToolsWrapper: {
    width: '60%',
  },
  deviceMenuWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    color: 'white',
    height: '10%',
    width: '100%',
    minWidth: 'fit-content',
  },
  deviceMenuItem: {
    margin: indents.eight,
    color: typoColors.bodyGrey,
    borderRadius: borderRadius,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  activeMenuItem: {
    backgroundColor: bgColors.deviceBorders,
  },
  dragWrapper: {
    cursor: 'grab',
    position: 'absolute',
    zIndex: 13,
  },
  studyInfoWrapper: {
    minWidth: 414,
    width: 632,
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: colors.primary.mainBlue,
    borderRadius: inputBorderRadius,
    padding: indents.four,
  },
  deviceEnvWrapper: {
    height: 'calc(100% - 48px)',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  deviceAndSampleWrapper: {
    display: 'flex',
    padding: indents.four,
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      height: '65%',
    },
    [theme.breakpoints.up('lg')]: {
      height: '70%',
    },
    minHeight: 'fit-content',
  },
  deviceWrapper: {
    minWidth: 'fit-content',
    minHeight: 'fit-content',
    position: 'relative',
    borderRadius: borderRadius,
    backgroundColor: bgColors.black,
    paddingLeft: indents.sixteen,
    paddingTop: indents.sixteen,
    paddingBottom: indents.eight,
    marginRight: indents.four,
    display: 'flex',
    flexDirection: 'column',
    border: 'solid grey 1px',
    height: '100%',
    width: '40%',
  },
  deviceCanvas: {
    borderRadius: borderRadius,
    position: 'absolute',
    left: '0px',
    top: '0px',
    height: '100%',
    width: '100%',
  },
  measurementTable: {
    position: 'relative',
    marginTop: indents.four,
    marginBottom: indents.four,
    [theme.breakpoints.down('lg')]: {
      height: '30%',
      maxHeight: '30%',
    },
    [theme.breakpoints.up('lg')]: {
      height: '25%',
      maxHeight: '25%',
    },
  },
  tableTitleRow: {
    paddingLeft: indents.sixteen,
    display: 'flex',
    alignItems: 'center',
    height: 'fit-content',
  },
  addRowBtn: {
    margin: indents.eight,
    width: 30,
    height: 30,
    color: colors.primary.mainBlue,
  },
}));

export default useStyles;
