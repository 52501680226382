import React from 'react';

import clsx from 'clsx';

import { Button } from '@material-ui/core';

import { H5Text } from '../../textWrappers';
import { Spinner } from '../../Spinner';

import { SizeSpinner } from '../../Spinner';
import { SizeButton, TypeButton, VariantButton } from './types';

import useStyles from './styles';

interface IProps {
  buttonClassName?: string;
  captionClassName?: string;
  color?: string;
  caption?: string;
  captionRender?: JSX.Element;
  disabled?: boolean;
  disableRipple?: boolean;
  endIcon?: JSX.Element;
  isPending?: boolean;
  startIcon?: JSX.Element;
  size?: SizeButton;
  type?: TypeButton;

  onClick(): void;
}

const BaseButton = (props: IProps) => {
  const {
    buttonClassName,
    captionClassName,
    caption,
    captionRender,
    color,
    disabled,
    disableRipple,
    endIcon,
    isPending,
    startIcon,
    size,
    type,
    onClick,
  } = props;

  const c = useStyles({ color });

  let classNameByType = '';
  let classNameBySize = '';

  let variant = VariantButton.Contained;

  switch (type) {
    case TypeButton.Primary: {
      classNameByType = c.primaryButton;
      variant = VariantButton.Contained;
      break;
    }

    case TypeButton.Secondary: {
      classNameByType = c.secondaryButton;
      variant = VariantButton.Outlined;
      break;
    }

    default: {
      variant = VariantButton.Outlined;
    }
  }

  switch (size) {
    case SizeButton.Sm: {
      classNameBySize = c.smButtonClass;
      break;
    }

    case SizeButton.Md: {
      classNameBySize = c.mdButtonClass;
      break;
    }

    case SizeButton.Lg: {
      classNameBySize = c.lgButtonClass;
      break;
    }
  }

  return (
    <Button
      startIcon={startIcon}
      endIcon={endIcon}
      focusVisibleClassName={c.focusedButton}
      className={
        buttonClassName
          ? clsx(classNameBySize, buttonClassName, classNameByType)
          : clsx(classNameBySize, classNameByType)
      }
      disabled={disabled}
      disableRipple={disableRipple !== false}
      variant={variant}
      onClick={onClick}>
      {captionRender ? (
        captionRender
      ) : (
        <>
          <Spinner size={SizeSpinner.Md} isLoading={isPending || false} />
          <H5Text
            noWrap={true}
            className={
              captionClassName || disabled
                ? c.disabledButtonCaption
                : type === TypeButton.Primary
                ? c.primaryCaption
                : c.secondaryCaption
            }
            text={caption}
          />
        </>
      )}
    </Button>
  );
};

export default BaseButton;
