import {ICourse} from 'Shared/Interfaces/App';
import {
  AdmissibilityLevels,
  CourseModulesIds,
  CoursesId,
  ModuleProgress,
  OptionsTabCode,
  RayLength,
  SensorMode,
  StudyFocusEl,
  VRHPointIndex,
} from 'Shared/Enums';

import {
  calibrateLevelsDefectData,
  sampleDefectCO2,
  sampleDefectCO3,
  sampleDefectStudy,
} from 'Shared/Constants/Simulation';

import {IFlawStudyCheckParams} from 'Shared/Interfaces/App/IFlawStudyParams';

export const flawDetection: ICourse = {
  code: CoursesId.FlawDetection,
  modules: [
    {
      id: CourseModulesIds.FlawIntroduce,
      name: 'Интерфейс дефектоскопа ',
      description: `Данный модуль позволит Вам ознакомиться с интерфейсом дефектоскопа.
                    Ориентировочное время прохождения модуля 30 минут`,
      welcomeText: 'Основной задачей прохождения данного модуля является ознакомление с устройством дефектоскопа.',
      successText: 'Вы успешно прошли модуль “Интерфейс дефектоскопа”.',
      state: ModuleProgress.Current,
      steps: [
        {
          id: 1,
          name: '',
          title: 'Дефектоскоп',
          description: `Дефектоскоп ультразвуковой общего назначения предназначен для: </br>
          <ul>
            <li>
            контроля продукции на наличие дефектов типа нарушения сплошности и однородности материалов готовых изделий, полуфабрикатов и сварных (паяных) соединений;
            </li>
            <li>
            измерения глубины и координат залегания дефектов;
            </li>
            <li>
            измерения толщины контролируемых изделий.
            Дефектоскоп реализует эхо-импульсный, теневой и зеркально-теневой методы неразрушающего контроля с ультразвуковыми преобразователями на различные частоты.
            </li>
          </ul>`,
          imageUrl: 'images/flawDetector/flaw-edu-step-1.png',
          isCompleted: false,
          cardStartPosition: { x: 50, y: 220 },
        },
        {
          id: 2,
          name: 'Передняя панель',
          title: 'Кнопка "Заморозка"/"Огибающая"',
          description: `Кнопка включения статической заморозки экрана для детального изучения найденного эхо-сигнала.
            <br/>/<br/>
            Вход в режим  вывода огибающей пика сигнала (если режим  огибающей выбран в дополнительном меню)`,
          imageUrl: 'images/flawDetector/flaw-edu-step-2.png',
          isCompleted: false,
        },
        {
          id: 3,
          name: 'Передняя панель',
          title: 'Кнопка "Режим “а-Масштаб"',
          description:
            'Увеличение содержимого а-зоны до полного размера окна А-скана. Уровень увеличения масштаба зависит от  ширины а-зоны (т.е. когда ширина зоны контроля  равна 100% развертки экрана реального  увеличения не происходит).',
          imageUrl: 'images/flawDetector/flaw-edu-step-3.png',
          isCompleted: false,
        },
        {
          id: 4,
          name: 'Передняя панель',
          title: 'Кнопка "Сохранение результата"',
          description: `Результаты измерения могут быть сохранены в виде базы данных протоколов измерений. При сохранении результата, автоматически сохраняется полный протокол контроля, т.е. это сам результат, текущий А-скан (включая огибающую, если она есть), все параметры настройки, дата и время сохранения протокола.`,
          imageUrl: 'images/flawDetector/flaw-edu-step-4.png',
          isCompleted: false,
        },
        {
          id: 5,
          name: 'Передняя панель',
          title: 'Кнопка "Вкл/Выкл"',
          description: `Кнопка включения/выключения дефектоскопа. Кнопку необходимо нажать и удерживать в течение    3-х секунд.`,
          imageUrl: 'images/flawDetector/flaw-edu-step-5.png',
          isCompleted: false,
        },
        {
          id: 6,
          name: 'Передняя панель',
          title: 'Кнопки "Влево" и "Вправо"',
          description: `Кнопки перемещения по меню дефектоскопа.`,
          imageUrl: 'images/flawDetector/flaw-edu-step-6.png',
          isCompleted: false,
        },
        {
          id: 7,
          name: 'Передняя панель',
          title: 'Кнопка "Вход в дополнительное меню"',
          description: `Вход в дополнительное меню дефектоскопа для установки даты, времени, выбора частоты посылок, цвета экрана и пр. Также используется для смены шага изменения усиления.`,
          imageUrl: 'images/flawDetector/flaw-edu-step-7.png',
          isCompleted: false,
        },
        {
          id: 8,
          name: 'Передняя панель',
          title: 'Кнопки "Вверх" и "Вниз"',
          description: `Выбор параметра в правой части экрана и изменение его значения.`,
          imageUrl: 'images/flawDetector/flaw-edu-step-8.png',
          isCompleted: false,
        },
        {
          id: 9,
          name: 'Передняя панель',
          title: 'Кнопка "Выбор функции"',
          description: `Вход (выход) в режим изменения параметра.`,
          imageUrl: 'images/flawDetector/flaw-edu-step-9.png',
          isCompleted: false,
        },
        {
          id: 10,
          name: 'Передняя панель',
          title: 'Светодиодные индикаторы АСД',
          description: `При появлении эхо-сигнала в а-Зоне или б-Зоне срабатывает автоматическая сигнализация дефектов (АСД). При срабатывании АСД загорается светодиодный индикатор соответствующей зоны.`,
          imageUrl: 'images/flawDetector/flaw-edu-step-10.png',
          isCompleted: false,
        },
        {
          id: 11,
          name: 'Передняя панель',
          title: 'Экран',
          description: `Зона отображения А-скана, меню, результатов измерения и ряда специальных графических символов.`,
          imageUrl: 'images/flawDetector/flaw-edu-step-11.png',
          isCompleted: false,
        },
        {
          id: 12,
          name: 'Передняя панель',
          title: 'Меню',
          description: `Используется для перехода между основными блоками настроек прибора, например, АСД, Генератор, Датчик и пр.`,
          imageUrl: 'images/flawDetector/flaw-edu-step-12.png',
          isCompleted: false,
        },
        {
          id: 13,
          name: 'Передняя панель',
          title: 'Подменю',
          description: `Используется для настройки прибора перед контролем, в т.ч. изменения характеристик генератора, усилителя, установки зон контроля, системы АСД и пр.`,
          imageUrl: 'images/flawDetector/flaw-edu-step-13.png',
          isCompleted: false,
        },
        {
          id: 14,
          name: 'Передняя панель',
          title: 'Область отображения А-скана',
          description: `А-скан - это представление ультразвуковых сигналов, в котором по оси абсцисс (оси X) откладывается время или расстояние по лучу, а по оси ординат (оси Y) - амплитуда сигнала.`,
          imageUrl: 'images/flawDetector/flaw-edu-step-14.png',
          isCompleted: false,
        },
        {
          id: 15,
          name: 'Верхняя панель',
          title: 'Разъёмы подключения преобразователей',
          description: `Разъёмы, позволяющие подключить совмещённые или раздельно-совмещённые преобразователи.`,
          imageUrl: 'images/flawDetector/flaw-edu-step-15.png',
          isCompleted: false,
        },
        {
          id: 16,
          name: 'Верхняя панель',
          title: 'Разъём VGA-USB',
          description: `Разъём подключения USB-кабеля для связи с ПК`,
          imageUrl: 'images/flawDetector/flaw-edu-step-16.png',
          isCompleted: false,
        },
        {
          id: 17,
          name: 'Верхняя панель',
          title: 'Разъём питания',
          description: `Подключение блока питания для работы от сети 220V`,
          imageUrl: 'images/flawDetector/flaw-edu-step-17.png',
          isCompleted: false,
        },
      ],
    },
    {
      id: CourseModulesIds.FlawWorkWithTool,
      name: 'Работа с дефектоскопом',
      description: 'Данный модуль позволит Вам ознакомиться с процедурами проверки и настройки дефектоскопа, а также порядком проведения ультразвукового контроля сварного соединения. Ориентировочное время прохождения модуля 120 минут.',
      welcomeText: `
        Основными задачами прохождения данного модуля являются ознакомление с параметрами настройки дефектоскопа, отработка навыков настройки дефектоскопа и проведение ультразвукового контроля образцов.
        
        Для одного образца: 
        1. Выполнить ультразвуковой контроль (УЗК).
        2. Оформить таблицу по результатам контроля.
        3. Сравнить результаты с требованиями нормативных документов (НД).
   
        Экзаменационный образец ЭО-УЗК-1-14      
        Тип образца: сегмент трубы d=1200 мм    
        Марка материала: сталь 09Г2С      
        Размеры образца: 200х300х14 мм      
        Шероховатость: не хуже Rz40      
        Способ сварки, разделка кромок: РД, С17
   
        НД на технологию контроля: ГОСТ Р 55724-2013       
        НД на нормы оценки: СТО Газпром 2-2.4-083-2006
             
        <i>При необходимости в разъяснении вопросов по нормативным документам обращайтесь к методисту</i>
        `,
      successText: 'Вы успешно прошли урок интерактивной работы с дефектоскопом',
      state: ModuleProgress.Locked,
      steps: [
        {
          id: 1,
          name: 'Работа с дефектоскопом / Шаг № 1.  Выбор преобразователя',
          title:
            'Для начала работы необходимо правильно выбрать датчик и подключить его к дефектоскопу. Тип датчика и его параметры определяются нормативной документацией. Для стыкового сварного соединения сегмента трубы диаметром 1200 мм и толщиной стенки 14 мм в соответствии с СТО Газпром 2-2.4-083-2006 необходимо выбрать преобразователь П121-2,5-65',
          description: 'Нажмите на кнопку и выберите преобразователь П121-2,5-65.',
          isCompleted: false,
          currentFocusIds: [StudyFocusEl.Sensor],
          cardStartPosition: { x: 50, y: 220 },
        },
        {
          id: 2,
          name: 'Работа с дефектоскопом / Шаг №2. Установка угла ввода',
          title:
            'Для правильного определения координат дефектов необходимо задать реальный угол ввода ультразвуковых колебаний в объект контроля. Изначально угол ввода устанавливается по выбранному преобразователю и определяется нормативной документацией, а в дальнейшем он может корректироваться по итогам настройки на образце СО-2.',
          description: 'Задайте значение угла ввода равное 65 градусам',
          isCompleted: false,
          currentFocusIds: [StudyFocusEl.DeviceSensorTab, StudyFocusEl.InputAngle],
          optionTabCode: OptionsTabCode.sensor,
          cardStartPosition: { x: 0, y: 348 },
        },
        {
          id: 3,
          name: 'Работа с дефектоскопом / Шаг №3. Выбор режима работы дефектоскопа',
          title:
            'Для правильной работы дефектоскопа необходимо установить режим работы, соответствующий выбранному преобразователю. Для преобразователя с одним пьезоэлементом (совмещённых) в дефектоскопе устанавливается режим “Совмещённый”. В случае, когда в преобразователе два пьезоэлемента - режим “Раздельный”.',
          description: 'Установите режим “Совмещённый”.',
          isCompleted: false,
          currentFocusIds: [StudyFocusEl.DeviceSensorTab, StudyFocusEl.Mode],
          optionTabCode: OptionsTabCode.sensor,
          cardStartPosition: { x: 0, y: 250 },
        },
        {
          id: 4,
          name: 'Работа с дефектоскопом / Шаг №4. Установка частоты',
          title:
            'Для правильной работы дефектоскопа необходимо установить значение частоты выбранного преобразователя. Данный параметр определяется нормативной документацией.',
          description: 'Задайте значение частоты равное 2,5 МГц',
          isCompleted: false,
          currentFocusIds: [StudyFocusEl.DeviceSensorTab, StudyFocusEl.Frequency],
          optionTabCode: OptionsTabCode.sensor,
        },
        {
          id: 5,
          name: 'Работа с дефектоскопом / Шаг №5.1 Определение точки выхода луча',
          cardStartPosition: { x: 0, y: 348 },
          title:
            'Точка выхода луча в каждом преобразователе определена производителем и обозначена на боковой поверхности преобразователя. Перед проведением контроля требуется уточнить расположение этой точки на выбранном преобразователе и при необходимости скорректировать это расположение. Корректировка осуществляется удалением старого обозначения точки выхода луча и нанесением нового обозначения после проведения калибровки на СО-3.',
          description: 'Нажмите на кнопку и выберите из списка СО-3.',
          isCompleted: false,
          currentFocusIds: [StudyFocusEl.Sample],
        },
        {
          id: 6,
          name: 'Работа с дефектоскопом / Шаг №5.2 Определение точки выхода луча',
          title:
            'Для отображения на экране отражённого сигнала необходимо установить значение развёртки большее, чем расстояние по лучу от точки выхода до отражающей поверхности. Это значение должно быть на 20-40% больше от значения расстояния по лучу.',
          description: 'Задайте значение развёртки равное 100 мм',
          isCompleted: false,
          currentFocusIds: [StudyFocusEl.Scan],
        },
        {
          id: 7,
          name: 'Работа с дефектоскопом / Шаг №5.3 Определение точки выхода луча',
          title:
            'Для создания акустического контакта необходимо нанесение контактной жидкости на СО-3. Если контактная жидкость не нанесена или её недостаточно, то  на экране будет отсутствовать отражённый сигнал или амплитуда этого сигнала будет занижена. В качестве контактной жидкости могут использоваться глицерин, трансформаторное масло, вода, гель для ультразвуковой дефектоскопии.',
          description: 'Переместите иконку контактной жидкости на образец',
          isCompleted: false,
          currentFocusIds: [StudyFocusEl.Liquid, StudyFocusEl.SampleSurfaceSegment],
          freeFocusPosition: [{ left: '0%', top: '00%', width: '100%', height: '36%' }],
          cardStartPosition: { x: 0, y: 348 },
        },
        {
          id: 8,
          name: 'Работа с дефектоскопом / Шаг №5.4 Определение точки выхода луча',
          title:
            'Ввод ультразвуковых колебаний и получение отражённого сигнала осуществляется путём установки и перемещения ультразвукового преобразователя по поверхности образца.',
          description: 'Переместите иконку преобразователя на образец',
          isCompleted: false,
          currentFocusIds: [StudyFocusEl.SensorImage, StudyFocusEl.SampleSurfaceSegment],
          freeFocusPosition: [{ left: '0%', top: '0%', width: '100%', height: '36%' }],
          cardStartPosition: { x: 0, y: 550 },
        },
        {
          id: 9,
          name: 'Работа с дефектоскопом / Шаг №5.5 Определение точки выхода луча',
          title:
            'На боковой поверхности преобразователя производителем наносится метка, обозначающая точку выхода ультразвукового (УЗ) луча. По мере использования преобразователя и его физического износа точка выхода может смещаться. Смещение отметки точки выхода обычно не превышает 5 мм, поэтому преобразователь устанавливают таким образом, чтобы отметка точки выхода находилась в диапазоне от -5 до +5 мм относительно ноля по шкале образца.',
          description:
            'Поставьте преобразователь таким образом, чтобы отметка точки выхода луча преобразователя находилась в районе ноля на шкале образца',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.SampleSurfaceSegment],
          freeFocusPosition: [{ left: '46%', top: '00%', width: '10%', height: '55%' }],
        },
        {
          id: 10,
          name: 'Работа с дефектоскопом / Шаг №5.6 Определение точки выхода луча',
          title:
            'Максимальный отражённый сигнал наблюдается при вводе ультразвуковых колебаний в точку, обозначенную нолём на шкале образца. Но эта точка необязательно будет совпадать с точкой выхода луча на преобразователе по причине износа контактной поверхности датчика. Поэтому необходимо перемещать датчик по поверхности образца до получения максимального уровня отражённого сигнала.',
          description: 'Перемещайте датчик влево и вправо по образцу, чтобы найти максимальный отражённый сигнал',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.SampleSurfaceSegment],
          freeFocusPosition: [{ left: '46%', top: '00%', width: '10%', height: '35%' }],
        },
        {
          id: 11,
          name: 'Работа с дефектоскопом / Шаг №5.7 Определение точки выхода луча',
          title:
            'При работе с дефектоскопом необходимо, чтобы пик отражённого сигнала постоянно находился в верхней трети экрана. Для этого используется настройка значения Усиление в любом меню дефектоскопа.',
          description:
            'Задайте значение усиления таким образом, чтобы на экране пик отражённого сигнала был виден полностью и находился в верхней трети экрана.',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.Gain],
        },
        {
          id: 12,
          name: 'Работа с дефектоскопом / Шаг №5.8 Определение точки выхода луча',
          title:
            'После получения максимального уровня отражённого сигнала проверяется расположение отметки выхода луча датчика относительно отметки ноля на образце. В случае расхождения отметок необходимо удалить старую отметку и нанести новую отметку выхода луча датчика так, чтобы она находилась напротив отметки ноля на образце. Если расположение отметок совпадает, то точка выхода луча нанесена корректно.',
          description:
            'Проверьте расположение отметки выхода луча относительно отметки ноля на образце.\n' + 'Нажмите “Далее”.',
          isCompleted: false,
          isDone: true,
          currentFocusIds: [StudyFocusEl.SampleSurfaceSegment],
          freeFocusPosition: [{ left: '46%', top: '35%', width: '10%', height: '20%' }],
        },
        {
          id: 13,
          name: 'Работа с дефектоскопом / Шаг №6.1 Определение угла ввода луча',
          title:
            'Угол ввода луча в каждом преобразователе определен производителем и обозначен на маркировке преобразователя. Перед проведением контроля требуется уточнить угол ввода на выбранном преобразователе и при необходимости скорректировать его значение, вносимое в прибор. Корректировка осуществляется путём проверки значения угла ввода на СО-2.',
          description: 'Нажмите на кнопку и выберите из списка СО-2.',
          isCompleted: false,
          currentFocusIds: [StudyFocusEl.Sample],
        },
        {
          id: 14,
          name: 'Работа с дефектоскопом / Шаг №6.2 Определение угла ввода луча',
          title:
            'Так как предполагаемое расстояние до отражающей поверхности в СО-2 больше, чем в СО-3, то для отображения эхо-сигнала необходимо увеличить длину развёртки.',
          description: 'Задайте значение развёртки равное 140 мм.',
          isCompleted: false,
          currentFocusIds: [StudyFocusEl.Scan],
        },
        {
          id: 15,
          name: 'Работа с дефектоскопом / Шаг №6.3 Определение угла ввода луча',
          title: 'Для создания акустического контакта необходимо нанесение контактной жидкости на СО-2.',
          description: 'Переместите иконку контактной жидкости на рабочую поверхность образца',
          isCompleted: false,
          currentFocusIds: [StudyFocusEl.Liquid, StudyFocusEl.SampleSurface],
        },
        {
          id: 16,
          name: 'Работа с дефектоскопом / Шаг №6.4 Определение угла ввода луча',
          title:
            'Ввод ультразвуковых колебаний и получение отражённого сигнала осуществляется путём установки и перемещения ультразвукового преобразователя на образце. При этом ультразвуковой луч должен быть направлен в сторону предполагаемого отражателя. В данном образце СО-2 роль отражателя играет боковое цилиндрическое отверстие диаметром 6 мм.',
          description:
            'Переместите иконку преобразователя на образец. Чтобы развернуть преобразователь, нажмите на кнопку «Преобразователь»',
          isCompleted: false,
          currentFocusIds: [StudyFocusEl.SensorImage, StudyFocusEl.SampleSurface, StudyFocusEl.SampleSurfaceSegment, StudyFocusEl.DeviceAZoneTab, StudyFocusEl.DeviceSensorTab, StudyFocusEl.AZone],
          freeFocusPosition: [{ left: '21%', top: '75%', width: '7%', height: '12%' }],
        },
        {
          id: 17,
          name: 'Работа с дефектоскопом / Шаг №6.5 Определение угла ввода луча',
          title:
            'Так как изменение угла ввода обычно не превышает 3 градуса, то преобразователь устанавливают таким образом, чтобы отметка точки выхода находилась в диапазоне от -3 до +3 градусов относительно значения угла ввода выбранного датчика на шкале образца.',
          description:
            'Поставьте преобразователь таким образом, чтобы отметка точки выхода луча находилась в районе предполагаемого значения угла ввода',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.SensorImage, StudyFocusEl.SampleSurfaceSegment, StudyFocusEl.DeviceAZoneTab,  StudyFocusEl.DeviceSensorTab, StudyFocusEl.AZone],
          freeFocusPosition: [{ left: '65%', top: '0%', width: '8%', height: '58%' }],
        },
        {
          id: 18,
          name: 'Работа с дефектоскопом / Шаг №6.6 Определение угла ввода луча',
          title:
            'Максимальный отражённый сигнал от отверстия будет наблюдаться при вводе ультразвуковых колебаний в точку, обозначенную на шкале СО-2 и совпадающую с реальным углом ввода преобразователя. Но эта точка необязательно будет совпадать с номинальным углом ввода преобразователя по причине износа контактной поверхности датчика. Поэтому необходимо перемещать преобразователь по поверхности образца до получения максимального уровня отражённого сигнала. Если пик эхо-сигнала не находится в верхней трети экрана прибора, то необходимо скорректировать усиление. ',
          description:
            'Перемещайте преобразователь влево и вправо по образцу, чтобы найти максимальный эхо-сигнал. Отрегулируйте усиление.',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.SampleSurfaceSegment, StudyFocusEl.Gain, StudyFocusEl.DeviceAZoneTab, StudyFocusEl.DeviceSensorTab, StudyFocusEl.AZone],
          freeFocusPosition: [{ left: '65%', top: '0%', width: '8%', height: '32%' }],
        },
        {
          id: 19,
          name: 'Работа с дефектоскопом / Шаг №6.7 Определение угла ввода луча',
          title:
            'Значение угла ввода на шкале образца, находящееся напротив отметки точки выхода луча преобразователя, - это фактический угол ввода. Если значение этого угла отличается от значения, введённого ранее в дефектоскоп, в прибор необходимо внести значение фактического угла ввода. Если же значения совпадают, то корректировка параметра в дефектоскопе не требуется.',
          description: 'Проверьте значение фактического угла ввода и угла ввода преобразователя.\n' + 'Нажмите “Далее”',
          isCompleted: false,
          isDone: true,
          currentFocusIds: [StudyFocusEl.SampleSurfaceSegment, StudyFocusEl.DeviceAZoneTab, StudyFocusEl.DeviceSensorTab, StudyFocusEl.AZone],
          freeFocusPosition: [{ left: '65%', top: '33%', width: '8%', height: '20%' }],
        },
        {
          id: 20,
          name: 'Работа с дефектоскопом / Шаг №7. Определение мёртвой зоны',
          title:
            'У каждого преобразователя существует область, когда эхо-сигнал от дефекта попадает в зону зондирующего импульса. Данную область называют мёртвой зоной. Размер этой зоны определяется на образце СО-2 с помощью боковых цилиндрических отверстий диаметром 2 мм, расположенных на глубинах 3 и 8 мм. Максимальное значение мёртвой зоны определяется нормативной документацией. При превышении этого максимального значения преобразователь подлежит утилизации.',
          description: 'Нажмите “Далее”',
          isCompleted: false,
          isDone: true,
          currentFocusIds: [StudyFocusEl.SampleSurfaceSegment, StudyFocusEl.DeviceAZoneTab, StudyFocusEl.DeviceSensorTab, StudyFocusEl.AZone],
          freeFocusPosition: [
            { left: '86%', top: '38%', width: '4%', height: '10%' },
            { left: '86%', top: '88%', width: '4%', height: '10%' },
          ],
        },
        {
          id: 21,
          name: 'Работа с дефектоскопом / Шаг №8.1 Определение значения задержки в призме преобразователя',
          title:
            'Время прохождения ультразвуковых колебаний в преобразователе называется задержкой в призме (протекторе). При определении реальных координат дефектов прибором данная задержка отнимается от общего времени прохождения эхо-сигнала. Настройка значения протектора выполняется на образце СО-3 и далее вносится в дефектоскоп.',
          description: 'Нажмите на кнопку и выберите из списка СО-3',
          isCompleted: false,
          currentFocusIds: [StudyFocusEl.Sample],
        },
        {
          id: 22,
          name: 'Работа с дефектоскопом / Шаг №8.2 Определение значения задержки в призме преобразователя',
          title: 'Для создания акустического контакта необходимо нанесение контактной жидкости на СО-3.',
          description: 'Переместите иконку контактной жидкости на образец',
          isCompleted: false,
          currentFocusIds: [StudyFocusEl.Liquid, StudyFocusEl.SampleSurface],
        },
        {
          id: 23,
          name: 'Работа с дефектоскопом / Шаг №8.3 Определение значения задержки в призме преобразователя',
          title:
            'Чтобы на экране отображались все нужные эхо-сигналы, развёртка должна быть больше, чем предполагаемое расстояние до второго эхо-сигнала.',
          description: 'Установите значение развёртки равное 200 мм',
          isCompleted: false,
          currentFocusIds: [StudyFocusEl.Scan],
        },
        {
          id: 24,
          name: 'Работа с дефектоскопом / Шаг №8.4 Определение значения задержки в призме преобразователя',
          title:
            'Для правильного отображения расстояния по лучу необходимо установить строб-импульс в предполагаемую область появления первого эхо-сигнала.',
          description: 'Установите а-Начало = 50 мм,  а-Ширина = 20 мм, а-Порог = 50%',
          isCompleted: false,
          currentFocusIds: [StudyFocusEl.DeviceAZoneTab, StudyFocusEl.AZone],
          optionTabCode: OptionsTabCode.aZone,
        },
        {
          id: 25,
          name: 'Работа с дефектоскопом / Шаг №8.5 Определение значения задержки в призме преобразователя',
          title:
            'Для правильного отображения расстояния по лучу необходимо установить строб-импульс в предполагаемую область появления второго эхо-сигнала.',
          description: 'Установите б-Начало = 160 мм, б-Ширина = 20 мм, б-Порог = 30%',
          isCompleted: false,
          currentFocusIds: [StudyFocusEl.DeviceBZoneTab, StudyFocusEl.BZone],
          optionTabCode: OptionsTabCode.bZone,
        },
        {
          id: 26,
          name: 'Работа с дефектоскопом / Шаг №8.6 Определение значения задержки в призме преобразователя',
          title:
            'Отображение правильных координат отражающей поверхности возможно только при максимальном эхо-сигнале. Если пик эхо-сигнала не находится в верхней трети экрана прибора, то необходимо скорректировать усиление.',
          description:
            'Установите датчик на образец и перемещайте его влево и вправо по образцу, чтобы найти максимальный пик. Отрегулируйте Усиление.',
          isCompleted: false,
          isDone: true,
          currentFocusIds: [StudyFocusEl.SensorImage, StudyFocusEl.Gain, StudyFocusEl.SampleSurface],
          optionTabCode: OptionsTabCode.bZone,
        },
        {
          id: 27,
          name: 'Работа с дефектоскопом / Шаг №8.7 Определение значения задержки в призме преобразователя',
          title:
            'Для измерения расстояния между первым и вторым эхо-сигналами прибор переключают в режим измерения "а-б зона". Между первым и вторым отражением эхо-сигналов УЗ колебания проходят путь равный двум радиусам образца СО-3. И это значение отображается на экране дефектоскопа в строке результатов S[a-b].',
          description:
            'Переключите дефектоскоп в режим измерения Sа-б и зафиксируйте значение радиуса СО-3 равное половине S[a-b]',
          isCompleted: false,
          currentFocusIds: [StudyFocusEl.DeviceCommonTab, StudyFocusEl.RayLengthMode],
          optionTabCode: OptionsTabCode.common,
        },
        {
          id: 28,
          name: 'Работа с дефектоскопом / Шаг №8.8 Определение значения задержки в призме преобразователя',
          title:
            'Для измерения расстояния между зондирующим импульсом и первым эхо-сигналом прибор переключают в режим измерения "0-а зона". При правильной установке значения протектора в дефектоскопе значение S[0-a] должно быть равно половине S[a-b]. Если S и S[a-b]/2 не равны, то значение протектора необходимо откорректировать.',
          description:
            'Переключите дефектоскоп в режим измерения S0-а и сравните значения S[0-а] и S[a-b]/2. При необходимости откорректируйте значение протектора в меню "Датчик". \n' +
            'Нажмите "Далее"',
          isCompleted: false,
          currentFocusIds: [
            StudyFocusEl.DeviceCommonTab,
            StudyFocusEl.DeviceSensorTab,
            StudyFocusEl.Protector,
            StudyFocusEl.RayLengthMode,
          ],
          optionTabCode: OptionsTabCode.common,
        },
        {
          id: 29,
          name: 'Работа с дефектоскопом / Шаг №9.1 Настройка браковочного уровня',
          title:
            'Для настройки браковочного уровня необходимо правильно выбрать настроечный образец. Вид настроечного образца и его параметры определяются нормативной документацией. Для стыкового сварного соединения сегмента трубы диаметром 1200мм и толщиной стенки 14мм в соответствии с СТО Газпром 2-2.4-083-2006 необходимо выбрать настроечный образец толщиной 14мм с зарубкой размерами 2,0х2,0мм.',
          description: 'Нажмите на кнопку и выберите из списка настроечный образец НО-14-2,0х2,0',
          isCompleted: false,
          currentFocusIds: [StudyFocusEl.Sample],
          optionTabCode: OptionsTabCode.common,
        },
        {
          id: 30,
          name: 'Работа с дефектоскопом / Шаг №9.2 Настройка браковочного уровня',
          title: 'Для создания акустического контакта необходимо нанесение контактной жидкости на настроечный образец.',
          description: 'Переместите иконку контактной жидкости на образец',
          isCompleted: false,
          currentFocusIds: [StudyFocusEl.Liquid, StudyFocusEl.SampleSurfaceSegment],
          freeFocusPosition: [{ left: '28%', top: '6%', width: '47%', height: '78%' }],
          optionTabCode: OptionsTabCode.common,
        },
        {
          id: 31,
          name: 'Работа с дефектоскопом / Шаг №9.3 Настройка браковочного уровня',
          title:
            'Настройка развёртки проводится в соответствии с толщиной контролируемого изделия с тем, чтобы сигналы от отражателей на наименьшей возможной и наибольшей возможной толщинах отображались на экране. То есть развёртка должна быть больше, чем предполагаемое расстояние по лучу от точки ввода до второй зарубки.',
          description: 'Установите значение развертки равное 95 мм',
          isCompleted: false,
          currentFocusIds: [StudyFocusEl.Scan],
          optionTabCode: OptionsTabCode.sensor,
        },
        {
          id: 32,
          name: 'Работа с дефектоскопом / Шаг №9.4 Настройка браковочного уровня',
          title:
            'Отображение координат дефектов в приборе происходит только при появлении эхо-сигнала в зоне строба (а-зона и/или б-зона). При появлении эхо-сигнала в зоне стробов срабатывает автоматический сигнализатор дефектов (АСД) в виде световых и звуковых сигналов. Стробы устанавливаются по высоте, ширине и началу.  Обычно первый строб (а-зона) используется для установки браковочного уровня.',
          description: 'Установите а-Начало = 15 мм, а-Ширина = 75 мм, а-Порог = 50%',
          isCompleted: false,
          currentFocusIds: [StudyFocusEl.DeviceAZoneTab, StudyFocusEl.AZone],
          optionTabCode: OptionsTabCode.aZone,
        },
        {
          id: 33,
          name: 'Работа с дефектоскопом / Шаг №9.5 Настройка браковочного уровня',
          title:
            'На настроечных образцах обычно зарубки располагаются на обеих сторонах. Для настройки браковочного уровня прямым лучом используется зарубка, находящаяся на нижней стороне образца. В этом случае наклонный преобразователь необходимо располагать так, чтобы УЗ луч был направлен в предполагаемое место расположения зарубки. Зарубка считается обнаруженной, если при перемещении преобразователя на расстояние больше двойного размера зарубки эхо-сигнал от зарубки пропадает полностью. Если пик эхо-сигнала не находится в верхней трети экрана прибора, то необходимо скорректировать усиление.',
          description:
            'Установите преобразователь на настроечный образец. Перемещайте преобразователь по образцу, чтобы найти максимальный пик. Отрегулируйте Усиление.',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.SensorImage, StudyFocusEl.Gain, StudyFocusEl.SampleSurfaceSegment],
          freeFocusPosition: [{ left: '28%', top: '44%', width: '2%', height: '5%' }],
          optionTabCode: OptionsTabCode.sensor,
        },
        {
          id: 34,
          name: 'Работа с дефектоскопом / Шаг №9.6 Настройка браковочного уровня',
          title:
            'Значение У на экране дефектоскопа отражает значение глубины, на которой найден дефект. Проверьте значение У на дефектоскопе. Если У соответствует реальной глубине расположения зарубки (толщине образца), то глубиномер дефектоскопа настроен правильно. Возможная погрешность значения глубины составляет плюс-минус 5%.',
          description:
            'Сравните значения на экране дефектоскопа и значения толщины образца и убедитесь, что они совпадают. \n' +
            'Нажмите “Далее”',
          isCompleted: false,
          isDone: true,
          currentFocusIds: [StudyFocusEl.Y, StudyFocusEl.SampleSurfaceSegment],
          optionTabCode: OptionsTabCode.sensor,
          freeFocusPosition: [{ left: '28%', top: '44%', width: '2%', height: '5%' }],
        },
        {
          id: 35,
          name: 'Работа с дефектоскопом / Шаг №9.7 Настройка браковочного уровня',
          title:
            'Установка браковочного уровня проводится с помощью доведения максимума эхо-сигнала от зарубки до строба (а-зона). Эта регулировка выполняется путём настройки значения Усиление.',
          description: 'Установите значение Усиления таким образом, чтобы пик эхо-сигнала достигал строб (а-зона)',
          isCompleted: false,
          isDone: true,
          currentFocusIds: [StudyFocusEl.Gain, StudyFocusEl.SampleSurfaceSegment],
          optionTabCode: OptionsTabCode.aZone,
          freeFocusPosition: [{ left: '28%', top: '44%', width: '2%', height: '5%' }],
        },

        {
          id: 36,
          name: 'Работа с дефектоскопом / Шаг №9.8 Настройка браковочного уровня',
          title:
            'Помимо браковочного уровня существуют поисковый уровень и уровень фиксации. В некоторых случаях поисковый уровень и уровень фиксации совпадают, и тогда при проведении УЗ контроля используется поисковый уровень (б-зона), который обычно отличается от браковочного на минус 6 дБ. В случае пересечения эхо-сигналом поискового уровня срабатывает АСД, и оператор имеет возможность более детально исследовать найдённый дефект.',
          description: 'Установите б-Начало = 15 мм, б-Ширина = 75 мм, б-Порог = 25%',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.DeviceBZoneTab, StudyFocusEl.BZone, StudyFocusEl.SampleSurfaceSegment],
          optionTabCode: OptionsTabCode.bZone,
          freeFocusPosition: [{ left: '28%', top: '44%', width: '2%', height: '5%' }],
        },
        {
          id: 37,
          name: 'Работа с дефектоскопом / Шаг №10.1 Настройка ВРЧ',
          title:
            'При нахождении дефектов одного размера на разной глубине из-за затухания эхо-сигнал от дальнего дефекта всегда будет меньше, чем эхо-сигнал от ближнего дефекта. Для приведения разных эхо-сигналов к одному уровню в дефектоскопе предусмотрена функция временной регулировки чувствительности (ВРЧ). Функция ВРЧ описывается графиком и при её включении этот график появляется на экране.',
          description: 'Включите функцию ВРЧ',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.DeviceVRHTab, StudyFocusEl.VRHSwitcher, StudyFocusEl.SampleSurfaceSegment],
          optionTabCode: OptionsTabCode.VRH,
          freeFocusPosition: [{ left: '28%', top: '44%', width: '2%', height: '5%' }],
        },
        {
          id: 38,
          name: 'Работа с дефектоскопом / Шаг №10.2 Настройка ВРЧ',
          title:
            'Построение графика ВРЧ проводится по опорным точкам (минимум две точки). Обычно настройку опорных точек начинают с первой точки и располагают её в зоне эхо-сигнала от зарубки, найденной прямым лучом.',
          description: 'Выберите Точку 1',
          isCompleted: false,
          isDone: true,
          currentFocusIds: [
            StudyFocusEl.DeviceVRHTab,
            StudyFocusEl.VrhPointSelector,
            StudyFocusEl.SampleSurfaceSegment,
          ],
          optionTabCode: OptionsTabCode.VRH,
          freeFocusPosition: [{ left: '28%', top: '44%', width: '2%', height: '5%' }],
        },
        {
          id: 39,
          name: 'Работа с дефектоскопом / Шаг №10.3 Настройка ВРЧ',
          title:
            'Корректировку положения Точки 1 ВРЧ возможно производить как по положению (вдоль оси Х), так и по усилению (вдоль оси У). Точку 1 вдоль оси Х обычно устанавливают в зоне эхо-сигнала от зарубки, найденной прямым лучом. Уровень усиления Точки 1 устанавливают равным нулю.',
          description: 'Установите значение положения Точки 1 равное 33 мм,  значение усиления Точки 1 равное 0',
          isCompleted: false,
          isDone: true,
          currentFocusIds: [
            StudyFocusEl.DeviceVRHTab,
            StudyFocusEl.VrhPointGainAndPosition,
            StudyFocusEl.SampleSurfaceSegment,
          ],
          optionTabCode: OptionsTabCode.VRH,
          freeFocusPosition: [{ left: '28%', top: '44%', width: '2%', height: '5%' }],
        },
        {
          id: 40,
          name: 'Работа с дефектоскопом / Шаг №10.4 Настройка ВРЧ',
          title:
            'Для настройки браковочного уровня однократно отражённым лучом используется зарубка, находящаяся на верхней стороне образца. В этом случае наклонный преобразователь необходимо располагать так, чтобы УЗ луч был направлен в предполагаемое место расположения зарубки. Зарубка считается обнаруженной, если при перемещении преобразователя на расстояние больше двойного размера зарубки эхо-сигнал от зарубки пропадает полностью.',
          description: 'Разверните преобразователь и перемещайте его по образцу, чтобы найти максимальный пик',
          isCompleted: false,
          isDone: true,
          currentFocusIds: [StudyFocusEl.SensorImage, StudyFocusEl.SampleSurfaceSegment],
          optionTabCode: OptionsTabCode.VRH,
          freeFocusPosition: [{ left: '75%', top: '44%', width: '2%', height: '5%' }],
        },
        {
          id: 41,
          name: 'Работа с дефектоскопом / Шаг №10.5 Настройка ВРЧ',
          title:
            'Задание второй, третьей и т.д. точек позволит построить более точный график зависимости затухания от глубины расположения дефекта. В нашем случае для правильного отображения графика ВРЧ необходимо задать вторую опорную точку, которая будет находиться в зоне зарубки, обнаруженной однократно отражённым лучом.',
          description: 'Выберите Точку 2',
          isCompleted: false,
          currentFocusIds: [
            StudyFocusEl.DeviceVRHTab,
            StudyFocusEl.VrhPointSelector,
            StudyFocusEl.SampleSurfaceSegment,
          ],
          optionTabCode: OptionsTabCode.VRH,
          freeFocusPosition: [{ left: '75%', top: '44%', width: '2%', height: '5%' }],
        },
        {
          id: 42,
          name: 'Работа с дефектоскопом / Шаг №10.6 Настройка ВРЧ',
          title:
            'Корректировку положения Точки 2 ВРЧ возможно производить как по положению (вдоль оси Х), так и по усилению (вдоль оси У). Точку 2 вдоль оси Х обычно устанавливают в зоне эхо-сигнала от зарубки, найденной однократно отражённым лучом. Уровень усиления Точки 2 увеличивают до пересечения максимума эхо-сигнала со стробом (а-зона).',
          description: 'Установите значение положения Точки 2 равное 68 мм, значение усиления Точки 2 равное 8 дБ',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [
            StudyFocusEl.DeviceVRHTab,
            StudyFocusEl.VrhPointGainAndPosition,
            StudyFocusEl.SampleSurfaceSegment,
          ],
          optionTabCode: OptionsTabCode.VRH,
          freeFocusPosition: [{ left: '75%', top: '44%', width: '2%', height: '5%' }],
        },
        {
          id: 43,
          name: 'Работа с дефектоскопом / Шаг №11.1 Проведение УЗ контроля сварного шва',
          title:
            'В обучении используются образцы в виде сварных соединений пластин и труб разной толщины, имитирующие реальные объекты контроля.',
          description: 'Нажмите на кнопку и выберите из списка образец изделия ЭО-УЗК-1-14',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.Sample],
          optionTabCode: OptionsTabCode.VRH,
        },
        {
          id: 44,
          name: 'Работа с дефектоскопом / Шаг №11.2 Проведение УЗ контроля сварного шва',
          title:
            'Для корректного отображения глубины расположения дефектов необходимо задать в приборе реальную толщину образца.',
          description: 'Установите значение толщины равное 14 мм',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.DeviceCommonTab, StudyFocusEl.Thickness],
          optionTabCode: OptionsTabCode.common,
        },
        {
          id: 45,
          name: 'Работа с дефектоскопом / Шаг №11.3 Проведение УЗ контроля сварного шва',
          title:
            'Для создания акустического контакта необходимо нанесение контактной жидкости на контрольный образец. Контактную жидкость необходимо наносить на зону перемещения преобразователя, ширина которой рассчитывается исходя из толщины объекта контроля, угла наклона преобразователя и требований НД.',
          description: 'Переместите иконку контактной жидкости на контрольный образец',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.Liquid, StudyFocusEl.SampleSurfaceSegment],
          optionTabCode: OptionsTabCode.common,
          freeFocusPosition: [
            { left: '12%', top: '5%', width: '38%', height: '81%' },
            { left: '54%', top: '5%', width: '38%', height: '81%' },
          ],
        },
        {
          id: 46,
          name: 'Работа с дефектоскопом / Шаг №11.4 Проведение УЗ контроля сварного шва',
          title:
            'В строке результатов на экране дефектоскопа возможно вывести индикацию разницы значений уровня эхо-сигнала и браковочного уровня в дБ. Эта информация указывается в заключении по итогам проведённого УЗ контроля.',
          description: 'Установите вывод значения Н, дБ',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.DeviceCommonTab, StudyFocusEl.RayLengthMode],
          optionTabCode: OptionsTabCode.common,
        },
        {
          id: 47,
          name: 'Работа с дефектоскопом / Шаг №11.5 Проведение УЗ контроля сварного шва',
          title:
            'Поиск несплошностей в образце проводится с помощью продольно-поперечного сканирования наклонным преобразователем по поверхности образца вдоль сварного шва. УЗ луч должен быть направлен в сторону сварного шва. Перемещение преобразователя необходимо начинать со стороны ноля разметки. Сканирование проводится поочерёдно с обеих сторон сварного шва. Все найденные дефекты, превышающие поисковый уровень, фиксируются в заключении.',
          description:
            'Переместите преобразователь на выделенное место на образце таким образом, чтобы видеть максимальный пик.',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.SensorImage, StudyFocusEl.SampleSurfaceSegment],
          optionTabCode: OptionsTabCode.common,
          freeFocusPosition: [{ left: '35%', top: '69%', width: '10%', height: '10%' }],
        },
        {
          id: 48,
          name: 'Работа с дефектоскопом / Шаг №11.6 Проведение УЗ контроля сварного шва',
          title:
            'Отклонение амплитуды эхо-сигнала от браковочного уровня отображается на экране дефектоскопа: Н (дБ) и фиксируется в Заключении.',
          description: 'Внесите значение Н = 3,4 дБ в соответствующую ячейку таблицы',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.RayLengthMode, StudyFocusEl.LevelOver],
        },
        {
          id: 49,
          name: 'Работа с дефектоскопом / Шаг №11.7 Проведение УЗ контроля сварного шва',
          title:
            'Точка начала дефекта определяется при первом пересечении эхо-сигнала от несплошности и поискового уровня. Координата оY этой точки описывает расстояние вдоль сварного шва от ноля до начала дефекта и фиксируется в Заключении. Для каждой несплошности эта координата определяется путём измерения с помощью поверенного средства измерения (линейка, рулетка, т.д.).',
          description: 'Внесите значение оY = 15 в ячейку таблицы, чтобы зафиксировать расположение дефекта по оси оY',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.oYTableInput],
        },
        {
          id: 50,
          name: 'Работа с дефектоскопом / Шаг №11.8 Проведение УЗ контроля сварного шва',
          title: 'Координата У, мм на приборе описывает глубину залегания несплошности и фиксируется в Заключении.',
          description:
            'Внесите значение У = 7,3 мм в ячейку таблицы, чтобы зафиксировать расположение дефекта по глубине',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.Y, StudyFocusEl.YTableInput],
        },
        {
          id: 51,
          name: 'Работа с дефектоскопом / Шаг №11.9 Проведение УЗ контроля сварного шва',
          title:
            'Протяжённость дефекта определяется в мм вдоль сварного шва с помощью поверенного средства измерения (линейка, рулетка, т.д.) и фиксируется в Заключении. Началом несплошности считается точка, в которой уровень эхо-сигнала от несплошности начинает превышать поисковый уровень, а концом несплошности - точка, где уровень сигнала падает ниже поискового уровня.',
          description: 'Измерьте протяжённость несплошности и внесите значение 22 мм в ячейку таблицы',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.DefectLengthTableInput],
        },
        {
          id: 52,
          name: 'Работа с дефектоскопом / Шаг №11.10 Проведение УЗ контроля сварного шва',
          title:
            'При оформлении Заключения по результатам проведённого УЗ контроля необходимым условием является составление дефектограммы, которая является схематичным изображением объекта контроля и найденных несплошностей с их координатами. В нашем случае при заполнении таблицы с результатами контроля такая дефектограмма составляется автоматически.',
          description: 'Нажмите “Далее”',
          isCompleted: false,
          isDone: true,
          currentFocusIds: [
            StudyFocusEl.CustomSample,
            StudyFocusEl.oYTableInput,
            StudyFocusEl.YTableInput,
            StudyFocusEl.DefectLengthTableInput,
            StudyFocusEl.DefectNoteTableNumber,
          ],
          freeFocusPosition: [{ left: '48%', top: '72%', width: '6%', height: '10%' }],
        },
        {
          id: 53,
          name: 'Работа с дефектоскопом / Шаг №11.11 Проведение УЗ контроля сварного шва',
          title:
            'Для описания дефектов используется сокращённая запись в соответствии с ГОСТ Р 55724-2013. В нашем случае сокращённая запись выглядит следующим образом: ДБ(+3,4)-Е-7,3-22, где \n' +
            ' ДБ(+3,4) - обозначение того, что амплитуда эхо-сигнала превышает допустимое значение и на сколько дБ;\n' +
            'Е - дефект, условная протяженность которого превышает допустимое значение;\n' +
            '7,3 - наибольшая глубина залегания дефекта в мм.',
          description: 'Внесите сокращённую запись дефекта ДБ(+3,4)-Е-7,3-22 в ячейку таблицы',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.ShortTableNote],
        },
        {
          id: 54,
          name: 'Работа с дефектоскопом / Шаг №11.12 Проведение УЗ контроля сварного шва',
          title:
            'Для определения годности сварного соединения необходимо провести оценку допустимости каждого найденного дефекта. Допустимость дефекта определяется по совокупности параметров: амплитуда эхо-сигнала (в частности, превышение браковочного уровня), протяжённость и количество дефектов на единицу длины сварного шва. Допустимые значения указываются в нормативных документах в проекте на данный объект контроля. В нашем случае выявленная несплошность является недопустимой, так как амплитуда эхо-сигнала и протяженность превышают установленные значения.',
          description: 'Нажмите на кнопку и выберите “крестик”.',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.Normality],
        },
        {
          id: 55,
          name: 'Работа с дефектоскопом / Шаг №11.13 Проведение УЗ контроля сварного шва',
          title:
            'Для обнаружения всех несплошностей в образце преобразователь перемещают продольно-поперечными движениями с направлением УЗ луча в сторону сварного шва. При этом шаг сканирования (расстояние между продольными линиями движения) составляет половину диаметра пьезопластины преобразователя.\n' +
            'Поиск дефектов необходимо проводить со всех возможных направлений. Для пластины возможны два направления: с левой и с правой стороны от сварного шва.',
          description: 'Нажмите “Далее”',
          isCompleted: false,
          isDone: true,
          currentFocusIds: [StudyFocusEl.SampleSurfaceSegment],
          optionTabCode: OptionsTabCode.VRH,
          freeFocusPosition: [
            { left: '12%', top: '5%', width: '38%', height: '81%' },
            { left: '54%', top: '5%', width: '38%', height: '81%' },
          ],
        },
        {
          id: 56,
          name: 'Работа с дефектоскопом / Шаг №11.14 Проведение УЗ контроля сварного шва',
          title:
            'Используя полученные знания,\n' +
            'найдите все несплошности на контрольном образце и заполните таблицу.\n' +
            'По окончании обучения нажмите кнопку “Завершить”.',
          description: ' ',
          isCompleted: false,
          isDone: true,
          currentFocusIds: [],
        },
      ],
    },
    {
      id: CourseModulesIds.FlawExam,
      name: 'Экзамен',
      description: 'Максимальное время сдачи экзамена 90 минут.',
      welcomeText:
       `  
        Для одного образца:   
        1. Выполнить ультразвуковой контроль (УЗК).      
        2. Оформить таблицу по результатам контроля.      
        3. Сравнить результаты с требованиями нормативных документов (НД).
        `,
      successText: 'Нажмите "Ок" чтобы отправить результаты на проверку',
      state: ModuleProgress.Locked,
      steps: [
        {
          id: 1,
          name: 'Экзамен по дефектоскопии',
          title: 'Заголовок',
          description: 'Экзамен по работе с дефектоскопом',
          isCompleted: false,
        },
      ],
    },
  ],
};

export const flawStudyTrueParamsPattern: IFlawStudyCheckParams[] = [
  { sensor: '00000000-0000-0000-0000-000000000003' },
  { angle: 65 },
  { mode: SensorMode.Combined },
  { frequency: 2.5 },
  { sampleId: sampleDefectCO3.id },
  { scan: 100 },
  { liquid: true },
  { direction: true },
  { movedSensor: true },
  {}, //10
  {},
  {},
  { sampleId: sampleDefectCO2.id },
  { scan: 140 },
  { liquid: true }, //15
  { direction: true },
  {},
  {},
  {},
  {}, //20
  { sampleId: sampleDefectCO3.id },
  { liquid: true },
  { scan: 200 },
  { aZone: [50, 20, 50] },
  { bZone: [160, 20, 30] }, //25
  {},
  { rayLengthMode: RayLength.Sab },
  { rayLengthMode: RayLength.S0a },
  { sampleId: calibrateLevelsDefectData.id },
  { liquid: true }, //30
  { scan: 95 },
  { aZone: [15, 75, 50] },
  {},
  {},
  {}, // 35
  { bZone: [15, 75, 25] },
  { vrhOn: true },
  { vrhPointNumber: VRHPointIndex.FirstPoint },
  { vrcPointGain: 0, vrhPointPosition: 33 },
  { direction: true }, // 40
  { vrhPointNumber: VRHPointIndex.SecondPoint },
  { vrcPointGain: 8, vrhPointPosition: 68 },
  { sampleId: sampleDefectStudy.id },
  { sampleThickness: 14 },
  { liquid: true }, // 45
  { rayLengthMode: RayLength.H },
  { direction: true },
  { overLevel: 3.4 },
  { oxTableInput: 15 },
  { yTableInput: 7.3 }, // 50
  { defectLengthTableInput: 22 },
  {},
  { shortDefectNote: ['ДБ(+3,4)-Е-7,3-22', 'ДБ(+3.4)-Е-7.3-22'] },
  { normality: AdmissibilityLevels.NotNormal },
];
