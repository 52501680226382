import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

import { H4Text, IconButtonWrapper, InputWrapper, LabelPosition, TextInput, TextInputSize } from '../../uiKitElems';

import useStyles from './styles';

interface IProps {
  className?: string;
  onChange(pattern: string): void;
  pattern: string;
}

const FilterInput = (props: IProps) => {
  const c = useStyles();

  const { className, pattern, onChange } = props;

  const onClear = () => {
    onChange('');
  };

  return (
    <InputWrapper
      className={className || c.filterWrapper}
      labelPosition={LabelPosition.Left}
      labelCell={<H4Text className={c.filterLabel}> Поиск: </H4Text>}
      inputCell={
        <TextInput
          className={c.filterInput}
          size={TextInputSize.Md}
          onChange={onChange}
          value={pattern}
          endAdornment={
            pattern ? (
              <IconButtonWrapper icon={<CloseIcon />} onClick={onClear} />
            ) : (
              <img className={c.filterIcn} src={'icons/filter.svg'} width={16} />
            )
          }
        />
      }
    />
  );
};

export default FilterInput;
