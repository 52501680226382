import HTTPService from './HTTPService';

import { apiPaths } from 'Shared/Constants/Navigation/apiPaths';

import { IOrganizationDto } from 'Shared/Interfaces/Api/Organizations';

class OrganizationsApiService extends HTTPService {
  constructor() {
    super(apiPaths.organizations);
  }

  public getOrganizations(): Promise<IOrganizationDto[]> {
    return this.GET(``);
  }

  public getOrganizationById(organizationId: string): Promise<IOrganizationDto> {
    return this.GET(`${organizationId}`);
  }

  public postOrganization(dto: IOrganizationDto): Promise<string> {
    return this.POST(``, dto);
  }

  public putOrganization(organizationId: string, dto: IOrganizationDto): Promise<string> {
    return this.PUT(`${organizationId}`, dto);
  }

  public removeOrganization(organizationId: string) {
    return this.DELETE(`?id=${organizationId}`);
  }
}

export default new OrganizationsApiService();
