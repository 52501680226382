import { makeStyles } from '@material-ui/core';
import { indents } from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  sampleWrapper: {
    position: 'relative',
    width: '80%',
    display: 'flex',
    justifyContent: 'space-around',
  },
  sampleDirectionsImg: {
    position: 'absolute',
    left: indents.sixteen,
    bottom: 0,
  },
  sampleImage: {
    width: `100%`,
  },
  canvas: {
    zIndex: 2,
    width: '100%',
  },
  empty: {
    display: 'none',
  },
  sampleEnv: {
    position: 'relative',
    width: `${100 / 1.2}%`,
  },
  stepSampleEnv: {
    position: 'relative',
    width: `${100 / 1.2}%`,
  },
  imageOverlay: {
    pointerEvents: 'none',
    position: 'absolute',
    left: '12%',
    top: '5%',
    height: '80%',
    width: '80%',
    zIndex: 9,
  },
  stepProfileImage: {
    width: '100%',
  },
}));

export default useStyles;
