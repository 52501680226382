import { makeStyles } from '@material-ui/core';

import { colors, indents } from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
  },
  passDate: {
    marginLeft: indents.sixteen,
  },
  icnBtn: {
    width: 40,
    height: 40,
  },
  passDateCard: {
    width: 30,
  },
  tabMenuWrapper: {
    marginLeft: indents.eight,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  progressContentWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  studentFullName: {
    marginLeft: 'auto',
  },
  studentInfoWrapper: {
    height: 42,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `solid ${colors.grayColor.strokeInputGray} 1px`,
  },
  modulesWrapper: {
    marginLeft: indents.thirtyTwo,
    display: 'flex',
    paddingTop: indents.thirtyTwo,
    overflow: 'auto',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  returnBtn: {
    marginRight: indents.eight,
    color: colors.secondary.mainGrey,
  },
}));

export default useStyles;
