import React from 'react';

import { observer } from 'mobx-react';

import FileCopy from '@material-ui/icons/FileCopy';

import {
  BaseButton,
  H4Text,
  IconButtonWrapper,
  InputWrapper,
  LabelPosition,
  TextInput,
  TextInputSize,
  TypeButton,
} from 'Components/uiKitElems';

import { usersStore } from 'Stores';

import useStyles from './styles';

const UpdatePasswordForm = () => {
  const c = useStyles();

  return (
    <div className={c.updatePasswordDialog}>
      <InputWrapper
        spaceBetweenSize={16}
        labelPosition={LabelPosition.Left}
        labelCell={<H4Text noWrap={true} text={'Пароль'} />}
        inputCell={
          <>
            <IconButtonWrapper
              className={c.copyBtn}
              hint={'Скопиовать пароль'}
              icon={<FileCopy />}
              onClick={usersStore.copyPassword}
            />
            <TextInput
              disabled={true}
              className={c.newPassword}
              size={TextInputSize.Md}
              onChange={usersStore.user.onChangePassword}
              value={usersStore.user.password}
            />

          </>
        }
      />

      <div className={c.actionRow}>
        <BaseButton
          buttonClassName={c.newPassword}
          type={TypeButton.Primary}
          onClick={usersStore.submitNewPassword}
          caption={'Сохранить новый пароль'}
        />
      </div>
    </div>
  );
};

export default observer(UpdatePasswordForm);
