import React from 'react';
import clsx from 'clsx';

import { Typography } from '@material-ui/core';

import { ITextWrapperProps } from '../ITextStyleProps';

import useStyles from './styles';

const H2Text = (props: ITextWrapperProps) => {
  const { className, children, color, fontWeight, isLight, noWrap, text } = props;

  const c = useStyles({ isLight, fontWeight, color, noWrap });

  return <Typography className={className ? clsx(className, c.h2Text) : c.h2Text}>{children || text}</Typography>;
};

export default H2Text;
