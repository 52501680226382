import { ModuleProgress, ValidationErrorTypes } from './Enums';
import { colors } from '../Components/uiKitElems';

export default class Utils {
  /**
   * Возвращает get параметр url по имени
   * @param {string} key - имя параметра
   * @return {string} значение параметра
   */
  public static getUrlParam(key: string): string | null {
    const url = new URL(document.location.href);
    return url.searchParams.get(key);
  }

  /**
   * Удаляет параметр из URL
   * @param key - параметр
   */
  public static removeUrlParam(key: string) {
    const url = new URL(document.location.href);
    url.searchParams.delete(key);

    window.history.replaceState(null, 'key', url.href);
  }

  /**
   * Добавление/замена параметра url
   * @param key {string} - параметр
   * @param value {string} - значение
   */
  public static insertUrlParam(key: string, value: string) {
    const url = new URL(document.location.href);

    if (Array.isArray(value)) {
      value = encodeURIComponent(JSON.stringify(value));
    }

    if (url.searchParams.has(key)) {
      url.searchParams.set(key, value);
    } else {
      url.searchParams.append(key, value);
    }

    window.history.replaceState(null, key, url.href);
  }

  public static ascCompare(a: any, b: any, propertyName: string) {
    return a[propertyName]! > b[propertyName]! ? 1 : -1;
  }

  public static descCompare(a: any, b: any, propertyName: string) {
    return a[propertyName]! > b[propertyName]! ? -1 : 1;
  }

  /**
   / Функция случайного числоа с вероятностью появления
   */
  public static randomProb = (value: number, probabilityValue: number) => {
    const probability = Math.random();
    return probabilityValue > probability ? Math.random() * value : 0;
  };

  /**
   * Отключение dbl click
   * @param element
   */
  public static disableDblClick(element?: HTMLElement | null) {
    if (!element) return;

    const callback = (event: MouseEvent) => {
      if (event.detail > 1) event.preventDefault();
    };

    element.addEventListener('mousedown', callback, false);
  }

  public static getColorByStudyProgress(state: ModuleProgress): string {
    let color = '';

    switch (state) {
      case ModuleProgress.Completed: {
        color = colors.statusColor.successGreen;
        break;
      }

      case ModuleProgress.Current: {
        color = colors.primary.mainBlue;
        break;
      }

      case ModuleProgress.Started: {
        color = colors.primary.mainBlue;
        break;
      }

      case ModuleProgress.Locked: {
        color = colors.grayColor.strokeInputGray;
        break;
      }
    }

    return color;
  }

  public static roundDigit(value: number, count: number): number {
    const k = 100 * count;
    return Math.round(value * k) / k;
  }

  public static checkForValid(value: string, maxLength?: number) {
    const maxLengthValue = maxLength || 38;

    if (!value) {
      return ValidationErrorTypes.RequiredField;
    }

    if (value.length === 0) {
      return ValidationErrorTypes.RequiredField;
    }

    if (value.length < 2) {
      return ValidationErrorTypes.MinLength;
    }

    if (value.length > maxLengthValue) {
      return ValidationErrorTypes.MaxLength + ` ${maxLengthValue} ` + 'символов';
    }

    return ValidationErrorTypes.NoError;
  }
}
