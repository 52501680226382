const apiPaths = {
  departments: 'api/departments',
  exams: 'api/examTemplates',
  exam: 'api/exam',
  groups: 'api/groups',
  organizations: 'api/organizations',
  samples: 'api/samples',
  users: 'api/users',
  sensors: 'api/sensors',
};

export { apiPaths };
