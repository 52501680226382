import { makeStyles } from '@material-ui/core';
import { colors, indents } from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  userListWrapper: {
    height: 'calc(100% - 120px)',
  },
  actionsRow: {
    marginTop: indents.thirtyTwo,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  returnBtn: {
    marginRight: indents.eight,
    color: colors.secondary.mainGrey,
  },
  addStudentBtn: {
    marginLeft: 'auto',
  },
  tableWrapper: {
    height: '100%',
    marginTop: indents.thirtyTwo,
  },
  filterWrapper: {
    marginLeft: indents.fortyEight,
  },
  noDataNotice: {
    position: 'absolute',
    top: '50%',
    left: '40%',
  },
}));

export default useStyles;
