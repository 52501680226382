import { makeStyles } from '@material-ui/core';
import { indents } from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
  },
  tableWrapper: {
    marginTop: indents.thirtyTwo,
  },
  noDataNotice: {
    position: 'absolute',
    top: '50%',
    left: '40%',
  },
  tableRowActions: {
    width: 'fit-content',
    display: 'flex',
  },
  actionsRow: {
    marginTop: indents.thirtyTwo,
    display: 'flex',
    justifyContent: 'flex-start',
  },
}));

export default useStyles;
