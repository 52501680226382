import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { Route } from 'react-router-dom';

import { AuthPage } from 'Modules';

import { StudentRoutes, AdminRoutes, TeacherRoutes } from './RoutesByRole';

import { userAuthStore } from 'Stores';

import { ApplicationRoutes, UserRole } from 'Shared/Constants/Navigation';

const Router = () => {
  const history = useHistory();

  useEffect(() => {
    if (!userAuthStore.authUser.role?.toString()) {
      history.push(ApplicationRoutes.Auth);

      return;
    }

    const defaultRout = userAuthStore.authUser.defaultRouteByRole;
    history.push(defaultRout);
  }, [userAuthStore.authUser.role]);

  switch (userAuthStore.authUser.role) {
    case UserRole.Student: {
      return <StudentRoutes />;
    }

    case UserRole.Admin: {
      return <AdminRoutes />;
    }

    case UserRole.Teacher: {
      return <TeacherRoutes />;
    }

    case null: {
      return (
        <Route exact={true} path={ApplicationRoutes.Auth}>
          <AuthPage />
        </Route>
      );
    }

    default: {
      return null;
    }
  }
};

export default observer(Router);
