import { makeStyles } from '@material-ui/core/styles';
import { colors, indents, inputBorderRadius } from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  filterPopover: {
    '& .MuiPopover-paper': {
      borderRadius: inputBorderRadius,
      backgroundColor: '#fafafa',
    },
  },
  card: {
    height: 'unset',
    padding: indents.eight,
  },
  content: {
    display: 'flex',
  },
  active: {
    color: colors.statusColor.infoAzure,
  },
  btn: {
    marginLeft: 4,
    width: 40,
  },
}));

export default useStyles;
