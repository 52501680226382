import { makeAutoObservable, runInAction } from 'mobx';

import { departmentsApiService, samplesApiService } from 'services/apiService';
import { examApiService, examTemplatesApiService } from 'services/apiService';

import { TableListParams } from 'services/dataServices/tableServices/TableListParams';
import { ExamFormModel, ExamRowModel } from 'Shared/Models/Exams';

import { ISelectItem } from 'Shared/Interfaces/App';

import { measurementStore, studyCoursesStore, userAuthStore } from './index';

import { CoursesId, MaterialType, SampleType } from 'Shared/Enums';
import { IDepartmentDto } from 'Shared/Interfaces/Api/Departments';

import Utils from 'Shared/Utils';

interface IExamStoreDef {
  isPending: boolean;
  isShowForm: boolean;
  examIdToRemove: string;
  listParams: TableListParams;
  samplesList: ISelectItem[];
  qualityLevels: ISelectItem[];
  exam: ExamFormModel;
  examToPass: ExamFormModel;
  exams: ExamRowModel[];
  filterPattern: string;
  examsPage: ExamRowModel[];

  get examNameToRemove(): string;
  get examsListIsExist(): boolean;

  addNewExam(): void;
  closeForm(): void;
  closeRemoveDialog(): void;
  showRemoveDialog(cloudId: string): void;
  submitExam(): Promise<void>;
  getExamById(examId: string): Promise<void>;
  removeExam(): Promise<void>;
  getSortedList(): void;
  getExams(): Promise<void>;
  getSamples(deviceTypeId: CoursesId): Promise<void>;
  onFilterPatternChange(value: string): void;
  deInit(): void;
}

class ExamsStore implements IExamStoreDef {
  public isPending = false;

  public isShowForm = false;

  public examIdToRemove = '';

  public listParams = new TableListParams(() => this.getSortedList());

  public samplesList: ISelectItem[] = [];

  public departmentsAndLevels: IDepartmentDto[] = [];

  public departmentsList: ISelectItem[] = [];

  public gosts: ISelectItem[] = [];

  public thicknessSampleTypes: ISelectItem[] = [{ id: SampleType.Teller, name: 'Пластина' }];

  public defectSampleTypes: ISelectItem[] = [
    { id: SampleType.Teller, name: 'Пластина' },
    { id: SampleType.Tube, name: 'Труба' },
    { id: SampleType.TubeSegment, name: 'Сегмент трубы' },
  ];

  public materialNames: ISelectItem[] = [
    { id: MaterialType.TypeStahl20, name: 'Сталь 20' },
    { id: MaterialType.Type12X18H10T, name: '12Х18Н10Т' },
    { id: MaterialType.Type09G2C, name: '09Г2С' },
  ];

  public qualityLevels: ISelectItem[] = [];

  public exam: ExamFormModel = new ExamFormModel();

  public examToPass: ExamFormModel = new ExamFormModel();

  public exams: ExamRowModel[] = [];

  public filterPattern = '';

  public examsPage: ExamRowModel[] = [];

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public get examNameToRemove(): string {
    return this.exams.find((item) => item.id === this.examIdToRemove)?.name || '';
  }

  public get examsListIsExist() {
    return this.exams.length > 0;
  }

  public async addNewExam() {
    await this.getDepartments();
    this.isShowForm = true;
  }

  public closeForm() {
    this.isShowForm = false;
  }

  public closeRemoveDialog() {
    this.examIdToRemove = '';
  }

  public showRemoveDialog(cloudId: string) {
    this.examIdToRemove = cloudId;
  }

  public async startExam(): Promise<void> {
    try {
      this.isPending = true;
      const userGroupByDevice = userAuthStore.authUser.userGroups.find(
        (item) => item.deviceTypeId === studyCoursesStore.selectedCourse.code
      );

      if (!userGroupByDevice) return;

      const examToPass = await examApiService.startExam(userAuthStore.authUser.id, userGroupByDevice.groupId);
      if (!examToPass) return;

      const examTemplate = await examTemplatesApiService.getExamById(examToPass.examTemplateId);
      this.examToPass.updateFromDto(examTemplate);
      this.examToPass.updateId(examToPass.id);
      studyCoursesStore.selectedCourse.selectedModule?.updateWelcomeText(examTemplate.description);
      await measurementStore.getTuningSamples();
      await measurementStore.getSampleForExam(examTemplate.sampleId);
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public async continueExam(examId: string, examTemplateId: string): Promise<void> {
    try {
      const examTemplate = await examTemplatesApiService.getExamById(examTemplateId);
      this.examToPass.updateFromDto(examTemplate);
      this.examToPass.updateId(examId);
      studyCoursesStore.selectedCourse.selectedModule?.updateWelcomeText(examTemplate.description);
      await measurementStore.getTuningSamples();
      await measurementStore.getSampleForExam(examTemplate.sampleId);
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public onChangeCourse(courseId: string) {
    this.exam.onChangeCourse(courseId as CoursesId);
    this.getSamples(this.exam.courseId);
  }

  public onChangeExamDepartment(departmentId: string) {
    this.exam.onChangeDepartment(departmentId);
    this.gosts = this.departmentsAndLevels
      .find((item) => item.id === departmentId)
      ?.gosts.map((item) => ({ id: item.id, name: item.name }))!;
  }

  public onChangeExamRejectionRate(regectionRateId: string) {
    this.exam.onChangeRejectionRate(regectionRateId);
    this.qualityLevels = this.departmentsAndLevels
      .find((item) => item.id === this.exam.departmentId)
      ?.gosts.find((item) => item.id === this.exam.rejectionRateId)?.qualityLevels!;
  }

  public async submitExam() {
    this.isPending = true;

    try {
      const dto = this.exam.getDto();
      if (!dto) return;

      if (!this.exam.id) {
        await examTemplatesApiService.postExam(dto);
      } else {
        await examTemplatesApiService.putExam(this.exam.id, dto);
      }

      this.closeForm();
      this.getExams();
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public async getExamById(examId: string) {
    this.isPending = true;
    const examRowItem = this.exams.find((item) => item.id === examId);
    if (!examRowItem) return;

    try {
      const dto = await examTemplatesApiService.getExamById(examId);
      this.exam.updateFromDto(dto);

      await this.getDepartments();

      this.isShowForm = true;
      const department = this.departmentsAndLevels.find((item) =>
        item.gosts.some((item) => item.qualityLevels.some((level) => level.id === this.exam.qualityLevelId))
      );
      if (!department) return;

      this.exam.onChangeDepartment(department.id);
      this.gosts = department.gosts;
      const gost = department.gosts.find((item) =>
        item.qualityLevels.some((level) => level.id === this.exam.qualityLevelId)
      );
      if (!gost) return;

      this.exam.onChangeRejectionRate(gost.id);

      this.qualityLevels = gost.qualityLevels;
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public async removeExam() {
    if (!this.examIdToRemove) return;

    this.isPending = true;

    try {
      const result = await examTemplatesApiService.deleteExam(this.examIdToRemove);
      if (!result) return;

      this.getExams();
      this.examIdToRemove = '';
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public async getDepartments() {
    this.isPending = true;

    try {
      const result = await departmentsApiService.getDepartments();
      if (!result) return;

      this.departmentsAndLevels = result;
      this.departmentsList = result.map((item) => ({ id: item.id, name: item.name }));
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public getSortedList() {
    const columnName = this.listParams.sorting.sortingField as keyof ExamRowModel;

    const filtered = this.exams.filter((item) =>
      item.name.toLowerCase().includes(this.filterPattern.toLowerCase().trim())
    );

    const sorted = filtered.sort((a: ExamRowModel, b: ExamRowModel) =>
      this.listParams.sorting.isAscending ? Utils.ascCompare(a, b, columnName) : Utils.descCompare(a, b, columnName)
    );

    const start = this.listParams.pagination.pageIndex * this.listParams.pagination.pageSize;

    this.examsPage = sorted.slice(
      start,
      start + this.listParams.pagination.pageSize > sorted.length
        ? sorted.length
        : start + this.listParams.pagination.pageSize
    );
  }

  public async getExams() {
    this.isPending = true;

    try {
      const result = await examTemplatesApiService.getExams();
      this.exams = result.map((dto) => new ExamRowModel(dto));

      this.listParams.pagination.setTotalCount(this.exams.length);
      this.listParams.onChangePage(0);
      this.getSortedList();
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public async getSamples(deviceTypeId: CoursesId): Promise<void> {
    this.isPending = true;

    try {
      this.samplesList = await samplesApiService.getSamples(deviceTypeId);
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public onFilterPatternChange(pattern: string) {
    this.filterPattern = pattern;
    this.getSortedList();
  }

  public async saveExam() {
    this.isPending = true;

    try {
      const examData = {
        id: this.examToPass.id,
        tuningSampleId: this.examToPass.tuningSampleId || null,
        sensorId: measurementStore.selectedSensor.id,
        defectData: JSON.stringify(measurementStore.defectNotes.map(item => item.getDtoToSave())),
      };

      await examApiService.saveExam(this.examToPass.id, examData);
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public async finishExam() {
    this.isPending = true;

    try {
      await examApiService.finishExam(this.examToPass.id);
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public deInit(): void {
    this.exams = [];
    this.examsPage = [];
    this.filterPattern = '';
    this.examIdToRemove = '';
  }

  public deInitForm(): void {
    this.exam.clear();
    this.samplesList = [];
    this.qualityLevels = [];
  }
}

export default new ExamsStore();
