import { makeStyles } from '@material-ui/core';

import { indents } from '../../uiKitElems';

const useStyles = makeStyles((theme) => ({
  filterWrapper: {
    marginLeft: indents.sixteen,
  },
  filterLabel: {
    marginRight: indents.eight,
  },
  filterIcn: {
    marginRight: indents.eight,
  },
  filterInput: {
    marginLeft: indents.sixteen,
    width: 250,
  },
}));

export default useStyles;
