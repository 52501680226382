import { makeStyles } from '@material-ui/core';
import { bgColors, borderRadius, colors, indents } from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  deviceDisplay: {
    position: 'absolute',
    top: '12%',
    left: '15%',
    height: '12%',
    width: '68%',
    paddingRight: indents.eight,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  lcdDigits: {
    fontSize: '200%',
    color: '#D52F23',
    fontFamily: 'Digital',
    fontWeight: 500,
  },
  thickDeviceImageWrapper: {
    position: 'relative',
    borderRadius: borderRadius,
    padding: indents.sixteen,
    backgroundColor: bgColors.thickBg,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  },
  additionalImageWrapper: {
    position: 'absolute',
    right: '15%',
    zIndex: 13,
  },
  backPanelImage: {
    height: '100%',
  },
  additionalImage: {
    position: 'relative',
  },
  sensorImage: {
    position: 'absolute',
    top: '56%',
    left: '38%',
  },
}));

export default useStyles;
