import { makeAutoObservable } from 'mobx';

import SensorModel from './SensorModel';

import { IDefectDeviceParams, IDefectSensor, IPoint, IVrc, IZone } from 'Shared/Interfaces/App';
import { SensorMode } from 'Shared/Enums';
import { deviceParams } from 'Shared/Constants/Simulation';

const MIN_SCAN_WIDTH = 0;
const MAX_SCAN_WIDTH = 3000;

const MIN_AMPLIFICATION = 0;
const MAX_AMPLIFICATION = 110;

const MIN_VRH_AMPLIFICATION = 0;
const MAX_VRH_AMPLIFICATION = 90;

const MIN_SPEED_WAVE = 0;
const MAX_SPEED_WAVE = 9999;

const MIN_OFFSET = -100;
const MAX_OFFSET = 992;

const MIN_SAMPLE_HEIGHT = 0;
const MAX_SAMPLE_HEIGHT = 3000;

const MIN_FREQ_VALUE = 0;
const MAX_FREQ_VALUE = 20;

const MIN_PROTECTOR_VALUE = 0;
const MAX_PROTECTOR_VALUE = 10;

const MIN_TRIM_VALUE = 0;
const MAX_TRIM_VALUE = 100;

export default class FlawDeviceParams {
  constructor(defaultParam: IDefectDeviceParams, updateCallBack: () => void) {
    makeAutoObservable(this, undefined, { autoBind: true });

    this.updateDisplay = updateCallBack;

    this.isPowerOn = defaultParam.isPowerOn;
    this.amplification = defaultParam.amplification;
    this.offset = defaultParam.offset;
    this.depth = defaultParam.depth;
    this.speed = defaultParam.speed;
    this.trim = defaultParam.trim;
    this.scanWidth = defaultParam.scanWidth;

    this.zoneA = new ZoneParamsModel(this.updateDisplay);
    this.zoneA.init(defaultParam.zoneA);

    this.zoneB = new ZoneParamsModel(this.updateDisplay);
    this.zoneB.init(defaultParam.zoneB);

    this.vrc = new VRHParamsModel(this.updateDisplay);
    this.vrc.init(defaultParam.vrc);

    this.sensor.init(defaultParam.sensor);
  }

  public init(defaultParam: IDefectDeviceParams) {
    this.isPowerOn = defaultParam.isPowerOn;
    this.amplification = defaultParam.amplification;
    this.offset = defaultParam.offset;
    this.depth = defaultParam.depth;
    this.speed = defaultParam.speed;
    this.trim = defaultParam.trim;
    this.scanWidth = defaultParam.scanWidth;

    this.sensor?.init(defaultParam.sensor);
    this.zoneA?.init(defaultParam.zoneA);
    this.zoneB?.init(defaultParam.zoneB);
    this.vrc?.init(defaultParam.vrc);
  }

  public deInit() {
    this.init(deviceParams);
  }

  public updateDisplay: any;

  public isPowerOn = true;

  public amplification = 1;

  public offset = 0;

  public depth = 0;

  public speed = 3200;

  public trim = 0;

  public scanWidth = 300;

  public sensor = new SensorParamsModel();

  public zoneA: ZoneParamsModel | null = null;

  public zoneB: ZoneParamsModel | null = null;

  public vrc: VRHParamsModel | null = null;

  public onAmplificationChange(value: number | null) {
    if (!value?.toString()) {
      this.amplification = 0;
      this.updateDisplay();
      return;
    }

    if (value < MIN_AMPLIFICATION) {
      this.amplification = MIN_AMPLIFICATION;
    }

    if (value > MAX_AMPLIFICATION) {
      this.amplification = MAX_AMPLIFICATION;
    }

    if (value >= MIN_AMPLIFICATION && value <= MAX_AMPLIFICATION) {
      this.amplification = value;
    }

    this.updateDisplay();
  }

  public onOffsetChange(value: number | null) {
    if (!value) {
      this.offset = 0;
      return;
    }

    if (value < MIN_OFFSET) {
      this.offset = MIN_OFFSET;
    }

    if (value > MAX_OFFSET) {
      this.offset = MAX_OFFSET;
    }

    if (value >= MIN_OFFSET && value <= MAX_OFFSET) {
      this.offset = value;
    }
  }

  public onHeightChange(value: number | null) {
    if (!value?.toString()) {
      this.depth = 0;
      return;
    }

    if (value < MIN_SAMPLE_HEIGHT) {
      this.depth = MIN_SAMPLE_HEIGHT;
    }

    if (value > MAX_SAMPLE_HEIGHT) {
      this.depth = MAX_SAMPLE_HEIGHT;
    }

    if (value >= MIN_SAMPLE_HEIGHT && value <= MAX_SAMPLE_HEIGHT) {
      this.depth = value;
    }
  }

  public onSpeedChange(value: number | null) {
    if (!value) {
      this.speed = MIN_SPEED_WAVE;
      this.updateDisplay();

      return;
    }

    if (value < MIN_SPEED_WAVE) {
      this.speed = MIN_SPEED_WAVE;
    }

    if (value > MAX_SPEED_WAVE) {
      this.speed = MAX_SPEED_WAVE;
    }

    if (value >= MIN_SPEED_WAVE && value <= MAX_SPEED_WAVE) {
      this.speed = value;
    }

    this.updateDisplay();
  }

  public onTrimChange(value: number | null) {
    if (!value?.toString()) {
      this.trim = 0;
      return;
    }

    if (value < MIN_TRIM_VALUE) {
      this.trim = MIN_TRIM_VALUE;
    }

    if (value > MAX_TRIM_VALUE) {
      this.trim = MAX_TRIM_VALUE;
    }

    if (value >= MIN_TRIM_VALUE && value <= MAX_TRIM_VALUE) {
      this.trim = value;
    }

    this.updateDisplay();
  }

  public onScanWidthChange(value: number | null) {
    if (!value) {
      this.scanWidth = MIN_SCAN_WIDTH;
      this.updateDisplay();
      return;
    }

    if (value < MIN_SCAN_WIDTH) {
      this.scanWidth = MIN_SCAN_WIDTH;
    }

    if (value > MAX_SCAN_WIDTH) {
      this.scanWidth = MAX_SCAN_WIDTH;
    }

    if (value >= MIN_SCAN_WIDTH && value <= MAX_SCAN_WIDTH) {
      this.scanWidth = value;
    }

    this.updateDisplay();
  }
}

class SensorParamsModel {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public name = 'Сенсор 1';

  public angle = 85;

  public isRight = true;

  public tread = 50;

  public frequency = 3000;

  public protector = 6.5;

  public mode = SensorMode.Separated;

  public init(initData: IDefectSensor) {
    this.name = initData.name;
    this.angle = initData.angle;
    this.frequency = initData.frequency;
    this.mode = initData.mode;
  }

  public onChangeMode(mode: SensorMode) {
    this.mode = mode;
  }

  public onChangeProtector(value: number | null) {
    if (!value?.toString()) {
      this.protector = MIN_PROTECTOR_VALUE;
      return;
    }

    if (value < MIN_PROTECTOR_VALUE) {
      this.protector = MIN_PROTECTOR_VALUE;
    }

    if (value > MAX_PROTECTOR_VALUE) {
      this.protector = MAX_PROTECTOR_VALUE;
    }

    if (value >= MIN_PROTECTOR_VALUE && value <= MAX_PROTECTOR_VALUE) {
      this.protector = value;
    }
  }

  public setSelectedSensorData(data: SensorModel) {
    this.name = data.name;
    this.frequency = data.frequency;
  }

  public onAngleChange(value: number | null) {
    if (!value) {
      this.angle = 0;
      return;
    }

    this.angle = value;
  }

  public onFrequencyChange(value: number | null) {
    if (!value?.toString()) {
      this.frequency = 0;
      return;
    }

    if (value < MIN_FREQ_VALUE) {
      this.frequency = MIN_FREQ_VALUE;
    }

    if (value > MAX_FREQ_VALUE) {
      this.frequency = MAX_FREQ_VALUE;
    }

    if (value >= MIN_FREQ_VALUE && value <= MAX_FREQ_VALUE) {
      this.frequency = value;
    }
  }

  public onSetDirection(isRight: boolean) {
    this.isRight = isRight;
  }
}

class ZoneParamsModel {
  constructor(updateCallback: () => void | null) {
    makeAutoObservable(this, undefined, { autoBind: true });

    this.updateDisplay = updateCallback;
  }

  public updateDisplay: any;

  public start = 10;

  public width = 20;

  public height = 70;

  public init(defaultZoneParams: IZone) {
    this.start = defaultZoneParams.start;
    this.width = defaultZoneParams.width;
    this.height = defaultZoneParams.height;
  }

  public onStartChange(value: number | null) {
    if (!value?.toString()) {
      this.start = 0;
      return;
    }

    if (value < 0) {
      this.start = 0;
    }

    if (value > MAX_SCAN_WIDTH) {
      this.start = MAX_SCAN_WIDTH;
    }

    if (value >= 0 && value <= MAX_SCAN_WIDTH) {
      this.start = value;
    }

    this.updateDisplay();
  }

  public onWidthChange(value: number | null) {
    if (!value) {
      this.width = 0;
      this.updateDisplay();
      return;
    }

    if (value < MIN_SCAN_WIDTH) {
      this.width = MIN_SCAN_WIDTH;
    }

    if (value > MAX_SCAN_WIDTH) {
      this.width = MAX_SCAN_WIDTH;
    }

    if (value >= MIN_SCAN_WIDTH && value <= MAX_SCAN_WIDTH) {
      this.width = value;
    }

    this.updateDisplay();
  }

  public onHeightChange(value: number | null) {
    if (!value) {
      this.height = 0;
      return;
    }

    if (value < 0 || value > 100) return;
    this.height = value;
    this.updateDisplay();
  }
}

class PointModel {
  constructor(updateCallback: () => void | null) {
    makeAutoObservable(this, undefined, { autoBind: true });
    this.updateDisplay = updateCallback;
  }

  public x = 0;

  public y = 0;

  public init(point: IPoint) {
    this.x = point.x;
    this.y = point.y;
  }

  public updateDisplay: any;

  public onXChange(value: number | null) {
    if (!value) {
      this.x = 0;
      this.updateDisplay();
      return;
    }

    if (value < 0) {
      this.x = 0;
    }

    if (value > MAX_SCAN_WIDTH) {
      this.x = MAX_SCAN_WIDTH;
    }

    if (value >= 0 && value <= MAX_SCAN_WIDTH) {
      this.x = value;
    }

    this.updateDisplay();
  }

  public onYChange(value: number | null) {
    if (!value) {
      this.y = 0;
      this.updateDisplay();

      return;
    }

    if (value < MIN_VRH_AMPLIFICATION) {
      this.y = MIN_VRH_AMPLIFICATION;
    }

    if (value > MAX_VRH_AMPLIFICATION) {
      this.y = MAX_VRH_AMPLIFICATION;
    }

    if (value >= MIN_VRH_AMPLIFICATION && value <= MAX_VRH_AMPLIFICATION) {
      this.y = value;
    }

    this.updateDisplay();
  }
}

class VRHParamsModel {
  constructor(updateCallback: () => void | null) {
    makeAutoObservable(this, undefined, { autoBind: true });

    this.updateDisplay = updateCallback;
    this.points = [new PointModel(this.updateDisplay), new PointModel(this.updateDisplay)];
  }

  public updateDisplay: any;

  public enable = false;

  public visible = false;

  public index = 0;

  public points: PointModel[] = [];

  public selectedPoint: PointModel | null = null;

  public init(defaultVrhParams: IVrc) {
    this.enable = defaultVrhParams.enable;
    this.visible = defaultVrhParams.visible;
    this.index = defaultVrhParams.index;
    this.points[0].init(defaultVrhParams.points[0]);
    this.points[1].init(defaultVrhParams.points[1]);
    this.selectedPoint = this.points[this.index];
  }

  public onSelectPoint(pointIndex: string) {
    const parsedIndex = parseInt(pointIndex);
    if (!parsedIndex.toString()) return;

    this.index = parsedIndex;

    if (this.index < 0 || this.index > this.points.length) return;

    this.selectedPoint = this.points[this.index];
    this.updateDisplay();
  }

  public enableToggle() {
    this.enable = !this.enable;
    this.visible = this.enable;
    this.updateDisplay();
  }
}
