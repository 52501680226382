import { makeStyles } from '@material-ui/core';

import {bgColors, colors, indents, inputBorderRadius} from '../theme';

const useStyles = makeStyles((theme) => ({
  introduceText: {
    minWidth: 414,
    minHeight: 200,
    width: 414,
    height: 'fit-content',
    backgroundColor: colors.primary.mainBlue,
    border: `solid ${colors.primary.mainBlue} 1px`,
    borderRadius: inputBorderRadius,
    marginLeft: indents.fortyEight,
    marginRight: indents.fortyEight,
    marginTop: indents.thirtyTwo,
    padding: indents.sixteen,
  },
  introduceTitle: {
    fontStyle: 'italic',
    marginTop: indents.eight,
    width: '100%',
  },
  introduceTitleCollapsed: {
  },
  stepTitle: {
    marginTop: indents.sixteen,
    marginBottom: indents.sixteen,
    width: '100%',
  },
  stepFullText: {
    marginTop: indents.eight,
    overflow: 'auto',
    height: 'auto',
    textAlign: 'center',
  },
  expander: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  expanderBtn: {
    marginRight: indents.eight,
    color: bgColors.grey,
  },
}));

export default useStyles;
