import React from 'react';
import { observer } from 'mobx-react';

import { Create } from '@material-ui/icons';

import { BaseButton, FontWeights, H4Text, H6Text, SizeButton, TypeButton } from 'Components/uiKitElems';

import {studentProgressStore, studyCoursesStore, userAuthStore} from 'Stores';

import { CourseModulesIds, ModuleProgress, UserRole } from 'Shared/Enums';

import useStyles from './styles';

interface IProps {
  courseModuleId?: CourseModulesIds | null;
  state: ModuleProgress;
  color: string;
  name: string;
  specialTextForExam?: string;
}
const ModuleProgressRender = (props: IProps) => {
  const { courseModuleId, color, specialTextForExam, state, name } = props;

  const c = useStyles({ color });

  const isShowCheckButton =
    CourseModulesIds.ThickExam === courseModuleId || CourseModulesIds.FlawExam === courseModuleId;

  const statusText = state === ModuleProgress.Completed ? `${state}. ${specialTextForExam || ''}` : state;

  return (
    <div className={c.progressWrapper}>
      <div className={c.progress}>
        {state === ModuleProgress.Completed && <img src={'images/course_completed.svg'} alt={'Модуль завершен'} />}
        {state === ModuleProgress.Current && <img src={'images/course_in_progress.svg'} alt={'Текущий модуль'} />}
        {state === ModuleProgress.Started && <img src={'images/course_in_progress.svg'} alt={'Текущий модуль'} />}
        {state === ModuleProgress.Locked && <img src={'images/course_locked.svg'} alt={'Модуль заблокирован'} />}
        <div className={c.statusFiller} />
      </div>

      <div className={c.moduleWrapper}>
        <H6Text className={c.moduleState} fontWeight={FontWeights.Regular} color={color} text={statusText} />
        <H4Text className={c.moduleName} fontWeight={FontWeights.Bold} text={name} />

        {isShowCheckButton && (
          <BaseButton
            disabled={!studentProgressStore.examId}
            endIcon={<Create className={c.checkBtnIcn} />}
            buttonClassName={c.checkResultButton}
            size={SizeButton.Md}
            type={TypeButton.Primary}
            onClick={studentProgressStore.showCheckTable}
            caption={'Результаты контроля'}
          />
        )}
      </div>
    </div>
  );
};

export default observer(ModuleProgressRender);
