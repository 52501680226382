import React, { useState, MouseEvent, ReactNode } from 'react';

import CheckIcon from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Popover from '@material-ui/core/Popover';

import { IconButtonWrapper } from 'Components/uiKitElems';

import useStyles from './styles';

interface IProps {
  className?: string;
  hasValue?: boolean;
  children?: ReactNode;
  onFilter?: () => void;
  onClear?: () => void;
}

const Filter = (props: IProps) => {
  const { className, hasValue, children, onFilter, onClear } = props;

  const c = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleClear = () => {
    if (onClear) {
      onClear();
    }

    handleClose();
  };

  return (
    <>
      <IconButton className={className} size="small" onClick={handleClick}>
        <img src={hasValue ? 'icons/filter-activated.svg' : 'icons/filter.svg'} width={16} />
      </IconButton>

      <Popover className={c.filterPopover} open={!!anchorEl} anchorEl={anchorEl} onClose={handleClose}>
        <div className={c.card}>
          <div className={c.content}>
            {children}
            <IconButtonWrapper icon={<CheckIcon />} className={c.btn} onClick={handleClose} />
            <IconButtonWrapper icon={<CloseIcon />} className={c.btn} onClick={handleClear} />
          </div>
        </div>
      </Popover>
    </>
  );
};

export default Filter;
