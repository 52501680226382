import { makeAutoObservable } from 'mobx';

import { IDefectNoteData } from 'Shared/Interfaces/App';
import { AdmissibilityLevels } from 'Shared/Enums';
import { markDownLevels } from 'Shared/Constants/autoMarking';

export enum DefectType {
  Found = 'Найденный',
  NotFound = 'Не найденный',
  NotExisted = 'Не существующий',
}

export class ComparedDefect {
  public static id = 0;

  constructor(defectType: DefectType, userDefect: IDefectNoteData | null, realDefect: IDefectNoteData | null) {
    makeAutoObservable(this, undefined, { autoBind: true });
    this.defectType = defectType;

    if (this.defectType === DefectType.NotFound || this.defectType === DefectType.NotExisted) {
      this.downLevel = 5;
    }

    this.id = ComparedDefect.id++;

    if (userDefect) {
      this.userDefectNumber = userDefect.defectNumber;
      this.userSensor = userDefect.sensorName;
      this.userOX = userDefect.oX;
      this.userY = userDefect.y;
      this.userDefectDepth = userDefect.defectDepth!;
      this.userLength = userDefect.defectLength!;
      this.userSign = userDefect.sign!;
      this.userOverLevel = userDefect.levelOver;
      this.userShortNote = userDefect.shortNote;
      this.userAdmissibility = userDefect.admissibility;
    }

    if (realDefect) {
      this.realDefectNumber = realDefect.defectNumber;
      this.realSensor = realDefect.sensorName;
      this.realOX = realDefect.oX;
      this.realY = realDefect.y;
      this.realDefectDepth = realDefect.defectDepth!;
      this.realLength = realDefect.defectLength!;
      this.realOverLevel = realDefect.levelOver;
      this.realShortNote = realDefect.shortNote;
      this.realAdmissibility = realDefect.admissibility;
    }

    if (
      this.defectType === DefectType.Found &&
      Math.abs(Math.abs(this.userDefectDepth!) - Math.abs(this.realDefectDepth!)) > markDownLevels.wrongDefect
    ) {
      this.downLevel! += markDownLevels.wrongDepth;
    }
  }

  public id = 0;

  public userDefectNumber: number | null = null;

  public realDefectNumber: number | null = null;

  public defectType: DefectType | null = null;

  public realSensor = '';

  public userSensor = '';

  public userOX: number | null = null;

  public userY: number | null = null;

  public userDefectDepth: number | null = null;

  public realDefectDepth: number | null = null;

  public userLength: number | null = null;

  public userSign = '';

  public userOverLevel: number | null = null;

  public userShortNote = '';

  public userAdmissibility: AdmissibilityLevels = AdmissibilityLevels.NotSet;

  public realOX: number | null = null;

  public realY: number | null = null;

  public realLength: number | null = null;

  public realOverLevel: number | null = null;

  public realShortNote = '';

  public realAdmissibility: AdmissibilityLevels = AdmissibilityLevels.NotSet;

  public downLevel: number | null = null;

  public onChangeDownLevel(level: number | null) {
    this.downLevel = level;
  }
}
