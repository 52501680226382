import { makeAutoObservable } from 'mobx';

import { IFreeWrapperParam, IModuleStep } from 'Shared/Interfaces/App';

import { measurementStore, studyCoursesStore } from 'Stores';

import { AdmissibilityLevels, ExceedLevel, OptionsTabCode, StudyFocusEl } from 'Shared/Enums';
import { flawStudyTrueParamsPattern } from 'Shared/Constants/Education/flawDetection';
import { ISensorPosition } from 'Shared/Interfaces/App/Simulation/ISensorPosition';
import { sampleDepthData, sampleStepData } from 'Shared/Constants/Simulation';

interface IModuleStepDef {
  id: number;
  name: string;
  title: string;
  descriptionTitle: string;
  description: string;
  imageUrl: string;
  isCompleted: boolean;
  currentValue: string;
  isDone: boolean;
  currentFocusIds: StudyFocusEl[];

  onComplete(value: boolean): void;
}

export default class ModuleStep implements IModuleStepDef {
  constructor(data: IModuleStep) {
    makeAutoObservable(this, undefined, { autoBind: true });

    this.id = data.id;
    this.name = data.name;
    this.title = data.title!;
    this.descriptionTitle = data.name;
    this.description = data.description;
    this.imageUrl = data.imageUrl!;
    this.currentFocusIds = data.currentFocusIds!;
    this.flawDeviceTabByStep = data.optionTabCode!;
    this.isDone = data.isDone!;
    this.freeFocusPosition = data.freeFocusPosition!;
    this.freeFocusPosition2 = data.freeFocusPosition2!;
    this.startCardPosition = data.cardStartPosition!;
  }

  public id = 0;

  public name = '';

  public currentFocusIds: StudyFocusEl[] = [];

  public freeFocusPosition: IFreeWrapperParam[] | null = null;

  public freeFocusPosition2: IFreeWrapperParam[] | null = null;

  public flawDeviceTabByStep: OptionsTabCode = OptionsTabCode.common;

  public descriptionTitle = '';

  public title = '';

  public description = '';

  public imageUrl = '';

  public currentValue = '';

  public isDone = false;

  public isCompleted = false;

  public startCardPosition = { x: 0, y: 0 };

  public lastXPosition = 0;

  public lastYPosition = 0;

  public onComplete(value: boolean): void {
    this.isCompleted = value;
  }

  public onDone(value: boolean): void {
    this.isDone = value;
  }

  public updateLastSensorPosition(sensorPosition: ISensorPosition) {
    this.lastXPosition = sensorPosition.x;
    this.lastYPosition = sensorPosition.y;
  }

  public check() {
    if (!studyCoursesStore.selectedCourse.selectedModule?.currentStep) return;

    try {
      const isSignalNearRejectLevel =
        measurementStore.chart?.exceededLevel! > ExceedLevel.RejectedLevelBottom &&
        measurementStore.chart?.exceededLevel! < ExceedLevel.RejectedLevelTop;

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 1) {
        this.isDone = flawStudyTrueParamsPattern[this.id - 1].sensor === measurementStore.selectedSensor.id;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 2) {
        this.isDone = flawStudyTrueParamsPattern[this.id - 1].angle === measurementStore.chart?.sensor.angle!;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 3) {
        this.isDone = flawStudyTrueParamsPattern[this.id - 1].mode === measurementStore.chart?.params.sensor.mode;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 4) {
        this.isDone =
          flawStudyTrueParamsPattern[this.id - 1].frequency === measurementStore.chart?.params.sensor.frequency;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 5) {
        this.isDone = flawStudyTrueParamsPattern[this.id - 1].sampleId === measurementStore.selectedSampleId;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 6) {
        this.isDone = flawStudyTrueParamsPattern[this.id - 1].scan === measurementStore.chart?.params.scanWidth;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 7) {
        this.isDone = flawStudyTrueParamsPattern[this.id - 1].liquid === measurementStore.sample?.isSurfaceWet;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 8) {
        this.isDone = measurementStore.selectedSensor.isOnSurface;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 9) {
        const x = measurementStore.selectedSensor.sensorPosition.x;
        this.isDone = x < 62 && x > 50 && measurementStore.chart?.exceededLevel! > ExceedLevel.TwoOfThree;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 10) {
        const x = measurementStore.selectedSensor.sensorPosition.x;
        this.isDone = x < 62 && x > 50 && measurementStore.chart?.exceededLevel! > ExceedLevel.TwoOfThree;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 11) {
        const x = measurementStore.selectedSensor.sensorPosition.x;
        this.isDone = x < 62 && x > 50 && measurementStore.chart?.exceededLevel! > ExceedLevel.TwoOfThree;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 12) {
        const x = measurementStore.selectedSensor.sensorPosition.x;
        this.isDone = x < 62 && x > 50 && measurementStore.chart?.exceededLevel! > ExceedLevel.TwoOfThree;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 13) {
        this.isDone = flawStudyTrueParamsPattern[this.id - 1].sampleId === measurementStore.selectedSampleId;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 14) {
        this.isDone = flawStudyTrueParamsPattern[this.id - 1].scan === measurementStore.chart?.params.scanWidth;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 15) {
        this.isDone = flawStudyTrueParamsPattern[this.id - 1].liquid === measurementStore.sample?.isSurfaceWet;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 16) {
        this.isDone = measurementStore.selectedSensor.isOnSurface;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 17) {
        const x = measurementStore.selectedSensor.sensorPosition.x;

        this.isDone = x !== this.lastXPosition && x < 152 && x > 134 && measurementStore.pipAngle === 180;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 18) {
        this.isDone =
          measurementStore.pipAngle === 180 && measurementStore.chart?.exceededLevel! > ExceedLevel.TwoOfThree;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 19) {
        const x = measurementStore.selectedSensor.sensorPosition.x;

        this.isDone = x !== this.lastXPosition && x < 152 && x > 134 && measurementStore.pipAngle === 180;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 21) {
        this.isDone = flawStudyTrueParamsPattern[this.id - 1].sampleId === measurementStore.selectedSampleId;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 22) {
        this.isDone = flawStudyTrueParamsPattern[this.id - 1].liquid === measurementStore.sample?.isSurfaceWet;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 23) {
        this.isDone = flawStudyTrueParamsPattern[this.id - 1].scan === measurementStore.chart?.params.scanWidth;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 24) {
        const aZone = flawStudyTrueParamsPattern[this.id - 1].aZone;
        if (!aZone) return;

        this.isDone =
          aZone[0] === measurementStore.chart?.params.zoneA?.start &&
          aZone[1] === measurementStore.chart?.params.zoneA?.width &&
          aZone[2] === measurementStore.chart?.params.zoneA?.height;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 25) {
        const bZone = flawStudyTrueParamsPattern[this.id - 1].bZone;
        if (!bZone) return;

        this.isDone =
          bZone[0] === measurementStore.chart?.params.zoneB?.start &&
          bZone[1] === measurementStore.chart?.params.zoneB?.width &&
          bZone[2] === measurementStore.chart?.params.zoneB?.height;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 26) {
        this.isDone =
          measurementStore.selectedSensor.isOnSurface &&
          measurementStore.chart?.exceededLevel! > ExceedLevel.TwoOfThree;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 27) {
        this.isDone = flawStudyTrueParamsPattern[this.id - 1].rayLengthMode === measurementStore.chart?.rayCalcMode;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 28) {
        this.isDone = flawStudyTrueParamsPattern[this.id - 1].rayLengthMode === measurementStore.chart?.rayCalcMode;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 29) {
        this.isDone = flawStudyTrueParamsPattern[this.id - 1].sampleId === measurementStore.selectedSampleId;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 30) {
        this.isDone = flawStudyTrueParamsPattern[this.id - 1].liquid === measurementStore.sample?.isSurfaceWet;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 31) {
        this.isDone = flawStudyTrueParamsPattern[this.id - 1].scan === measurementStore.chart?.params.scanWidth;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 32) {
        const aZone = flawStudyTrueParamsPattern[this.id - 1].aZone;
        if (!aZone) return;

        this.isDone =
          aZone[0] === measurementStore.chart?.params.zoneA?.start &&
          aZone[1] === measurementStore.chart?.params.zoneA?.width &&
          aZone[2] === measurementStore.chart?.params.zoneA?.height;

        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 33) {
        this.isDone = measurementStore.chart?.exceededLevel! > ExceedLevel.TwoOfThree && measurementStore.pipAngle === 180;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 34) {
        this.isDone = measurementStore.chart?.exceededLevel! > ExceedLevel.TwoOfThree && measurementStore.pipAngle === 180;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 35) {
        this.isDone = isSignalNearRejectLevel && measurementStore.pipAngle === 180;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 36) {
        const bZone = flawStudyTrueParamsPattern[this.id - 1].bZone;
        if (!bZone) return;

        this.isDone =
          isSignalNearRejectLevel &&
          bZone[0] === measurementStore.chart?.params.zoneB?.start &&
          bZone[1] === measurementStore.chart?.params.zoneB?.width &&
          bZone[2] === measurementStore.chart?.params.zoneB?.height &&
          measurementStore.pipAngle === 180;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 37) {
        this.isDone =
          isSignalNearRejectLevel &&
          flawStudyTrueParamsPattern[this.id - 1].vrhOn === measurementStore.chart?.params.vrc?.enable &&
          measurementStore.pipAngle === 180;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 38) {
        this.isDone =
          flawStudyTrueParamsPattern[this.id - 1].vrhPointNumber ===
            measurementStore.chart?.params.vrc?.index.toString() &&
          measurementStore.pipAngle === 180;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 39) {
        this.isDone =
          isSignalNearRejectLevel &&
          flawStudyTrueParamsPattern[this.id - 1].vrcPointGain ===
            measurementStore.chart?.params.vrc?.selectedPoint?.y &&
          flawStudyTrueParamsPattern[this.id - 1].vrhPointPosition ===
            measurementStore.chart?.params.vrc?.selectedPoint?.x &&
          measurementStore.pipAngle === 180;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 40) {
        this.isDone = measurementStore.selectedSensor.isOnSurface && measurementStore.pipAngle === 0;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 41) {
        this.isDone =
          flawStudyTrueParamsPattern[this.id - 1].vrhPointNumber ===
            measurementStore.chart?.params.vrc?.index.toString() && measurementStore.pipAngle === 0;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 42) {
        this.isDone =
          isSignalNearRejectLevel &&
          flawStudyTrueParamsPattern[this.id - 1].vrcPointGain ===
            measurementStore.chart?.params.vrc?.selectedPoint?.y &&
          flawStudyTrueParamsPattern[this.id - 1].vrhPointPosition ===
            measurementStore.chart?.params.vrc?.selectedPoint?.x &&
          measurementStore.pipAngle === 0;

        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 43) {
        this.isDone = flawStudyTrueParamsPattern[this.id - 1].sampleId === measurementStore.selectedSampleId;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 44) {
        this.isDone = flawStudyTrueParamsPattern[this.id - 1].sampleThickness === measurementStore.chart?.params.depth;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 45) {
        this.isDone = flawStudyTrueParamsPattern[this.id - 1].liquid === measurementStore.sample?.isSurfaceWet;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 46) {
        this.isDone = flawStudyTrueParamsPattern[this.id - 1].rayLengthMode === measurementStore.chart?.rayCalcMode;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 47) {
        this.isDone =
          flawStudyTrueParamsPattern[this.id - 1].direction === measurementStore.selectedSensor.isOnSurface &&
          measurementStore.chart?.exceededLevel! > ExceedLevel.OneOfThree!;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 48) {
        this.isDone = flawStudyTrueParamsPattern[this.id - 1].overLevel === measurementStore.defectNotes[0].levelOver;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 49) {
        this.isDone = flawStudyTrueParamsPattern[this.id - 1].oxTableInput === measurementStore.defectNotes[0].oX;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 50) {
        this.isDone = flawStudyTrueParamsPattern[this.id - 1].yTableInput === measurementStore.defectNotes[0].y;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 51) {
        this.isDone =
          flawStudyTrueParamsPattern[this.id - 1].defectLengthTableInput ===
          measurementStore.defectNotes[0].defectLength;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 52) {
        this.isDone = true;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 53) {
        const patterns = flawStudyTrueParamsPattern[this.id - 1].shortDefectNote;
        if (!patterns) return;

        this.isDone =
          patterns[0] === measurementStore.defectNotes[0].shortNote ||
          patterns[1] === measurementStore.defectNotes[0].shortNote;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 54) {
        this.isDone =
          flawStudyTrueParamsPattern[this.id - 1].normality === measurementStore.defectNotes[0].admissibility;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 55) {
        this.isDone = true;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 56) {
        this.isDone = true;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep.id === 57) {
        this.isDone = true;
      }
    } catch (e) {
      console.log('Произошла ошибка при проверке введённый значений шага обучения на соответствие эталону');
      console.log(e);
    }
  }

  public checkThicknessStep() {
    if (!studyCoursesStore.selectedCourse.selectedModule?.currentStep) return;

    try {
      if (studyCoursesStore.selectedCourse.selectedModule.currentStep?.id === 1) {
        this.isDone = measurementStore.selectedSensor.id === '00000000-0000-0000-0000-000000000006';
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep?.id === 2) {
        this.isDone = !!measurementStore.thickDevice.isSwitchedOn;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep?.id === 4) {
        this.isDone = !!measurementStore.thickDevice?.isSampleImitatorWet;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep?.id === 5) {
        this.isDone = !!measurementStore.thickDevice.isAboveSampleSimulator;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep?.id === 6) {
        this.isDone = measurementStore.thickDevice.instDepthValue === '6.5';
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep?.id === 7) {
        this.isDone = measurementStore.thickDevice.instDepthValue === '6.0';
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep?.id === 8) {
        this.isDone = measurementStore.selectedSampleId === sampleStepData.id;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep?.id === 9) {
        this.isDone = !!measurementStore.sample?.isSurfaceWet;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep?.id === 10) {
        const value = parseFloat(measurementStore.sample?.pip?.depthValue!);
        this.isDone = value >= 10 && value < 12;

        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep?.id === 11) {
        const value = parseFloat(measurementStore.sample?.pip?.depthValue!);
        this.isDone = value >= 12 && value < 14;

        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep?.id === 12) {
        const value = parseFloat(measurementStore.sample?.pip?.depthValue!);
        this.isDone = value >= 16 && value < 18;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep?.id === 13) {
        const value = parseFloat(measurementStore.sample?.pip?.depthValue!);
        this.isDone = value >= 22 && value < 24;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep?.id === 14) {
        const value = parseFloat(measurementStore.sample?.pip?.depthValue!);
        this.isDone = value >= 14 && value < 16;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep?.id === 15) {
        this.isDone = measurementStore.selectedSampleId === sampleDepthData.id;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep?.id === 16) {
        this.isDone = !!measurementStore.sample?.isSurfaceWet;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep?.id === 17) {
        this.isDone = measurementStore.selectedSensor.isOnSurface;

        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep?.id === 18) {
        const x = parseFloat(measurementStore.sample?.textX?.text!);
        const y = parseFloat(measurementStore.sample?.textY?.text!);

        const studyDefect = sampleDepthData.defects[sampleDepthData.defects.length - 1];
        this.isDone =
          x > studyDefect.x &&
          x < studyDefect.x + studyDefect.width &&
          y > studyDefect.y &&
          y < studyDefect.y + studyDefect.height;

        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep?.id === 19) {
        this.isDone = !!measurementStore.defectNotes[0].defectDepth;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep?.id === 20) {
        this.isDone = !!measurementStore.defectNotes[0].oX;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep?.id === 21) {
        this.isDone = !!measurementStore.defectNotes[0].y;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep?.id === 23) {
        this.isDone = measurementStore.defectNotes[0].admissibility !== AdmissibilityLevels.NotSet;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep?.id === 24) {
        this.isDone = true;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep?.id === 25) {
        this.isDone = true;
        return;
      }

      if (studyCoursesStore.selectedCourse.selectedModule.currentStep?.id === 26) {
        this.isDone = true;
        return;
      }
    } catch (e) {
      console.log('Произошла ошибка при проверке введённый значений шага обучения на соответствие эталону');
      console.log(e);
    }
  }
}
