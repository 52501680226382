import React from 'react';
import clsx from 'clsx';

import { H5Text } from '../textWrappers';

import useStyles from './styles';

interface IProps {
  className?: string;
  isOn: boolean;
  height: number;
  onSwitch(isOn: boolean): void;
}

const OnOffSwitcher = (props: IProps) => {
  const { className, isOn, height, onSwitch } = props;

  const c = useStyles({ height });

  const handleChange = () => {
    onSwitch(!isOn);
  };

  return (
    <div className={clsx(className, c.switchWrapper)} onClick={handleChange}>
      <H5Text text={'вкл'} isLight={true} className={isOn ? c.stateHighLight : c.stateMain} />
      <H5Text text={'выкл'} isLight={true} className={isOn ? c.stateMain : c.stateHighLight} />
    </div>
  );
};

export default OnOffSwitcher;
