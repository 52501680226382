import { makeStyles } from '@material-ui/core';
import { bgColors, colors, indents, inputBorderRadius } from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  authWrapper: {
    display: 'flex',
    width: '100vw',
    height: 'calc(100vh - 56px - 60px)',
    backgroundImage: 'url(images/auth_background.jpg)',
    backgroundSize: '100%',
    overflow: 'auto',
    position: 'relative',
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: indents.eight,
  },
  authForm: {
    position: 'absolute',
    left: indents.fortyEight,
    top: indents.ninetySix,
    zIndex: 2,
    backgroundColor: bgColors.white,
    border: `solid ${colors.primary.mainBlue} 1px`,
    borderRadius: inputBorderRadius,
    width: 412,
    height: 'fit-content',
    padding: `${indents.sixteen}px ${indents.fortyEight}px`,
  },
  authFormRow: {
    marginTop: indents.twentyFour,
  },
  authInput: {
    width: '250px',
  },
  authRememberCheck: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '240px',
  },
  authFormActions: {
    marginTop: indents.thirtyTwo,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  forgetPassBtn: {
    marginLeft: indents.thirtyTwo,
  },
  forgetPassBtnCap: {
    textDecoration: 'underline',
  },
}));

export default useStyles;
