import HTTPService from './HTTPService';

import { apiPaths } from 'Shared/Constants/Navigation/apiPaths';
import { IDepartmentDto } from 'Shared/Interfaces/Api/Departments';

class DepartmentsApiService extends HTTPService {
  constructor() {
    super(apiPaths.departments);
  }

  public getDepartments(): Promise<IDepartmentDto[]> {
    return this.GET(``);
  }
}

export default new DepartmentsApiService();
