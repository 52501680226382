import React from 'react';

import { H5Text } from 'Components/uiKitElems';

import useStyles from './styles';

const ThicknessCheckTitleRow = () => {
  const c = useStyles();

  return (
    <div className={c.rowContainer}>
      <div className={c.leftCell}>
        <H5Text className={c.titleColumnCell} text={'Датчик'} />
      </div>

      <div className={c.middleCell}>
        <H5Text className={c.titleColumnCell} text={'Толщина, мм'} />
      </div>

      <div className={c.middleCell}>
        <H5Text className={c.titleColumnCell} text={'Координата X, мм'} />
      </div>

      <div className={c.middleCell}>
        <H5Text className={c.titleColumnCell} text={'Координата Y, мм'} />
      </div>

      <div className={c.middleCell}>
        <H5Text className={c.titleColumnCell} text={'Оценка допустимости'} />
      </div>

      <div className={c.rightCell}>
        <H5Text className={c.titleColumnCell} text={'Тип дефекта'} />
      </div>

      <div className={c.rightCell}>
        <H5Text className={c.titleColumnCell} text={'Норма снижения'} />
      </div>
    </div>
  );
};

export default ThicknessCheckTitleRow;
