import React from 'react';

import { Route } from 'react-router-dom';

import { AuthPage } from 'Modules';
import { StudentCabinet } from 'Modules';

import { ApplicationRoutes } from 'Shared/Constants/Navigation';

const StudentRoutes = () => {
  return (
    <>
      <Route exact={true} path={ApplicationRoutes.Cabinet}>
        <StudentCabinet />
      </Route>

      <Route exact={true} path={ApplicationRoutes.Auth}>
        <AuthPage />
      </Route>
    </>
  );
};

export default StudentRoutes;
