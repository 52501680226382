import { makeStyles } from '@material-ui/core';
import { bgColors, indents } from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  mainSampleWrapper: {
    backgroundColor: bgColors.grey,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'calc(100% - 46px)',
    minWidth: 400,
    position: 'relative',
    overflow: 'hidden',
  },
  sampleDirectionsImg: {
    position: 'absolute',
    left: -indents.eight,
    bottom: -indents.sixteen,
    zIndex: 3,
    transform: 'scale(0.8)',
  },
  sampleWrapper: {
    position: 'relative',
  },
  sampleImage: {
    width: `100%`,
  },
  canvas: {
    zIndex: 2,
    width: '100%',
  },
  empty: {
    display: 'none',
  },
  co2SampleEnv: {
    position: 'relative',
    width: `${100 / 1.5}%`,
  },
  co3SampleEnv: {
    position: 'relative',
    width: `${100 / 2}%`,
  },
  customSampleEnv: {
    width: '90%',
  },
  calibrationSampleEnv: {
    width: '90%',
  },
  imageOverlay: {
    pointerEvents: 'none',
    position: 'absolute',
    left: '26%',
    top: '10%',
    height: '70%',
    transform: 'scaleX(1.5)',
  },
  imageOverlay2: {
    pointerEvents: 'none',
    position: 'absolute',
    right: '20%',
    top: '10%',
    height: '70%',
    transform: 'scaleX(1.5)',
  },
}));

export default useStyles;
