import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { studentProgressStore } from 'Stores';

import { BaseButton, FontWeights, H5Text, SizeButton, TextInput, TypeButton, typoColors } from 'Components/uiKitElems';
import { ModalDialog } from 'Components/Common';
import { TextInputSize, VariantInput } from 'Components/uiKitElems/NumberInput/types';

import { ThicknessCheckRow, ThicknessCheckTitleRow } from 'Components/Common/ThicknessChecking';

import { CoursesId } from 'Shared/Enums';

import useStyles from './styles';

const ThickCheckingTable = () => {
  const c = useStyles({ finalMarkColor: studentProgressStore.finalMarkColor });

  useEffect(() => {
    studentProgressStore.defectCheckTable?.buildComparedDefects(CoursesId.ThicknessMeasurement);
    return () => {
      studentProgressStore.defectCheckTable?.clearDefectCheckTable();
    };
  }, []);

  useEffect(() => {
    if (!studentProgressStore.finalMark && studentProgressStore.defectCheckTable?.calculatedMark) {
      studentProgressStore.setFinalMark(studentProgressStore.defectCheckTable?.calculatedMark.toString());
    }
  }, [studentProgressStore.defectCheckTable?.calculatedMark]);

  return (
    <div className={c.defectCheckingTableWrapper}>
      <ThicknessCheckTitleRow />
      <div className={c.defectsContainer}>
        {studentProgressStore.defectCheckTable?.comparedDefects.map((item) => (
          <ThicknessCheckRow key={item.id} comparedDefect={item} />
        ))}
      </div>

      <ModalDialog
        onClose={studentProgressStore.closeSaveConfirmDialog}
        isOpen={studentProgressStore.isShowSaveConfirmDialog}
        title={`Завершить проверку?`}
        onConfirm={studentProgressStore.saveVerify}>
        <H5Text className={c.reExamConfirmText} text={'Сведения об оценке будут сохранены'} />
      </ModalDialog>

      <ModalDialog
        onClose={studentProgressStore.closeReExamDialog}
        isOpen={studentProgressStore.isShowReExamDialog}
        title={`Допустить слушателя к пересдаче экзамена?`}
        onConfirm={studentProgressStore.removePassedExam}>
        <H5Text className={c.reExamConfirmText} text={'Результаты предыдущей сдачи экзамена будут удалены'} />
      </ModalDialog>

      <div className={c.resultsRow}>
        <div className={c.resultWrapper}>
          <div className={c.finalMarkWrapper}>
            <H5Text color={typoColors.bodyGrey} className={c.calculatedMark} text={`Расчётная оценка:`} />
            <H5Text
              color={studentProgressStore.defectCheckTable?.calculatedMarkColor}
              className={c.calculatedMark}
              text={`${studentProgressStore.defectCheckTable?.calculatedMark}`}
            />
            <H5Text color={typoColors.bodyGrey} className={c.calculatedMark} text={`баллов`} />
          </div>

          <div className={c.finalMarkWrapper}>
            <H5Text
              fontWeight={FontWeights.Medium}
              color={typoColors.bodyGrey}
              noWrap={true}
              text={'Итоговая оценка:'}
            />
            <TextInput
              variant={VariantInput.Standard}
              size={TextInputSize.Sm}
              className={c.manualMarkInput}
              onChange={studentProgressStore.setFinalMark}
              value={studentProgressStore.finalMark?.toString()!}
            />
            <H5Text fontWeight={FontWeights.Medium} color={typoColors.bodyGrey} text={'баллов'} />
          </div>

          <div className={c.markActions}>
            <BaseButton
              buttonClassName={c.resultActionBtn}
              onClick={studentProgressStore.showSaveConfirmDialog}
              size={SizeButton.Md}
              caption={'Завершить оценку'}
              type={TypeButton.Primary}
            />

            <BaseButton
              buttonClassName={c.resultActionBtn}
              onClick={studentProgressStore.showReExamDialog}
              size={SizeButton.Md}
              caption={'Пересдача экзамена'}
              type={TypeButton.Secondary}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(ThickCheckingTable);
