import { makeAutoObservable, runInAction } from 'mobx';

import {organizationsApiService } from 'services/apiService';

import { OrganizationFormModel, OrganizationRowModel } from 'Shared/Models/Organizations';
import { TableListParams } from '../services/dataServices/tableServices/TableListParams';
import { ISelectItem } from 'Shared/Interfaces/App';

import Utils from '../Shared/Utils';

interface IOrganizationsStore {
  isPending: boolean;
  organizations: OrganizationRowModel[];

  getOrganizations(): Promise<void>;
  get organizationsSelectList(): ISelectItem[];
}

class OrganizationsStore implements IOrganizationsStore {
  public isPending = false;

  public isShowForm = false;

  public organizationIdToRemove = '';

  public filterPattern = '';

  public organization = new OrganizationFormModel();

  public organizations: OrganizationRowModel[] = [];

  public sortedOrganizations: OrganizationRowModel[] = [];

  public listParams = new TableListParams(() => this.getSortedList());

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public get organizationNameToRemove() {
    return this.organizations.find(item => item.id === this.organizationIdToRemove)?.name || '';
  }

  public get isOrganizationsListIsExist() {
    return this.organizations.length > 0;
  }

  public addOrganization() {
    this.isShowForm = true;
  }

  public closeForm() {
    this.isShowForm = false;
  }

  public onFilterPatternChange(pattern: string) {
    this.filterPattern = pattern;
    this.getSortedList();
  }

  public get organizationsSelectList(): ISelectItem[] {
    return this.organizations.map((item) => ({ id: item.id, name: item.name }));
  }

  public async getOrganizations() {
    this.isPending = true;

    try {
      const result = await organizationsApiService.getOrganizations();
      this.organizations = result.map((dto) => new OrganizationRowModel(dto));
      this.listParams.pagination.setTotalCount(result.length);
      this.listParams.onChangePage(0);
      this.getSortedList();
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public getSortedList() {
    const columnName = this.listParams.sorting.sortingField as keyof OrganizationRowModel;

    const filtered = this.organizations.filter((item) =>
      item.name.toLowerCase().includes(this.filterPattern.toLowerCase().trim())
    );

    this.listParams.pagination.setTotalCount(filtered.length);

    const sorted = filtered.sort((a: OrganizationRowModel, b: OrganizationRowModel) =>
      this.listParams.sorting.isAscending ? Utils.ascCompare(a, b, columnName) : Utils.descCompare(a, b, columnName)
    );

    const start = this.listParams.pagination.pageIndex * this.listParams.pagination.pageSize;

    this.sortedOrganizations = sorted.slice(
      start,
      start + this.listParams.pagination.pageSize > sorted.length
        ? sorted.length
        : start + this.listParams.pagination.pageSize
    );
  }

  public async getOrganizationById(organizationId: string) {
    this.isPending = true;

    try {
      const dto = await organizationsApiService.getOrganizationById(organizationId);
      if (!dto) return;

      this.organization.updateFromDto(dto);
      this.isShowForm = true;
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public async submitOrganization() {
    this.isPending = true;

    try {
      if (this.organization.id) {
        const dto = this.organization.getDto();
        if (!dto) return;

        await organizationsApiService.putOrganization(this.organization.id, dto);
      } else {
        const dto = this.organization.getDto();
        if (!dto) return;

        const organizationId = await organizationsApiService.postOrganization(dto);
        if (!organizationId) return;
      }

      this.closeForm();
      this.getOrganizations();
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public async removeOrganization() {
    if (!this.organizationIdToRemove) return;

    this.isPending = true;

    try {
      const result = await organizationsApiService.removeOrganization(this.organizationIdToRemove);
      if (!result) return;

      this.getOrganizations();
      this.organizationIdToRemove = '';
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public showRemoveDialog(organizationId: string) {
    this.organizationIdToRemove = organizationId;
  }

  public closeRemoveDialog() {
    this.organizationIdToRemove = '';
  }

  public deInit() {
    this.organizations = [];
    this.sortedOrganizations = [];
    this.organizationIdToRemove = '';
  }
}

export default new OrganizationsStore();
