import { makeAutoObservable } from 'mobx';

import { generate } from 'generate-password';

import { IUserPostDto, IUserPutDto } from 'Shared/Interfaces/Api/Useres';

import { UserRole, ValidationErrorTypes } from 'Shared/Enums';
import Utils from '../../Utils';

interface IUserFormModel {
  id: string;
  firstName: string;
  surname: string;
  patronymic: string;
  login: string;
  organizationId: string;
  password: string;
  departmentIds: string[];
  roleId: UserRole | null;

  getPostDto(): IUserPostDto | null;
  getPutDto(): IUserPutDto | null;
  updateFromDto(dto: IUserPostDto): void;
  clear(): boolean;

  onChangeSurname(value: string): void;
  onChangeFirstName(value: string): void;
  onChangePatronymic(value: string): void;
  onChangeOrg(value: string): void;
  onChangeLogin(value: string): void;
  onChangePassword(value: string): void;
  generatePassword(): void;
  onChangeDepartment(depIds: string[]): void;
}

class UserFormModel implements IUserFormModel {
  public id = '';

  public firstName = '';

  public patronymic = '';

  public surname = '';

  public login = '';

  public organizationId = '';

  public password = '';

  public roleId: UserRole | null = null;

  public departmentIds: string[] = [];

  public isValidationActivated = false;

  public organizationValidState = '';

  public firstNameValidState = '';

  public surnameValidState = '';

  public loginNameValidState = '';

  public departmentValidState = '';

  public patronymicValidState = '';

  public constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public getPostDto(): IUserPostDto | null {
    if (this.isNotValid()) return null;

    return {
      roleId: this.roleId || UserRole.Student,
      organizationId: this.roleId === UserRole.Student ? this.organizationId : null,
      departmentIds: this.roleId === UserRole.Student ? this.departmentIds : null,
      login: this.login,
      firstName: this.firstName,
      surname: this.surname,
      patronymic: this.patronymic,
      password: this.password,
    };
  }

  public getPutDto(): IUserPutDto | null {
    if (this.isNotValid()) return null;

    return {
      roleId: this.roleId!,
      organizationId: this.organizationId,
      departmentIds: this.departmentIds,
      firstName: this.firstName,
      patronymic: this.patronymic,
      surname: this.surname,
      login: this.login,
    };
  }

  public updateFromDto(dto: IUserPostDto): void {
    this.id = dto.id!;
    this.roleId = dto.roleId!;
    this.organizationId = dto.organizationId!;
    this.departmentIds = dto.departmentIds!;
    this.login = dto.login;
    this.firstName = dto.firstName;
    this.surname = dto.surname;
    this.patronymic = dto.patronymic;
    this.password = dto.password;
  }

  public clear(): boolean {
    this.isValidationActivated = false;

    this.firstNameValidState = '';
    this.surnameValidState = '';
    this.loginNameValidState = '';
    this.departmentValidState = '';
    this.patronymicValidState = '';

    this.id = '';
    this.firstName = '';
    this.patronymic = '';
    this.surname = '';
    this.login = '';
    this.organizationId = '';
    this.password = '';
    this.roleId = null;
    this.departmentIds = [];

    return true;
  }

  public onChangeSurname(value: string) {
    this.surname = value;

    if (this.isValidationActivated) {
      this.surnameValidState = this.checkForValid(this.surname);
    }
  }

  public onChangeFirstName(value: string) {
    this.firstName = value;

    if (this.isValidationActivated) {
      this.firstNameValidState = this.checkForValid(this.firstName);
    }
  }

  public onChangePatronymic(value: string) {
    this.patronymic = value;

    if (this.isValidationActivated) {
      this.patronymicValidState = this.checkForValidNotRequired(this.patronymic, 38);
    }
  }

  public onChangeOrg(value: string) {
    this.organizationId = value;
  }

  public onChangeLogin(value: string) {
    this.login = value;

    if (this.isValidationActivated) {
      this.loginNameValidState = this.checkForValid(this.login);
    }
  }

  public onChangePassword(value: string) {
    this.password = value;
  }

  public generatePassword() {
    this.password = generate({ length: 6, lowercase: false });
  }

  public setRole(roleId: UserRole) {
    this.roleId = roleId;
  }

  public onChangeDepartment(depIds: string[]) {
    this.departmentIds = depIds;

    if (this.isValidationActivated) {
      this.departmentValidState =
        this.departmentIds.length > 0 ? ValidationErrorTypes.NoError : ValidationErrorTypes.RequiredField;
    }
  }

  private isNotValid(): boolean {
    this.isValidationActivated = true;
    this.surnameValidState = this.checkForValid(this.surname);
    this.firstNameValidState = this.checkForValid(this.firstName);
    this.loginNameValidState = this.checkForValid(this.login);
    this.patronymicValidState = this.checkForValidNotRequired(this.patronymic, 38);
    this.departmentValidState =
      this.departmentIds.length > 0 ? ValidationErrorTypes.NoError : ValidationErrorTypes.RequiredField;

    if (this.roleId === UserRole.Teacher) {
      return !!this.surnameValidState || !!this.firstNameValidState || !!this.loginNameValidState || !!this.patronymicValidState;
    }

    if (this.roleId === UserRole.Student) {
      return (
        !!this.surnameValidState ||
        !!this.firstNameValidState ||
        !!this.loginNameValidState ||
        !!this.departmentValidState ||
        !!this.patronymicValidState
      );
    }

    return false;
  }

  private checkForValid(value: string): string {
    return Utils.checkForValid(value);
  }

  private checkForValidNotRequired(value: string, maxLengthValue: number): string {
    if (value.length > maxLengthValue) {
      return ValidationErrorTypes.MaxLength + ` ${maxLengthValue} ` + 'символов';
    }

    return ValidationErrorTypes.NoError;
  }
}

export default UserFormModel;
