import React from 'react';

import { FontWeights, H6Text } from '../../uiKitElems';

import useStyles from './styles';

const Footer = () => {
  const c = useStyles();

  return (
    <div className={c.footer}>
      <H6Text fontWeight={FontWeights.Regular}>
        <span dangerouslySetInnerHTML={{ __html: '&copy;' }} />
        АРЦ НК 2021
      </H6Text>
    </div>
  );
};

export default Footer;
