import { makeStyles } from '@material-ui/core';

import { indents } from '../../uiKitElems';

const useStyles = makeStyles((theme) => ({
  userInfoWrapper: {
    margin: indents.fortyEight,
    width: 764,
  },
  userRow: {
    minWidth: 400,
    overflow: 'hidden',
    marginTop: indents.thirtyTwo,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  rowName: {
    width: 150,
  },
  infoText: {
    display: 'block',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export default useStyles;
