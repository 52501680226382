import { makeAutoObservable, runInAction } from 'mobx';

import { groupsApiService, usersApiService } from 'services/apiService';

import { UserRowModel } from 'Shared/Models/Users';
import { TableListParams } from 'services/dataServices/tableServices/TableListParams';

import { studyCoursesStore } from './index';

import { CoursesId, UserRole } from 'Shared/Enums';
import Utils from '../Shared/Utils';
import {courses} from "../Shared/Constants/courses";

interface IStudentsInGroupStore {
  isPending: boolean;

  isShowStudentAddForm: boolean;
  // параметры пагинации, сортировки списка студентов в группе
  groupStudentsListParams: TableListParams;
  // параметры пагинации, сортировки списка студентов не этой группы
  otherStudentsListParams: TableListParams;
  selectedGroupId: string;
  selectedGroupName: string;
  // паттрер фильтра для таблица студентов в группе
  groupStudentsFilterPattern: string;
  // паттрер фильтра для таблица остальных студентов в форме добавления
  otherStudentsFilterPattern: string;
  // ids выбранных студентов для добавления в группу
  studentIdsToJoin: string[];
  // id студента для удаления из гурппы
  studentIdToRemoveFromGroup: string;

  // полный список студентов выбранной группы
  studentsByGroup: UserRowModel[];
  // фильтрованный сортированный список студентов выбранной группы текущей старницы
  studentsPage: UserRowModel[];

  // полный список других студентов
  otherStudents: UserRowModel[];
  // фильтрованный сортированный список студентов не этой группы
  otherStudentsPage: UserRowModel[];

  get studentFullNameToRemove(): string;
  get studentsListByGroupExist(): boolean;
  get otherStudentsListExist(): boolean;

  // открывает форму добавления студентов в группу
  showStudentsAddFrom(): void;
  // закрывает форму добавления студентов в группу
  closeStudentsAddFrom(): void;
  // api запрос на добавление студентов в группу
  addStudentsInGroup(): Promise<void>;
  // открывает диалог удаления студентов из группы
  openRemoveDialog(studentId: string): void;
  // закрывает диалог удаления студентов из группы
  closeRemoveDialog(): void;
  // api запрос на удаление студентов из группы
  removeStudentFromGroup(): Promise<void>;
  // обработчик чекбокса добавления студента в форме добавления
  checkStudentToJoinList(studentId: string): void;
  // обработчик чекбокса добавления всей страницы студентов в форме добавления
  checkAllStudentsToJoinList(checked: boolean): void;
  // формирует соритурованную фильтрованную страцину студентов выбранной группы
  getStudentsPage(): void;
  // api запрос на получение всех студентов и список studentId этой группы
  getStudents(): Promise<void>;
  // формирует соритурованную фильтрованную страцину студентов не этой группы
  getOtherStudentsPage(): void;
  // управляет отображением ui компонета списка студентов группы
  showStudentsInGroup(groupId: string, groupName: string): void;
  // обработывает ввод фильтра для списка студентов группы
  onGroupStudentsFilterPatternChange(value: string): void;
  // обработывает ввод фильтра для списка в форме добавления студентов
  onOtherStudentsFilterPatternChange(value: string): void;
  deInit(): void;
  deInitForm(): void;
}

class StudentsInGroupStore implements IStudentsInGroupStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isPending = false;

  public groupStudentsListParams = new TableListParams(() => this.getStudentsPage());

  public otherStudentsListParams = new TableListParams(() => this.getOtherStudentsPage());

  public selectedGroupId = '';

  public deviceIdByGroup = '';

  public selectedGroupName = '';

  public groupStudentsFilterPattern = '';

  public otherStudentsFilterPattern = '';

  public isShowStudentAddForm = false;

  public studentIdsToJoin: string[] = [];

  public studentIdToRemoveFromGroup = '';

  public studentsByGroup: UserRowModel[] = [];

  public studentsPage: UserRowModel[] = [];

  public otherStudents: UserRowModel[] = [];

  public otherStudentsPage: UserRowModel[] = [];

  public get courseName() {
    return courses.find(item => item.code === this.deviceIdByGroup)?.name || '';
  }

  public get studentFullNameToRemove() {
    return this.studentsByGroup.find((item) => item.id === this.studentIdToRemoveFromGroup)?.fullName || '';
  }

  public get studentsListByGroupExist() {
    return this.studentsByGroup.length > 0;
  }

  public get otherStudentsListExist() {
    return this.otherStudents.length > 0;
  }

  public async getGroupNameById(): Promise<void> {
    if (this.selectedGroupName || !this.selectedGroupId) return;

    this.isPending = true;

    try {
      const group = await groupsApiService.getGroupById(this.selectedGroupId);
      if (group) {
        this.selectedGroupName = group.name;
      }
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public showStudentsAddFrom() {
    this.isShowStudentAddForm = true;
  }

  public closeStudentsAddFrom() {
    this.isShowStudentAddForm = false;
  }

  public async addStudentsInGroup() {
    this.isPending = true;

    try {
      await groupsApiService.joinStudentsToGroup(this.selectedGroupId, this.studentIdsToJoin);
      this.closeStudentsAddFrom();
      this.getStudents();
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public openRemoveDialog(studentId: string) {
    this.studentIdToRemoveFromGroup = studentId;
  }

  public closeRemoveDialog() {
    this.studentIdToRemoveFromGroup = '';
  }

  public async removeStudentFromGroup() {
    this.isPending = true;

    try {
      await groupsApiService.joinStudentsToGroup(
        this.selectedGroupId,
        this.studentIdsToJoin.filter((item) => item !== this.studentIdToRemoveFromGroup)
      );
      this.studentIdToRemoveFromGroup = '';
      this.getStudents();
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public async showStudentsInGroup(groupId: string, groupName: string) {
    this.selectedGroupId = groupId;
    this.selectedGroupName = groupName;

    try {
      this.isPending = true;
      const group = await groupsApiService.getGroupById(groupId);
      this.deviceIdByGroup = group.deviceTypeId;
      studyCoursesStore.onSelectCourse(this.deviceIdByGroup as CoursesId);
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => {
        this.isPending = false;
      });
    }
  }

  public checkStudentToJoinList(studentId: string) {
    if (this.studentIdsToJoin.includes(studentId)) {
      this.studentIdsToJoin = this.studentIdsToJoin.filter((item) => item !== studentId);
    } else {
      this.studentIdsToJoin.push(studentId);
    }
  }

  public checkAllStudentsToJoinList(checked: boolean) {
    this.otherStudentsPage.forEach((item) => item.onCheck(checked));

    if (!checked) {
      this.studentIdsToJoin = this.studentIdsToJoin.filter((studentId) =>
        this.studentsPage.some((item) => item.id === studentId)
      );
    }

    if (checked) {
      const ids = this.otherStudentsPage.map((item) => item.id);

      ids.forEach((id) => {
        if (!this.studentIdsToJoin.includes(id)) {
          this.studentIdsToJoin.push(id);
        }
      });
    }
  }

  public getStudentsPage() {
    const columnName = this.groupStudentsListParams.sorting.sortingField as keyof UserRowModel;

    const filtered = this.studentsByGroup.filter(
      (item) =>
        item.fullName.toLowerCase().includes(this.groupStudentsFilterPattern.toLowerCase().trim()) ||
        item.organizationName.toLowerCase().includes(this.groupStudentsFilterPattern.toLowerCase().trim())
    );

    this.groupStudentsListParams.pagination.setTotalCount(filtered.length);

    const sorted = filtered.sort((a: UserRowModel, b: UserRowModel) =>
      this.groupStudentsListParams.sorting.isAscending
        ? Utils.ascCompare(a, b, columnName)
        : Utils.descCompare(a, b, columnName)
    );

    const start = this.groupStudentsListParams.pagination.pageIndex * this.groupStudentsListParams.pagination.pageSize;

    this.studentsPage = sorted.slice(
      start,
      start + this.groupStudentsListParams.pagination.pageSize > sorted.length
        ? sorted.length
        : start + this.groupStudentsListParams.pagination.pageSize
    );
  }

  public async getStudents() {
    this.isPending = true;

    try {
      const studentsIdsByGroupReq = groupsApiService.getStudentsByGroup(this.selectedGroupId);
      const studentsListReq = usersApiService.getUsers(UserRole.Student);

      const results = await Promise.all([studentsIdsByGroupReq, studentsListReq]);

      this.studentIdsToJoin = results[0];
      this.studentsByGroup = results[1]
        .filter((item) => results[0].includes(item.id))
        .map((dto) => new UserRowModel(dto));

      this.otherStudents = results[1]
        .filter((item) => !results[0].includes(item.id))
        .map((dto) => new UserRowModel(dto));

      this.groupStudentsListParams.pagination.setTotalCount(this.studentsByGroup.length);
      this.groupStudentsListParams.onChangePage(0);

      this.otherStudentsListParams.pagination.setTotalCount(this.otherStudents.length);
      this.otherStudentsListParams.onChangePage(0);
      this.getStudentsPage();
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public getOtherStudentsPage() {
    const columnName = this.otherStudentsListParams.sorting.sortingField as keyof UserRowModel;

    const filtered = this.otherStudents.filter(
      (item) =>
        item.fullName.toLowerCase().includes(this.otherStudentsFilterPattern.toLowerCase().trim()) ||
        item.organizationName.toLowerCase().includes(this.otherStudentsFilterPattern.toLowerCase().trim())
    );

    this.otherStudentsListParams.pagination.setTotalCount(filtered.length);

    const sorted = filtered.sort((a: UserRowModel, b: UserRowModel) =>
      this.otherStudentsListParams.sorting.isAscending
        ? Utils.ascCompare(a, b, columnName)
        : Utils.descCompare(a, b, columnName)
    );

    const start = this.otherStudentsListParams.pagination.pageIndex * this.otherStudentsListParams.pagination.pageSize;

    this.otherStudentsPage = sorted.slice(
      start,
      start + this.otherStudentsListParams.pagination.pageSize > sorted.length
        ? sorted.length
        : start + this.otherStudentsListParams.pagination.pageSize
    );
  }

  public onGroupStudentsFilterPatternChange(pattern: string) {
    this.groupStudentsFilterPattern = pattern;
    this.getStudentsPage();
  }

  public onOtherStudentsFilterPatternChange(pattern: string) {
    this.otherStudentsFilterPattern = pattern;
    this.getOtherStudentsPage();
  }

  public deInit() {
    this.studentsByGroup = [];
    this.studentsPage = [];
    this.groupStudentsFilterPattern = '';
  }

  public deInitForm() {
    this.otherStudentsFilterPattern = '';
  }
}

export default new StudentsInGroupStore();
