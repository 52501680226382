import { makeStyles } from '@material-ui/core';

import { inputBorderRadius, indents } from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    width: '100%',
    display: 'flex',
    marginTop: indents.sixteen,
    marginBottom: indents.eight,
    height: 'fit-content',
    alignItems: 'center',
  },
  leftCell: {
    width: '12%',
    height: 'fit-content',
    display: 'flex',
    justifyContent: 'space-around',
  },
  titleColumnCell: {
    fontSize: 12,
  },
  middleCell: {
    height: 'fit-content',
    width: '11%',
    display: 'flex',
    justifyContent: 'space-around',
  },
  rightCell: {
    height: 'fit-content',
    width: '11%',
    borderLeft: 'none',
    borderRadius: `${0}px ${inputBorderRadius}px ${inputBorderRadius}px ${0}px`,
    display: 'flex',
    justifyContent: 'space-around',
  },
}));

export default useStyles;
