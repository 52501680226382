import { makeAutoObservable } from 'mobx';

import { ISensorDto } from 'Shared/Interfaces/Api/Sensors';
import { IDefectSensor, ISelectItem } from 'Shared/Interfaces/App';
import { ISensorPosition } from 'Shared/Interfaces/App/Simulation/ISensorPosition';

const MIN_ANGLE_VALUE = 0;
const MAX_ANGLE_VALUE = 85;

export default class SensorModel {
  constructor(dto?: ISensorDto) {
    makeAutoObservable(this, undefined, { autoBind: true });
    if (!dto) return;

    this.id = dto.id;
    this.deviceTypeId = dto.deviceTypeId;
    this.name = dto.name;
    this.frequency = dto.frequency;
    this.minThickness = dto.maxThickness;
    this.maxThickness = dto.maxThickness;
  }

  public id = '';

  public isOnSurface = false;

  public deviceTypeId = '';

  public name = '';

  public x = 0;

  public y = 0;

  public frequency: number = 0;

  public minThickness: number | null = null;

  public maxThickness: number | null = null;

  public angle = 65;

  public get sensorPosition(): ISensorPosition {
    return { x: this.x, y: this.y };
  }

  public onSurface(isOnSurface: boolean) {
    this.isOnSurface = isOnSurface;
  }

  public updatePosition(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  public onAngleChange(value: number | null) {
    if (!value) {
      this.angle = 0;
      return;
    }

    if (value < MIN_ANGLE_VALUE) {
      this.angle = MIN_ANGLE_VALUE;
    }

    if (value > MAX_ANGLE_VALUE) {
      this.angle = MAX_ANGLE_VALUE;
    }

    if (value >= MIN_ANGLE_VALUE && value <= MAX_ANGLE_VALUE) {
      this.angle = value;
    }
  }

  public get inputAngle(): number | null {
    return this.angle;
  }

  public getSelectItem(): ISelectItem {
    return { id: this.id, name: this.name };
  }

  public initChartSensor(data: IDefectSensor) {
    this.angle = data.angle;
    this.frequency = data.frequency;
  }
}
