export const departments = [
  { id: '00000000-0000-0000-0000-000000000001', name: 'Оборудование нефтяной и газовой промышленности' },
  { id: '00000000-0000-0000-0000-000000000002', name: 'Оборудование работающее под избыточным давлением' },
  { id: '00000000-0000-0000-0000-000000000003', name: 'Деятельность в области использования атомной энергии' },
  { id: '00000000-0000-0000-0000-000000000004', name: 'Здания и сооружения (строительные объекты)' },
  { id: '00000000-0000-0000-0000-000000000005', name: 'Подъемные сооружения' },
  {
    id: '00000000-0000-0000-0000-000000000006',
    name: 'Оборудование взрывопожароопасных и химически опасных производств (технологические трубопроводы)',
  },
  { id: '00000000-0000-0000-0000-000000000007', name: 'Трубы стальные бесшовные горячедеформированные' },
  { id: '00000000-0000-0000-0000-000000000008', name: 'Трубы стальные электросварные прямошовные' },
];
