import { makeStyles } from '@material-ui/core';
import { indents } from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  examFormWrapper: {
    height: 'fit-content',
    width: 'fit-content',
    paddingLeft: indents.fortyEight,
    paddingTop: indents.sixteen,
    paddingRight: indents.thirtyTwo,
    paddingBottom: indents.thirtyTwo,
  },
  examFormRow: {
    marginTop: indents.twentyFour,
    position: 'relative',
  },
  briefLabel: {
    display: 'flex',
    marginBottom: indents.eight,
    alignSelf: 'baseline',
  },
  examFormInput: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 450,
  },
  copyBtn: {
    position: 'absolute',
    right: -45,
    minWidth: 40,
    minHeight: 40,
    marginLeft: indents.eight,
  },
  examFormActionRow: {
    marginTop: indents.thirtyTwo,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  examFormContentWrapper: {
    display: 'flex',
  },
  inputsWrapper: {

  },
  briefWrapper: {
    margin: indents.thirtyTwo,
    marginLeft: indents.ninetySix,
    display: 'flex',
  },
  briefInput: {
    width: 491,
    height: 322,
  },
  spacer: {
    height: 64,
  },
}));

export default useStyles;
