import { action, makeObservable, observable } from 'mobx';
import { Application, Graphics, Text, TextStyle } from 'pixi.js';

import { measurementStore } from 'Stores';
import { NO_SENSOR } from 'Stores/MeasurementStore';
import { FlawDeviceParams, SensorModel } from 'Shared/Models/Devices';

import {
  IDefectDeviceParams,
  IDefectSensor,
  ILine,
  IPoint,
  ISampleDefectData
} from 'Shared/Interfaces/App';

import Utils from 'Shared/Utils';
import MathDefectsUtils from './MathDefectsUtils';
import {RayLength, SampleMode, SensorMode} from 'Shared/Enums';

interface ISignal {
  active: boolean,
  isLimit: boolean,
  amplitude: number,
  offset: number,
  lengthDef: number,
  crossPoint: IPoint,
}

interface IComplexSignal {
  signal1: ISignal,
  signal2: ISignal,
}

/**
 * График дефектоскопа
 */
export default class Chart {
  /**
   * Признак обновления динамических частей графика
   */
  isUpdate = false;

  /**
   * Цвет линии АПЧ
   */
  colorLineC = 0x009900;

  /**
   * Экземпляр PIXI
   */
  app: Application;

  /**
   * График
   */
  series: Graphics;

  /**
   * Линия зоны А (деффект)
   */
  lineA: Graphics;

  /**
   * Линия зоны Б (обнаружение)
   */
  lineB: Graphics;

  /**
   * Линия АПЧ
   */
  lineC: Graphics;

  /**
   * Сетка
   */
  graphGrid: Graphics;

  /**
   * Отсечка
   */
  graphTrim: Graphics;
  
  /**
   * Рамка выбора меток для режима обучения
   */
  graphLabelSelection: Graphics;
  
  /**
   * Метки графика
   */
  labels: Text;
  
  /**
   * Ширина пика Гауса
   */
  gaussWidth = 1;

  /**
   * Образец
   */
  sample: ISampleDefectData;
  
  /**
   * Цвет фона
   */
  backgroundColor = 0x000000;

  /**
   * Размер точек линии коррекции, пикс
   */
  correctLineSize = 4;

  /**
   * Параметры прибора
   */
  params: FlawDeviceParams;

  sensor = new SensorModel();
  
  x = 0;
  y = 0;

  width: number;
  height: number;
  
  /**
   *  Уровень (% экрана)
   */
  exceededLevel: number | null = null;

  /**
   * Таймер перерендера графика 
   */
  timer: any | null = null;

  public rayCalcMode: RayLength = RayLength.S0a;

  /**
   * Последняя метка на графике
   */
  lastLabel: string = "";
  
  /**
   * Constructor
   * @param view - HTML канва
   * @param sample
   * @param params - ссылка на параметры прибора
   */
  constructor(view: HTMLCanvasElement, sample: ISampleDefectData, params: IDefectDeviceParams) {
    makeObservable(this, {
      rayCalcMode: observable,
      params: observable,
      sensor: observable,

      initSensor: action,
      drawZones: action,
      update: action.bound,
      updateRayCalcMode: action.bound,
    });

    Utils.disableDblClick(view);
    view.style.userSelect = 'none';

    this.addSecurityPanel(view, sample);

    this.params = new FlawDeviceParams(params, this.update);
    this.sensor.initChartSensor(params.sensor);

    this.sample = sample;

    if (this.sample.mode === SampleMode.DefectCO3)
      measurementStore.calcCO3(this.sensor.angle, sample.defects);

    this.width = view.clientWidth;
    this.height = view.clientHeight;

    this.app = new Application({
      antialias: true,
      backgroundColor: this.backgroundColor,
      width: this.width,
      height: this.height,
      view,
    });
    
    this.series = new Graphics();
    this.app.stage.addChild(this.series);
    
    this.graphTrim = new Graphics();
    this.app.stage.addChild(this.graphTrim);
    
    this.graphGrid = new Graphics();
    this.app.stage.addChild(this.graphGrid);
    
    this.lineA = new Graphics();
    this.app.stage.addChild(this.lineA);
    
    this.lineB = new Graphics();
    this.app.stage.addChild(this.lineB);
    
    this.lineC = new Graphics();
    this.app.stage.addChild(this.lineC);
    
    this.graphLabelSelection = new Graphics();
    this.app.stage.addChild(this.graphLabelSelection);

    this.drawGrid();  
    this.drawZones();
    this.drawCorrectLine();

    const labelsFontStyle = new TextStyle({ fontSize: 20, fontWeight: 'bold', fill: '#ffff00' });
    this.labels = new Text("", labelsFontStyle);
    this.labels.anchor.set(0, 0);
    this.labels.x = 10;
    this.labels.y = 10;
    this.app.stage.addChild(this.labels);
    
    this.app.renderer.plugins.interaction.cursorStyles.pointer = 'crosshair';
    this.app.renderer.plugins.interaction.cursorStyles.default = 'crosshair';
    this.app.renderer.plugins.interaction.setCursorMode('crosshair');
    
    this.render();
  }

  /**
   * Старт таймера
   */
  public start = () => {
    this.timer = window.setInterval(() => this.render(), 100);
  };

  /**
   * Стоп таймера
   */
  public stop = () => {
    if (this.timer) clearInterval(this.timer);
  };

  /**
   * Инит сенсора
   * @param sensor
   */
  public initSensor(sensor: IDefectSensor) {
    this.sensor.initChartSensor(sensor);
  }
  
  /**
   * Устанока новых координат
   * @param x
   * @param y
   */
  public setCoord = (x: number, y: number) => {
    this.x = x;
    this.y = y;
  };

  /**
   * Задает режим луча S(0-a) S(a-b)
   * @param mode
   */
  public updateRayCalcMode(mode: RayLength) {
    this.rayCalcMode = mode;
  }

  /**
   * Рисованеи сетки
   */
  private drawGrid() {
    const tickCount = 10;
    const tickRadius = 1;
    const tickRadiusCenter = 3;
    const stepWidth = this.width / tickCount;
    const stepHeight = this.height / tickCount;
    for (let i = 0; i <= this.width + 1; i += stepWidth) {
      for (let j = 0; j <= this.height + 1; j += stepHeight) {
        this.graphGrid.beginFill(0xffffff, 0.7);
        this.graphGrid.drawCircle(i, j, tickRadius);
        // Центральные линии
        if (Math.abs(i - this.width / 2) < 1 || Math.abs(j - this.height / 2) < 1)
          this.graphGrid.drawCircle(i, j, tickRadiusCenter);
        this.graphGrid.endFill();
      }
    }
  }
  
  /**
   * Рисование зон
   */
  public drawZones = () => {
    this.lineA.clear();
    this.lineB.clear();

    const { zoneA, zoneB } = this.params;

    if (!zoneA || !zoneB) return;

    const xa1 = this.xToPx(zoneA.start);
    const xa2 = this.xToPx(zoneA.start + zoneA.width);
    const ya = this.height - this.yToPx(zoneA.height);

    this.lineA.lineStyle(2, 0xff0000, 1);
    this.lineA.moveTo(xa1, ya);
    this.lineA.lineTo(xa2, ya);
    this.lineA.beginFill(0xff0000, 1);
    this.lineA.drawCircle(xa1, ya, 4);
    this.lineA.drawCircle(xa2, ya, 4);
    this.lineA.endFill();

    const xb1 = this.xToPx(zoneB.start);
    const xb2 = this.xToPx(zoneB.start + zoneB.width);
    const yb = this.height - this.yToPx(zoneB.height);

    this.lineB.lineStyle(2, 0xff00ff, 1);
    this.lineB.moveTo(xb1, yb);
    this.lineB.lineTo(xb2, yb);
    this.lineB.beginFill(0xff00ff, 1);
    this.lineB.drawCircle(xb1, yb, 4);
    this.lineB.drawCircle(xb2, yb, 4);
    this.lineB.endFill();
  };
  
  /**
   * Обновление ВРЧ и зон
   */
  public update() {
    this.isUpdate = true;
  }

  private addSecurityPanel(view: HTMLCanvasElement, sample: ISampleDefectData) {
    const step = 0.2;
    
    // Если есть панель размера дейектов, то удаляем
    const chartSecurityPanelName = 'chart-security-panel';
    const chartSecurityPanel = document.getElementById(chartSecurityPanelName);
    if (chartSecurityPanel) chartSecurityPanel.remove();
    
    // Панель размера дефектов
    const security_panel = document.createElement('div');
    security_panel.className = 'chart-security-panel';
    security_panel.id = chartSecurityPanelName;
    sample.defects.forEach(defect => {
      const sizePanel = document.createElement('div');
      sizePanel.style.display = 'flex';
      const valuePanel = document.createElement('div');
      const incButton = document.createElement('button');
      const decButton = document.createElement('button');
      valuePanel.innerText = defect.size.toString();
      valuePanel.style.width = '50px';
      incButton.innerText = '+';
      decButton.innerText = '-';
      sizePanel.appendChild(valuePanel);
      sizePanel.appendChild(incButton);
      sizePanel.appendChild(decButton);
      security_panel.appendChild(sizePanel);

      incButton.onclick = () => {
        defect.size += step;
        valuePanel.innerText = defect.size.toFixed(2);
      };
      decButton.onclick = () => {
        if (defect.size.toFixed(2) == step.toFixed(2)) return;

        defect.size -= step;
        valuePanel.innerText = defect.size.toFixed(2);
      };
    });
    
    view.parentNode?.appendChild(security_panel);

    // Отображаем панель
    document.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.code == 'KeyH' && e.ctrlKey && e.shiftKey) 
        if (security_panel.style.display === "block")
          security_panel.style.display = "none";
      else
          security_panel.style.display = "block";
    });
  }
  
  /**
   * Рамка меток
   * @param index - индекс выделения: 0, 1, 2
   * @private
   */
  public selectLabel(index: number | null) {
    this.graphLabelSelection.clear();

    if (!index?.toString()) return;

    const baseX = 6;
    const baseY = 8;
    this.graphLabelSelection.beginFill(0x0263b2, 0.3);
    this.graphLabelSelection.lineStyle(4, 0x0263b2, 1);
    this.graphLabelSelection.drawRoundedRect(baseX, baseY + index * 25, 200, 26, 5);
    this.graphLabelSelection.endFill();
  }

  /**
   * Отрисовка графика
   */
  private render = () => {
    // Инициализация графика
    this.series.clear();

    // Обновление ВРЧ и зон
    if (this.isUpdate) {
      this.drawZones();
      this.drawCorrectLine();
      this.drawTrim();

      this.isUpdate = false;
    }
    
    this.lineC.visible = this.params.vrc?.visible!;

    let maxValue: number = 0;
    let lastValue: number = 0;

    // Нет графика, если:
    // 1. Не выбран датчик
    // 2. Не комбенирвоаный режим
    const isEmptyChart = (
        !measurementStore.selectedSensor || 
        measurementStore.chart?.params.sensor.mode !== SensorMode.Combined);

    this.series.lineStyle(1, 0xffff00, 1);
    this.series.position.x = 0;
    this.series.position.y = this.height;
    this.series.moveTo(0, 0);

    const complexSignal = this.calc();

    isEmptyChart ? this.setLabelEmpty() : this.setLabels(complexSignal);
    
    // Развёртка
    const noiseA = 2e-1;
    // Шаг развертки
    const stepScan = this.params.scanWidth / this.width;
    for (let x = 0; x < this.params.scanWidth; x += stepScan) {
      const noise = Math.random() * noiseA + Utils.randomProb(noiseA, 0.1) + Utils.randomProb(2 * noiseA, 0.01);
      const zeroPeak = MathDefectsUtils.getGauss(x, 1e2, -1, this.gaussWidth * 2);
      let value = noise + zeroPeak;
      
      const gauss = (a: number, f: number) => MathDefectsUtils.getGauss(x, a, f, this.gaussWidth)

      // Нет пика, если:
      // 1. Не нанесена жидкость
      if (measurementStore.sample?.isSurfaceWet)
        complexSignal?.forEach(data => {
          const { signal1, signal2 } = data;
          
          if (signal1.active) value += gauss(signal1.amplitude, signal1.offset);
          if (signal2.active) value += gauss(signal2.amplitude, signal2.offset);
        });

      // Усиление
      value *= measurementStore.selectedSensor.id !== NO_SENSOR.id ? this.dbToK(this.params.amplification) : 0;

      // Сдвиг по растоянию луча
      const xOffset = this.xToPx(x - this.params.offset);

      // Коррекция по ВРЧ
      value = this.filterByVrc(xOffset, value);
      
      if (isEmptyChart) value = 0;
      
      this.series.lineTo(xOffset, -value);
      
      // Поиск максимума пиков. Ищем только на нарастающих пиках, что-бы исключить фонвый пик в начале   
      if (isFinite(value) && value < this.height && value > maxValue && value > lastValue) maxValue = value;
      lastValue = value;
    }

    this.exceededLevel = maxValue / this.height;
  };

  /**
   * Отсечка
   */
  private drawTrim() {
    this.graphTrim.clear();
    this.graphTrim.beginFill(0x000000, 1);
    this.graphTrim.drawRect(0, this.height, this.width, -this.yToPx(this.params.trim));
    this.graphTrim.endFill();
  }

  /**
   * Коррекция точки по ВРЧ
   * @param xOffset - сдвиг
   * @param value - значение
   */
  private filterByVrc(xOffset: number, value: number): number {
    if (!this.params.vrc?.enable) return value;

    const { points } = this.params.vrc;

    // Попадаения в граничные отрезки
    if (xOffset <= this.xToPx(points[0].x)) value = value * this.dbToK(points[0].y);
    if (xOffset >= this.xToPx(points[points.length - 1].x)) value = value * this.dbToK(points[points.length - 1].y);

    // Попадаения в отрезки между границами
    for (let i = 0; i < points.length - 1; i++) {
      const point1 = points[i];
      const point2 = points[i + 1];

      if (xOffset >= this.xToPx(point1.x) && xOffset <= this.xToPx(point2.x)) {
        const y = MathDefectsUtils.getLine(this.xToDev(xOffset), point1, point2);
        value = value * this.dbToK(y);
      }
    }
    
    return value;
  }
  
  /**
   * Пересчёт из ширины сканирования в px
   */
  private xToPx(value: number): number {
    return value * (this.width / this.params.scanWidth);
  };

  /**
   * Пересчёт из высоты экрана в px
   */
  private yToPx(value: number): number {
    return (this.height * value) / 100;
  };

  /**
   * Пересчёт из ширины сканирования в px
   */
  private xToDev(value: number): number {
    return value / (this.width / this.params.scanWidth);
  };

  /**
   * Из dB в разы
   * @param db - значение в Дб
   */
  private dbToK(db: number): number {
    return  Math.pow(10, db / 20);
  }

  /**
   * Перевод в Дб
   * @param value
   */
  private toDb(value: number): number {
    return 20 * Math.log10(value);
  }

  /**
   * Амплитуда пика
   * @param length - Длина луча
   * @param length_Eps - Длина перпендикуляра от дефекта до луча
   * @param length_xy - Длина отрезка от ПИП до ближайшего края дефекта по поверхности (XY)
   * @param size
   */
  private amplitude(length: number, length_Eps: number, length_xy: number, size: number) {
    const minLimitLength = 0.2; // Минимальная длина
    const fadingK = 1; // Степень коэффициента затухания

    // Если < 1 мм
    if (length < minLimitLength) length = minLimitLength;
    if (length_Eps < minLimitLength) length_Eps = minLimitLength;
    if (length_xy < minLimitLength) length_xy = minLimitLength;

    //let a: number = parseFloat(localStorage.getItem('a') ?? "0.055"); // Базовая амплитуда
    //const k: number = parseFloat(localStorage.getItem('k') ?? "0.7"); // Степень ослабления сигнала по мере прохождения им единицы длины пути по материалу

    let a: number = 0.017;
    const depth = this.sample.mode === SampleMode.DefectCO3 ? 55 : this.sample.depth;
    const k: number = depth * 7.2; 
    a *= this.toDb(1 / length * k);
    
    return size * a *
        (1 / Math.pow(length_Eps, fadingK)) *
        (1 / Math.pow(length_xy, fadingK));
  }
  
  /**
   * Расчёт параметров пика
   * @return {IComplexSignal | null}
   */
  private calc() {
    if (!this.sensor || !this.sample) return null;
    
    const { defects, depth } = this.sample;

    const result: IComplexSignal[] = [];

    // Точка ПИП
    const pointStart = <IPoint> { x: this.x, y: 0 };
    
    // Смещение отражения по Х относительно 0
    const shiftX = depth * Math.tan((this.sensor.angle * Math.PI / 180)) * (this.params.sensor.isRight ? 1 : -1);
    
    // --- 1 луч     
    const pointRef1 = <IPoint> { x: this.x + shiftX, y: depth };     // Точка отражения 1 от низа
    let line1 = <ILine> { point1: pointStart, point2: pointRef1 };   // Отрезок 1 луча
    let length1 = MathDefectsUtils.getLength(pointStart, pointRef1); // Полная длина 1 луча
    
    // --- 2 луч
    const pointRef2 = <IPoint> { x: pointRef1.x + shiftX, y: 0 };     // Точка отражения 2 - от верха
    const line2 = <ILine> { point1: pointRef1, point2: pointRef2 };   // Отрезок 2 луча
    const length2 = MathDefectsUtils.getLength(pointRef1, pointRef2); // Полная длина 2 луча

    // Коррекция на протектор
    const protector = this.params.sensor.protector - 6.5;

    // Корреция на скорость
    const df = (this.params.speed - 3200) / 100;
    
    // Обработка дефектов относительно 1 и 2 лучей 
    defects.forEach(def => {
      const pointDefect = <IPoint> { x: def.x, y: def.depth };  // Точка дефекта

      // --- 1 луч
      // Точка пересечения 1 луча и перпендикуляра из дефекта
      let pointCross1 = MathDefectsUtils.getCrossPoint(line1, pointDefect);
      // Длина перпендикуляра от дефекта до 1 луча
      const lengthDef1 = MathDefectsUtils.getLength(pointCross1, pointDefect);
      // Длина 1 луча от ПИП для точки перпендикуляра от дефекта
      let length_start_def = MathDefectsUtils.getLength(pointStart, pointCross1);
      length_start_def += protector + df;

      // --- 2 луч
      // Точка пересечения 2 луча и перпендикуляра из дефекта
      let pointCross2 = MathDefectsUtils.getCrossPoint(line2, pointDefect);
      // Длина перпендикуляра от дефекта до 2 луча
      const lengthDef2 = MathDefectsUtils.getLength(pointCross2, pointDefect);
      // Длина 2 луча от ПИП для точки перпендикуляра от дефекта
      let length_ref1_def = MathDefectsUtils.getLength(pointRef1, pointCross2);
      
      // Попадает ли точка перпендикуляра в луч
      const isExistOnLine1 = MathDefectsUtils.isNodeInsideLine(line1, pointCross1);
      const isExistOnLine2 = MathDefectsUtils.isNodeInsideLine(line2, pointCross2);
      
      // Длина л2 - это полная длина л1 + длина л2 до дефекта
      const length_ref1_original = length_ref1_def;
      length_ref1_def = length1 + length_ref1_def + protector + df;

      // Разница ПИП и дефекта по Y
      let length_xy = Math.abs(this.y - def.y); 

      // Если протяженный дефект
      if (def.length > 0) {
        length_xy = 0; // По дефолту - нутр
        // Вне нутра
        if (this.y < def.y || this.y > def.y + def.length)
          length_xy = Math.min(Math.abs(this.y - (def.y + def.length)), Math.abs(this.y - def.y));         
      }

      // Если ушел вниз
      const isBottomLimit = pointCross1.y >=depth;
      // Если ушел вверх
      const isTopLimit = pointCross2.y <= 0;

      // Активность точки
      const maxLength = 4; // Макс длина перпендикуляра, после котрого точка игнорируется, мм
      const maxXY = 4;     // Макс длина от края дефекта, после которого точка игнорируется, мм
      const minLengthReflectionRay = 10; // Длина мёртвой зоны 2 отражённого луча 
      const active1 = (isExistOnLine1 || isBottomLimit) && lengthDef1 <= maxLength && length_xy <= maxXY;
      const active2 = (isExistOnLine2 || isTopLimit) && lengthDef2 <= maxLength && length_xy <= maxXY && length_ref1_original > minLengthReflectionRay;

      // Амплитуды
      const amplitude1 = this.amplitude(length_start_def, lengthDef1, length_xy, def.size ?? 1);
      const amplitude2 = this.amplitude(length_ref1_def, lengthDef2, length_xy, def!.size ?? 1)
      
      const complexSignal = <IComplexSignal> {
        signal1: {
          active: active1,
          amplitude: amplitude1,
          offset: length_start_def,
          lengthDef: lengthDef1,
          crossPoint: pointCross1,
          isLimit: isBottomLimit,
        },
        signal2: {
          active: active2,
          amplitude: amplitude2,
          offset: length_ref1_def,
          lengthDef: lengthDef2,
          crossPoint: pointCross2,
          isLimit: isTopLimit,
        },
      }
      
      result.push(complexSignal);
    })

    return result;
  }
  
  private getLabelSh() {
    let shLabel: string = "H";
      switch (this.rayCalcMode) {
      case RayLength.H: { shLabel = "H"; break; }
      case RayLength.S0a: { shLabel = "S[0-a]"; break; }
      case RayLength.Sab: { shLabel = "S[a-b]"; break; }
    }
    
    return shLabel;
  }
  
  private setLabelEmpty() {
    const label = `${this.getLabelSh()} = ---\nY = ---\nX = ---`;
    if (label === this.lastLabel) return;
    
    this.labels.text = label;
    this.lastLabel = label;
  }

  /**
   * Текстовые метки
   * @param result
   */
  private setLabels(result: IComplexSignal[] | null) {
    if (!result || result.length === 0) {
      this.setLabelEmpty();
      return;
    }

    // Расчёт смещение от ПИП до первого дефекта и глубины залегания первого дефекта
    let x: number = 0;
    let y: number = 0;
    let labelX: string = "---";
    let labelY: string = "---";
    let nearestSignal1 = null;
    let nearestSignal2 = null;

    // Активные дефекты в прямом и отраженном сигналах
    const activeSignals1 = result.filter(_ => _.signal1.active);
    const activeSignals2 = result.filter(_ => _.signal2.active);

    if (result.length > 0) {
      // Первый обнаруженый пик в прямом сигнале, ближайший к ПИП
      if (activeSignals1 && activeSignals1.length > 0) {
        // Если в прямом сигнале есть активность
        nearestSignal1 = activeSignals1
            .reduce((p, c) => p.signal1.offset < c.signal1.offset ? p : c);
        x = Math.abs(this.x - nearestSignal1.signal1.crossPoint.x);
        y = nearestSignal1.signal1.crossPoint.y;
        // Если задана толщина и если вышли за нижнюю границу
        if (this.params.depth > 0 && nearestSignal1.signal1.isLimit) y = 2 * this.params.depth - y;
      } else {
        // Первый обнаруженый пик в отраженном сигнале, ближайший к ПИП
        if (activeSignals2 && activeSignals2.length > 0) {
          // Если в отраженном сигнале есть активность
          nearestSignal2 = activeSignals2
              .reduce((p, c) => p.signal2.offset < c.signal2.offset ? p : c);
          x = Math.abs(this.x - nearestSignal2.signal2.crossPoint.x);

          y = nearestSignal2.signal2.crossPoint.y;
          // Если толщина в приборе = 0, то глубина найденного дефекта расчитывается как 2 * depth - defectDepth     
          const yThrough = 2 * this.sample.depth - y;
          if (this.params.depth === 0) y = yThrough;
          else {
            const n = Math.floor(yThrough / this.params.depth);
            if (n % 2 == 0) y = yThrough - (n * this.params.depth)
            else y = (n + 1) * this.params.depth - yThrough;
          }
        }
      }

      // Коррекция на протектор
      const protector = this.params.sensor.protector - 6.5;
      
      // Корреция на скорость
      const df = (this.params.speed - 3200) / 100;
      
      x += protector + df;
      y -= protector + df;

      labelX = x.toFixed(0).toString();
      labelY = y.toFixed(1).toString();
    }
    
    // Расчёт H/S 
    let sh = "---";

    const { zoneA, zoneB } = this.params;
    switch (this.rayCalcMode) {

      // Разница м/у пиком и зоной А
      case RayLength.H: {
        const dhZone: number = this.yToPx(zoneA!.height);

        // Расчёт разницы между зоной А и пиком
        const calcH = (signal: ISignal) => {
          const { offset, amplitude } = signal;

          // Если не входит в зону А
          if (!(offset > zoneA!.start && offset < zoneA!.start + zoneA!.width)) return;

          // Амплитуда с учётом усиления и ВРЧ
          const offsetPx = this.xToPx(offset + this.params.offset);
          const fixAmplitude = this.filterByVrc(offsetPx, amplitude * this.dbToK(this.params.amplification));
          
          const dbAmplitude = this.toDb(1 / fixAmplitude);
          const dbZone = this.toDb(1 / dhZone);
          const dbDiff = dbZone - dbAmplitude;
          const sign = dbDiff < 0 ? "- " : "" ;
          sh = sign + Math.abs(dbDiff).toFixed(1).toString();
        }

        if (nearestSignal1) calcH(nearestSignal1.signal1);      // Сигнал прямой
        else if (nearestSignal2) calcH(nearestSignal2.signal2); // Сигнал отражённый

        break;
      }

      // Расчёт длины луча первого пика в границах: 0 - конец зоны А
      case RayLength.S0a: {
        const calcS0a = (signal: ISignal) => {
          const { offset } = signal;
          if (offset > zoneA!.start && offset < zoneA!.start + zoneA!.width)
            sh = offset.toFixed(1).toString();
        }

        if (nearestSignal1) calcS0a(nearestSignal1.signal1);
        else if (nearestSignal2) calcS0a(nearestSignal2.signal2);
        break;
      }

      // Длина луча от ПИП до первого отражения в диапазоне от конца Б зоны до начала А зоны
      case RayLength.Sab: {
        // Берем смещение сигнала второго дефекта прямого луча и умножаем на 1.5 
        if (result.length > 1) {
          // Т.к. используется только для СО3, то берем отраженный луч от 1 или 3 дефектов (глубоких)
          let { offset } = result[1].signal1;
          const isRightSignal = result.length > 3 && !result[1].signal1.active; 
          if (isRightSignal) offset = result[3].signal1.offset;

          // Если попадаем в зону А 
          if (offset > zoneA!.start + zoneA!.width && offset < zoneB!.start + zoneB!.width)
            if (isRightSignal) sh = (result[3].signal1.offset - result[2].signal1.offset).toFixed(1).toString();
            else sh = (result[1].signal1.offset - result[0].signal1.offset).toFixed(1).toString();
        }

        break;
      }
    }

    if (nearestSignal1) {
      const offset = nearestSignal1.signal1.offset;
      if (!((offset > zoneA!.start && offset < zoneA!.start + zoneA!.width) || (offset > zoneB!.start && offset < zoneB!.start + zoneB!.width)))  {
        labelY = "---";
        labelX = "---";
      } 
    } else if (nearestSignal2) {
      const offset = nearestSignal2.signal2.offset;
      if (!((offset > zoneA!.start && offset < zoneA!.start + zoneA!.width) || (offset > zoneB!.start && offset < zoneB!.start + zoneB!.width)))  {
        labelY = "---";
        labelX = "---";
      }
    }
    
    const label = `${this.getLabelSh()} = ${sh}\nY = ${labelY}\nX = ${labelX}`;
    if (label === this.lastLabel) return;
    
    this.labels.text = label;
    this.lastLabel = label;
  }

  /**
   * Рисование линии АПЧ
   */
  private drawCorrectLine = () => {
    this.lineC.clear();

    this.lineC.lineStyle(2, this.colorLineC, 1);

    if (!this.params.vrc) return;

    const { points, index } = this.params.vrc;

    // Начало линии
    const startX = 0;
    const startY: number = this.height / 2 / this.dbToK(points[0].y);
    this.lineC.moveTo(startX, startY);

    // Линии
    points.forEach((_) => {
      const x: number = this.xToPx(_.x);
      const y: number = this.height / 2 / this.dbToK(_.y);
      this.lineC.lineTo(x, y);
    });

    // Окончание линии
    const endX: number = this.width;
    const endY: number = this.height / 2 / this.dbToK(points[points.length - 1].y);
    this.lineC.lineTo(endX, endY);

    // Точка
    this.lineC.beginFill(0x000000, 1);
    this.lineC.drawRect(
        this.xToPx(points[index].x) - this.correctLineSize,
        this.height / 2 / this.dbToK(points[index].y) - this.correctLineSize,
        this.correctLineSize * 2,
        this.correctLineSize * 2
    );
    this.lineC.endFill();
  };
}
