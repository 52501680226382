import { makeStyles } from '@material-ui/core';
import { colors, indents } from '../theme';

const useStyles = makeStyles({
  checkboxWrapper: {
    marginRight: indents.eight,
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      '&:hover': {
        color: colors.primary.darkBlue,
      },
      color: colors.primary.mainBlue,
      backgroundColor: 'transparent',
      padding: `${indents.eight}px !important`,
    },
    '& .MuiCheckbox-colorSecondary': {
      backgroundColor: 'transparent',
      padding: `${indents.eight}px !important`,
    },
  },
});

export default useStyles;
