import { Theme, makeStyles } from '@material-ui/core';

import { borderRadius, colors, indents } from 'Components/uiKitElems';

const useStyles = makeStyles<Theme>((style) => ({
  studyTabsWrapper: {
    display: 'flex',
    marginTop: indents.thirtyTwo,
    marginLeft: indents.thirtyTwo,
    marginRight: indents.thirtyTwo,
  },
  mainTab: {
    paddingTop: indents.eight,
    paddingLeft: indents.sixteen,
    paddingRight: indents.sixteen,
    paddingBottom: indents.eight,
    cursor: 'pointer',
  },
  studyTab: {
    borderBottom: `solid ${colors.grayColor.strokeInputGray} 1px`,
    display: 'flex',
  },
  disabledCaption: {
    color: colors.grayColor.strokeInputGray,
  },
  lockIcn: {
    marginLeft: indents.eight,
    width: 15,
    color: colors.grayColor.strokeInputGray,
  },
  currentStudyTab: {
    borderRadius: `${borderRadius}px ${borderRadius}px ${0}px ${0}px`,
    borderTop: `solid ${colors.grayColor.strokeInputGray} 1px`,
    borderLeft: `solid ${colors.grayColor.strokeInputGray} 1px`,
    borderRight: `solid ${colors.grayColor.strokeInputGray} 1px`,
  },
  filler: {
    flex: 1,
    borderBottom: `solid ${colors.grayColor.strokeInputGray} 1px`,
  },
}));

export default useStyles;
