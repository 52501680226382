import { makeAutoObservable } from 'mobx';
import { createTheme } from "@material-ui/core";

import { measurementStore, studyCoursesStore } from './index';

import { IDefectDeviceParams, IDefectStateLSDto, IThickDeviceToolsState, IToolsStateLSDto} from 'Shared/Interfaces/App';

import { LocalStorageParam } from 'Shared/Enums';
import { deviceParams } from 'Shared/Constants/Simulation';

interface IAppStoreDef {
  putDataToLocalStorage(param: LocalStorageParam, value: string): void;
  removeParamFromLocalStorage(param: LocalStorageParam): void;
  getDataFromLocalStorage(param?: LocalStorageParam): string | null;
  // Сохраняет в LocalStorage текущие настройки дефектоскопа и окружения при обучении
  putDeviceEnvParamsToLocalStorage(): void;
  // Восстанавливает из LocalStorage текущие настройки дефектоскопа и окружения при обучении
  initDeviceEnvParamsFromLs(): void;

  putDefectNotesToLocalStorage(): void;

  initDefectNotesToLocalStorage(): void;
}

class AppStore implements IAppStoreDef {
  public mainTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1600,
        xl: 1920,
      },
    },
  });

  public isStudCardExpanded = true;

  public lastAdminTab = '';

  public lastToastMessage = '';

  public defectDeviceParam: IDefectDeviceParams = deviceParams;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
    const lastTab = this.getDataFromLocalStorage(LocalStorageParam.CurrentAdminTab);
    if (!lastTab) return;

    this.lastAdminTab = lastTab;
  }

  public studyCardToggle() {
    this.isStudCardExpanded = !this.isStudCardExpanded;
  }

  public showToastMessage(text: string) {
    this.lastToastMessage = text;
  }

  public closeToaster() {
    this.lastToastMessage = '';
  }

  public putDataToLocalStorage(param: LocalStorageParam, value: string): void {
    window.localStorage.setItem(param, value);
  }

  public removeParamFromLocalStorage(param: LocalStorageParam): void {
    window.localStorage.removeItem(param);
  }

  public getDataFromLocalStorage(param: LocalStorageParam): string | null {
    const value = window.localStorage.getItem(param);
    if (!value) return null;

    return value;
  }

  /**
   * Сохраняет окружение дефектоскопа в local storage
   */
  public putDeviceEnvParamsToLocalStorage(): void {
    if (!measurementStore.chart) return;

    const deviceParams: IDefectDeviceParams = {
      isPowerOn: true,
      amplification: measurementStore.chart.params.amplification,
      offset: measurementStore.chart.params.offset,
      depth: measurementStore.chart.params.depth,
      speed: measurementStore.chart.params.speed,
      trim: measurementStore.chart.params.trim,
      scanWidth: measurementStore.chart.params.scanWidth,
      vrc: {
        enable: !!measurementStore.chart.params.vrc?.enable,
        visible: !!measurementStore.chart.params.vrc?.visible,
        index: measurementStore.chart.params.vrc?.index!,
        points: [
          { x: measurementStore.chart.params.vrc?.points[0].x!, y: measurementStore.chart.params.vrc?.points[0].y! },
          { x: measurementStore.chart.params.vrc?.points[1].x!, y: measurementStore.chart.params.vrc?.points[1].y! },
        ],
      },
      zoneA: {
        start: measurementStore.chart.params.zoneA?.start!,
        width: measurementStore.chart.params.zoneA?.width!,
        height: measurementStore.chart.params.zoneA?.height!,
      },
      zoneB: {
        start: measurementStore.chart.params.zoneB?.start!,
        width: measurementStore.chart.params.zoneB?.width!,
        height: measurementStore.chart.params.zoneB?.height!,
      },
      sensor: {
        name: 'Сенсор 1',
        angle: measurementStore.chart.sensor.angle,
        isRight: measurementStore.chart.params.sensor.isRight,
        mode: measurementStore.chart.params.sensor.mode,
        tread: measurementStore.chart.params.sensor.tread,
        frequency: measurementStore.chart.params.sensor.frequency,
      },
    };

    const studyDefectDeviceEnv: IDefectStateLSDto = {
      deviceParams: deviceParams,
      lastCompletedStepId: studyCoursesStore.selectedCourse.selectedModule?.currentStep?.isDone
        ? studyCoursesStore.selectedCourse.selectedModule?.currentStep?.id!
        : studyCoursesStore.selectedCourse.selectedModule?.currentStep?.id! - 1,
    };

    window.localStorage.setItem(LocalStorageParam.FlawDeviceParams, JSON.stringify(studyDefectDeviceEnv));
  }

  public putDeviceToolsStateToLocalStorage() {
    const toolsState: IToolsStateLSDto = {
      wetSampleId: measurementStore.sample?.isSurfaceWet! ? measurementStore.selectedSampleId : '',
      sampleId: measurementStore.selectedSampleId,
      sensorId: measurementStore.selectedSensor.id,
      currentTab: measurementStore.currentDeviceTab,
      isPipOnSurface: measurementStore.selectedSensor.isOnSurface,
      sensor: {
        name: 'Сенсор 1',
        angle: measurementStore.chart?.sensor.angle!,
        isRight: measurementStore.chart?.params.sensor.isRight!,
        mode: measurementStore.chart?.params.sensor.mode!,
        tread: measurementStore.chart?.params.sensor.tread!,
        frequency: measurementStore.chart?.params.sensor.frequency!,
      },
    };

    window.localStorage.setItem(LocalStorageParam.ToolsState, JSON.stringify(toolsState));
  }

  public initDeviceToolsStateFromLs(): void {
    const toolsState = window.localStorage.getItem(LocalStorageParam.ToolsState);
    if (!toolsState) return;
    const parsed = JSON.parse(toolsState) as IToolsStateLSDto;
    if (!parsed) return;

    if (parsed.sensorId) {
      measurementStore.selectSensor(parsed.sensorId);
    }

    if (parsed.sensor) {
      measurementStore.chart?.params.sensor.init(parsed.sensor);
    }

    if (parsed.sampleId) {
      measurementStore.selectSample(parsed.sampleId);
    }

    if (parsed.currentTab) {
      measurementStore.setDeviceTab(parsed.currentTab);
    }

    if (parsed.isPipOnSurface) {
      // TODO VarlamovAA убрать setTimeOut
      setTimeout(() => measurementStore.dropSensorOnSample(null), 100);
    }

    if (parsed.wetSampleId === measurementStore.selectedSampleId) {
      // TODO VarlamovAA убрать setTimeOut
      setTimeout(() => measurementStore.sample?.putLiquidOnSurface(measurementStore.selectedSampleId), 100);
    }
  }

  /**
   * Восстанавливает окружение дефектоскопа из local storage
   */
  public initDeviceEnvParamsFromLs(): void {
    const flawDeviceParams = window.localStorage.getItem(LocalStorageParam.FlawDeviceParams);
    if (!flawDeviceParams) return;

    const parsed = JSON.parse(flawDeviceParams) as IDefectStateLSDto;
    if (!parsed) return;

    this.defectDeviceParam = parsed.deviceParams;

    measurementStore.chart?.params.init(this.defectDeviceParam);

    if (parsed.lastCompletedStepId) {
      studyCoursesStore.selectedCourse.selectedModule?.initCurrentStep(parsed.lastCompletedStepId);
      for (let i = 0; i < parsed.lastCompletedStepId - 1; i++) {
        studyCoursesStore.selectedCourse.selectedModule?.steps[i]?.onDone(true);
      }
    }
  }

  public initOnlyFlawDeviceParamFromLS(): void {
    const flawDeviceParams = window.localStorage.getItem(LocalStorageParam.FlawDeviceParams);
    if (!flawDeviceParams) return;

    const parsed = JSON.parse(flawDeviceParams) as IDefectStateLSDto;
    if (!parsed) return;

    this.defectDeviceParam = parsed.deviceParams;

    measurementStore.chart?.params.init(this.defectDeviceParam);
  }

  public clearStudyEnv() {
    window.localStorage.removeItem(LocalStorageParam.FlawDeviceParams);
    window.localStorage.removeItem(LocalStorageParam.ToolsState);
    window.localStorage.removeItem(LocalStorageParam.ThickDeviceToolState);
    window.localStorage.removeItem(LocalStorageParam.DefectNotes);
  }

  /**
   * Сохраняет заполненную таблицу дефектов в local storage
   */
  public putDefectNotesToLocalStorage(): void {
    window.localStorage.setItem(
      LocalStorageParam.DefectNotes,
      JSON.stringify(measurementStore.defectNotes.map((item) => item.getDtoToSave()))
    );
  }

  /**
   * Восстанавливает таблицу дефектоскопии из local storage
   */
  public initDefectNotesToLocalStorage(): void {
    const defectNotes = window.localStorage.getItem(LocalStorageParam.DefectNotes);
    if (!defectNotes) return;

    const parsed = JSON.parse(defectNotes);
    measurementStore.initDefectNoted(parsed);
  }

  public saveThickEnvToLocalStorage(): void {
    const toolsState: IThickDeviceToolsState = {
      isCheckWet: measurementStore.thickDevice.isSampleImitatorWet,
      isSensorOnCheck: measurementStore.thickDevice.isAboveSampleSimulator,
      isSwitchedOn: measurementStore.thickDevice.isSwitchedOn,
      wetSampleId: measurementStore.sample?.isSurfaceWet! ? measurementStore.selectedSampleId : '',
      sampleId: measurementStore.selectedSampleId,
      sensorId: measurementStore.selectedSensor.id,
      isPipOnSurface: measurementStore.selectedSensor.isOnSurface,
    };

    window.localStorage.setItem(LocalStorageParam.ThickDeviceToolState, JSON.stringify(toolsState));
  }

  public initThickEnvFromLocalStorage(): void {
    const toolsState = window.localStorage.getItem(LocalStorageParam.ThickDeviceToolState);
    if (!toolsState) return;

    const parsed = JSON.parse(toolsState) as IThickDeviceToolsState;
    if (!parsed) return;

    if (parsed.sensorId) {
      measurementStore.selectSensor(parsed.sensorId);
    }

    if (parsed.isSwitchedOn) {
      measurementStore.thickDevice.switchToggle();
    }

    if (parsed.isCheckWet) {
      measurementStore.thickDevice.dropLiquidOnImitator();
    }

    if (parsed.isSensorOnCheck) {
      measurementStore.thickDevice.setSensorAboveSimulator(true);
    }


    if (parsed.sampleId) {
      measurementStore.selectSample(parsed.sampleId);
    }

    if (parsed.isPipOnSurface) {
      // TODO VarlamovAA убрать setTimeOut
      setTimeout(() => measurementStore.dropSensorOnSample(null), 100);
    }

    if (parsed.wetSampleId === measurementStore.selectedSampleId) {
      // TODO VarlamovAA убрать setTimeOut
      setTimeout(() => measurementStore.sample?.putLiquidOnSurface(measurementStore.selectedSampleId), 100);
    }
  }

  public deInit() {
    this.defectDeviceParam = deviceParams;
  }
}

export default new AppStore();
