export enum VariantInput {
  Standard = 'standard',
  Outlined = 'outlined',
}

export enum TextInputSize {
  Sm = 'sm',
  Md = 'md',
  Lg = 'lg',
}
