import { InteractionEvent} from 'pixi.js';
import { GlowFilter } from '@pixi/filter-glow';
import Sample from '../Sample/SampleAbstract';
import { Container } from 'pixi.js';
import {IPoint} from "../../../Interfaces/App";

/**
 * ПИП абстрактный
 */
export default class PipAbstract {
  /**
   * Фильтр свечения
   */
  outlineFilter: GlowFilter = new GlowFilter();

  /**
   * Признак переноса
   */
  dragging: boolean = false;

  /**
   * Значание координат, когда зашли за границу
   */
  xValueStopMeasure: number | null = null;
  yValueStopMeasure: number | null = null;

  depthValue: string;
  /**
   * Объект который начали переносить
   */
  target: any = false;

  /**
   * Объект образца
   */
  sample: Sample;

  /**
   * Значенеи последней позции
   */
  lastPosition: IPoint | null = null;

  /**
   * Событие движения мышью
   */
  onDragMoveEvent: any;

  /**
   * ПИП
   */
  pip: Container;

  constructor(sample: Sample) {
    this.sample = sample;

    this.pip = new Container();

    this.depthValue = '';
  }

  /**
   * Добавление Pip
   * @param onDragMoveEvent
   * @constructor
   */
  public AddPip = (onDragMoveEvent: any) => {
    this.onDragMoveEvent = onDragMoveEvent;

    this.pip.interactive = true;
    this.pip.cursor = 'pointer';

    this.sample.app.stage.addChild(this.pip);

    this.pip
      .on('pointerover', this.onButtonOver)
      .on('pointerout', this.onButtonOut)
      .on('pointerdown', this.onDragStart)
      .on('pointerup', this.onDragEnd)
      .on('pointerupoutside', this.onDragEnd)
      .on('pointermove', this.onDragMove);
  };

  /**
   * Инитит ПИП после выбора датчика
   * @param point - абсолютные координаты на канве
   */
  public initPip(point: IPoint) {
    let { metric } = this.mathPositions(point);

    this.sample.metricX = metric.x;
    this.sample.metricY = metric.y;
    
    this.sample.relativeX = this.sample.sampleX + this.sample.toPx(metric.x);
    this.sample.relativeY = this.sample.sampleY + this.sample.sampleHeight - this.sample.toPx(metric.y);
    
    this.sample.setCoordPip();
  }

  public onSetDepthValue(depth: number): void {}

  /**
   * Поворот ПИП
   * @param angle
   */
  public setRotation(angle: number) {}

  private onButtonOver = (e: InteractionEvent) => {
    if (this.dragging) return;

    // Подсветка
    e.currentTarget.filters = [this.outlineFilter];

    this.sample.changeCursorMode('pointer');
  };

  private onButtonOut = (e: InteractionEvent) => {
    if (this.dragging) return;

    e.currentTarget.filters = [];

    this.sample.changeCursorMode('crosshair');
  };

  /**
   * Начало перемещения
   * @param e
   */
  private onDragStart = (e: InteractionEvent) => {
    this.dragging = true;
    this.target = e.target;

    this.sample.changeCursorMode('none');
  };

  /**
   * Окончание перемещения
   */
  private onDragEnd = () => {
    this.dragging = false;
    this.sample.changeCursorMode('pointer');
  };

  /**
   * Перемещение
   * @param e
   */
  onDragMove = (e: InteractionEvent) => {
    if (!this.dragging) return;

    this.xValueStopMeasure = null;
    this.yValueStopMeasure = null;
    
    const newPosition = e.data.getLocalPosition(this.target.parent);

    let { absolute, relative, metric } = this.mathPositions(newPosition);
    
    // Колбэк
    if (this.onDragMoveEvent) this.onDragMoveEvent(metric.x, metric.y);

    // Выход за границы образца
    {
      const pipHalfWidth = Math.abs(this.pip.width / 2);
      const pipHalfHeight = Math.abs(this.pip.height / 2);

      const outsideLeft = relative.x - pipHalfWidth < 0;
      const outsideRight = relative.x + pipHalfWidth > this.sample.sampleWidth;
      const outsideTop = relative.y - pipHalfHeight < 0;
      const outsideBottom = relative.y + pipHalfHeight > this.sample.sampleHeight;

      if (outsideLeft) this.xValueStopMeasure = this.sample.sampleX + pipHalfWidth;
      if (outsideRight) this.xValueStopMeasure = this.sample.sampleRightBorder - pipHalfWidth;
      if (outsideTop) this.yValueStopMeasure = this.sample.sampleY + this.sample.toPx(this.sample.height) - pipHalfHeight;
      if (outsideBottom) this.yValueStopMeasure = this.sample.sampleY + pipHalfHeight;
    }

    // Если блокировка перемещения, то берём старые позиции
    if (this.lastPosition && (this.xValueStopMeasure !== null || this.yValueStopMeasure !== null) ) {
      // Берём граничные координтаы
      this.lastPosition.x = this.xValueStopMeasure ?? this.lastPosition.x;
      this.lastPosition.y = this.yValueStopMeasure ?? this.lastPosition.y;

      // Пересчитываем позиции
      const lastPosition = this.mathPositions(this.lastPosition);
      absolute = lastPosition.absolute;
      relative = lastPosition.relative;
      metric = lastPosition.metric;

      // Повторный колбэк
      if (this.onDragMoveEvent) this.onDragMoveEvent(metric.x, metric.y);
    }
    
    this.pip.position.set(absolute.x, absolute.y);

    if (this.sample.textX) {
      // Позиция текстовых меток
      this.sample.textX.x = absolute.x;
      this.sample.textX.text = Math.round(metric.x).toString();
    }

    if (this.sample.textY) {
      this.sample.textY.y = absolute.y;
      this.sample.textY.text = Math.round(metric.y).toString();
    }

    if (this.sample.coordLines) {
      // Новое положение линий на Оy х oZ
      this.sample.coordLines.clear();
      this.sample.changeCoordLines(relative.x, relative.y);
    }
    
    if (this.lastPosition) this.lastPosition.y = newPosition.y;
    if (!this.xValueStopMeasure) this.lastPosition = newPosition;
  };
  
  private mathPositions(position: IPoint) {
    // Абсолютная позиция в контейнере PIXI, пикс
    const xAbsolute = position.x;
    const yAbsolute = position.y;

    // Позиция относительно левого нижнего угла образца, пикс
    const xRelative = xAbsolute - this.sample.sampleX;
    const yRelative = this.sample.sampleY + this.sample.sampleHeight - yAbsolute;

    // Позиция в метрических координатах, мм
    const xMetric = this.sample.toMetric(xRelative);
    const yMetric = this.sample.toMetric(yRelative);

    return {
      absolute: position,
      relative: <IPoint> { x: xRelative, y: yRelative },
      metric: <IPoint> { x: xMetric, y: yMetric },
    }
  }
}
