import React from 'react';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import { SizeSpinner, Spinner } from '../Spinner';

import { ISelectItem } from 'Shared/Interfaces/App';

import { H5Text } from '../textWrappers';

import useStyles from './styles';

interface IProps {
  className?: string;
  error?: boolean;
  itemsWrapperClass?: string;
  disabled?: boolean;
  height?: number;
  label?: string;
  isLoading?: boolean;
  items: ISelectItem[];
  values: string[];
  onChange(selected: any[]): void;
}

const SelectWrapper = (props: IProps) => {
  const { className, error, itemsWrapperClass, disabled, height, isLoading, items, label, onChange, values } = props;

  const c = useStyles({ height });

  const onChangeValue = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as string[]);
  };

  const renderSelected = (values: any) => {
    const selectedValues = items.filter((item) => values.includes(item.id));

    return (
      <div className={c.selectedItemsWrapper}>
        {selectedValues.map((item) => (
          <H5Text className={c.selectedItem} key={item.id} text={item.name} />
        ))}
      </div>
    );
  };

  return (
    <div className={`${className} ${c.wrapper}`}>
      <Spinner isLoading={!!isLoading} size={SizeSpinner.Sm} />
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        className={c.selectWrapper}
        error={error}
        disabled={disabled}
        multiple={true}
        variant="outlined"
        labelId="select-label"
        value={values}
        renderValue={renderSelected}
        onChange={onChangeValue}>
        {items.map((item) => (
          <MenuItem className={itemsWrapperClass || c.listItems} key={item.id} value={item.id}>
            {item.iconSrc && <img className={c.itemIcon} src={item.iconSrc} />}
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default SelectWrapper;
