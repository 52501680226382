import React, {DragEventHandler, ReactNode} from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';

import { measurementStore, studyCoursesStore } from 'Stores';

import { IFreeWrapperParam } from 'Shared/Interfaces/App';

import { StudyFocusEl } from 'Shared/Enums';

import useStyles from './styles';

interface IProps {
  className?: string;
  force?: boolean;
  isNotVisibleForMouse?: boolean;
  fullWidth?: boolean;
  isNotDisabled?: boolean;
  id: StudyFocusEl;
  children?: ReactNode;
  isLightBg?: boolean;
  freeParams?: IFreeWrapperParam;

  onDrop?(e: any): void;
}

const StudyFocusWrapper = (props: IProps) => {
  const { className, children, force, id, onDrop, fullWidth, isNotVisibleForMouse, isNotDisabled, isLightBg, freeParams } = props;

  const c = useStyles({ isNotVisibleForMouse, fullWidth, isLightBg });

  const onMouseDown = () => {
    measurementStore.updateMouseDownElement(id);
  };

  const onMouseUp = () => {
    measurementStore.updateMouseDownElement(null);
  };

  const isDisabled =
    !isNotDisabled && !studyCoursesStore.selectedCourse.selectedModule?.currentStep?.currentFocusIds?.includes(id);

  return (
    <div
      onDrop={onDrop}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      style={
        freeParams
          ? {
              zIndex: 14,
              position: 'absolute',
              width: freeParams?.width,
              height: freeParams?.height,
              top: freeParams?.top,
              left: freeParams?.left,
            }
          : {}
      }
      className={
        (studyCoursesStore.selectedCourse.selectedModule?.currentStep?.currentFocusIds?.includes(id)! || force)
          ? clsx(className, c.studyFocusWrapper)
          : clsx(className, c.studyFocusWrapperHidden)
      }>
      {isDisabled && !studyCoursesStore.selectedCourse.selectedModule?.isExam && <div className={c.studyLocker} />}
      {children}
    </div>
  );
};

export default observer(StudyFocusWrapper);
