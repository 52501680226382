import React from 'react';

import { H5Text } from 'Components/uiKitElems';

import useStyles from './styles';

const DefectCheckingTitleRow = () => {
  const c = useStyles();

  return (
    <div className={c.rowContainer}>
      <div className={c.leftCell}>
        <H5Text className={c.titleColumnCell} text={'Датчик'} />
      </div>

      <div className={c.middleCell}>
        <H5Text className={c.titleColumnCell} text={'Координата оХ, мм'} />
      </div>

      <div className={c.middleCell}>
        <H5Text className={c.titleColumnCell} text={'Координата У, мм'} />
      </div>

      <div className={c.middleCell}>
        <H5Text className={c.titleColumnCell} text={'Протяжённость дефекта, мм'} />
      </div>

      <div className={c.middleCell}>
        <H5Text className={c.titleColumnCell} text={'Превышение браковочного уровня, дБ'} />
      </div>

      <div className={c.middleCell}>
        <H5Text className={c.titleColumnCell} text={'Сокращённая запись'} />
      </div>

      <div className={c.middleCell}>
        <H5Text className={c.titleColumnCell} text={'Оценка допустимости'} />
      </div>

      <div className={c.middleCell}>
        <H5Text className={c.titleColumnCell} text={'Дефект'} />
      </div>

      <div className={c.rightCell}>
        <H5Text className={c.titleColumnCell} text={'Норма снижения'} />
      </div>
    </div>
  );
};

export default DefectCheckingTitleRow;
