import { makeStyles } from '@material-ui/core';

import { indents } from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
  },
  archiveContent: {
    marginTop: indents.thirtyTwo,
    height: 'calc(100% - 170px)',
  },
}));

export default useStyles;
