import { makeStyles } from '@material-ui/core';
import { bgColors, colors, indents } from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    height: '55vh',
    marginTop: indents.thirtyTwo,
  },
  noDataNotice: {
    position: 'absolute',
    top: '50%',
    left: '35%',
  },
  actionsRow: {
    marginTop: indents.thirtyTwo,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  userCheck: {
    display: 'flex',
    justifyContent: 'center',
  },
  selectAllCheckBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '56.3px',
    backgroundColor: colors.grayColor.strokeLightGray,
  },
  groupFormWrapper: {
    minWidth: '60vw',
    minHeight: '65vh',
    width: 'fit-content',
    paddingLeft: indents.fortyEight,
    paddingTop: indents.sixteen,
    paddingRight: indents.thirtyTwo,
    paddingBottom: indents.thirtyTwo,
  },
  filterWrapper: {
    width: '30%',
    marginLeft: indents.thirtyTwo,
  },
  groupFormRow: {
    marginTop: indents.twentyFour,
  },
  groupFormInput: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 537,
  },
  dateInput: {
    width: '48%',
  },
  groupFormActionRow: {
    marginTop: indents.thirtyTwo,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default useStyles;
