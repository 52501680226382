import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener/ClickAwayListener';
import { Delete, Edit } from '@material-ui/icons';

import CustomTable, { ITableColumn } from 'Components/Common/CustomTable';
import { Select } from 'Components/uiKitElems/Select';
import { ModalDialog } from 'Components/Common';
import { H5Text, IconButtonWrapper, NumberInput } from 'Components/uiKitElems';

import { appStore, measurementStore, studyCoursesStore } from 'Stores';

import { DefectNote } from 'Shared/Models/ForkFlow/DefectNote';
import { AdmissibilityLevels, DefectTableFocusedCell } from 'Shared/Enums';

import useStyles from './styles';

const ThicknessReportTable = () => {
  const c = useStyles();
  const ref = useRef<HTMLElement | null>(null);

  const goTopLastRow = () => {
    if (!ref) return;

    ref.current?.scrollIntoView({ block: 'center' });
  };

  useEffect(() => {
    goTopLastRow();
  }, [measurementStore.defectNotes.length]);

  useEffect(() => {
    appStore.initDefectNotesToLocalStorage();
  }, []);

  useEffect(() => {
    if (
      studyCoursesStore.selectedCourse.selectedModule?.currentStep?.id! === 19 &&
      measurementStore.defectNotes.length === 0
    ) {
      measurementStore.addDefectNote();
    }

    if (
      studyCoursesStore.selectedCourse.selectedModule?.currentStep?.id! < 19 &&
      measurementStore.defectNotes.length > 0
    ) {
      measurementStore.removeDefectNotes();
    }
  }, [studyCoursesStore.selectedCourse?.selectedModule?.currentStep?.id]);

  const [selectedRowId, setSelectedRowId] = useState(1);

  const thicknessColumns: ITableColumn[] = [
    {
      key: 'sensorName',
      label: 'ПЭП',
      align: 'center',
      width: '5%',
    },
    {
      key: 'defectNumber',
      label: '№ дефекта',
      align: 'center',
      width: '3%',
      cell: (row: DefectNote) => <>{row.defectNumber}</>,
    },
    {
      key: 'oY',
      label: 'X',
      align: 'center',
      width: '5%',
      cell: (row: DefectNote) => {
        const isEditThisCell = selectedRowId === row.defectNumber && row.focused === DefectTableFocusedCell.OX;

        const cellEditRender = (
          <NumberInput className={c.cellInput} height={30} value={row.oX!} onChange={row.onOXChange} />
        );

        const cellInfoRender = row.oX?.toString() ? (
          <H5Text className={c.cellInput} text={row.oX?.toString()} />
        ) : (
          <IconButtonWrapper icon={<Edit className={c.editCellIcon} />} />
        );

        const onMouseDown = () => {
          row.setFocused(DefectTableFocusedCell.OX);
          setSelectedRowId(row.defectNumber!);
        };

        return (
          <div className={c.cellInput} onMouseDown={onMouseDown} onBlur={() => row.setFocused(null)}>
            {isEditThisCell ? cellEditRender : cellInfoRender}
          </div>
        );
      },
    },
    {
      key: 'y',
      label: 'Y',
      align: 'center',
      width: '5%',
      cell: (row: DefectNote) => {
        const isEditThisCell = selectedRowId === row.defectNumber && row.focused === DefectTableFocusedCell.Y;

        const cellEditRender = (
          <NumberInput className={c.cellInput} height={30} value={row.y!} onChange={row.onYChange} />
        );

        const cellInfoRender = row.y?.toString() ? (
          <H5Text className={c.cellInput} text={row.y?.toString()} />
        ) : (
          <IconButtonWrapper icon={<Edit className={c.editCellIcon} />} />
        );

        const onMouseDown = () => {
          row.setFocused(DefectTableFocusedCell.Y);
          setSelectedRowId(row.defectNumber!);
        };

        return (
          <div className={c.cellInput} onMouseDown={onMouseDown} onBlur={() => row.setFocused(null)}>
            {isEditThisCell ? cellEditRender : cellInfoRender}
          </div>
        );
      },
    },
    {
      key: 'defectDepth',
      label: 'Толщина, мм',
      align: 'center',
      width: '5%',
      cell: (row: DefectNote) => {
        const isEditThisCell = selectedRowId === row.defectNumber && row.focused === DefectTableFocusedCell.DefectDepth;

        const cellEditRender = (
          <NumberInput className={c.cellInput} height={30} value={row.defectDepth!} onChange={row.onDepthChange} />
        );

        const cellInfoRender = row.defectDepth?.toString() ? (
          <H5Text className={c.cellInput} text={row.defectDepth?.toString()} />
        ) : (
          <IconButtonWrapper icon={<Edit className={c.editCellIcon} />} />
        );

        const onMouseDown = () => {
          row.setFocused(DefectTableFocusedCell.DefectDepth);
          setSelectedRowId(row.defectNumber!);
        };

        return (
          <div className={c.cellInput} onMouseDown={onMouseDown} onBlur={() => row.setFocused(null)}>
            {isEditThisCell ? cellEditRender : cellInfoRender}
          </div>
        );
      },
    },
    {
      key: 'marking',
      label: 'Оценка допустимости',
      align: 'center',
      width: '5%',
      cell: (row: DefectNote) => {
        const onChangeNormal = (value: AdmissibilityLevels) => {
          row.onSetAdmissibility(value);
        };

        return (
          <div className={''}>
            <Select
              className={c.selectCellInput}
              height={30}
              items={[
                { id: AdmissibilityLevels.NotSet, name: '', iconSrc: 'icons/not-set.svg' },
                { id: AdmissibilityLevels.Normal, name: '', iconSrc: 'icons/normal.svg' },
                { id: AdmissibilityLevels.NotNormal, name: '', iconSrc: 'icons/not-normal.svg' },
              ]}
              value={row.admissibility}
              onChange={onChangeNormal}
            />
          </div>
        );
      },
    },
    {
      key: 'actions',
      label: '',
      align: 'center',
      width: '3%',
      cell: (row: DefectNote) => {
        const onRemoveDefectNote = () => {
          measurementStore.showDefectNoteRemoveDialog(row.defectNumber!);
        };

        return <IconButtonWrapper icon={<Delete />} onClick={onRemoveDefectNote} />;
      },
    },
  ];

  const onCloseRemoveDialog = () => {
    measurementStore.showDefectNoteRemoveDialog(null);
  };

  const onClickAway = () => {
    measurementStore.defectNotes.forEach((item) => item.setFocused(null));
  };

  return (
    <>
      <ModalDialog
        onClose={onCloseRemoveDialog}
        onConfirm={measurementStore.removeDefectNote}
        isOpen={!!measurementStore.defectNoteIdToRemove}
        title={
          measurementStore.defectNoteIdToRemove
            ? `Удалить записть о дефекте №${measurementStore.defectNoteIdToRemove}`
            : ''
        }
      />

      <ClickAwayListener onClickAway={onClickAway}>
        <div className={c.tableListenerWrapper}>
          <CustomTable
            lastRowRef={ref}
            className={c.tableWrapper}
            customClassCell={c.measurementInputCell}
            dataKey={'defectNumber'}
            columns={thicknessColumns}
            data={measurementStore.defectNotes}
          />
        </div>
      </ClickAwayListener>
    </>
  );
};

export default observer(ThicknessReportTable);
