import React from 'react';

import { Lock } from '@material-ui/icons';

import { H4Text } from 'Components/uiKitElems/textWrappers';

import { ISelectItem } from 'Shared/Interfaces/App';
import { CoursesId } from 'Shared/Enums';

import useStyles from './styles';

interface IProps {
  items: ISelectItem[];
  selected: CoursesId;
  tabsWrapperClassName: string;
  enabledTabIds: CoursesId[];

  onChange: (selected: CoursesId) => void;
}

const Tabs = (props: IProps) => {
  const { enabledTabIds, selected, items, onChange, tabsWrapperClassName } = props;

  const c = useStyles();

  return (
    <div className={tabsWrapperClassName || c.studyTabsWrapper}>
      {items.map((item) => {
        const isDisabled = !enabledTabIds.includes(item.id as CoursesId);

        const onChangeTab = () => {
          if (isDisabled) {
            return;
          }

          onChange(item.id as CoursesId);
        };

        const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.code === 'Enter') {
            onChangeTab();
          }
        };

        const isCurrent = selected === item.id;

        return (
          <div
            onKeyDown={onKeyDown}
            tabIndex={0}
            className={
              isCurrent
                ? `${c.currentStudyTab} ${c.mainTab} unselectable`
                : `${c.studyTab} ${c.mainTab} ${isDisabled ? c.disabled : ''} unselectable`
            }
            key={item.id}
            onClick={onChangeTab}>
            <H4Text noWrap={true} className={isDisabled ? c.disabledCaption : ''} text={item.name} />
            {isDisabled && <Lock className={c.lockIcn} />}
          </div>
        );
      })}

      <div className={c.filler} />
    </div>
  );
};

export default Tabs;
