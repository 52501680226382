import { makeStyles } from '@material-ui/core';
import { indents } from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  userFormWrapper: {
    paddingLeft: indents.fortyEight,
    paddingTop: indents.sixteen,
    paddingRight: indents.thirtyTwo,
    paddingBottom: indents.thirtyTwo,
  },
  userFormRow: {
    marginTop: indents.twentyFour,
  },
  passwordInput: {
    width: 490,
  },
  departmentsSelect: {
    width: 490,
  },
  rowBtn: {
    minWidth: 40,
    maxHeight: 40,
    marginLeft: indents.eight,
  },
  userFormInput: {
    width: 538,
  },
  userFormActionRow: {
    marginTop: indents.thirtyTwo,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default useStyles;
