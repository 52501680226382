import { Theme, makeStyles } from '@material-ui/core';
import { borderRadius, colors, inputBorderRadius } from '../theme';

interface IProps {
  height?: number;
}

const useStyles = makeStyles<Theme, IProps>((theme) => ({
  errorIcon: {
    color: 'red',
  },
  smTextInputClass: props => ({
    '& .MuiInputBase-root': {
      height: props.height || '32px',
    },
  }),
  mdTextInputClass: props => ({
    '& .MuiInputBase-root': {
      height: props.height || '40px',
      minWidth: '72px',
    },
  }),
  lgTextInputClass: props => ({
    '& .MuiInputBase-root': {
      height: props.height || '48px',
      minWidth: '80px',
    },
  }),
  inputWrapper: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.primary.mainBlue,
      },
      '&:hover fieldset': {
        borderColor: colors.primary.darkBlue,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.primary.darkBlue,
      },
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiInputBase-root': {
      borderRadius: inputBorderRadius,
    },
  },
}));

export default useStyles;
