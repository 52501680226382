import { makeStyles } from '@material-ui/core';

import { bgColors } from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  examWrapper: {
    height: 'calc(100% - 60px)',
    width: '100%',
    position: 'absolute',
    zIndex: 2,
    backgroundColor: bgColors.light,
    overflow: 'hidden',
  },
}));

export default useStyles;
