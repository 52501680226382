import { makeStyles, Theme } from '@material-ui/core';
import { indents, typoColors } from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  wrapperDisabled: {
    paddingTop: indents.sixteen,
    opacity: 0.6,
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
    minWidth: '400px',
  },
  studyModuleWrapper: {
    position: 'relative',
    paddingTop: indents.sixteen,
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
    minWidth: '400px',
  },
  moduleNameInner: {
    marginTop: indents.sixteen,
    marginBottom: indents.eight,
  },
  contentWrapper: {
    marginTop: indents.fortyEight,
    display: 'flex',
    flexDirection: 'row',
    '&:hover': {
      boxShadow: `0px 10px 20px rgba(0, 0, 0, 0.1)`,
    },
    cursor: 'pointer',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: indents.twentyFour,
    paddingTop: indents.twentyFour,
    marginRight: indents.thirtyTwo,
  },
  moduleDesc: {
    textAlign: 'start',
    color: typoColors.bodyGrey,
  },
}));

export default useStyles;
