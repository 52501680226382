import { Theme, makeStyles } from '@material-ui/core';
import { borderRadius, colors, indents, typoColors } from '../theme';

const useStyles = makeStyles<Theme>((style) => ({
  currentTab: {
    backgroundColor: `${colors.primary.mainBlue} !important`,
    color: typoColors.white,
  },
  tabItem: {
    minWidth: 'fit-content',
    width: 350,
    height: 50,
    marginTop: 1,
    padding: indents.sixteen,
    borderBottom: `solid ${colors.grayColor.strokeInputGray} 1px`,
    borderRadius: `${borderRadius}px ${borderRadius}px 0px 0px`,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tabsWrapper: {},
  tabsHorizontalWrapper: {
    marginTop: indents.sixteen,
    display: 'flex',
  },
  currentHorizontalTab: {
    borderBottom: `solid ${colors.primary.mainBlue} 1px !important`,
  },
  tabHorizontalItem: {
    width: 350,
    height: 50,
    marginTop: 1,
    padding: indents.sixteen,
    borderBottom: `solid ${colors.grayColor.strokeInputGray} 1px`,
    borderRadius: `${borderRadius}px ${borderRadius}px 0px 0px`,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors.primary.lightBlue,
    },
  },
}));

export default useStyles;
