import { makeStyles } from '@material-ui/core/styles';
import { bgColors, colors, indents } from '../../uiKitElems';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    height: '100%',
  },
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
    '& .MuiTableRow-root.Mui-selected': {
      backgroundColor: bgColors.white,
      '&:hover': {
        backgroundColor: bgColors.light,
      },
    },
    '& .Mui-selected': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  pagination: {
    marginTop: 'auto',
    '&.MuiTablePagination-root': {
      minHeight: '52px',
      overflow: 'hidden',
    },
  },
  head: {
    '& .MuiTableCell-root': {
      [theme.breakpoints.down('lg')]: {
        padding: 0,
      },
      [theme.breakpoints.up('lg')]: {
        padding: indents.eight,
      },
    },
    '& .MuiTableCell-head': {
      backgroundColor: bgColors.light,
      borderBottom: 'none',
      '&:first-child': {
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
      },
      '&:last-child': {
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
      },
    },
  },
}));

export default useStyles;
