import { makeAutoObservable, runInAction } from 'mobx';

import { groupsApiService } from 'services/apiService';

import { TableListParams } from 'services/dataServices/tableServices/TableListParams';
import { GroupRowModel, GroupFormModel } from 'Shared/Models/Groups';
import Utils from '../Shared/Utils';

interface IGroupStoreDef {
  isPending: boolean;
  isShowForm: boolean;
  groupIdToRemove: string;
  groupIdToRestore: string;
  listParams: TableListParams;
  group: GroupFormModel;
  groups: GroupRowModel[];
  filterPattern: string;
  sortedGroups: GroupRowModel[];

  get groupNameToRestore(): string;
  get groupNameToRemove(): string;
  get groupsListIsExist(): boolean;

  addNewGroup(): void;
  closeForm(): void;
  closeRemoveDialog(): void;
  showRemoveDialog(cloudId: string): void;
  showRestoreDialog(groupId: string): void;
  restoreGroup(): Promise<void>;
  submitGroup(): Promise<void>;
  getGroupById(groupId: string): Promise<void>;
  removeGroup(): Promise<void>;
  getSortedList(): void;
  getGroups(): Promise<void>;
  onFilterPatternChange(value: string): void;
  deInit(): void;
}

class GroupsStore implements IGroupStoreDef {
  public isPending = false;

  public isShowForm = false;

  public groupIdToRemove = '';

  public groupIdToRestore = '';

  public listParams = new TableListParams(() => this.getSortedList());

  public group: GroupFormModel = new GroupFormModel();

  public groups: GroupRowModel[] = [];

  public filterPattern = '';

  public sortedGroups: GroupRowModel[] = [];

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public get groupNameToRestore() {
    return this.groups.find((item) => item.id === this.groupIdToRestore)?.name || '';
  }

  public get groupNameToRemove() {
    return this.groups.find((item) => item.id === this.groupIdToRemove)?.name || '';
  }

  public get groupsListIsExist() {
    return this.groups.length > 0;
  }

  public addNewGroup() {
    this.isShowForm = true;
  }

  public closeForm() {
    this.isShowForm = false;
    this.group.clear();
  }

  public closeRemoveDialog() {
    this.groupIdToRemove = '';
  }

  public showRemoveDialog(cloudId: string) {
    this.groupIdToRemove = cloudId;
  }

  public showRestoreDialog(groupId: string) {
    this.groupIdToRestore = groupId;
  }

  public closeRestoreDialog() {
    this.groupIdToRestore = '';
  }

  public async restoreGroup(): Promise<void> {
    this.isPending = true;

    try {
      await groupsApiService.restoreGroup(this.groupIdToRestore);
      this.closeRestoreDialog();

      this.getGroups(true);
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public async submitGroup() {
    this.isPending = true;

    try {
      const dto = this.group.getDto();
      if (!dto) return;

      if (this.group.id) {
        await groupsApiService.putGroup(this.group.id, dto);
        await this.getGroups();
      } else {
        if (!dto) return;

        const groupId = await groupsApiService.postGroup(dto);
        if (!groupId) return;
      }

      this.closeForm();
      this.getGroups();
    } catch (e) {
      console.log(e);
      this.group.nameValidState = (e as any).response.data.error || '';
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public async getGroupById(groupId: string) {
    this.isPending = true;

    try {
      const dto = await groupsApiService.getGroupById(groupId);
      if (!dto) return;

      this.group.updateFromDto(dto);
      this.isShowForm = true;
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public async removeGroup() {
    if (!this.groupIdToRemove) return;

    this.isPending = true;

    try {
      const result = await groupsApiService.deleteGroup(this.groupIdToRemove);
      if (!result) return;

      this.getGroups();
      this.groupIdToRemove = '';
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public getSortedList() {
    const columnName = this.listParams.sorting.sortingField as keyof GroupRowModel;

    const filtered = this.groups.filter(
      (item) =>
        item.name.toLowerCase().includes(this.filterPattern.toLowerCase().trim()) ||
        item.studentsCount?.toString().includes(this.filterPattern.toLowerCase().trim()) ||
        item.studyPeriod.includes(this.filterPattern.toLowerCase().trim())
    );

    const sorted = filtered.sort((a: GroupRowModel, b: GroupRowModel) =>
      this.listParams.sorting.isAscending ? Utils.ascCompare(a, b, columnName) : Utils.descCompare(a, b, columnName)
    );

    const start = this.listParams.pagination.pageIndex * this.listParams.pagination.pageSize;

    this.sortedGroups = sorted.slice(
      start,
      start + this.listParams.pagination.pageSize > sorted.length
        ? sorted.length
        : start + this.listParams.pagination.pageSize
    );
  }

  public async getGroups(isArchive?: boolean) {
    this.isPending = true;

    try {
      const result = await groupsApiService.getGroups(isArchive);
      this.groups = result.map((dto) => new GroupRowModel(dto));

      this.listParams.pagination.setTotalCount(this.groups.length);
      this.listParams.onChangePage(0);
      this.getSortedList();
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public onFilterPatternChange(pattern: string) {
    this.filterPattern = pattern;
    this.getSortedList();
  }

  public deInit(): void {
    this.groups = [];
    this.sortedGroups = [];
    this.filterPattern = '';
    this.groupIdToRestore = '';
    this.groupIdToRemove = '';
  }
}

export default new GroupsStore();
