import React from 'react';

import { FontWeights, H4Text, InputWrapper, LabelPosition } from 'Components/uiKitElems';

import { userAuthStore } from 'Stores';

import useStyles from './styles';

const UserInfo = () => {
  const c = useStyles();

  return (
    <div className={c.userInfoWrapper}>
      <InputWrapper
        className={c.userRow}
        spaceBetweenSize={16}
        labelPosition={LabelPosition.Left}
        labelCell={<H4Text className={c.rowName} noWrap={true} text={'Фамилия'} />}
        inputCell={<H4Text fontWeight={FontWeights.Medium} noWrap={true} text={userAuthStore.authUser.surname} />}
      />

      <InputWrapper
        className={c.userRow}
        spaceBetweenSize={16}
        labelPosition={LabelPosition.Left}
        labelCell={<H4Text className={c.rowName} noWrap={true} text={'Имя'} />}
        inputCell={<H4Text fontWeight={FontWeights.Medium} noWrap={true} text={userAuthStore.authUser.firstName} />}
      />

      <InputWrapper
        className={c.userRow}
        spaceBetweenSize={16}
        labelPosition={LabelPosition.Left}
        labelCell={<H4Text className={c.rowName} noWrap={true} text={'Отчество'} />}
        inputCell={<H4Text fontWeight={FontWeights.Medium} noWrap={true} text={userAuthStore.authUser.patronymic} />}
      />

      <InputWrapper
        className={c.userRow}
        spaceBetweenSize={16}
        labelPosition={LabelPosition.Left}
        labelCell={<H4Text className={c.rowName} noWrap={true} text={'Организация'} />}
        inputCell={<H4Text fontWeight={FontWeights.Medium} noWrap={true} text={userAuthStore.authUser.organization} />}
      />

      <InputWrapper
        className={c.userRow}
        spaceBetweenSize={16}
        labelPosition={LabelPosition.Left}
        labelCell={<H4Text className={c.rowName} noWrap={true} text={'Отрасли'} />}
        inputCell={
          <div>
            {userAuthStore.authUser.departments.map((item) => (
              <H4Text
                key={item}
                className={c.infoText}
                fontWeight={FontWeights.Medium}
                noWrap={true}
                text={item + `${userAuthStore.authUser.departments.length > 1 ? ',' : ''}`}
              />
            ))}
          </div>
        }
      />
    </div>
  );
};

export default UserInfo;
