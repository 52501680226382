import { action, makeObservable, observable } from 'mobx';

export interface ISortingDto<T> {
  sortingField: T;
  isAscending: boolean;
}

export default class SortingParams<T> {
  public sortingField: T;

  public isAscending = true;

  constructor(sortingField: T, isAscending: boolean) {
    makeObservable(this, {
      sortingField: observable,
      isAscending: observable,

      updateField: action,
      updateDirection: action,
    });

    this.sortingField = sortingField;
    this.isAscending = isAscending;
  }

  public updateField(field: T) {
    this.sortingField = field;
  }

  public updateDirection(isAscending: boolean) {
    this.isAscending = isAscending;
  }

  public getDto(): ISortingDto<T> {
    return {
      sortingField: this.sortingField,
      isAscending: this.isAscending,
    };
  }

  public deInit() {
    this.isAscending = false;
  }
}
