import React, { useEffect, useRef } from 'react';

import { observer } from 'mobx-react';

import { measurementStore, studyCoursesStore } from 'Stores';
import { H5Text, StudyFocusWrapper } from 'Components/uiKitElems';
import { StudyFocusEl } from 'Shared/Enums';
import { sampleStepData } from 'Shared/Constants/Simulation';
import Utils from 'Shared/Utils';

import useStyles from './styles';

const ThicknessDeviceImage = () => {
  const c = useStyles();

  const ref = useRef<SVGRectElement>(null);

  useEffect(() => {
    return () => measurementStore.thickDevice.deInitThickDevice();
  }, []);

  useEffect(() => {
    const isCheckingForStepSample =
      measurementStore.selectedSampleId === sampleStepData.id &&
      studyCoursesStore.selectedCourse.selectedModule?.currentStep?.id! > 8 &&
      studyCoursesStore.selectedCourse.selectedModule?.currentStep?.id! < 15;

    if (!isCheckingForStepSample) return;

    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.checkThicknessStep();
  }, [measurementStore.sample?.pip?.depthValue]);

  useEffect(() => {
    if (studyCoursesStore.selectedCourse.selectedModule?.currentStep?.id! > 17) {
      studyCoursesStore.selectedCourse.selectedModule?.currentStep?.checkThicknessStep();
    }
  }, [measurementStore.sample?.pip?.depthValue]);

  useEffect(() => {
    measurementStore.thickDevice.setDepthValue(measurementStore.sample?.pip?.depthValue!);
  }, [measurementStore.sample?.pip?.depthValue]);

  const onDropLiquid = () => {
    if (measurementStore.mouseDownElement !== StudyFocusEl.Liquid) return;
    measurementStore.thickDevice.dropLiquidOnImitator();
    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.checkThicknessStep();
  };

  const onSensorDrop = () => {
    if (measurementStore.mouseDownElement !== StudyFocusEl.SensorImage) return;

    measurementStore.thickDevice.setSensorAboveSimulator(true);

    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.checkThicknessStep();
  };

  const onSensorOver = (e: any) => {
    e.preventDefault();

    if (e.clientX === measurementStore.thickDevice.x && e.clientY === measurementStore.thickDevice.y) return;

    if (measurementStore.mouseDownElement !== StudyFocusEl.SensorImage) return;

    const newValue = (4 + Utils.randomProb(0.1, 0.4)).toFixed(1);

    if (newValue === measurementStore.thickDevice.depthValue) return;

    if (measurementStore.thickDevice.isWasCalibrated) {
      measurementStore.thickDevice.setDepthValue('6.0');
      return;
    }

    measurementStore.thickDevice.setMousePosition(e.clientX, e.clientY);
    measurementStore.thickDevice.setDepthValue(newValue);
  };

  const onSensorLeave = () => {
    if (measurementStore.mouseDownElement !== StudyFocusEl.SensorImage) return;

    measurementStore.thickDevice.setSensorAboveSimulator(false);
    measurementStore.thickDevice.setDepthValue('0.0');
  };

  const onSwitchOn = () => {
    measurementStore.thickDevice.switchToggle();

    if (
      studyCoursesStore.selectedCourse.selectedModule?.isExam &&
      measurementStore.thickDevice.isAboveSampleSimulator
    ) {
      measurementStore.thickDevice.setDepthValue('6.0');
      return;
    }

    if (studyCoursesStore.selectedCourse.selectedModule?.currentStep?.id! === 5) {
      measurementStore.thickDevice.setDepthValue('4.5');
    }

    if (studyCoursesStore.selectedCourse.selectedModule?.currentStep?.id! === 6) {
      measurementStore.thickDevice.setDepthValue('6.5');
    }

    if (studyCoursesStore.selectedCourse.selectedModule?.currentStep?.id! > 6) {
      measurementStore.thickDevice.setDepthValue('6.0');
    }

    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.checkThicknessStep();
  };

  return (
    <div className={c.thickDeviceImageWrapper}>
      <div style={{ width: 'fit-content', position: 'relative' }}>
        <div className={c.deviceDisplay}>
          <H5Text className={c.lcdDigits} text={measurementStore.thickDevice.depthValue} />
        </div>
        {studyCoursesStore.selectedCourse.selectedModule?.currentStep?.freeFocusPosition?.map((item) => (
          <StudyFocusWrapper
            isNotVisibleForMouse={true}
            isLightBg={true}
            id={StudyFocusEl.DeviceImage}
            freeParams={item}
          />
        ))}

        {!studyCoursesStore.selectedCourse.selectedModule?.isExam &&
          !studyCoursesStore.selectedCourse.selectedModule?.currentStep?.freeFocusPosition && (
            <StudyFocusWrapper
              isLightBg={true}
              id={StudyFocusEl.DeviceImage}
              freeParams={{ width: '100%', height: '100%', left: '0%', top: '0%' }}
            />
          )}

        {measurementStore.thickDevice.isAboveSampleSimulator && (
          <img onDragLeave={onSensorLeave} className={c.sensorImage} src={'images/thick_sensor.png'} />
        )}
        <svg width="100%" height="100%" viewBox="0 0 155 300" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="154.286" height="300" rx="20" fill="url(#paint0_linear)" />
          <rect width="154.286" height="300" rx="20" fill="url(#paint1_linear)" />
          <rect x="8.57153" y="8.57153" width="137.143" height="282.857" rx="10" fill="url(#paint2_linear)" />
          <rect x="17.5715" y="17.5715" width="119.143" height="264.857" rx="2" fill="url(#paint3_linear)" />
          <path d="M17.5712 282.428H136.714L141.428 287.143H12.8569L17.5712 282.428Z" fill="url(#paint4_linear)" />
          <path d="M12.8572 12.8572H141.429L136.714 17.5715H17.5715L12.8572 12.8572Z" fill="url(#paint5_linear)" />
          <path d="M136.714 17.5712L141.429 12.8569V287.143L136.714 282.428V17.5712Z" fill="url(#paint6_linear)" />
          <path d="M12.8572 12.8572L17.5715 17.4857V282.429L12.8572 287.143V12.8572Z" fill="url(#paint7_linear)" />
          <rect x="23.1426" y="29.4854" width="108" height="45.4286" rx="10" fill="url(#paint8_linear)" />
          <circle onClick={() => {}} cx="47.9997" cy="120.342" r="6.85714" fill="url(#paint9_linear)" />
          <circle
            onClick={onSwitchOn}
            style={{ cursor: 'pointer' }}
            cx="48"
            cy="120.343"
            r="6"
            fill="url(#paint10_linear)"
          />
          <path
            d="M60.9205 124L62.1802 117.525H60.0533L58.7935 124H57.065L58.6002 116.096H64.1842L62.649 124H60.9205ZM65.2857 124.117C65.0045 124.117 64.731 124.082 64.4654 124.012C64.2037 123.941 64.0064 123.859 63.8736 123.766L64.6353 122.324C64.9439 122.488 65.2388 122.57 65.5201 122.57C65.8013 122.57 66.0494 122.479 66.2642 122.295C66.483 122.107 66.7427 121.783 67.0435 121.322L65.0338 116.096H66.8326L68.1158 119.887L70.3834 116.096H72.1353L68.1744 122.137C67.6783 122.895 67.2193 123.414 66.7974 123.695C66.3756 123.977 65.8717 124.117 65.2857 124.117ZM73.5474 121.152C73.5474 122.223 73.9556 122.758 74.772 122.758C75.4127 122.758 75.9752 122.281 76.4595 121.328L78.0006 121.633C77.149 123.289 75.9791 124.117 74.4908 124.117C73.5924 124.117 72.9146 123.887 72.4576 123.426C72.0006 122.961 71.772 122.275 71.772 121.369C71.772 120.947 71.8189 120.494 71.9127 120.01C72.4439 117.318 73.815 115.973 76.0259 115.973C77.4908 115.973 78.3131 116.732 78.4927 118.252L76.8228 118.639C76.7877 118.221 76.6802 117.898 76.5006 117.672C76.3209 117.445 76.0826 117.332 75.7857 117.332C75.3287 117.332 74.9361 117.5 74.608 117.836C74.2838 118.168 74.0259 118.664 73.8345 119.324C73.6431 119.984 73.5474 120.594 73.5474 121.152ZM80.2213 116.096H81.9439L81.3228 119.312C81.815 119.312 82.4517 118.814 83.233 117.818L84.5982 116.096H86.3853L84.5806 118.293C84.3619 118.559 84.1119 118.826 83.8306 119.096C83.5533 119.361 83.356 119.531 83.2388 119.605L85.0494 124H83.1099L81.7857 120.467C81.6959 120.518 81.5787 120.562 81.4342 120.602C81.2896 120.641 81.1646 120.66 81.0592 120.66L80.4088 124H78.6861L80.2213 116.096Z"
            fill="white"
          />
          <circle cx="77.143" cy="187.2" r="20.5714" fill="url(#paint11_linear)" />
          <circle cx="77.143" cy="187.2" r="19.7143" fill="url(#paint12_linear)" />
          <circle cx="77.1431" cy="187.2" r="18" fill="#363638" />
          <circle cx="77.1431" cy="187.2" r="16.2857" fill="url(#paint13_linear)" />
          <circle
            cx="77.1433"
            cy="187.2"
            r="15.8571"
            fill="url(#paint14_linear)"
            onDrop={onDropLiquid}
            onDragOver={(e) => e.preventDefault()}
          />
          {measurementStore.thickDevice.isSampleImitatorWet && (
            <circle
              onDrop={onSensorDrop}
              onDragOver={onSensorOver}
              onDragLeave={onSensorLeave}
              cx="77.1433"
              cy="187.2"
              r="15.8571"
              fill="yellow"
              style={{ opacity: 0.2 }}
            />
          )}
          <path
            d="M120.198 84.9143L121.188 79.8284C121.227 79.6292 121.296 79.3342 121.393 78.9436C121.491 78.5491 121.567 78.2561 121.622 78.0647C121.2 79.1272 120.925 79.7932 120.796 80.0627L119.536 82.8049H118.259L118.053 80.0627C118.026 79.6721 118.007 79.0061 117.995 78.0647C117.885 78.9241 117.8 79.512 117.737 79.8284L116.747 84.9143H115.458L116.993 77.01H119.026L119.249 79.9338L119.26 80.2092C119.26 80.3772 119.243 80.7366 119.208 81.2874C119.407 80.7053 119.573 80.2561 119.706 79.9397L121.071 77.01H123.034L121.499 84.9143H120.198ZM127.405 84.9143L128.395 79.8284C128.434 79.6292 128.503 79.3342 128.6 78.9436C128.698 78.5491 128.774 78.2561 128.829 78.0647C128.407 79.1272 128.132 79.7932 128.003 80.0627L126.743 82.8049H125.466L125.26 80.0627C125.233 79.6721 125.214 79.0061 125.202 78.0647C125.093 78.9241 125.007 79.512 124.944 79.8284L123.954 84.9143H122.665L124.2 77.01H126.233L126.456 79.9338L126.468 80.2092C126.468 80.3772 126.45 80.7366 126.415 81.2874C126.614 80.7053 126.78 80.2561 126.913 79.9397L128.278 77.01H130.241L128.706 84.9143H127.405Z"
            fill="white"
          />
          <rect x="25.7144" y="32.0574" width="102.857" height="40.2857" rx="10" fill="#3A0807" />
          <rect
            x="25.7144"
            y="32.0574"
            width="102.857"
            height="40.2857"
            rx="10"
            fill="#3A0807"
            ref={ref}
            // style="mix-blend-mode:multiply"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="193.329"
              y1="153.643"
              x2="-28.4583"
              y2="113.66"
              gradientUnits="userSpaceOnUse">
              <stop />
              <stop offset="1" stop-color="#2C2C2C" />
            </linearGradient>
            <linearGradient
              id="paint1_linear"
              x1="2.26202e-09"
              y1="150"
              x2="154.245"
              y2="149.388"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.00520833" stop-color="#B4B8BB" />
              <stop offset="0.0390184" stop-color="#2C2C2C" />
              <stop offset="0.977055" stop-color="#191919" />
              <stop offset="1" stop-color="#CED4DA" />
            </linearGradient>
            <linearGradient
              id="paint2_linear"
              x1="-23.2476"
              y1="219.57"
              x2="182.944"
              y2="160.696"
              gradientUnits="userSpaceOnUse">
              <stop />
              <stop offset="1" stop-color="#262626" />
            </linearGradient>
            <linearGradient
              id="paint3_linear"
              x1="-10.0713"
              y1="215.143"
              x2="170.957"
              y2="167.186"
              gradientUnits="userSpaceOnUse">
              <stop />
              <stop offset="1" stop-color="#262626" />
            </linearGradient>
            <linearGradient
              id="paint4_linear"
              x1="12.8569"
              y1="284.786"
              x2="141.428"
              y2="284.786"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#363638" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint5_linear"
              x1="12.8572"
              y1="15.2143"
              x2="141.429"
              y2="15.2143"
              gradientUnits="userSpaceOnUse">
              <stop />
              <stop offset="0.552083" stop-color="#5B5A5F" />
              <stop offset="1" stop-color="#262626" />
            </linearGradient>
            <linearGradient
              id="paint6_linear"
              x1="139.114"
              y1="17.0569"
              x2="139.114"
              y2="287.143"
              gradientUnits="userSpaceOnUse">
              <stop />
              <stop offset="0.114534" stop-color="#262626" />
              <stop offset="0.257218" stop-color="#898A8E" />
              <stop offset="0.43972" stop-color="#262626" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint7_linear"
              x1="15.257"
              y1="12.8572"
              x2="15.2572"
              y2="287.143"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#262626" />
              <stop offset="0.549968" stop-color="#262626" />
              <stop offset="0.694515" stop-color="#898A8E" />
              <stop offset="0.828858" stop-color="#262626" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id="paint8_linear"
              x1="23.1426"
              y1="52.1996"
              x2="131.143"
              y2="52.1996"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#48484A" />
              <stop offset="0.109375" />
              <stop offset="0.937022" />
              <stop offset="1" stop-color="#EFF0F2" />
            </linearGradient>
            <linearGradient
              id="paint9_linear"
              x1="41.1426"
              y1="113.485"
              x2="54.8569"
              y2="127.2"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#EFF0F2" />
              <stop offset="1" stop-color="#5B5A5F" />
            </linearGradient>
            <linearGradient
              id="paint10_linear"
              x1="42"
              y1="120.343"
              x2="54"
              y2="120.343"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#48484A" />
              <stop offset="1" stop-color="#EFF0F2" />
            </linearGradient>
            <linearGradient
              id="paint11_linear"
              x1="56.5715"
              y1="166.628"
              x2="97.7144"
              y2="207.771"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#EFF0F2" />
              <stop offset="1" stop-color="#5B5A5F" />
            </linearGradient>
            <linearGradient
              id="paint12_linear"
              x1="57.4287"
              y1="187.2"
              x2="96.8573"
              y2="187.2"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#48484A" />
              <stop offset="1" stop-color="#EFF0F2" />
            </linearGradient>
            <linearGradient
              id="paint13_linear"
              x1="60.8574"
              y1="170.914"
              x2="93.4288"
              y2="203.486"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#EFF0F2" />
              <stop offset="1" stop-color="#5B5A5F" />
            </linearGradient>
            <linearGradient
              id="paint14_linear"
              x1="61.2861"
              y1="187.2"
              x2="93.0004"
              y2="187.2"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#48484A" />
              <stop offset="1" stop-color="#EFF0F2" />
            </linearGradient>
            <linearGradient
              id="paint15_linear"
              x1="21.2572"
              y1="44.7859"
              x2="66.4498"
              y2="105.2"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#5B5A5F" />
              <stop offset="0.583333" stop-color="#EFF0F2" />
              <stop offset="1" stop-color="#5B5A5F" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      {studyCoursesStore.selectedCourse.selectedModule?.currentStep?.id === 5 && (
        <div className={c.additionalImage}>
          {studyCoursesStore.selectedCourse.selectedModule?.currentStep?.freeFocusPosition2?.map((item) => (
            <StudyFocusWrapper
              isNotVisibleForMouse={true}
              isNotDisabled={true}
              isLightBg={true}
              id={StudyFocusEl.DeviceImage}
              freeParams={item}
            />
          ))}

          <img className={c.backPanelImage} src={'images/thickDevice/thick-back-panel.png'} />
        </div>
      )}

      {studyCoursesStore.selectedCourse.selectedModule?.currentStep?.id === 3 && (
        <div className={c.additionalImageWrapper}>
          <div className={c.additionalImage}>
            {studyCoursesStore.selectedCourse.selectedModule?.currentStep?.freeFocusPosition2?.map((item) => (
              <StudyFocusWrapper
                isNotVisibleForMouse={true}
                isNotDisabled={true}
                isLightBg={true}
                id={StudyFocusEl.DeviceImage}
                freeParams={item}
              />
            ))}

            {/*<img src={'images/thickDevice/thick-top-side.svg'} />*/}

            <svg width="155" height="52" viewBox="0 0 155 52" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="154.29" height="51.43" rx="10" fill="url(#paint0_linear_361:11700)" />
              <rect width="154.29" height="51.43" rx="10" fill="url(#paint1_linear_361:11700)" />
              <rect
                x="8.57178"
                y="8.57178"
                width="137.147"
                height="34.2867"
                rx="5"
                fill="url(#paint2_linear_361:11700)"
              />
              <rect x="9.57178" y="9.57178" width="135.147" height="32.2867" rx="4" stroke="black" stroke-width="2" />
              <path d="M36.001 17.1433H43.7155" stroke="white" stroke-width="3" stroke-linecap="square" />
              <path
                d="M40.7153 25.7151C40.7153 31.6326 35.9182 36.4297 30.0007 36.4297C24.0832 36.4297 19.2861 31.6326 19.2861 25.7151C19.2861 19.7976 24.0832 15.0005 30.0007 15.0005"
                stroke="white"
                stroke-width="2"
              />
              <circle cx="30.0006" cy="25.715" r="6.85733" fill="url(#paint3_linear_361:11700)" />
              <circle cx="30.0007" cy="25.7658" r="6.00017" fill="url(#paint4_linear_361:11700)" />
              <path d="M66.002 17.1433H73.7165" stroke="white" stroke-width="3" stroke-linecap="square" />
              <path d="M69.8591 21.0005V13.286" stroke="white" stroke-width="3" stroke-linecap="square" />
              <path
                d="M70.7163 25.7151C70.7163 31.6326 65.9192 36.4297 60.0017 36.4297C54.0842 36.4297 49.2871 31.6326 49.2871 25.7151C49.2871 19.7976 54.0842 15.0005 60.0017 15.0005"
                stroke="white"
                stroke-width="2"
              />
              <circle cx="60.0016" cy="25.715" r="6.85733" fill="url(#paint5_linear_361:11700)" />
              <circle cx="60.0016" cy="25.7658" r="6.00017" fill="url(#paint6_linear_361:11700)" />
              <rect
                x="90.4312"
                y="12.8574"
                width="50.1442"
                height="25.2864"
                rx="8"
                fill="url(#paint7_linear_361:11700)"
              />
              <rect x="91.2883" y="13.7146" width="48.4299" height="23.5721" rx="8" fill="#722B22" />
              <rect x="104.574" y="18.8577" width="21.4292" height="12.8575" rx="5" fill="black" />
              <rect x="108.86" y="18.8577" width="12.8575" height="12.8575" fill="url(#paint8_linear_361:11700)" />
              <rect
                x="108.86"
                y="27.4294"
                width="4.28583"
                height="12.8575"
                transform="rotate(-90 108.86 27.4294)"
                fill="url(#paint9_linear_361:11700)"
              />
              <rect x="113.146" y="19.2861" width="4.28583" height="12.0003" fill="url(#paint10_linear_361:11700)" />
              <circle cx="97.7172" cy="25.2863" r="3.42867" fill="url(#paint11_linear_361:11700)" />
              <circle cx="97.7171" cy="25.2865" r="3.00008" fill="url(#paint12_linear_361:11700)" />
              <circle cx="132.861" cy="25.2863" r="3.42867" fill="url(#paint13_linear_361:11700)" />
              <circle cx="132.861" cy="25.2865" r="3.00008" fill="url(#paint14_linear_361:11700)" />
              <path d="M97.2886 26.5723V24.0008" stroke="#69696B" stroke-width="2" stroke-linecap="round" />
              <path d="M97.7173 26.5723V24.0008" stroke="#D6D6D6" stroke-width="2" stroke-linecap="round" />
              <path d="M132.433 26.5723V24.0008" stroke="#69696B" stroke-width="2" stroke-linecap="round" />
              <path d="M132.861 26.5723V24.0008" stroke="#D6D6D6" stroke-width="2" stroke-linecap="round" />
              <circle cx="128.147" cy="33.8581" r="2.5715" fill="url(#paint15_linear_361:11700)" />
              <circle cx="102.432" cy="33.8581" r="2.5715" fill="url(#paint16_linear_361:11700)" />
              <circle cx="102.432" cy="16.7148" r="2.5715" fill="url(#paint17_linear_361:11700)" />
              <circle cx="128.147" cy="16.7148" r="2.5715" fill="url(#paint18_linear_361:11700)" />
              <circle cx="102.432" cy="16.7147" r="2.14292" fill="url(#paint19_linear_361:11700)" />
              <circle cx="128.147" cy="16.7147" r="2.14292" fill="url(#paint20_linear_361:11700)" />
              <circle cx="102.432" cy="33.858" r="2.14292" fill="url(#paint21_linear_361:11700)" />
              <circle cx="128.147" cy="33.858" r="2.14292" fill="url(#paint22_linear_361:11700)" />
              <defs>
                <linearGradient
                  id="paint0_linear_361:11700"
                  x1="193.334"
                  y1="26.3395"
                  x2="84.5907"
                  y2="-88.0163"
                  gradientUnits="userSpaceOnUse">
                  <stop />
                  <stop offset="1" stop-color="#2C2C2C" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_361:11700"
                  x1="2.26208e-09"
                  y1="25.715"
                  x2="154.169"
                  y2="22.1454"
                  gradientUnits="userSpaceOnUse">
                  <stop offset="0.00520833" stop-color="#B4B8BB" />
                  <stop offset="0.0390184" stop-color="#2C2C2C" />
                  <stop offset="0.977055" stop-color="#191919" />
                  <stop offset="1" stop-color="#CED4DA" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_361:11700"
                  x1="-23.2482"
                  y1="34.1481"
                  x2="10.8037"
                  y2="-46.0662"
                  gradientUnits="userSpaceOnUse">
                  <stop />
                  <stop offset="1" stop-color="#262626" />
                </linearGradient>
                <linearGradient
                  id="paint3_linear_361:11700"
                  x1="23.1433"
                  y1="18.8577"
                  x2="36.858"
                  y2="32.5723"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#EFF0F2" />
                  <stop offset="1" stop-color="#5B5A5F" />
                </linearGradient>
                <linearGradient
                  id="paint4_linear_361:11700"
                  x1="24.0005"
                  y1="25.7658"
                  x2="36.0008"
                  y2="25.7658"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#48484A" />
                  <stop offset="1" stop-color="#EFF0F2" />
                </linearGradient>
                <linearGradient
                  id="paint5_linear_361:11700"
                  x1="53.1443"
                  y1="18.8577"
                  x2="66.859"
                  y2="32.5723"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#EFF0F2" />
                  <stop offset="1" stop-color="#5B5A5F" />
                </linearGradient>
                <linearGradient
                  id="paint6_linear_361:11700"
                  x1="54.0015"
                  y1="25.7658"
                  x2="66.0018"
                  y2="25.7658"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#48484A" />
                  <stop offset="1" stop-color="#EFF0F2" />
                </linearGradient>
                <linearGradient
                  id="paint7_linear_361:11700"
                  x1="90.4312"
                  y1="17.7861"
                  x2="140.575"
                  y2="34.9295"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#8F4036" />
                  <stop offset="1" stop-color="#521A13" />
                </linearGradient>
                <linearGradient
                  id="paint8_linear_361:11700"
                  x1="115.289"
                  y1="18.8577"
                  x2="115.289"
                  y2="31.7152"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#722B22" />
                  <stop offset="0.328125" />
                  <stop offset="0.677083" />
                  <stop offset="1" stop-color="#722B22" />
                </linearGradient>
                <linearGradient
                  id="paint9_linear_361:11700"
                  x1="113.146"
                  y1="33.8582"
                  x2="108.86"
                  y2="33.8582"
                  gradientUnits="userSpaceOnUse">
                  <stop />
                  <stop offset="0.515625" stop-color="#722B22" />
                  <stop offset="1" />
                </linearGradient>
                <linearGradient
                  id="paint10_linear_361:11700"
                  x1="115.289"
                  y1="19.2861"
                  x2="115.289"
                  y2="31.2865"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#2A100C" />
                  <stop offset="0.139025" stop-color="#722B22" />
                  <stop offset="0.854167" stop-color="#722B22" />
                  <stop offset="1" stop-color="#2A100C" />
                </linearGradient>
                <linearGradient
                  id="paint11_linear_361:11700"
                  x1="94.2886"
                  y1="21.8577"
                  x2="101.146"
                  y2="28.715"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#EFF0F2" />
                  <stop offset="1" stop-color="#5B5A5F" />
                </linearGradient>
                <linearGradient
                  id="paint12_linear_361:11700"
                  x1="94.717"
                  y1="25.2865"
                  x2="100.717"
                  y2="25.2865"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#48484A" />
                  <stop offset="1" stop-color="#EFF0F2" />
                </linearGradient>
                <linearGradient
                  id="paint13_linear_361:11700"
                  x1="129.432"
                  y1="21.8577"
                  x2="136.29"
                  y2="28.715"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#EFF0F2" />
                  <stop offset="1" stop-color="#5B5A5F" />
                </linearGradient>
                <linearGradient
                  id="paint14_linear_361:11700"
                  x1="129.861"
                  y1="25.2865"
                  x2="135.861"
                  y2="25.2865"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#48484A" />
                  <stop offset="1" stop-color="#EFF0F2" />
                </linearGradient>
                <linearGradient
                  id="paint15_linear_361:11700"
                  x1="130.075"
                  y1="35.5725"
                  x2="126.004"
                  y2="32.3581"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#924137" />
                  <stop offset="1" stop-color="#501912" />
                </linearGradient>
                <linearGradient
                  id="paint16_linear_361:11700"
                  x1="104.36"
                  y1="35.5725"
                  x2="100.289"
                  y2="32.3581"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#924137" />
                  <stop offset="1" stop-color="#501912" />
                </linearGradient>
                <linearGradient
                  id="paint17_linear_361:11700"
                  x1="104.36"
                  y1="18.4291"
                  x2="100.289"
                  y2="15.2148"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#924137" />
                  <stop offset="1" stop-color="#501912" />
                </linearGradient>
                <linearGradient
                  id="paint18_linear_361:11700"
                  x1="130.075"
                  y1="18.4291"
                  x2="126.004"
                  y2="15.2148"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#924137" />
                  <stop offset="1" stop-color="#501912" />
                </linearGradient>
                <linearGradient
                  id="paint19_linear_361:11700"
                  x1="100.289"
                  y1="14.5718"
                  x2="104.575"
                  y2="18.8576"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#924137" />
                  <stop offset="1" stop-color="#501912" />
                </linearGradient>
                <linearGradient
                  id="paint20_linear_361:11700"
                  x1="126.004"
                  y1="14.5718"
                  x2="130.289"
                  y2="18.8576"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#924137" />
                  <stop offset="1" stop-color="#501912" />
                </linearGradient>
                <linearGradient
                  id="paint21_linear_361:11700"
                  x1="100.289"
                  y1="31.7151"
                  x2="104.575"
                  y2="36.0009"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#924137" />
                  <stop offset="1" stop-color="#501912" />
                </linearGradient>
                <linearGradient
                  id="paint22_linear_361:11700"
                  x1="126.004"
                  y1="31.7151"
                  x2="130.289"
                  y2="36.0009"
                  gradientUnits="userSpaceOnUse">
                  <stop stop-color="#924137" />
                  <stop offset="1" stop-color="#501912" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(ThicknessDeviceImage);
