import { ICourse } from 'Shared/Interfaces/App';
import { CourseModulesIds, CoursesId, ModuleProgress, StudyFocusEl } from 'Shared/Enums';

export const thickness: ICourse = {
  code: CoursesId.ThicknessMeasurement,
  modules: [
    {
      id: CourseModulesIds.ThickIntroduce,
      name: 'Интерфейс толщиномера',
      description:
        'Данный модуль позволит Вам ознакомиться с интерфейсом толщиномера. Ориентировочное время прохождения модуля 30 минут.',
      welcomeText: 'Основной задачей прохождения данного модуля является ознакомление с устройством толщиномера.',
      successText: 'Вы успешно прошли модуль “Интерфейс толщиномера”',
      steps: [
        {
          id: 1,
          name: '',
          title: 'Толщиномер',
          description:
            'Толщиномер предназначен для измерения толщины изделий, выполненных из различных материалов, по времени распространения ультразвуковой волны. \n' +
            'Толщиномер реализует эхо-импульсный метод измерения с ультразвуковыми преобразователями различных частот.',
          imageUrl: 'images/thickDevice/thick-edu-1.png',
          isCompleted: false,
        },
        {
          id: 2,
          name: 'Передняя панель',
          title: 'Кнопка “Пуск”',
          description: 'Кнопка включения и калибровки толщиномера.',
          imageUrl: 'images/thickDevice/thick-edu-2.png',
          isCompleted: false,
        },
        {
          id: 3,
          name: 'Передняя панель',
          title: 'Имитатор изделия',
          description:
            'Эталонный образец толщиной 6 мм, предназначенный для выполнения операций калибровки и который также может использоваться для настройки по скорости звука.',
          imageUrl: 'images/thickDevice/thick-edu-3.png',
          isCompleted: false,
        },
        {
          id: 4,
          name: 'Передняя панель',
          title: 'Индикаторное табло',
          description: 'Область отображения результатов измерения и дополнительной информации.',
          imageUrl: 'images/thickDevice/thick-edu-4.png',
          isCompleted: false,
        },
        {
          id: 5,
          name: 'Верхняя панель',
          title: 'Кнопка “Минус” и кнопка “Плюс”',
          description:
            'Регулировка чувствительности прибора и его настройка для измерения толщины различных материалов.',
          imageUrl: 'images/thickDevice/thick-edu-5.png',
          isCompleted: false,
        },
        {
          id: 6,
          name: 'Верхняя панель',
          title: 'Сигнальный разъём',
          description: 'Разъём подключения пьезопреобразователей.',
          imageUrl: 'images/thickDevice/thick-edu-6.png',
          isCompleted: false,
        },
        {
          id: 7,
          name: 'Задняя панель',
          title: 'Таблица',
          description: 'Значения толщины при калибровке по встроенному имитатору для изделий из различных материалов',
          imageUrl: 'images/thickDevice/thick-edu-8.png',
          isCompleted: false,
        },
        {
          id: 8,
          name: 'Задняя панель',
          title: 'Батарейный отсек',
          description: 'Отсек с аккумуляторными батареями.',
          imageUrl: 'images/thickDevice/thick-edu-9.png',
          isCompleted: false,
        },
      ],
      state: ModuleProgress.Current,
    },
    {
      id: CourseModulesIds.ThickWorkWithTool,
      name: 'Работа с прибором',
      steps: [
        {
          id: 1,
          name: 'Шаг №1. Выбор преобразователя',
          title:
            'В конструкции толщиномера ввод акустических колебаний в изделие и регистрация выходящих из изделия колебаний осуществляется с помощью пьезопреобразователя. Пьезопреобразователи подразделяются на различные типы по конструкции и характеристикам. Выбор типа преобразователя зависит от характеристик объекта контроля – толщины, состояния поверхности, материала и т.д. Чаще всего главной характеристикой, на которую опираются при выборе преобразователя, является толщина объекта контроля. Чем меньше толщина объекта контроля, тем более высокочастотный преобразователь необходимо выбирать.',
          description: 'Нажмите на кнопку и выберите преобразователь УД1, 5 Мгц, 1...50 мм.',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.Sensor],
          cardStartPosition: { x: 50, y: 520 },
        },
        {
          id: 2,
          name: 'Шаг №2. Включение прибора',
          title:
            'Толщиномер включается кнопкой «Пуск». Если измерения не проводятся, то по истечении 60-90 секунд прибор автоматически выключается.',
          description: 'Нажмите на кнопку “Пуск”. ',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.DeviceImage],
          freeFocusPosition: [{ left: '20%', top: '35%', width: '45%', height: '10%' }],
          cardStartPosition: { x: 0, y: 348 },
        },
        {
          id: 3,
          name: 'Шаг №3. Настройка чувствительности',
          title:
            'После включения прибора с помощью кнопки «Минус» можно выбрать один из четырех уровней чувствительности толщиномера. Настройка чувствительности необходима при работе с различными толщинами и характеристиками объектов контроля. При правильно установленной чувствительности прибора толщины в диапазоне 1,5-50 мм должны измеряться с погрешностью не более 2%. При дальнейшей работе с прибором кнопки «Плюс» и «Минус» служат для калибровки толщиномера по имитатору или образцу измеряемого изделия.',
          description: 'Нажмите “Далее”',
          isCompleted: false,
          isDone: true,
          currentFocusIds: [StudyFocusEl.DeviceImage],
          freeFocusPosition: [{ left: '0%', top: '-2%', width: '100%', height: '10%' }],
          freeFocusPosition2: [{ left: '10%', top: '-10%', width: '40%', height: '110%' }],
          cardStartPosition: { x: 0, y: 348 },
        },
        {
          id: 4,
          name: 'Шаг №4.1 Калибровка толщиномера по имитатору изделия',
          title:
            'Для создания акустического контакта необходимо нанесение контактной жидкости на имитатор изделия. Если контактная жидкость не нанесена, то на экране прибора будет отсутствовать индикация измеренной толщины. В качестве контактной жидкости могут использоваться глицерин, трансформаторное масло, вода, гель для ультразвуковой дефектоскопии.',
          description: 'Перетащите иконку контактной жидкости на имитатор изделия.',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.DeviceImage, StudyFocusEl.Liquid],
          freeFocusPosition: [{ left: '35%', top: '55%', width: '30%', height: '15%' }],
          cardStartPosition: { x: 0, y: 348 },
        },
        {
          id: 5,
          name: 'Шаг №4.2 Калибровка толщиномера по имитатору изделия',
          title:
            'Для проверки работоспособности и калибровки толщиномера пьезопреобразователь устанавливается на имитатор изделия. После чего прибор измеряет толщину имитатора изделия и отображает значение этой толщины на экране толщиномера. На задней стороне толщиномера указаны значения толщин имитатора изделия, которые должны быть получены в зависимости от материала будущего объекта контроля. Например, при измерении толщин объектов из меди необходимо получить значение 4,6 мм, а при измерении толщин объектов из стали марки 12Х18Н10Т – 6,0 мм. При неверном значении на индикаторном табло производится корректировка: до значения 6,0 мм – кнопкой «Пуск», до других значений – кнопками «Плюс» и «Минус». ',
          description:
            'Удерживая зажатой ЛКМ, переместите преобразователь на имитатор изделия. Если значение на индикаторном табло не равно 6.0, то необходимо провести корректировку.\nНажмите кнопку «Далее».',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.DeviceImage, StudyFocusEl.SensorImage, StudyFocusEl.AdditionalImage],
          freeFocusPosition: [{ left: '35%', top: '55%', width: '30%', height: '15%' }],
          freeFocusPosition2: [{ left: '10%', top: '18%', width: '80%', height: '12%' }],
          cardStartPosition: { x: 0, y: 348 },
        },
        {
          id: 6,
          name: 'Шаг №4.3 Калибровка толщиномера по имитатору изделия',
          title:
            'Так как получено неправильное значение (должно быть 6,0), то нажмите на кнопку "Пуск" для перенастройки толщиномера.',
          description: 'Нажмите кнопку “Пуск”.',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.DeviceImage],
          freeFocusPosition: [{ left: '20%', top: '35%', width: '45%', height: '10%' }],
          cardStartPosition: { x: 0, y: 348 },
        },
        {
          id: 7,
          name: 'Шаг №4.4 Калибровка толщиномера по имитатору изделия',
          title:
            'Так как получено неправильное значение (должно быть 6,0), то нажмите на кнопку "Пуск" для перенастройки толщиномера.',
          description: 'Нажмите кнопку “Пуск”.',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.DeviceImage],
          freeFocusPosition: [{ left: '20%', top: '35%', width: '45%', height: '10%' }],
          cardStartPosition: { x: 0, y: 348 },
        },
        {
          id: 8,
          name: 'Шаг №5.1 Калибровка толщиномера по настроечному образцу',
          title:
            'Полученное значение верно, поэтому следующим этапом является проверка показаний толщиномера в широком диапазоне толщин. Для этого используют специальные настроечные образцы разнообразной формы и толщины. В нашем случае применяется настроечный образец в виде ступенек разной высоты с определённым шагом.',
          description: 'Нажмите кнопку и выберите настроечный образец в виде ступенек.',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.Sample],
          cardStartPosition: { x: 0, y: 348 },
        },
        {
          id: 9,
          name: 'Шаг №5.2 Калибровка толщиномера по настроечному образцу',
          title:
            'Для создания акустического контакта необходимо нанесение контактной жидкости на каждую ступеньку настроечного образца. ',
          description: 'Перетащите иконку контактной жидкости на настроечный образец.',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.Liquid, StudyFocusEl.SampleSurface],
          cardStartPosition: { x: 0, y: 348 },
        },
        {
          id: 10,
          name: 'Шаг №5.3 Калибровка толщиномера по настроечному образцу',
          title:
            'При работе с настроечными образцами в виде ступенек первое измерение производится на ступеньке с самой маленькой толщиной. Далее последовательно замеряется толщина каждой ступени и показания прибора сравниваются со значением толщины предыдущей ступени. В данном случае устанавливаем преобразователь на выделенную ступень и фиксируем значение на индикаторном табло.',
          description:
            'Перетащите иконку преобразователя на выделенную ступень настроечного образца. Значение на индикаторном табло должно быть равно 10 мм.',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.SensorImage, StudyFocusEl.SampleSurfaceSegment],
          freeFocusPosition: [{ left: '0%', top: '0%', width: '19%', height: '56%' }],
          cardStartPosition: { x: 0, y: 348 },
        },
        {
          id: 11,
          name: 'Шаг №5.4 Калибровка толщиномера по настроечному образцу',
          title:
            'На данной ступеньке значение на индикаторном табло должно отличаться на величину, указанную рядом со ступенькой. \n' +
            'На предыдущей ступени было зафиксировано значение 10 мм. ',
          description:
            'Перетащите иконку преобразователя  на выделенную ступеньку настроечного образца. Значение на индикаторном табло должно быть равно 12 мм.',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.SensorImage, StudyFocusEl.SampleSurfaceSegment],
          freeFocusPosition: [{ left: '19%', top: '0%', width: '20%', height: '56%' }],
          cardStartPosition: { x: 0, y: 348 },
        },
        {
          id: 12,
          name: 'Шаг №5.5 Калибровка толщиномера по настроечному образцу',
          title:
            'На данной ступеньке значение на индикаторном табло должно отличаться на величину, указанную рядом со ступенькой. \n' +
            'На предыдущей ступени было зафиксировано значение 12 мм. ',
          description:
            'Перетащите иконку преобразователя на выделенную ступеньку настроечного образца. Значение на индикаторном табло должно быть равно 16 мм.',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.SensorImage, StudyFocusEl.SampleSurfaceSegment],
          freeFocusPosition: [{ left: '39%', top: '0%', width: '21%', height: '56%' }],
          cardStartPosition: { x: 0, y: 348 },
        },
        {
          id: 13,
          name: 'Шаг №5.6 Калибровка толщиномера по настроечному образцу',
          title:
            'На данной ступеньке значение на индикаторном табло должно отличаться на величину, указанную рядом со ступенькой. \n' +
            'На предыдущей ступени было зафиксировано значение 16 мм. ',
          description:
            'Перетащите иконку преобразователя на выделенную ступеньку настроечного образца. Значение на индикаторном табло должно быть равно 22 мм.',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.SensorImage, StudyFocusEl.SampleSurfaceSegment],
          freeFocusPosition: [{ left: '60%', top: '0%', width: '22%', height: '56%' }],
          cardStartPosition: { x: 0, y: 348 },
        },
        {
          id: 14,
          name: 'Шаг №5.7 Калибровка толщиномера по настроечному образцу',
          title:
            'На данной ступеньке значение на индикаторном табло должно отличаться на величину, указанную рядом со ступенькой. \n' +
            'На предыдущей ступени было зафиксировано значение 22 мм.',
          description:
            'Перетащите иконку преобразователя на выделенную ступеньку настроечного образца. Значение на индикаторном табло должно быть равно 14 мм.',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.SensorImage, StudyFocusEl.SampleSurfaceSegment],
          freeFocusPosition: [{ left: '82%', top: '0%', width: '18%', height: '56%' }],
          cardStartPosition: { x: 0, y: 348 },
        },
        {
          id: 15,
          name: 'Шаг №6.1 Проведение УЗ толщинометрии контрольного образца',
          title:
            'После успешной настройки толщиномера следует процесс измерения толщины на контрольном образце. Тип образца, на котором производится контроль, указан в брифинге (условия задачи).',
          description: 'Нажмите кнопку и выберите контрольный образец ЭО-УТ-01.',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.Sample],
          cardStartPosition: { x: 0, y: 348 },
        },
        {
          id: 16,
          name: 'Шаг №6.2 Проведение УЗ толщинометрии контрольного образца',
          title: 'Для создания акустического контакта необходимо нанесение контактной жидкости на контрольный образец.',
          description: 'Перетащите иконку контактной жидкости на контрольный образец. ',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.Liquid, StudyFocusEl.SampleSurfaceSegment],
          freeFocusPosition: [{ left: '11%', top: '5%', width: '84%', height: '78%' }],
          cardStartPosition: { x: 0, y: 348 },
        },
        {
          id: 17,
          name: 'Шаг №6.3 Проведение УЗ толщинометрии контрольного образца',
          title: 'Для начала проведения толщинометрии установите преобразователь на образец.',
          description: 'Перетащите иконку преобразователя на контрольный образец. ',
          isCompleted: false,
          isDone: true,
          currentFocusIds: [StudyFocusEl.SensorImage, StudyFocusEl.SampleSurfaceSegment],
          freeFocusPosition: [{ left: '11%', top: '5%', width: '84%', height: '78%' }],
          cardStartPosition: { x: 0, y: 348 },
        },
        {
          id: 18,
          name: 'Шаг №6.4 Проведение УЗ толщинометрии контрольного образца',
          title:
            'Область утонения может иметь разнообразную форму и разную глубину. Основной задачей толщинометрии является определение границ и минимальной остаточной толщины образца в зоне каждого утонения. Для поиска утонений следует перемещать преобразователь по поверхности объекта контроля. При обнаружении изменения значения толщины необходимо тщательно обследовать найденное утонение для определения его границ и минимальной толщины образца в зоне этого утонения. В зависимости от исходной толщины объекта контроля и нормативной документации определяется предельно допустимое (минимальное) значение толщины этого объекта. При сравнении предельно допустимого с полученными значениями толщины выносится решение о допустимости или не допустимости данного дефекта.',
          description:
            'Перемещайте преобразователь по образцу в выделенной зоне до тех пор, пока значение на табло толщиномера не изменится на значительную величину.',
          isCompleted: false,
          isDone: true,
          currentFocusIds: [StudyFocusEl.SampleSurfaceSegment],
          freeFocusPosition: [{ left: '62%', top: '55%', width: '5%', height: '5%' }],
          cardStartPosition: { x: 0, y: 348 },
        },
        {
          id: 19,
          name: 'Шаг №6.5 Проведение УЗ толщинометрии контрольного образца',
          title:
            'Для оформления заключения необходимо зафиксировать минимальную остаточную толщину каждого утонения. Значения с индикаторного табло вводятся в ячейку «Результат измерений».',
          description: 'Внесите значение с табло в ячейку таблицы, чтобы зафиксировать утонение.',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.TableDepthValue],
          cardStartPosition: { x: 0, y: 348 },
        },
        {
          id: 20,
          name: 'Шаг №6.6 Проведение УЗ толщинометрии контрольного образца',
          title:
            'Также необходимо зафиксировать расположение (координаты) выявленной несплошности по оси Х. В реальных условиях значения координат определяется с помощью поверенного средства измерения (линейка, рулетка, т.д.). Начало замеров производится от точки отсчёта, выбранной самостоятельно и указываемой в схеме контроля.',
          description: 'Внесите значение в ячейку таблицы, чтобы зафиксировать расположение дефекта по оси Х.',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.oXTableInput],
          cardStartPosition: { x: 0, y: 348 },
        },
        {
          id: 21,
          name: 'Шаг №6.7 Проведение УЗ толщинометрии контрольного образца',
          title:
            'Также необходимо зафиксировать расположение (координаты) выявленной несплошности по оси У. В реальных условиях значения координат определяется с помощью поверенного средства измерения (линейка, рулетка, т.д.). Начало замеров производится от точки отсчёта, выбранной самостоятельно и указываемой в схеме контроля.',
          description: 'Внесите значение в ячейку таблицы, чтобы зафиксировать расположение дефекта по оси У.',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.YTableInput],
          cardStartPosition: { x: 0, y: 348 },
        },
        {
          id: 22,
          name: 'Шаг №6.8 Проведение УЗ толщинометрии контрольного образца',
          title:
            'При оформлении Заключения по результатам проведённой УЗ толщинометрии необходимым условием является составление дефектограммы, которая является схематичным изображением объекта контроля и найденных утонений с их координатами. В нашем случае при заполнении таблицы с результатами толщинометрии такая дефектограмма составляется автоматически.',
          description: 'Нажмите “Далее”',
          isCompleted: false,
          isDone: true,
          currentFocusIds: [StudyFocusEl.SampleSurface],
          freeFocusPosition: [{ left: '0%', top: '00%', width: '100%', height: '36%' }],
          cardStartPosition: { x: 0, y: 348 },
        },
        {
          id: 23,
          name: 'Шаг №6.9 Проведение УЗ толщинометрии контрольного образца',
          title:
            'Исходя из полученных значений необходимо произвести оценку допустимости каждого утонения. Предельно допустимое (минимальное) значение толщины указывается в нормативных документах или в проекте на данный объект контроля.',
          description:
            'В соответствии с РД РОСЭК-001-96 данное утонение недопустимо, так как минимальная остаточная толщина его менее 80% от первоначальной. Нажмите на кнопку выпадающего списка и выберите «Х».',
          isCompleted: false,
          isDone: false,
          currentFocusIds: [StudyFocusEl.Normality],
          cardStartPosition: { x: 0, y: 348 },
        },
        {
          id: 24,
          name: 'Шаг №6.10 Проведение УЗ толщинометрии контрольного образца',
          title:
            'В толщинометрии существует необходимость создания дефектограммы - чертежа образца, который отображает расположение утонений по зафиксированным координатам. Это необходимо для оценки правильности координат найденных утонений.',
          description: 'Пронаблюдайте локализацию найденного утонения и нажмите кнопку «Далее».',
          isCompleted: false,
          isDone: true,
          cardStartPosition: { x: 0, y: 348 },
        },
        {
          id: 25,
          name: 'Шаг №6.11 Проведение УЗ толщинометрии контрольного образца',
          title:
            'Для обнаружения всех участков утонения в образце продолжите работу, перемещая преобразователь продольно-поперечными движениями. На пластине приведена схема данного перемещения. При этом шаг сканирования (расстояние между продольными линиями движения) составляет половину диаметра преобразователя.',
          description: 'Нажмите “Далее”',
          isCompleted: false,
          isDone: true,
          currentFocusIds: [StudyFocusEl.SampleSurfaceSegment],
          cardStartPosition: { x: 0, y: 348 },
        },
        {
          id: 26,
          name: 'Шаг №6.12 Проведение УЗ толщинометрии контрольного образца',
          title:
            'Используя полученные знания,\n' +
            'найдите все участки утонения на контрольном образце и заполните таблицу.\n' +
            'По окончании обучения проверьте локализацию найденных дефектов и нажмите кнопку “Завершить”.',
          description: 'Нажмите кнопку “Завершить” чтобы закончить обучение',
          isCompleted: false,
          isDone: true,
          cardStartPosition: { x: 0, y: 348 },
        },
      ],
      description:
        'Данный модуль позволит Вам ознакомиться с процедурами настройки толщиномера и проведения измерений. Ориентировочное время прохождения модуля 90 минут',
      welcomeText: `
            Основными задачами прохождения данного модуля являются ознакомление с параметрами настройки толщиномера, отработка навыков настройки толщиномера и проведение измерений толщины образцов.
           
            Для одного образца:      
            1. Выполнить ультразвуковую толщинометрию (УТ).
            2. Оформить таблицу по результатам контроля.
            3. Сравнить результаты с требованиями нормативных документов (НД).
    
            Экзаменационный образец ЭО-УТ-01        
            Тип образца: пластина
            Марка материала: сталь 09Г2С
            Размеры образца: 100х100х7,6 мм
            Шероховатость: не хуже Rz40.
 
            НД на технологию контроля: ГОСТ Р ИСО 16809-2015
            НД на нормы оценки: РД РОСЭК-001-96
 
            <i>При необходимости в разъяснении вопросов по нормативным документам обращайтесь к методисту.</i>
            `,
      successText: 'Вы успешно прошли урок интерактивной работы с толщиномером',
      state: ModuleProgress.Locked,
    },
    {
      id: CourseModulesIds.ThickExam,
      name: 'Экзамен',
      description: 'Максимальное время сдачи экзамена 60 минут',
      welcomeText: 'Нажимите "Начать", чтобы приступить к экзамену',
      successText: 'Нажмите "Ок" чтобы отправить результаты на проверку',
      state: ModuleProgress.Locked,
      steps: [
        {
          id: 1,
          name: 'Экзамен по толщинометрии',
          title: 'Заголовок',
          description: 'Экзамен по работе с толщиномером',
          isCompleted: false,
          isDone: false,
        },
      ],
    },
  ],
};
