import { makeStyles } from '@material-ui/core';

import { indents } from '../../Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
  },
  attentionWrapper: {
    padding: indents.eight,
    margin: indents.sixteen,
    display: 'flex',
    justifyContent: 'space-around',
  },
  noticeText: {
    textAlign: 'center',
  },
}));

export default useStyles;
