import React from 'react';

import { Route } from 'react-router-dom';

import { AdminCabinet, AuthPage } from 'Modules';

import { ApplicationRoutes } from 'Shared/Constants/Navigation';

const AdminRoutes = () => {
  return (
    <>
      <Route exact={true} path={ApplicationRoutes.Cabinet}>
        <AdminCabinet />
      </Route>

      <Route exact={true} path={ApplicationRoutes.Auth}>
        <AuthPage />
      </Route>
    </>
  );
};

export default AdminRoutes;
