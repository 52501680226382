import { IExamRowDto } from 'Shared/Interfaces/Api/ExamTemplate';
import { CoursesId } from 'Shared/Enums';

class ExamRowModel {
  constructor(dto: IExamRowDto) {
    this.id = dto.id;
    this.name = dto.name;
    this.courseName = dto.deviceTypeName;
    this.sampleName = dto.samplesName;
    this.rejectionRateName = dto.gostName;
    this.qualityLevel = dto.qualityLevelName;
  }

  public id = '';

  public name = '';

  public courseId: CoursesId | null = null;

  public courseName = '';

  public sampleName = '';

  public rejectionRateName = '';

  public qualityLevel = '';
}

export default ExamRowModel;
