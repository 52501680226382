import React from 'react';

import { Checkbox, FormControlLabel } from '@material-ui/core';

import useStyles from './styles';

interface IProps {
  className?: string;
  checked?: boolean;
  textLabel: string;
  onChange: (checked: boolean) => void;
}

const CheckBox = (props: IProps) => {
  const c = useStyles();
  const { className, checked, textLabel, onChange } = props;

  const handleChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  return (
    <div className={`${c.checkboxWrapper} ${className}`}>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleChecked} />}
        label={<span>{textLabel}</span>}
      />
    </div>
  );
};

export default CheckBox;
