import React from 'react';
import { observer } from 'mobx-react';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { H6Text, IconButtonWrapper } from 'Components/uiKitElems';

import { studentProgressStore, studyCoursesStore, userAuthStore } from 'Stores';

import useStyles from './styles';

const Header = () => {
  const c = useStyles();

  const logOut = () => {
    studyCoursesStore.deInit();
    userAuthStore.authUser.logOut();
    studentProgressStore.showStudentProgress(null);
    studyCoursesStore.flawDetection.modules.forEach(item => item.deInitModuleOnExit());
    studyCoursesStore.thicknessCourse.modules.forEach(item => item.deInitModuleOnExit());
  };

  const leaveStudy = () => {
    studyCoursesStore.selectedCourse.onExit();
  };

  const onUserNameClick = () => {
    userAuthStore.showUserData(true);
  };

  return (
    <div className={c.header}>
      <div className={c.arcNkLogo}>
        <img className={c.logoImg} src={'arc-nk-logo.svg'} alt={'logo'} />
        <H6Text noWrap={true} text={'ШКОЛА ДЕФЕКТОСКОПИИ'} isLight={true} />
      </div>

      {studyCoursesStore.selectedCourse.selectedModule && (
        <IconButtonWrapper
          className={c.briefButton}
          mediumSize={true}
          icon={<img src={'images/card-list.svg'} />}
          onClick={studyCoursesStore.selectedCourse.briefHandle}
        />
      )}

      <>
        {userAuthStore.authUser.role?.toString() && (
          <div className={c.userData}>
            <div className={c.userNameWrapper} onClick={onUserNameClick}>
              <H6Text noWrap={true} text={userAuthStore.authUser.shortName} isLight={true} />
            </div>
            {studyCoursesStore.selectedCourse.selectedModule ? (
              <img className={c.exitBtn} src={'icons/home-button.svg'} onClick={leaveStudy} />
            ) : (
              <IconButtonWrapper className={c.exitBtn} icon={<ExitToAppIcon />} onClick={logOut} />
            )}
          </div>
        )}
      </>
    </div>
  );
};

export default observer(Header);
