import { makeAutoObservable } from 'mobx';

import { DefectNote } from 'Shared/Models/ForkFlow/DefectNote';
import { IDefectDefectData, IDefectDepthData, IDefectNoteData } from 'Shared/Interfaces/App';

import { colors } from 'Components/uiKitElems';

import { ComparedDefect, DefectType } from './ComparedDefect';

import { measurementStore } from 'Stores';

import { ISaveExamDto } from 'Shared/Interfaces/Api/Exam';
import { AdmissibilityLevels, CoursesId } from 'Shared/Enums';

import Utils from 'Shared/Utils';

class DefectCheckTableModel {
  constructor(
    courseId: CoursesId,
    passedExamData: ISaveExamDto,
    sampleDefects: IDefectDefectData[],
    depthDefects: IDefectDepthData[],
    tuningSampleId?: string,
    sensorId?: string
  ) {
    makeAutoObservable(this, undefined, { autoBind: true });

    this.courseId = courseId;

    const parsed = JSON.parse(passedExamData?.defectData!);
    if (parsed) {
      this.userDefects = (parsed as IDefectNoteData[]).map((item, index) => new DefectNote(() => {}, index, item));
      this.tuningSampleName = measurementStore.samplesList.find(
        (sample) => sample.id === passedExamData.tuningSampleId
      )?.name!;
      this.tuningSampleNameByRule = measurementStore.samplesList.find((sample) => sample.id === tuningSampleId)?.name!;
    }

    if (sampleDefects.length > 0) {
      this.realDefects = sampleDefects.map((item, index) => {
        let admissibility = AdmissibilityLevels.NotSet;

        if (item.isAccepted) {
          admissibility = AdmissibilityLevels.Normal;
        }

        if (item.isAccepted === false) {
          admissibility = AdmissibilityLevels.NotNormal;
        }

        return new DefectNote(() => {}, index, {
          defectNumber: index,
          oX: item.y,
          y: item.depth,
          defectLength: item.length,
          shortNote: item.shortResultName || '',
          admissibility: admissibility,
          sensorName: measurementStore.allSensors.find((s) => s.id === sensorId)?.name || '',
          levelOver: item.excessLevel!,
        });
      });
    }

    if (depthDefects.length > 0) {
      this.realDefects = [];
      this.realDefects = depthDefects.map((item, index) => {
        let admissibility = AdmissibilityLevels.NotSet;

        if (item.isAccepted) {
          admissibility = AdmissibilityLevels.Normal;
        }

        if (item.isAccepted === false) {
          admissibility = AdmissibilityLevels.NotNormal;
        }

        return new DefectNote(() => {}, index, {
          defectNumber: index,
          oX: item.x,
          y: item.y,
          width: item.width,
          height: item.height,
          defectDepth: item.depth,
          shortNote: '',
          admissibility: admissibility,
          sensorName: measurementStore.allSensors.find((s) => s.id === sensorId)?.name || '',
          levelOver: 0,
        });
      });
    }
  }

  public courseId: CoursesId | null = null;

  public tuningSampleName = '';

  public tuningSampleNameByRule = '';

  public comparedDefects: ComparedDefect[] = [];

  public userDefects: DefectNote[] = [];

  public realDefects: DefectNote[] = [];

  public get calculatedMarkColor(): string {
    let calculatedMarkColor = '';

    if (this.calculatedMark < 50) {
      calculatedMarkColor = colors.statusColor.dangerRed;
    }

    if (this.calculatedMark > 49 && this.calculatedMark < 75) {
      calculatedMarkColor = colors.statusColor.warningYello;
    }

    if (this.calculatedMark > 74) {
      calculatedMarkColor = colors.statusColor.successGreen;
    }

    return calculatedMarkColor;
  }

  public buildComparedDefects(courseId: CoursesId) {
    const comparedDefects: ComparedDefect[] = [];
    console.log(this.realDefects.length);
    for (let i = 0; i < this.realDefects.length; i++) {
      for (let j = 0; j < this.userDefects.length; j++) {
        if (this.compareDefects(courseId, this.userDefects[j], this.realDefects[i])) {
          comparedDefects.push(new ComparedDefect(DefectType.Found, this.userDefects[j], this.realDefects[i]));
        }
      }
    }

    for (let i = 0; i < this.realDefects.length; i++) {
      if (!comparedDefects.some((item) => item.realDefectNumber === this.realDefects[i].defectNumber)) {
        comparedDefects.push(new ComparedDefect(DefectType.NotFound, null, this.realDefects[i]));
      }
    }

    for (let i = 0; i < this.userDefects.length; i++) {
      if (!comparedDefects.some((item) => item.userDefectNumber === this.userDefects[i].defectNumber)) {
        comparedDefects.push(new ComparedDefect(DefectType.NotExisted, this.userDefects[i], null));
      }
    }

    this.comparedDefects = comparedDefects.sort((a: ComparedDefect, b: ComparedDefect) =>
      Utils.ascCompare(a, b, 'defectType')
    );
  }

  private compareDefects(courseId: CoursesId, userDefect: IDefectNoteData, realDefect: IDefectNoteData): boolean {
    if (courseId === CoursesId.FlawDetection) {
      return this.compareFlawDefect(userDefect, realDefect);
    }

    if (courseId === CoursesId.ThicknessMeasurement) {
      return this.compareThickDefect(userDefect, realDefect);
    }

    return false;
  }

  private compareThickDefect(userDefect: IDefectNoteData, realDefect: IDefectNoteData): boolean {
    const horizontalMatching = userDefect.oX! > realDefect.oX! && userDefect.oX! < realDefect.oX! + realDefect.width!;
    const verticalMatching = userDefect.y! > realDefect.y! && userDefect.y! < realDefect.y! + realDefect.height!;

    return horizontalMatching && verticalMatching;
  }

  private compareFlawDefect(userDefect: IDefectNoteData, realDefect: IDefectNoteData): boolean {
    const userOx = Math.abs(userDefect.oX!);
    const realOx = Math.abs(realDefect.oX!);

    return Math.abs(userOx - realOx) < 5;
  }

  public get calculatedMark() {
    let mark = 100;

    this.comparedDefects.forEach((item) => (mark -= item.downLevel!));

    if (mark < 0) mark = 0;
    if (mark > 100) mark = 100;

    return mark;
  }

  public clearDefectCheckTable() {
    this.comparedDefects = [];
  }
}

export default DefectCheckTableModel;
