import { makeAutoObservable } from 'mobx';

import { IGroupDto, IGroupPostDto } from 'Shared/Interfaces/Api/Groups';
import {CoursesId, ValidationErrorTypes} from 'Shared/Enums';
import dayjs from 'dayjs';
import Utils from '../../Utils';

interface IGroupFormModelDef {
  id: string;
  name: string;
  teacherId: string;
  dateFrom: string;
  dateTo: string;

  getDto(): IGroupPostDto | null;
  updateFromDto(dto: IGroupDto): void;
  clear(): boolean;

  onChangeName(value: string): void;
  onChangeDateFrom(date: string): void;
  onChangeDateTo(date: string): void;
  onChangeTeacher(teacherId: string): void;
}

class GroupFormModel implements IGroupFormModelDef {
  public id = '';

  public teacherId = '';

  public courseId: CoursesId | null = null;

  public name = '';

  public dateFrom = '';

  public dateTo = '';

  public isValidationActivated = false;

  public nameValidState = '';

  public dateFromValidState = '';

  public dateToValidState = '';

  public courseIdState = '';

  public teacherIdState = '';

  public constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public getDto(): IGroupPostDto | null {
    if (this.isNotValid()) return null;
    return {
      name: this.name,
      teacherId: this.teacherId,
      deviceTypeId: this.courseId!,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
    };
  }

  public updateFromDto(dto: IGroupDto): void {
    this.id = dto.id;
    this.name = dto.name;
    this.teacherId = dto.teacherId;
    this.courseId = dto.deviceTypeId;
    this.dateFrom = dto.dateFrom;
    this.dateTo = dto.dateTo;
  }

  public clear(): boolean {
    this.id = '';
    this.name = '';
    this.teacherId = '';
    this.courseId = null;
    this.dateFrom = '';
    this.dateTo = '';

    this.isValidationActivated = false;
    this.nameValidState = '';
    this.dateFromValidState = '';
    this.dateToValidState = '';
    this.courseIdState = '';
    this.teacherIdState = '';

    return true;
  }

  public onChangeName(value: string) {
    this.name = value;

    if (this.isValidationActivated) {
      this.nameValidState = this.checkForValid(this.name);
    }
  }

  public onChangeDateFrom(value?: string | undefined) {
    this.dateFrom = value!;

    if (this.isValidationActivated) {
      this.dateFromValidState = this.checkForValid(this.dateFrom);
      this.isDatesOrderNotValid();
    }
  }

  public onChangeDateTo(value?: string | undefined) {
    this.dateTo = value!;

    if (this.isValidationActivated) {
      this.dateToValidState = this.checkForValid(this.dateTo);
      this.isDatesOrderNotValid();
    }
  }

  public onChangeCourse(courseId: CoursesId) {
    this.courseId = courseId;

    if (this.isValidationActivated) {
      this.courseIdState = this.checkForValid(this.courseId);
    }
  }

  public onChangeTeacher(teacherId: string) {
    this.teacherId = teacherId;

    if (this.isValidationActivated) {
      this.teacherIdState = this.checkForValid(this.teacherId);
    }
  }

  private isNotValid(): boolean {
    this.isValidationActivated = true;

    this.nameValidState = this.checkForValid(this.name);
    this.dateFromValidState = this.checkForValid(this.dateFrom);
    this.dateToValidState = this.checkForValid(this.dateTo);

    this.courseIdState = this.checkForValid(this.courseId!);
    this.teacherIdState = this.checkForValid(this.teacherId);

    if (this.dateFrom && this.dateTo && dayjs(this.dateFrom) <= dayjs(this.dateTo)) {
      this.dateFromValidState = '';
    }

    return (
      !!this.nameValidState || !!this.dateFromValidState || !!this.dateToValidState || !!this.courseIdState || !!this.teacherIdState || this.isDatesOrderNotValid()
    );

    return true;
  }

  private checkForValid(value: string): string {
    return Utils.checkForValid(value);
  }

  private isDatesOrderNotValid(): boolean {
    if (this.dateFrom && this.dateTo && dayjs(this.dateFrom) > dayjs(this.dateTo)) {
      this.dateFromValidState = ValidationErrorTypes.DatesOrder;

      return true;
    } else {
      this.dateFromValidState = '';
      return false;
    }
  }
}

export default GroupFormModel;
