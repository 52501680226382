import { makeStyles, Theme } from '@material-ui/core';
import { borderRadius, colors, typoColors } from '../../theme';

interface IProps {
  color?: string;
}

const useStyles = makeStyles<Theme, IProps>((theme) => ({
  smButtonClass: {
    height: '32px',
    minWidth: '64px',
  },
  mdButtonClass: {
    height: '40px',
    minWidth: '96px',
  },
  lgButtonClass: {
    height: '48px',
    minWidth: '120px',
  },
  primaryButton: (props) => ({
    borderRadius: borderRadius,
    color: typoColors.headingBlack,
    backgroundColor: colors.primary.mainBlue,
    '&:hover': {
      backgroundColor: props.color || colors.primary.darkBlue,
    },
  }),
  disabledButtonCaption: {
    color: colors.grayColor.gray,
  },

  secondaryButton: (props) => ({
    borderRadius: borderRadius,
    borderColor: props.color || colors.primary.mainBlue,
    color: props.color || typoColors.white,
    '&:hover': {
      borderColor: props.color || colors.primary.darkBlue,
      color: props.color || colors.primary.mainBlue,
    },
    '&:disabled': {
      color: colors.grayColor.gray,
    },
  }),

  primaryCaption: {
    color: `${typoColors.white} !important`,
  },

  secondaryCaption: {
    color: colors.primary.mainBlue,
  },

  focusedButton: {
    boxShadow: `0px 10px 20px rgba(0, 0, 0, 0.05)`,
  },
}));

export default useStyles;
