import { action, makeObservable, observable, runInAction } from 'mobx';

import { groupsApiService, organizationsApiService, usersApiService } from 'services/apiService';

import { UserAuthModel } from 'Shared/Models/Users';
import { IUserPostDto } from 'Shared/Interfaces/Api/Useres';
import { UserRole } from '../Shared/Enums';

interface IUserAuthDef {
  isPending: boolean;
  authUser: UserAuthModel;

  logIn(): Promise<void>;
  init(): void;
}

class UserAuthStore implements IUserAuthDef {
  public isPending = false;

  public isShowUserData = false;

  public authUser = new UserAuthModel();

  constructor() {
    makeObservable(this, {
      isPending: observable,
      isShowUserData: observable,

      logIn: action.bound,
      showUserData: action.bound,
    });
  }

  public init() {
    this.authUser.clear();
  }

  public showUserData(value: boolean) {
    this.isShowUserData = value;
  }

  public async logIn(): Promise<void> {
    this.authUser.clearBeforeAuth();

    const authData = this.authUser.getCredentialsDto();

    try {
      this.isPending = true;
      const result = await usersApiService.authUser(authData);

      this.authUser.setAuthState(true);

      let userData: IUserPostDto | null = null;
      let userOrgName = '';

      if (authData.login !== 'admin') {
        userData = await usersApiService.getUserById(result.id);
        const organizations = await organizationsApiService.getOrganizations();
        const userOrg = organizations.find((item) => item.id === userData?.organizationId);
        userOrgName = userOrg?.name!;
      } else {
        userData = {
          organizationId: '',
          surname: 'Администратор',
          login: 'admin',
          roleId: UserRole.Admin,
          departmentIds: null,
          firstName: '',
          patronymic: '',
          password: '',
          id: '01',
        };

        userOrgName = 'АРЦ НК';
      }

      const userGroups = await groupsApiService.getGroupsByStudent(result.id);

      this.authUser.updateGroupAndDeviceType(userGroups);

      runInAction(() => {
        if (!result) {
          this.authUser.clearBeforeAuth();
          this.authUser.setAuthState(false);
        }
      });

      if (result) {
        this.authUser.updateBySuccessAuth(result, userData, userOrgName);
      }
    } catch (e) {
      console.log(e);
      this.authUser.setAuthState(false);
      // ignore
    } finally {
      runInAction(() => {
        this.isPending = false;
      });
    }
  }
}

export default new UserAuthStore();
