import React from 'react';
import clsx from 'clsx';
import { H4Text } from '../textWrappers';

import { ISelectItem } from 'Shared/Interfaces/App';

import useStyles from './styles';

interface IProps {
  tabsWrapperClassName?: string;
  tabClassName?: string;
  currentTabClassName?: string;
  captionClassName?: string;
  isVertical?: boolean;
  items: ISelectItem[];
  selected: string;
  onChange: (selected: string) => void;
}

const TabsMenu = (props: IProps) => {
  const {
    currentTabClassName,
    captionClassName,
    selected,
    tabsWrapperClassName,
    tabClassName,
    isVertical,
    items,
    onChange,
  } = props;

  const c = useStyles();

  const wrapperClassName = isVertical ? c.tabsWrapper : c.tabsHorizontalWrapper;

  return (
    <div className={tabsWrapperClassName || wrapperClassName}>
      {items.map((item) => {
        const onChangeTab = () => {
          onChange(item.id);
        };

        const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.code === 'Enter') {
            onChangeTab();
          }
        };

        const isCurrent = selected === item.id;

        const verticalClasses = isCurrent
          ? `${clsx(currentTabClassName, c.currentTab)} ${clsx(tabClassName, c.tabItem)} unselectable`
          : `${clsx(tabClassName, c.tabItem)} unselectable`;

        const horizontalClasses = isCurrent
          ? `${clsx(currentTabClassName, c.currentHorizontalTab)} ${clsx(
              tabClassName,
              c.tabHorizontalItem
            )} unselectable`
          : `${clsx(tabClassName, c.tabHorizontalItem)} unselectable`;

        const classes = isVertical ? verticalClasses : horizontalClasses;

        return (
          <div onKeyDown={onKeyDown} tabIndex={0} className={classes} key={item.id} onClick={onChangeTab}>
            <H4Text noWrap={true} className={captionClassName || ''} isLight={isCurrent && isVertical}>
              {item.name}
            </H4Text>
          </div>
        );
      })}
    </div>
  );
};

export default TabsMenu;
