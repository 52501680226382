import { action, makeObservable, observable } from 'mobx';
import { Sprite, Texture } from 'pixi.js';
import SampleDepth from '../Sample/SampleDepth';
import PipAbstract from './pipAbstract';
import {IDefectDepthData, IPoint} from 'Shared/Interfaces/App';
import Utils from 'Shared/Utils';

/**
 * ПИП глубины
 */
export default class PipDepth extends PipAbstract {
  elementResult: HTMLElement | null;
  sampleDepth: number;
  pipDepthRadius: number;
  defects: IDefectDepthData[];

  pipDepthImage = 'images/thick_sensor.png';

  public depthValue = '';
  
  constructor(sample: SampleDepth, pipDepthRadius: number, x: number, y: number, labelResult: string) {
    super(sample);
    this.sampleDepth = sample.sampleData.depth;
    this.defects = sample.sampleData.defects;
    this.pipDepthRadius = pipDepthRadius;
    
    this.elementResult = document.getElementById(labelResult);

    makeObservable(this, {depthValue: observable, onDragMoveHandler: action });
  }

  /**
   * Построение ПИП
   * @param radius - радиус, мм
   * @param x
   * @param y
   * @private
   */
  private buildPip(radius: number, x: number, y: number) {
    const sensor = Sprite.from(Texture.from(this.pipDepthImage));
    
    this.pip.addChild(sensor);
    sensor.anchor.set(0.5);
    this.pip.position.set(x, y);
  }

  /**
   * Инитит ПИП при выборе датчика
   */
  public initPip(point: IPoint) {
    super.initPip(point);

    this.buildPip(this.sample.toPx(this.pipDepthRadius), this.sample.relativeX, this.sample.relativeY);
    this.AddPip(this.onDragMoveHandler);
  }

  /**
   * Перемещение
   * @param xMetric - метрический х
   * @param yMetric - метрический y
   */
  onDragMoveHandler = (xMetric: number, yMetric: number) => {
    // Оригинальная глубина
    let depth = this.sampleDepth;

    // Попадение внутрь
    this.defects.forEach((data: IDefectDepthData) => {
      const isX = xMetric > data.x && xMetric < data.x + data.width;
      const isY = yMetric > data.y && yMetric < data.y + data.height;
      if (isX && isY) depth = data.depth;
    });

    // Шум глубины (+ до 0.1 единиц, с вероятностью 50%)
    depth = depth + Utils.randomProb(0.1, 0.5);

    this.onSetDepthValue(depth);

    if (this.elementResult) this.elementResult.innerText = depth.toFixed(2);
  };

  public onSetDepthValue(depth?: number): void {
    if (!depth) {
      this.depthValue = '';
      return;
    }

    this.depthValue = depth.toFixed(1);
  }
}
