import { makeAutoObservable } from 'mobx';

import { appStore, studyCoursesStore } from 'Stores';

import { AdmissibilityLevels, CoursesId, DefectTableFocusedCell } from 'Shared/Enums';
import { IDefectNoteData, IUserDefectDefects } from 'Shared/Interfaces/App';

interface IDefectNodeDef {
  focused: DefectTableFocusedCell | null;
  sensorName: string;
  defectNumber: number | null;
  oX: number | null;
  y: number | null;
  defectLength: number | null;
  levelOver: number | null;
  shortNote: string;

  // допустимость дефекта
  admissibility: AdmissibilityLevels;

  onSensorNameChange(name: string): void;
  initDefectNumber(defectNumber: number | null): void;
  setFocused(focused: DefectTableFocusedCell | null): void;
  onOXChange(value: number | null): void;
  onYChange(value: number | null): void;
  onDefectLengthChange(value: number | null): void;
  onLevelOverChange(value: number | null): void;
  onShortNoteChange(note: string): void;
  onSetAdmissibility(level: AdmissibilityLevels): void;
}

/**
 * Запись в таблице дефектов
 */
export class DefectNote implements IDefectNodeDef {
  private sendDataCallback: any;

  constructor(
    sendDefectData: (data: IUserDefectDefects) => void,
    defectNumber: number | null,
    initData?: IDefectNoteData
  ) {
    makeAutoObservable(this, undefined, { autoBind: true });

    this.sendDataCallback = sendDefectData;

    if (defectNumber) {
      this.defectNumber = defectNumber;
    }

    if (!initData) return;

    this.sensorName = initData.sensorName;
    this.shortNote = initData.shortNote;
    this.defectNumber = initData.defectNumber;
    this.defectLength = initData.defectLength!;
    this.oX = initData.oX;
    this.y = initData.y;
    this.width = initData.width!;
    this.height = initData.height!;
    this.defectDepth = initData.defectDepth!;
    this.levelOver = initData.levelOver;
    this.sign = initData.sign!;
    this.admissibility = initData.admissibility || AdmissibilityLevels.NotSet;
  }

  public focused: DefectTableFocusedCell | null = null;

  public sensorName = '';

  public defectNumber: number | null = null;

  public sign = '+';

  public oX: number | null = null;

  public y: number | null = null;

  public defectLength: number | null = null;

  public defectDepth: number | null = null;

  public levelOver: number | null = null;

  public width: number | null = null;

  public height: number | null = null;

  public admissibility: AdmissibilityLevels = AdmissibilityLevels.NotSet;

  public shortNote = '';

  public getDtoToSave(): IDefectNoteData {
    return {
      sensorName: this.sensorName,
      defectNumber: this.defectNumber,
      oX: this.oX,
      y: this.y,
      defectLength: this.defectLength,
      defectDepth: this.defectDepth,
      levelOver: this.levelOver,
      sign: this.sign,
      shortNote: this.shortNote,
      admissibility: this.admissibility,
    };
  }

  public getUserDefectData(): IUserDefectDefects {
    const y = studyCoursesStore.selectedCourse.code === CoursesId.ThicknessMeasurement ? this.y : this.oX;
    const x = studyCoursesStore.selectedCourse.code === CoursesId.ThicknessMeasurement ? this.oX : 0;
    return {
      counter: this.defectNumber!,
      y: y!,
      x: x!,
      length: this.defectLength!,
    };
  }

  public onChangeSign(value: string): void {
    this.sign = value;
  }

  public onSensorNameChange(name: string): void {
    this.sensorName = name;
    appStore.putDefectNotesToLocalStorage();
  }

  public initDefectNumber(defectNumber: number): void {
    this.defectNumber = defectNumber;
    appStore.putDefectNotesToLocalStorage();
  }

  public setFocused(focused: DefectTableFocusedCell | null): void {
    this.focused = focused;
  }

  public onOXChange(value: number | null): void {
    this.oX = value;
    appStore.putDefectNotesToLocalStorage();
    this.sendDataCallback(this.getUserDefectData());
    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.check();
    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.checkThicknessStep();
  }

  public onYChange(value: number | null): void {
    this.y = value;
    appStore.putDefectNotesToLocalStorage();
    this.sendDataCallback(this.getUserDefectData());
    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.check();
    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.checkThicknessStep();
  }

  public onDepthChange(value: number | null): void {
    this.defectDepth = value;
    this.sendDataCallback(this.getUserDefectData());
    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.checkThicknessStep();
  }

  public onDefectLengthChange(value: number | null): void {
    this.defectLength = Math.abs(value!);
    appStore.putDefectNotesToLocalStorage();
    this.sendDataCallback(this.getUserDefectData());
    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.check();
  }

  public onLevelOverChange(value: number | null): void {
    this.levelOver = Math.abs(value!);
    appStore.putDefectNotesToLocalStorage();
    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.check();
  }

  public onShortNoteChange(note: string): void {
    this.shortNote = note;
    appStore.putDefectNotesToLocalStorage();
    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.check();
  }

  public onSetAdmissibility(level: AdmissibilityLevels): void {
    this.admissibility = level;
    appStore.putDefectNotesToLocalStorage();
    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.check();
    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.checkThicknessStep();
  }
}
