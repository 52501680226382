import { makeStyles, Theme } from '@material-ui/core';
import { bgColors, colors, indents, inputBorderRadius } from '../theme';

interface IProps {
  height?: number;
  isLightBg?: boolean;
}

const useStyles = makeStyles<Theme, IProps>({
  wrapper: {
    position: 'relative',
  },
  selectWrapper: (props) => ({
    textAlign: 'left',
    height: props.height || 40,
    marginRight: 24,
    width: '100%',
    borderRadius: inputBorderRadius,
    '& .MuiOutlinedInput-input': {
      padding: `${indents.four}px ${indents.eight}px`,
    },
    '& fieldset': {
      borderColor: colors.primary.mainBlue,
    },
    '&:hover fieldset': {
      borderColor: colors.primary.darkBlue,
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.primary.darkBlue,
    },
  }),
  items: {
    backgroundColor: bgColors.deviceBg,
  },
  itemIcon: {
    marginRight: '16px',
  },
});

export default useStyles;
