import React from 'react';

import { observer } from 'mobx-react';

import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

import { H5Text } from '../textWrappers';
import { FontWeights } from '../theme';

import { IconButtonWrapper } from '../Buttons';

import { appStore } from 'Stores';

import useStyles from './styles';

interface IProps {
  className?: string;
  title: string;
  subtitle: string;
  htmlNodeId: string;
  withExpander?: boolean;
}

const COLLAPSED_TITLE_LENGTH = 50;

const StudyTextWrapper = (props: IProps) => {
  const { className, title, subtitle, htmlNodeId, withExpander } = props;
  const c = useStyles();

  let titleText = '';

  if (!title && !appStore.isStudCardExpanded) {
    titleText = subtitle.substr(0, COLLAPSED_TITLE_LENGTH) + '...';
  }

  if (title) {
    titleText = titleText;
  }

  return (
    <div
      className={className ? className : c.introduceText}
      style={!appStore.isStudCardExpanded ? { height: '36px' } : {}}>
      {withExpander && (
        <div className={c.expander}>
          <IconButtonWrapper
            hint={appStore.isStudCardExpanded ? 'Свернуть карточку' : 'Развернуть карточку'}
            onClick={appStore.studyCardToggle}
            icon={
              appStore.isStudCardExpanded ? (
                <KeyboardArrowUp className={c.expanderBtn} />
              ) : (
                <KeyboardArrowDown className={c.expanderBtn} />
              )
            }
          />
        </div>
      )}

      {appStore.isStudCardExpanded && (
        <>
          <H5Text fontWeight={FontWeights.Bold} isLight={true} className={c.introduceTitle} text={titleText} />
          <H5Text className={c.stepTitle} fontWeight={FontWeights.Bold} isLight={true} text={subtitle} />
          <H5Text id={htmlNodeId} className={c.stepFullText} isLight={true} />
        </>
      )}

      {!appStore.isStudCardExpanded && (
        <H5Text fontWeight={FontWeights.Bold} isLight={true} className={c.introduceTitleCollapsed} text={titleText} />
      )}
    </div>
  );
};

export default observer(StudyTextWrapper);
