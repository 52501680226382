import { makeStyles } from '@material-ui/core';

import { bgColors, borderRadius, colors, indents, smallestBorderRadius, typoColors } from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  optionsWrapper: {
    color: 'white',
    height: '100%',
    width: 150,
    maxWidth: 150,
    minWidth: 150,
    paddingTop: indents.eight,
    paddingLeft: indents.sixteen,
    paddingRight: indents.sixteen,
    minHeight: 'fit-content',
    overflow: 'auto',
    overflowX: 'hidden',
  },
  commonParamsRow: {
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
  },
  paramsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
  },
  inputParamWrapper: {
    marginBottom: indents.sixteen,
    minWidth: 'fit-content',
    width: 120,
  },
  inputParamLabel: {
    marginLeft: indents.four,
    fontSize: 14,
    color: bgColors.deviceFont,
  },
  selectRows: {
    backgroundColor: bgColors.deviceBg,
    color: bgColors.deviceFont,
  },
  paramInput: {
    marginTop: indents.eight,
    width: 120,
    '& .MuiSelect-icon': {
      color: typoColors.white,
    },
    '& .MuiOutlinedInput-input': {
      color: typoColors.white,
    },
    '& .MuiOutlinedInput-input:disabled': {
      color: typoColors.bodyGrey,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: bgColors.deviceBorders,
      },
      '&:hover fieldset': {
        borderColor: bgColors.deviceBorderActive,
      },
      '&.Mui-focused fieldset': {
        borderColor: bgColors.deviceBorderActive,
      },
    },
    '& .MuiInputBase-root': {
      borderRadius: smallestBorderRadius,
    },
  },
  paramInputDisabled: (props) => ({
    marginBottom: indents.sixteen,
    width: 120,
    '& .MuiInputBase-root': {
      borderRadius: borderRadius,
    },
  }),
  listItemsWrapper: {
    backgroundColor: bgColors.deviceBg,
    color: bgColors.deviceFont,
    '&.MuiListItem-root:hover': {
      backgroundColor: bgColors.deviceBg2,
    },
    '&.Mui-selected': {
      backgroundColor: bgColors.deviceBg,
    },
  },
}));

export default useStyles;
