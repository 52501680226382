import { makeStyles } from '@material-ui/core';

import { borderRadius, colors, indents } from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  deviceTools: {
    display: 'flex',
    alignItems: 'center',
    height: 'fit-content',
    marginRight: indents.four,
    borderRadius: borderRadius,
    minWidth: 'fit-content',
  },
  toolItemWrapper: {
    width: 'fit-content',
    marginRight: indents.eight,
  },
  focusWrapper: {
    padding: indents.four,
    width: 'fit-content',
  },
  sensorSelector: {
    minWidth: '120px',
    width: 'fit-content',
  },
  sampleSelector: {
    minWidth: '120px',
    width: 'fit-content',
  },
  draggbleToolItem: {
    height: 30,
    minWidth: 30,
    padding: indents.four,
    cursor: 'pointer',
    border: `solid ${colors.primary.mainBlue} 1px`,
    borderRadius: borderRadius,
    display: 'flex',
    alignItems: 'center',
  },
  pipDirectionToggle: {
    height: '100%',
    marginLeft: indents.four,
    marginRight: indents.four,
  },
  pipToggleReversed: {
    height: '100%',
    marginLeft: indents.four,
    marginRight: indents.four,
    transform: 'scale(-1, 1)',
  },
}));

export default useStyles;
