import dayjs from 'dayjs';
import { IGroupRowDto } from 'Shared/Interfaces/Api/Groups';

interface IGroupRowModelDef {
  id: string;
  name: string;
  studentsCount: number | null;
  studyPeriod: string;
}

class GroupRowModel implements IGroupRowModelDef {
  public id = '';

  public name = '';

  public studyPeriod = '';

  public studentsCount: number | null = null;

  constructor(dto: IGroupRowDto) {
    this.id = dto.id;
    this.name = dto.name;
    this.studentsCount = dto.studentsCount;
    this.studyPeriod = `${dayjs(dto.dateFrom).format('DD.MM.YYYY')} - ${dayjs(dto.dateTo).format('DD.MM.YYYY')}`;
  }
}

export default GroupRowModel;
