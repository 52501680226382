import PaginationParams from './PaginationParams';
import SortingParams from './SortingParams';

export class TableListParams {
  public pagination = new PaginationParams();

  public sorting = new SortingParams<any>('name', true);

  private readonly onChange: (pageIndex: number, pageSize: number, sortingColumnName: string, direction: any) => void;

  constructor(
    onChange: (pageIndex: number, pageSize: number, sortingColumnName: any, isAscending: boolean) => void,
    pageIndex?: number,
    pageSize?: number
  ) {
    this.onChange = onChange;

    this.onChangePage = this.onChangePage.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onChangePageSize = this.onChangePageSize.bind(this);
  }

  public onSort(order: 'asc' | 'desc' | undefined, orderBy: string | undefined) {
    this.sorting.updateDirection(order === 'asc');

    this.sorting.updateField(orderBy);

    this.onChange(
      this.pagination.pageIndex,
      this.pagination.pageSize,
      this.sorting.sortingField,
      this.sorting.isAscending
    );
  }

  public onChangePage(pageNumber: number) {
    this.pagination.updatePageIndex(pageNumber);
    this.onChange(
      this.pagination.pageIndex,
      this.pagination.pageSize,
      this.sorting.sortingField,
      this.sorting.isAscending
    );
  }

  public onChangePageSize(pageSize: number) {
    this.pagination.updatePageSize(pageSize);
    this.onChange(
      this.pagination.pageIndex,
      this.pagination.pageSize,
      this.sorting.sortingField,
      this.sorting.isAscending
    );
  }

  public deInit() {
    this.pagination.deInit();
    this.sorting.deInit();
  }
}
