import { makeAutoObservable, runInAction } from 'mobx';

import { usersApiService } from 'services/apiService';

import { appStore } from './index';

import { TableListParams } from 'services/dataServices/tableServices/TableListParams';
import { UserFormModel, UserRowModel } from 'Shared/Models/Users';

import { UserRole } from 'Shared/Enums';
import { ISelectItem } from 'Shared/Interfaces/App';
import Utils from 'Shared/Utils';

interface IUserStoreDef {
  isPending: boolean;
  isShowForm: boolean;
  userIdForUpdatePassword: string;
  userIdForRestore: string;
  usersRole: UserRole;
  userIdToRemove: string;
  listParams: TableListParams;
  user: UserFormModel;
  users: UserRowModel[];
  filterPattern: string;
  sortedUsers: UserRowModel[];
  teachersList: ISelectItem[];

  get userNameForRestore(): string;
  get userNameForUpdatePassword(): string;
  get userNameToRemove(): string;
  get usersListIsExist(): boolean;

  addNewUser(userRole: UserRole): void;
  closeForm(): void;
  showRemoveDialog(cloudId: string): void;
  closeRemoveDialog(): void;
  showRestoreDialog(userId: string): void;
  closeRestoreDialog(): void;
  updatePasswordForUser(useId: string): void;
  copyPassword(): void;
  submitUser(): Promise<void>;
  removeUser(): Promise<void>;
  restoreUser(): Promise<void>;
  submitNewPassword(): Promise<void>;
  getUserById(userId: string): Promise<void>;
  getUsers(): Promise<void>;
  getSortedList(): void;
  onFilterPatternChange(pattern: string): void;
  getTeachersSelectList(): Promise<void>;
  deInit(): void;
}

class UsersStore implements IUserStoreDef {
  public isPending = false;

  public isShowForm = false;

  public userIdForUpdatePassword = '';

  public userIdForRestore = '';

  public usersRole: UserRole = UserRole.Student;

  public userIdToRemove = '';

  public listParams = new TableListParams(() => this.getSortedList());

  public user: UserFormModel = new UserFormModel();

  public selectedTab: UserRole = UserRole.Student;

  public users: UserRowModel[] = [];

  public filterPattern = '';

  public sortedUsers: UserRowModel[] = [];

  public teachersList: ISelectItem[] = [];

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public get userNameForRestore() {
    return this.users.find((item) => item.id === this.userIdForRestore)?.fullName || '';
  }

  public get userNameForUpdatePassword() {
    return this.users.find((item) => item.id === this.userIdForUpdatePassword)?.fullName || '';
  }

  public get userNameToRemove() {
    return this.users.find((item) => item.id === this.userIdToRemove)?.fullName || '';
  }

  public get usersListIsExist() {
    return this.users.length > 0;
  }

  public onChangeSelectedTab(selectedTab: UserRole) {
    this.selectedTab = selectedTab;
    this.usersRole = selectedTab;
  }

  public addNewUser(userRole: UserRole) {
    this.isShowForm = true;
    this.user.generatePassword();
    this.user.setRole(userRole);
  }

  public closeForm() {
    this.isShowForm = false;
    this.user.clear();
  }

  public closeRemoveDialog() {
    this.userIdToRemove = '';
  }

  public closeRestoreDialog() {
    this.userIdForRestore = '';
  }

  public showRemoveDialog(cloudId: string) {
    this.userIdToRemove = cloudId;
  }

  public showRestoreDialog(userId: string) {
    this.userIdForRestore = userId;
  }

  public async restoreUser() {
    this.isPending = true;

    try {
      await usersApiService.restoreUser(this.userIdForRestore);

      this.closeRestoreDialog();
      this.getUsers(true);
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public async submitUser() {
    this.isPending = true;

    try {
      if (this.user.id) {
        const dto = this.user.getPutDto();
        if (!dto) return;

        await usersApiService.putUser(this.user.id, dto);
        await this.getUsers();
      } else {
        const dto = this.user.getPostDto();
        if (!dto) return;

        const userId = await usersApiService.postUser(dto);

        if (!userId) return;
      }

      this.closeForm();
      this.getUsers();
    } catch (e) {
      console.log(e);
      this.user.loginNameValidState = (e as any).response.data.error || '';
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public updatePasswordForUser(userId: string) {
    if (userId) {
      this.user.generatePassword();
    }

    this.userIdForUpdatePassword = userId;
  }

  public copyPassword() {
    navigator.clipboard.writeText(this.user.password);
  }

  public async submitNewPassword(): Promise<void> {
    this.isPending = true;

    try {
      await usersApiService.updatePassword(this.userIdForUpdatePassword, this.user.password);
      appStore.showToastMessage('Пароль успешно обновлён');
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public async getUserById(userId: string) {
    this.isPending = true;

    try {
      const dto = await usersApiService.getUserById(userId);
      if (!dto) return;

      this.user.updateFromDto(dto);
      this.isShowForm = true;
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public async removeUser() {
    if (!this.userIdToRemove) return;

    this.isPending = true;

    try {
      const result = await usersApiService.deleteUser(this.userIdToRemove);
      if (!result) return;

      this.getUsers();
      this.userIdToRemove = '';
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public getSortedList() {
    const columnName = this.listParams.sorting.sortingField as keyof UserRowModel;

    const filtered = this.users.filter(
      (item) =>
        item.fullName.toLowerCase().includes(this.filterPattern.toLowerCase().trim()) ||
        item.organizationName.toLowerCase().includes(this.filterPattern.toLowerCase().trim()) ||
        item.groupNames.toLowerCase().includes(this.filterPattern.toLowerCase().trim())
    );

    this.listParams.pagination.setTotalCount(filtered.length);

    const sorted = filtered.sort((a: UserRowModel, b: UserRowModel) =>
      this.listParams.sorting.isAscending ? Utils.ascCompare(a, b, columnName) : Utils.descCompare(a, b, columnName)
    );

    const start = this.listParams.pagination.pageIndex * this.listParams.pagination.pageSize;

    this.sortedUsers = sorted.slice(
      start,
      start + this.listParams.pagination.pageSize > sorted.length
        ? sorted.length
        : start + this.listParams.pagination.pageSize
    );
  }

  public async getUsers(isArchive?: boolean) {
    this.isPending = true;

    try {
      const result = await usersApiService.getUsers(this.usersRole, isArchive);
      this.users = result.map((dto) => new UserRowModel(dto));
      this.listParams.pagination.setTotalCount(result.length);
      this.listParams.onChangePage(0);
      this.getSortedList();
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public onFilterPatternChange(pattern: string) {
    this.filterPattern = pattern;
    this.getSortedList();
  }

  public async getTeachersSelectList() {
    this.isPending = true;

    try {
      const result = await usersApiService.getUsers(UserRole.Teacher);
      this.teachersList = result.map((dto) => ({ id: dto.id, name: dto.fullName }));
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => (this.isPending = false));
    }
  }

  public deInit() {
    this.users = [];
    this.sortedUsers = [];
    this.filterPattern = '';
    this.userIdForRestore = '';
    this.userIdToRemove = '';
  }
}

export default new UsersStore();
