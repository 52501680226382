import React from 'react';

import { colors, H5Text, H6Text, NumberInput } from 'Components/uiKitElems';

import { ComparedDefect, DefectType } from 'Shared/Models/Checking/ComparedDefect';

import useStyles from './styles';

interface IProps {
  className?: string;
  comparedDefect: ComparedDefect;
}

const ThicknessCheckRow = (props: IProps) => {
  const c = useStyles();

  const { comparedDefect } = props;

  return (
    <div className={c.rowContainer}>
      <div className={c.leftCell}>
        <div className={c.topOfCell}>
          <H6Text color={'#828282'} text={comparedDefect.realSensor || '-'} />
        </div>
        <div className={c.bottomOfCell}>
          <H5Text text={comparedDefect.userSensor || '-'} />
        </div>
      </div>

      <div className={c.middleCell}>
        <div className={c.topOfCell}>
          <H6Text color={'#828282'} text={comparedDefect.realDefectDepth?.toString() || '-'} />
        </div>
        <div className={c.bottomOfCell}>
          <H5Text text={comparedDefect.userDefectDepth?.toString() || '-'} />
        </div>
      </div>

      <div className={c.middleCell}>
        <div className={c.topOfCell}>
          <H6Text color={'#828282'} text={comparedDefect.realOX?.toString() || '-'} />
        </div>
        <div className={c.bottomOfCell}>
          <H5Text text={comparedDefect.userOX?.toString() || '-'} />
        </div>
      </div>

      <div className={c.middleCell}>
        <div className={c.topOfCell}>
          <H6Text color={'#828282'} text={comparedDefect.realY?.toString() || '-'} />
        </div>
        <div className={c.bottomOfCell}>
          <H5Text text={comparedDefect.userY?.toString() || '-'} />
        </div>
      </div>

      <div className={c.middleCell}>
        <div className={c.topOfCell}>
          <H6Text color={'#828282'} text={comparedDefect.realAdmissibility || '-'} />
        </div>
        <div className={c.bottomOfCell}>
          <H5Text noWrap={true} text={comparedDefect.userAdmissibility || ''} />
        </div>
      </div>

      <div className={c.middleCell}>
        <div className={c.topOfCell} />
        <div className={c.bottomOfCell}>
          <H5Text
            noWrap={true}
            color={
              comparedDefect.defectType === DefectType.Found
                ? colors.statusColor.successGreen
                : colors.statusColor.dangerRed
            }
            text={comparedDefect.defectType || ''}
          />
        </div>
      </div>

      <div className={c.rightCell}>
        <div className={c.topOfCell}/>
        <div className={c.bottomOfCell}>
          <NumberInput
            className={c.downLeveInput}
            height={26}
            onChange={comparedDefect.onChangeDownLevel}
            value={comparedDefect.downLevel!}
          />
        </div>
      </div>
    </div>
  );
};

export default ThicknessCheckRow;
