import {
  IDefectDeviceParams,
  ISampleDefectData,
  ISampleDepthData,
  IUserDefectDefects,
  IUserDepthDefects,
} from 'Shared/Interfaces/App';

import { SampleMode, SensorMode } from 'Shared/Enums';

/**
 * Образец "Ступенька"
 */
export const sampleStepData: ISampleDepthData = {
  id: 'step',
  mode: SampleMode.DepthStep,
  name: "Образец 'Ступенька'",
  width: 250,
  height: 73,
  depth: 40,
  defects: [
    { id: '1', x: 0, y: 0, width: 50, height: 73, depth: 10 },
    { id: '2', x: 50, y: 0, width: 50, height: 73, depth: 12 },
    { id: '3', x: 100, y: 0, width: 50, height: 73, depth: 16 },
    { id: '4', x: 150, y: 0, width: 50, height: 73, depth: 22 },
    { id: '5', x: 200, y: 0, width: 50, height: 73, depth: 14 },
  ],
};

// Пример образца толщиномера
export const sampleDepthData: ISampleDepthData = {
  id: 'depth',
  mode: SampleMode.DepthStudy,
  name: 'ЭО-УТ-01',
  width: 100,
  height: 100,
  depth: 7.6,
  defects: [
    { id: '1', x: 10, y: 10, width: 5, height: 4, depth: 5 },
    { id: '2', x: 27, y: 34, width: 4, height: 5, depth: 2 },
    { id: '3', x: 60, y: 30, width: 6, height: 6, depth: 4 },
  ],
};

// Пример образца дефектоскопа для обучения
export const sampleDefectStudy: ISampleDefectData = {
  id: 'StudyDefect',
  mode: SampleMode.DefectStudy,
  name: 'ЭО-УЗК-1-14',
  width: 300,
  height: 150,
  depth: 14,
  weld: { x: 150, width: 20 },
  defects: [
    { id: '1', x: 150, y: 15, length: 22, depth: 7.3, size: 6 },
    { id: '2', x: 150, y: 48, length: 2, depth: 13.2, size: 7.4 },
    { id: '3', x: 150, y: 75, length: 7, depth: 7.5, size: 3.2 },
    { id: '4', x: 150, y: 95, length: 51, depth: 8.6, size: 3 },
  ],
};

// Образец калибровки браковочного уровня
const calibrateDepth = 14; // Всегда 14
const calibrateDefectDepth = 2.0; // todo kvv из БД
const calibrateDefectWidth = 2.0; // todo kvv из БД
const calibrateDefectSize = calibrateDefectDepth * calibrateDefectWidth;
export const calibrateLevelsDefectData: ISampleDefectData = {
  id: '00000000-0000-0000-0000-000000000999', // todo kvv из БД
  mode: SampleMode.DefectCalibrationLevels,
  name: `НО-14-${calibrateDefectDepth.toFixed(1)}x${calibrateDefectDepth.toFixed(1)}`,
  width: 150,
  height: 75,
  depth: calibrateDepth,
  defects: [
    { id: '1', x: 120, y: 35, length: 2, depth: 0, size: calibrateDefectSize },
    { id: '2', x: 33, y: 35, length: 2, depth: calibrateDepth, size: calibrateDefectSize },
  ],
};

export const emptySampleData: ISampleDefectData = {
  id: 'default',
  mode: SampleMode.Empty,
  name: 'Без образца',
  width: 208,
  height: 30,
  depth: 58,
  defects: [],
};

// Калибровочный образец СО2
export const sampleDefectCO2: ISampleDefectData = {
  id: 'СО-2',
  mode: SampleMode.DefectCO2,
  name: 'СО 2',
  width: 208,
  height: 30,
  depth: 58,
  defects: [
      { id: '1', x: 49, y: 0, length: 30, depth: 44, size: 2 },
      { id: '2', x: 183, y: 0, length: 30, depth: 8, size: 0.5 },
      { id: '3', x: 183, y: 0, length: 30, depth: 56, size: 0.5 },
  ],
};

// Калибровочный образец СО3
// Без дефектов, т.к. они расчитываются динамически, на основании угла датчика
export const sampleDefectCO3: ISampleDefectData = {
  id: 'СО-3',
  mode: SampleMode.DefectCO3,
  name: 'СО 3',
  width: 110,
  height: 30,
  depth: 125,
  defects: [
    { id: '1', x: 0, y: 0, length: 30, depth: 0, size: 1 },
    { id: '2', x: 0, y: 0, length: 30, depth: 0, size: 1 },
    { id: '3', x: 0, y: 0, length: 30, depth: 0, size: 1 },
    { id: '4', x: 0, y: 0, length: 30, depth: 0, size: 1 },
  ],
};

export const depthSamplesStudy = [sampleDepthData, sampleStepData];
export const depthSamplesExam = [sampleStepData];

export const defectSamplesStudy = [
  sampleDefectStudy,
  sampleDefectCO2,
  sampleDefectCO3,
  emptySampleData,
  calibrateLevelsDefectData,
];

export const defectSamplesExam = [sampleDefectCO2, sampleDefectCO3];

// Пример прибора
export const deviceParams: IDefectDeviceParams = {
  isPowerOn: true,
  amplification: 36,
  offset: 0,
  depth: 0,
  speed: 3200,
  trim: 0,
  scanWidth: 200,
  vrc: {
    enable: false,
    visible: false,
    index: 0,
    points: [
      { x: 20, y: 1 },
      { x: 50, y: 1 },
    ],
  },
  zoneA: {
    start: 50,
    width: 30,
    height: 38,
  },
  zoneB: {
    start: 10,
    width: 30,
    height: 30,
  },
  sensor: {
    mode: SensorMode.Separated,
    name: 'Сенсор 1',
    angle: 85,
    isRight: true,
    tread: 50,
    frequency: 5.5,
  },
};

// Пример дефектов который нашел пользователь
export const userDepthData: IUserDepthDefects[] = [
  { counter: 1, x: 20, y: 20 },
  { counter: 2, x: 180, y: 30 },
];

// Пример дефектов который нашел пользователь
export const userDefectData: IUserDefectDefects[] = [
  { counter: 1, x: 20, y: 20, length: 30 },
  { counter: 2, x: 180, y: 30, length: 0 },
];
