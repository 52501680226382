import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import useStyles from './styles';

interface IProps {
  error?: boolean;
  value?: string | Date;
  className?: string;
  placeholder?: string;
  minDate?: string | Date;
  maxDate?: string | Date;
  onChange: (value?: string) => void;
}

const DateInput = (props: IProps) => {
  const { className, error, value, minDate, maxDate, placeholder, onChange } = props;

  const c = useStyles();

  const [date, setDate] = useState<MaterialUiPickersDate>(dayjs());

  useEffect(() => {
    setDate(value ? dayjs(value) : null);
  }, [value]);

  const handleChange = (pickersDate: MaterialUiPickersDate) => {
    setDate(pickersDate);

    if (!pickersDate || !pickersDate.isValid()) return;

    onChange(pickersDate ? pickersDate.toISOString() : undefined);
  };

  return (
    <KeyboardDatePicker
      error={error}
      helperText={null}
      variant="inline"
      value={date}
      fullWidth={true}
      minDate={minDate}
      maxDate={maxDate}
      className={className ? `${className} ${c.dateInputWrapper}` : `${c.dateInputWrapper}`}
      inputVariant="outlined"
      placeholder={placeholder || 'дд.мм.гггг'}
      format="DD.MM.YYYY"
      InputAdornmentProps={{ position: 'start' }}
      onChange={handleChange}
    />
  );
};

export default DateInput;
