import axios, { AxiosRequestConfig, Method } from 'axios';

import { appStore, userAuthStore } from 'Stores';

export default class HTTPService {
  private readonly basePath: string;

  private readonly host = localStorage.getItem('arc_host') || process.env.REACT_APP_API_ENDPOINT;

  constructor(basePath: string) {
    this.basePath = basePath;
  }

  /**
   * GET request
   */
  public GET<T>(path = '', options?: AxiosRequestConfig): Promise<T> {
    return this.safeFetch(`${this.basePath}/${path}`, 'get', null, options);
  }

  /**
   * POST request
   */
  public POST<T>(path = '', data?: any, options?: AxiosRequestConfig): Promise<T> {
    return this.safeFetch(`${this.basePath}/${path}`, 'post', data, options);
  }

  /**
   * PUT request
   */
  public PUT<T>(path = '', data?: any, options?: AxiosRequestConfig): Promise<T> {
    return this.safeFetch(`${this.basePath}/${path}`, 'put', data, options);
  }

  /**
   * DELETE request
   */
  public DELETE(path = '', options?: AxiosRequestConfig): Promise<any> {
    return this.safeFetch(`${this.basePath}/${path}`, 'delete', null, options);
  }

  /**
   * Base Request
   */
  public async safeFetch(path: string, method: Method, data?: any, options: AxiosRequestConfig = {}): Promise<any> {
    if (!options.headers?.['Content-Type']) options.headers = { 'Content-Type': 'application/json' };
    options.headers.Authorization = 'Bearer ' + userAuthStore.authUser.apiToken;

    const baseURL = this.host;
    const url = `${path}`;

    try {
      const response = await axios.request({ baseURL, url, method, data, ...options });

      return response.data;
    } catch (errorData: any) {
      if (axios.isCancel(errorData)) {
        // eslint-disable-next-line
        console.log(errorData);
        return {};
      }

      throw errorData;
    }
  }
}
