import { makeStyles } from '@material-ui/core';

import { borderRadius, indents } from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  deviceTools: {
    padding: indents.eight,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    marginRight: indents.four,
    borderRadius: borderRadius,
    flexDirection: 'column',
    minWidth: 'fit-content',
  },
  sensorsWrapper: {
    minHeight: 42,
  },
  sensorFocusWrapper: {
    padding: indents.four,
  },
  sensorSelector: {
    minWidth: '120px',
  },
  samplesWrapper: {
    minHeight: 42,
  },
  samplesFocusWrapper: {
    padding: indents.four,
  },
  sampleSelector: {
    minWidth: '120px',
  },
  sensorImageWrapper: {
    height: '35%',
  },
  sensorImageFocusWrapper: {
    height: '100%',
    padding: indents.four,
  },
  sensorImage: {
    borderRadius: borderRadius,
    backgroundColor: '#EFF1F3',
    height: '100%',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  bottleWrapper: {
    marginTop: indents.eight,
    height: '35%',
  },
  bottleFocusWrapper: {
    height: '100%',
    padding: indents.four,
  },
  liquidBottleWrapper: {
    borderRadius: borderRadius,
    backgroundColor: '#EFF1F3',
    height: '100%',
    cursor: 'pointer',
  },
  pipDirectionToggle: {
    padding: indents.four,
  },
  pipToggleReversed: {
    padding: indents.four,
    transform: 'scale(-1, 1)',
  },
}));

export default useStyles;
