import {Graphics} from 'pixi.js';
import PipDepth from '../Pip/PipDepth';
import { IDefectDepthData, ISampleDepthData } from 'Shared/Interfaces/App';
import SampleAbstract from "./SampleAbstract";
import {SampleMode} from "../../../Enums";

export default class SampleDepth extends SampleAbstract {

    /**
     * URL картинок образца
     */
    imageDefaultSample = 'images/sampleSurface/sample.jpg';
    pipDepthStepImage = 'images/sampleSurface/depth-step.jpg';
    
    /**
     * Данные образца
     */
    sampleData: ISampleDepthData;
    
    constructor(view: HTMLCanvasElement, sampleData: ISampleDepthData) {
        super(view, sampleData)
        
        this.sampleData = sampleData;
        
        const imageSampleUrl = sampleData.mode === SampleMode.DepthStep 
            ? this.pipDepthStepImage 
            : this.imageDefaultSample
        this.setMaterial(imageSampleUrl);

        this.addChild(this.liquidGraphics);
        
        this.addChild(this.userDefectsContainer);
        if (this.isShowDefects) this.setDefects();

        this.pip = new PipDepth(this, 5, this.metricX, this.metricY, 'label-depth');
    }

    /**
     * Дефекты (реальное положение)
     */
    public setDefects() {
        const graphDefects = new Graphics();
        this.sampleData.defects.forEach((data: IDefectDepthData) => {
            graphDefects.beginFill(0xde3249, 0.6);
            const x = this.sampleX + this.toPx(data.x);
            const y = this.sampleY + this.sampleHeight - this.toPx(data.y);
            graphDefects.drawRect(x, y, this.toPx(data.width), this.toPx(-data.height));
            graphDefects.endFill();
        });
        this.addChild(graphDefects);
    }

    public putLiquidOnSurface() {
        if (this.isSurfaceWet) return;
        this.isSurfaceWet = true;

        this.liquidGraphics.lineStyle(0);
        this.liquidGraphics.beginFill(0xFFC107, 0.2);
        this.liquidGraphics.drawRect(this.sampleX + 10, this.sampleY + 10, this.sampleWidth - 20, this.sampleHeight - 20);
        this.liquidGraphics.endFill();
    }
}