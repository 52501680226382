import { makeStyles } from '@material-ui/core';
import { indents } from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  groupFormWrapper: {
    height: 'fit-content',
    width: 'fit-content',
    paddingLeft: indents.fortyEight,
    paddingTop: indents.sixteen,
    paddingRight: indents.thirtyTwo,
    paddingBottom: indents.thirtyTwo,
  },
  groupFormRow: {
    marginTop: indents.twentyFour,
  },
  groupFormInput: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 537,
  },
  dateInput: {
    width: '48%',
  },
  groupFormActionRow: {
    marginTop: indents.thirtyTwo,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default useStyles;
