import { makeStyles, Theme } from '@material-ui/core';

import { borderRadius, colors } from '../theme';

interface IProps {
  fullWidth?: boolean;
  isLightBg?: boolean;
  isNotVisibleForMouse?: boolean;
}

const useStyles = makeStyles<Theme, IProps>({
  studyFocusWrapperHidden: (props) => ({
    position: 'relative',
    width: props.fullWidth ? '100%' : 'auto',
  }),
  studyFocusWrapper: (props) => ({
    pointerEvents: props.isNotVisibleForMouse ? 'none' : 'auto',
    zIndex: 3,
    width: props.fullWidth ? '100%' : 'fit-content',
    position: 'relative',
    outline: `solid ${colors.primary.mainBlue} 4px`,
    borderRadius: borderRadius,
    backgroundColor: props.isLightBg ? 'none' : colors.primary.darkBlue,
  }),
  studyLocker: {
    zIndex: 2,
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
});

export default useStyles;
