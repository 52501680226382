import React from 'react';
import { observer } from 'mobx-react';

import { Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { Footer, Header } from 'Components/Common';

import { IconButtonWrapper } from 'Components/uiKitElems';

import { appStore, userAuthStore } from 'Stores';

import ModalDialog from '../ModalDialog';
import UserInfo from '../UserInfo';

import useStyles from './styles';

interface IProps {
  children: JSX.Element;
}

const Layout = (props: IProps) => {
  const c = useStyles();
  const { children } = props;

  return (
    <div className={c.mainWrapper}>
      <Header />
      <div className={c.bodyContent}>{children}</div>
      <Footer />

      <ModalDialog
        title={'Информация'}
        hideBackdrop={true}
        isOpen={userAuthStore.isShowUserData}
        onClose={() => userAuthStore.showUserData(false)}>
        <UserInfo />
      </ModalDialog>

      <div className={c.overlay}>
        <Snackbar
          className={c.toastWrapper}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={!!appStore.lastToastMessage}
          autoHideDuration={3000}
          onClose={appStore.closeToaster}
          message={appStore.lastToastMessage}
          action={
            <IconButtonWrapper className={c.closeBtn} icon={<CloseIcon />} onClick={() => appStore.closeToaster()} />
          }
        />
      </div>
    </div>
  );
};

export default observer(Layout);
