import { makeStyles } from '@material-ui/core';
import { indents } from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  thicknessModulesWrapper: {
    marginLeft: indents.thirtyTwo,
    display: 'flex',
    paddingTop: indents.thirtyTwo,
    overflow: 'auto',
    height: '100%',
  },
}));

export default useStyles;
