import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateJsUtils from '@date-io/dayjs';

import { CssBaseline, MuiThemeProvider } from '@material-ui/core';

import Layout from './Components/Common/Layout/Layout';
import Router from 'Router';

import { appStore } from './Stores';

import './App.css';

const App = () => {
  return (
    <MuiThemeProvider theme={appStore.mainTheme}>
      <MuiPickersUtilsProvider utils={DateJsUtils}>
        <div className="App">
          <CssBaseline />
          <Layout>
            <BrowserRouter>
              <Switch>
                <Router />
              </Switch>
            </BrowserRouter>
          </Layout>
        </div>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default App;
