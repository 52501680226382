import { makeStyles } from '@material-ui/core';

import { colors, indents } from '../../../uiKitElems';

const useStyles = makeStyles((theme) => ({
  tableListenerWrapper: {
    height: '100%',
  },
  tableWrapper: {
    height: '100%',
  },
  selectCellInput: {
    width: 90,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  cellInput: {
    minHeight: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '150px',
    minWidth: '50%',
  },
  editCellIcon: {
    color: colors.primary.mainBlue,
    width: 20,
    height: 20,
  },
  measurementInputCell: {
    height: "fit-content",
    [theme.breakpoints.down('lg')]: {
      padding: 1,
    },
    [theme.breakpoints.up('lg')]: {
      padding: indents.four,
    },
  },
}));

export default useStyles;
