import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { H5Text, InputWrapper, LabelPosition, NumberInput, TextInputSize } from 'Components/uiKitElems';
import { OnOffSwitcher } from 'Components/uiKitElems/OnOffSwitcher';
import { Select } from 'Components/uiKitElems/Select';
import { StudyFocusWrapper } from 'Components/uiKitElems/StudyFocusWrapper';

import { measurementStore, studyCoursesStore } from 'Stores';

import {OptionsTabCode, RayLength, SampleMode, SensorMode, StudyFocusEl, VRHPointIndex} from 'Shared/Enums';

import useStyles from './styles';
import SampleDefect from "../../../../../../../../Shared/Models/Simulation/Sample/SampleDefect";

const FlawOptions = () => {
  const c = useStyles();

  useEffect(() => {
    if (!studyCoursesStore.selectedCourse.selectedModule?.currentStep?.flawDeviceTabByStep) return;

    if (
      measurementStore.currentDeviceTab !==
      studyCoursesStore.selectedCourse.selectedModule.currentStep.flawDeviceTabByStep
    ) {
      measurementStore.setDeviceTab(studyCoursesStore.selectedCourse.selectedModule.currentStep.flawDeviceTabByStep);
    }
  }, [studyCoursesStore.selectedCourse.selectedModule?.currentStep?.id]);

  const onChangeAmplification = useCallback((value: number | null) => {
    measurementStore.chart?.params.onAmplificationChange(value);
    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.check();
  }, []);

  const checkAZoneParams = useCallback(() => {
    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.check();
  }, []);

  const checkBZoneParams = useCallback(() => {
    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.check();
  }, []);

  const onAngleChange = useCallback((value: number) => {
    measurementStore.chart?.sensor.onAngleChange(value);
    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.check();

    // Пересчёт положения дефектов для СО3
    if ((measurementStore.sample as SampleDefect).sampleData.mode === SampleMode.DefectCO3)
        measurementStore.calcCO3(value, (measurementStore.sample as SampleDefect).sampleData.defects);
  }, []);
  
  const onChangeMode = useCallback((mode: string) => {
    measurementStore.chart?.params.sensor.onChangeMode(mode as SensorMode);
    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.check();
  }, []);

  const onFrequencyChange = useCallback((frequency: number | null) => {
    measurementStore.chart?.params.sensor.onFrequencyChange(frequency);
    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.check();
  }, []);

  const onChangeScan = useCallback((scan: number | null) => {
    measurementStore.chart?.params.onScanWidthChange(scan);
    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.check();
  }, []);

  const onAZoneStartChange = useCallback((value: number | null) => {
    measurementStore.chart?.params.zoneA?.onStartChange(value);
    checkAZoneParams();
  }, []);

  const onAZoneWidthChange = useCallback((value: number | null) => {
    measurementStore.chart?.params.zoneA?.onWidthChange(value);
    checkAZoneParams();
  }, []);

  const onAZoneHeightChange = useCallback((value: number | null) => {
    measurementStore.chart?.params?.zoneA?.onHeightChange(value);
    checkAZoneParams();
  }, []);

  const onBZoneStartChange = useCallback((value: number | null) => {
    measurementStore.chart?.params.zoneB?.onStartChange(value);
    checkBZoneParams();
  }, []);

  const onBZoneWidthChange = useCallback((value: number | null) => {
    measurementStore.chart?.params.zoneB?.onWidthChange(value);
    checkBZoneParams();
  }, []);

  const onBZoneHeightChange = useCallback((value: number | null) => {
    measurementStore.chart?.params?.zoneB?.onHeightChange(value);
    checkBZoneParams();
  }, []);

  const onChangeRayCalcMode = useCallback((mode: string) => {
    measurementStore.chart?.updateRayCalcMode(mode as RayLength);
    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.check();
  }, []);

  const vrhToggle = useCallback(() => {
    measurementStore.chart?.params.vrc?.enableToggle();
    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.check();
  }, []);

  const onSelectVrhPoint = useCallback((selectedPointNumber: string) => {
    measurementStore.chart?.params.vrc?.onSelectPoint(selectedPointNumber);
    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.check();
  }, []);

  const onVrhPointGainChange = useCallback((value: number | null) => {
    measurementStore.chart?.params.vrc?.selectedPoint?.onXChange(value);
    onVrhPointCheck();
  }, []);

  const onVrhPointPositionChange = useCallback((value: number | null) => {
    measurementStore.chart?.params.vrc?.selectedPoint?.onYChange(value);
    onVrhPointCheck();
  }, []);

  const onVrhPointCheck = useCallback(() => {
    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.check();
  }, []);

  const onThicknessChange = useCallback((value: number | null) => {
    measurementStore.chart?.params.onHeightChange(value);
    studyCoursesStore.selectedCourse.selectedModule?.currentStep?.check();
  }, []);

  const commonControls = (
    <div className={c.paramsWrapper}>
      <InputWrapper
        labelPosition={LabelPosition.TopLeft}
        className={c.inputParamWrapper}
        labelCell={<H5Text noWrap={true} className={c.inputParamLabel} text={'Скорость, м/с'} />}
        inputCell={
          <NumberInput
            height={24}
            className={c.paramInput}
            size={TextInputSize.Sm}
            onChange={measurementStore.chart?.params.onSpeedChange!}
            value={measurementStore.chart?.params.speed!}
          />
        }
      />

      <StudyFocusWrapper id={StudyFocusEl.Thickness}>
        <InputWrapper
          labelPosition={LabelPosition.TopLeft}
          className={c.inputParamWrapper}
          labelCell={<H5Text noWrap={true} className={c.inputParamLabel} text={'Толщина, мм'} />}
          inputCell={
            <NumberInput
              height={24}
              step={1}
              className={c.paramInput}
              size={TextInputSize.Sm}
              onChange={onThicknessChange}
              value={measurementStore.chart?.params.depth!}
            />
          }
        />
      </StudyFocusWrapper>

      <StudyFocusWrapper id={StudyFocusEl.RayLengthMode}>
        <InputWrapper
          labelPosition={LabelPosition.TopLeft}
          className={c.inputParamWrapper}
          labelCell={<H5Text noWrap={true} className={c.inputParamLabel} text={'Величина'} />}
          inputCell={
            <Select
              items={[
                { id: RayLength.S0a, name: 'S0-a' },
                { id: RayLength.Sab, name: 'Sa-b' },
                { id: RayLength.H, name: RayLength.H },
              ]}
              height={24}
              className={c.paramInput}
              itemsWrapperClass={c.listItemsWrapper}
              onChange={onChangeRayCalcMode}
              value={measurementStore.chart?.rayCalcMode.toString()!}
            />
          }
        />
      </StudyFocusWrapper>

      <InputWrapper
        labelPosition={LabelPosition.TopLeft}
        className={c.inputParamWrapper}
        labelCell={<H5Text noWrap={true} className={c.inputParamLabel} text={'Задержка, мкс'} />}
        inputCell={
          <NumberInput
            height={24}
            className={c.paramInput}
            size={TextInputSize.Sm}
            onChange={measurementStore.chart?.params.onOffsetChange!}
            value={measurementStore.chart?.params.offset!}
          />
        }
      />

      <InputWrapper
        labelPosition={LabelPosition.TopLeft}
        className={c.inputParamWrapper}
        labelCell={<H5Text noWrap={true} className={c.inputParamLabel} text={'Отсечка, %'} />}
        inputCell={
          <NumberInput
            height={24}
            className={c.paramInput}
            size={TextInputSize.Sm}
            onChange={measurementStore.chart?.params.onTrimChange!}
            value={measurementStore.chart?.params.trim!}
          />
        }
      />
    </div>
  );

  const sensorControls = (
    <div className={c.paramsWrapper}>
      <StudyFocusWrapper id={StudyFocusEl.InputAngle}>
        <InputWrapper
          labelPosition={LabelPosition.TopLeft}
          className={c.inputParamWrapper}
          labelCell={<H5Text noWrap={true} className={c.inputParamLabel} text={'Угол ввода, град'} />}
          inputCell={
            <NumberInput
              height={24}
              className={c.paramInput}
              size={TextInputSize.Sm}
              onChange={onAngleChange}
              value={measurementStore.chart?.sensor.angle!}
            />
          }
        />
      </StudyFocusWrapper>

      <StudyFocusWrapper id={StudyFocusEl.Frequency}>
        <InputWrapper
          labelPosition={LabelPosition.TopLeft}
          className={c.inputParamWrapper}
          labelCell={<H5Text noWrap={true} className={c.inputParamLabel} text={'Частота, МГц'} />}
          inputCell={
            <NumberInput
              step={0.1}
              height={24}
              className={c.paramInput}
              size={TextInputSize.Sm}
              onChange={onFrequencyChange}
              value={measurementStore.chart?.params.sensor.frequency!}
            />
          }
        />
      </StudyFocusWrapper>

      <StudyFocusWrapper id={StudyFocusEl.Mode}>
        <InputWrapper
          labelPosition={LabelPosition.TopLeft}
          className={c.inputParamWrapper}
          labelCell={<H5Text noWrap={true} className={c.inputParamLabel} text={'Режим'} />}
          inputCell={
            <Select
              height={24}
              className={c.paramInput}
              itemsWrapperClass={c.listItemsWrapper}
              items={[
                { id: SensorMode.Separated.toString(), name: 'Раздельний' },
                { id: SensorMode.Combined.toString(), name: 'Совмещённый' },
              ]}
              onChange={onChangeMode}
              value={measurementStore.chart?.params.sensor.mode!}
            />
          }
        />
      </StudyFocusWrapper>

      <StudyFocusWrapper id={StudyFocusEl.Protector}>
        <InputWrapper
          labelPosition={LabelPosition.TopLeft}
          className={c.inputParamWrapper}
          labelCell={<H5Text noWrap={true} className={c.inputParamLabel} text={'Протектор, мкс'} />}
          inputCell={
            <NumberInput
              step={0.1}
              height={24}
              className={c.paramInput}
              size={TextInputSize.Sm}
              onChange={measurementStore.chart?.params.sensor.onChangeProtector!}
              value={measurementStore.chart?.params.sensor.protector!}
            />
          }
        />
      </StudyFocusWrapper>
    </div>
  );

  const aZoneControls = (
    <div className={c.paramsWrapper}>
      <StudyFocusWrapper id={StudyFocusEl.AZone}>
        <InputWrapper
          labelPosition={LabelPosition.TopLeft}
          className={c.inputParamWrapper}
          labelCell={<H5Text noWrap={true} className={c.inputParamLabel} text={'а-Начало, мм'} />}
          inputCell={
            <NumberInput
              height={24}
              className={c.paramInput}
              size={TextInputSize.Sm}
              onChange={onAZoneStartChange}
              value={measurementStore.chart?.params.zoneA?.start!}
            />
          }
        />

        <InputWrapper
          labelPosition={LabelPosition.TopLeft}
          className={c.inputParamWrapper}
          labelCell={<H5Text noWrap={true} className={c.inputParamLabel} text={'а-Ширина, мм'} />}
          inputCell={
            <NumberInput
              height={24}
              className={c.paramInput}
              size={TextInputSize.Sm}
              onChange={onAZoneWidthChange}
              value={measurementStore.chart?.params.zoneA?.width!}
            />
          }
        />

        <InputWrapper
          labelPosition={LabelPosition.TopLeft}
          className={c.inputParamWrapper}
          labelCell={<H5Text noWrap={true} className={c.inputParamLabel} text={'а-Порог, %'} />}
          inputCell={
            <NumberInput
              height={24}
              className={c.paramInput}
              size={TextInputSize.Sm}
              onChange={onAZoneHeightChange}
              value={measurementStore.chart?.params.zoneA?.height!}
            />
          }
        />
      </StudyFocusWrapper>
    </div>
  );

  const bZoneControls = (
    <div className={c.paramsWrapper}>
      <StudyFocusWrapper id={StudyFocusEl.BZone}>
        <InputWrapper
          labelPosition={LabelPosition.TopLeft}
          className={c.inputParamWrapper}
          labelCell={<H5Text noWrap={true} className={c.inputParamLabel} text={'б-Начало, мм'} />}
          inputCell={
            <NumberInput
              height={24}
              className={c.paramInput}
              size={TextInputSize.Sm}
              onChange={onBZoneStartChange}
              value={measurementStore.chart?.params.zoneB?.start!}
            />
          }
        />

        <InputWrapper
          labelPosition={LabelPosition.TopLeft}
          className={c.inputParamWrapper}
          labelCell={<H5Text noWrap={true} className={c.inputParamLabel} text={'б-Ширина, мм'} />}
          inputCell={
            <NumberInput
              height={24}
              className={c.paramInput}
              size={TextInputSize.Sm}
              onChange={onBZoneWidthChange}
              value={measurementStore.chart?.params.zoneB?.width!}
            />
          }
        />

        <InputWrapper
          labelPosition={LabelPosition.TopLeft}
          className={c.inputParamWrapper}
          labelCell={<H5Text noWrap={true} className={c.inputParamLabel} text={'б-Порог, %'} />}
          inputCell={
            <NumberInput
              height={24}
              className={c.paramInput}
              size={TextInputSize.Sm}
              onChange={onBZoneHeightChange}
              value={measurementStore.chart?.params.zoneB?.height!}
            />
          }
        />
      </StudyFocusWrapper>
    </div>
  );

  const vrhControls = (
    <div className={c.paramsWrapper}>
      <StudyFocusWrapper id={StudyFocusEl.VRHSwitcher}>
        <InputWrapper
          labelPosition={LabelPosition.TopLeft}
          className={c.inputParamWrapper}
          labelCell={<H5Text noWrap={true} className={c.inputParamLabel} text={'Вкл ВРЧ'} />}
          inputCell={
            <OnOffSwitcher
              className={c.paramInput}
              isOn={measurementStore.chart?.params.vrc?.enable!}
              height={24}
              onSwitch={vrhToggle}
            />
          }
        />
      </StudyFocusWrapper>

      <StudyFocusWrapper id={StudyFocusEl.VrhPointSelector}>
        <InputWrapper
          labelPosition={LabelPosition.TopLeft}
          className={c.inputParamWrapper}
          labelCell={<H5Text noWrap={true} className={c.inputParamLabel} text={'Точка'} />}
          inputCell={
            <Select
              height={24}
              className={!measurementStore.chart?.params.vrc?.visible! ? c.paramInputDisabled : c.paramInput}
              itemsWrapperClass={c.listItemsWrapper}
              disabled={!measurementStore.chart?.params.vrc?.visible!}
              items={[
                { id: VRHPointIndex.FirstPoint, name: '1' },
                { id: VRHPointIndex.SecondPoint, name: '2' },
              ]}
              onChange={onSelectVrhPoint}
              value={measurementStore.chart?.params.vrc?.index.toString()!}
            />
          }
        />
      </StudyFocusWrapper>

      <StudyFocusWrapper id={StudyFocusEl.VrhPointGainAndPosition}>
        <InputWrapper
          labelPosition={LabelPosition.TopLeft}
          className={c.inputParamWrapper}
          labelCell={<H5Text noWrap={true} className={c.inputParamLabel} text={'Усиление, дБ'} />}
          inputCell={
            <NumberInput
              step={0.1}
              height={24}
              className={!measurementStore.chart?.params.vrc?.visible! ? c.paramInputDisabled : c.paramInput}
              disabled={!measurementStore.chart?.params.vrc?.visible!}
              size={TextInputSize.Sm}
              onChange={onVrhPointPositionChange}
              value={measurementStore.chart?.params.vrc?.selectedPoint?.y!}
            />
          }
        />

        <InputWrapper
          labelPosition={LabelPosition.TopLeft}
          className={c.inputParamWrapper}
          labelCell={<H5Text noWrap={true} className={c.inputParamLabel} text={'Положение, мм'} />}
          inputCell={
            <NumberInput
              height={24}
              className={!measurementStore.chart?.params.vrc?.visible! ? c.paramInputDisabled : c.paramInput}
              disabled={!measurementStore.chart?.params.vrc?.visible!}
              size={TextInputSize.Sm}
              onChange={onVrhPointGainChange}
              value={measurementStore.chart?.params.vrc?.selectedPoint?.x!}
            />
          }
        />
      </StudyFocusWrapper>
    </div>
  );

  return (
    <div className={c.optionsWrapper}>
      <div className={c.commonParamsRow}>
        <StudyFocusWrapper isNotDisabled={true} id={StudyFocusEl.Gain}>
          <InputWrapper
            labelPosition={LabelPosition.TopLeft}
            className={c.inputParamWrapper}
            labelCell={<H5Text noWrap={true} className={c.inputParamLabel} text={'Усиление, дБ'} />}
            inputCell={
              <NumberInput
                height={24}
                className={c.paramInput}
                size={TextInputSize.Sm}
                step={0.1}
                onChange={onChangeAmplification}
                value={measurementStore.chart?.params.amplification!}
              />
            }
          />
        </StudyFocusWrapper>

        <StudyFocusWrapper id={StudyFocusEl.Scan}>
          <InputWrapper
            labelPosition={LabelPosition.TopLeft}
            className={c.inputParamWrapper}
            labelCell={<H5Text noWrap={true} className={c.inputParamLabel} text={'Развертка, мм'} />}
            inputCell={
              <NumberInput
                height={24}
                className={c.paramInput}
                size={TextInputSize.Sm}
                onChange={onChangeScan}
                value={measurementStore.chart?.params.scanWidth!}
              />
            }
          />
        </StudyFocusWrapper>
      </div>

      {measurementStore.currentDeviceTab === OptionsTabCode.common && commonControls}
      {measurementStore.currentDeviceTab === OptionsTabCode.sensor && sensorControls}
      {measurementStore.currentDeviceTab === OptionsTabCode.aZone && aZoneControls}
      {measurementStore.currentDeviceTab === OptionsTabCode.bZone && bZoneControls}
      {measurementStore.currentDeviceTab === OptionsTabCode.VRH && vrhControls}
    </div>
  );
};

export default observer(FlawOptions);
