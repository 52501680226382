import { makeStyles, Theme } from '@material-ui/core';
import { bgColors, borderRadius, colors, indents, inputBorderRadius } from '../theme';

interface IProps {
  height?: number;
  isLightBg?: boolean;
}

const useStyles = makeStyles<Theme, IProps>({
  wrapper: {
    position: 'relative',
  },
  selectedItemsWrapper: {
    display: 'flex',
    flexWrap: "wrap",
  },
  selectedItem: {
    backgroundColor: colors.primary.lightBlue,
    borderRadius: borderRadius,
    marginRight: indents.four,
    marginTop: indents.four,
    marginBottom: indents.four,
    padding: indents.four,
  },
  selectWrapper: (props) => ({
    textAlign: 'left',
    height: 'auto',
    minHeight: 40,
    width: '100%',
    borderRadius: inputBorderRadius,
    '& .MuiOutlinedInput-input': {
      maxHeight: '200px',
      overflow: 'auto',
      padding: `${indents.four}px ${indents.eight}px`,
    },
    '& fieldset': {
      borderColor: colors.primary.mainBlue,
    },
    '&:hover fieldset': {
      borderColor: colors.primary.darkBlue,
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.primary.darkBlue,
    },
  }),
  items: {
    backgroundColor: bgColors.deviceBg,
  },
  itemIcon: {
    marginRight: '16px',
  },
});

export default useStyles;
