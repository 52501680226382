import { action, makeObservable, observable} from "mobx";
import { Application, TilingSprite, Texture, Graphics, TextStyle, Text, DisplayObject, Container } from 'pixi.js';

import PipAbstract from '../Pip/pipAbstract';
import Utils from 'Shared/Utils';
import {SampleMode} from "../../../Enums";
import {ISampleData, IUserDefects} from "../../../Interfaces/App";

export default class SampleAbstract {

    /**
     * Показывать ли реальные дефекты на образце 
     */
    isShowDefects = false;
    
    /**
     * Цвет фона
     */
    backgroundColor: number = 0xdee2e6;

    /**
     * Размер мажорных рисок линейки
     */
    majorTickRulerSize: number = 15;

    /**
     * Размер минорных рисок линейки
     */
    minorTickRulerSize: number = 7;

    /**
     * Смещение от линейки до краёв
     */
    rulerOffset: number;

    /**
     * Отступы откраёв
     */
    margin: number;

    /**
     * Начало образца X, пикс
     */
    sampleX: number;

    /**
     * Начало образца Y, пикс
     */
    sampleY: number;

    /**
     * Высота образца, с учётом пропорций, пикс
     */
    sampleHeight: number;

    /**
     * Права граница образца в пикс.
     */
    sampleRightBorder: number;

    /**
     * Отображение линейки
     */
    isShowRuler = true;

    /**
     * Кастомная линейка по Х
     */
    isCustomRulerX = false;

    /**
     * Ширина образца, пикс
     */
    sampleWidth: number;

    width: number;
    height: number;

    /**
     * Экземпляр PIXI
     */
    app: Application;

    // Координаты
    metricX: number = 0;
    metricY: number = 0;
    relativeX: number = 0;
    relativeY: number = 0;

    /**
     * Текстовая метка Х
     */
    textX: Text | undefined;

    /**
     * Текстовая метка Y
     */
    textY: Text | undefined;

    /**
     * Линии координат
     */
    coordLines: Graphics | undefined;

    /**
     * Callback изменения координат от ПИП
     */
    setCoord: any;

    /**
     * ППИ
     */
    pip: PipAbstract | null = null;

    /**
     * Есть ли жидкость на образце
     */
    public isSurfaceWet = false;

    /**
     * Стиль подписей линейки
     */
    textRulerFontStyle = new TextStyle({ fontSize: 12 });

    /**
     * Контейнер точек дефектов пользователя
     */
    userDefectsContainer: Container = new Container();

    /**
     * Контейнер рисования жидкости
     */
    liquidGraphics: Graphics = new Graphics();

    constructor(view: HTMLCanvasElement, sampleData: ISampleData, setCoord: any = null) {
        makeObservable(this, { isSurfaceWet: observable, putLiquidOnSurface: action })

        const mode = sampleData.mode;
        
        this.putLiquidOnSurface = this.putLiquidOnSurface.bind(this);
        
        // Для СО2 / СО3 скрываем линейку
        if ([SampleMode.DefectCO2, SampleMode.DefectCO3, SampleMode.DepthStep].includes(mode)) this.isShowRuler = false;
        // Для дефектоскопа - кастомная линейка по Х (от шва)
        if ([SampleMode.Defect, SampleMode.DefectStudy].includes(mode)) this.isCustomRulerX = true;

        Utils.disableDblClick(view);
        view.style.userSelect = 'none';

        this.width = sampleData.width;
        this.height = sampleData.height;
        this.setCoord = setCoord;

        const clientWidth = view.clientWidth;

        this.margin = this.isShowRuler ? 30 : 0;
        this.sampleY = this.margin;
        this.rulerOffset = this.isShowRuler ? this.majorTickRulerSize + 30 : 0;
        this.sampleX = this.rulerOffset + this.margin;

        this.sampleWidth = clientWidth - this.rulerOffset - this.margin * 2;
        this.sampleHeight = this.sampleWidth * (this.height / this.width);
        this.sampleRightBorder = clientWidth - this.margin;
        
        this.app = new Application({
            antialias: true,
            backgroundColor: this.backgroundColor,
            width: clientWidth,
            height: clientWidth * (this.sampleHeight / this.sampleWidth) + this.margin * 2,
            view,
        });

        this.changeCursorMode('crosshair');
        
        if (this.isShowRuler) this.setRuler();

        // Динамические  метки
        if (this.isShowRuler) {
            const rulerFontStyle = new TextStyle({fontSize: 18});
            
            // Если от центра, то не показываем динамическую метку
            if (!this.isCustomRulerX) {
                this.textX = new Text(Math.round(this.metricX).toString(), rulerFontStyle);
                this.addChild(this.textX);
            }
            this.textY = new Text(Math.round(this.metricY).toString(), rulerFontStyle);
            this.addChild(this.textY);

            this.coordLines = new Graphics();

            this.setCoordPip();
        }

        // Отображаем / скрываем дефекты
        document.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.code == 'KeyK' && e.ctrlKey && e.shiftKey) this.setDefects();
        });
    }

    public setDefects() { };
    
    public putLiquidOnSurface(sampleId: string) { }
    
    /**
     * Вид курсора
     * @param cursorMode
     */
    public changeCursorMode(cursorMode: string) {
        this.app.renderer.plugins.interaction.cursorStyles.pointer = cursorMode;
        this.app.renderer.plugins.interaction.cursorStyles.default = cursorMode;
        this.app.renderer.plugins.interaction.setCursorMode(cursorMode);
    }

    /**
     * Пересчёт из мм в px
     */
    public toPx = (value: number) => {
        return value * (this.sampleWidth / this.width);
    };

    /**
     * Пересчёт из px в мм
     */
    public toMetric = (value: number) => {
        return value / (this.sampleWidth / this.width);
    };

    /**
     * Линии от координат до ПИП
     * @param x
     * @param y
     */
    public changeCoordLines(x: number, y: number) {
        if (!this.isShowRuler) return;

        this.coordLines!.clear();
        this.coordLines!.lineStyle(1, 0x0000ff, 0.6);

        const x1 = this.sampleX;
        const y1 = this.sampleY + this.sampleHeight - y;
        const x2 = this.sampleX + x;
        const y2 = this.sampleY + this.sampleHeight;

        this.coordLines!.moveTo(x1, y1);
        this.coordLines!.lineTo(x2, y1);
        this.coordLines!.lineTo(x2, y2);
    }

    /**
     * Подложка
     */
    public setMaterial(imageSample: string) {
        const material = new TilingSprite(Texture.from(imageSample), 100, 100);
        material.position.set(this.sampleX, this.sampleY);
        material.width = this.sampleWidth;
        material.height = this.sampleHeight;
        this.addChild(material);

        // Пропорции по высоте
        const img = new Image();
        img.src = imageSample;
        img.onload = (e: Event) => material.tileScale.set(this.sampleHeight / (<HTMLImageElement>e.target).height);

        // Контур
        const materialContour = new Graphics();
        materialContour.lineStyle(1, 0x000000, 1);
        materialContour.drawRect(this.sampleX, this.sampleY, this.sampleWidth, this.sampleHeight);
        this.addChild(materialContour);

        this.coordLines && this.addChild(this.coordLines);
    }

    /**
     * Линейка
     */
    private setRuler() {
        const graphRuler = new Graphics();
        graphRuler.lineStyle(1, 0x000000, 1);

        const xRulerY = this.sampleY + this.sampleHeight;

        if (!this.isCustomRulerX) {
            // Мажор Х
            for (let i = 0; i <= this.width; i += 10) {
                const x = this.sampleX + this.toPx(i);
                graphRuler.moveTo(x, xRulerY);
                graphRuler.lineTo(x, xRulerY + this.majorTickRulerSize);

                const textRulerText = new Text(Math.round(i).toString(), this.textRulerFontStyle);
                textRulerText.anchor.set(0.5, 0);
                textRulerText.x = x;
                textRulerText.y = xRulerY + this.majorTickRulerSize;
                this.addChild(textRulerText);
            }

            // Минор Х
            for (let i = 0; i <= this.width; i += 5) {
                const x = this.sampleX + this.toPx(i);
                graphRuler.moveTo(x, xRulerY);
                graphRuler.lineTo(x, xRulerY + this.minorTickRulerSize);
            }
        }
        
        // Мажор Y
        for (let i = 0; i <= this.height; i += 10) {
            const y = xRulerY - +this.toPx(i);
            graphRuler.moveTo(this.sampleX, y);
            graphRuler.lineTo(this.sampleX - this.majorTickRulerSize, y);

            const textRulerText = new Text(Math.round(i).toString(), this.textRulerFontStyle);
            textRulerText.anchor.set(1, 0.5);
            textRulerText.x = this.sampleX - this.majorTickRulerSize;
            textRulerText.y = y;
            this.addChild(textRulerText);
        }

        // Минор Y
        for (let i = this.height; i >= 0; i -= 5) {
            const y = this.sampleY + this.sampleHeight - this.toPx(i);
            graphRuler.moveTo(this.sampleX, y);
            graphRuler.lineTo(this.sampleX - this.minorTickRulerSize, y);
        }

        this.addChild(graphRuler);
    }

    /**
     * Установка новых линий коодинеат и числовых значений ПИП
     */
    public setCoordPip() {
        if (!this.isShowRuler) return;

        // Линии от ПИП до осей
        this.changeCoordLines(this.toPx(this.metricX), this.toPx(this.metricY));

        // Подписи Х
        if (!this.isCustomRulerX) {
            this.textX!.text = Math.round(this.metricX).toString();
            this.textX!.anchor.set(0.5, 0);
            this.textX!.x = this.sampleX + this.toPx(this.metricX);
            this.textX!.y = this.sampleY + this.sampleHeight + this.rulerOffset;
        }

        // Подписи Y
        this.textY!.text = Math.round(this.metricY).toString();
        this.textY!.anchor.set(1, 0.5);
        this.textY!.x = this.sampleX - this.rulerOffset;
        this.textY!.y = this.sampleY + this.sampleHeight - this.toPx(this.metricY);
    }

    /**
     * Добавление элементов на канву
     * @param component
     */
    public addChild(component: DisplayObject) {
        this.app.stage.addChild(component);
    }

    /**
     * Установка пользовательских данных дефектов
     * @param userData
     * @param extraDraw
     */
    public setUserDefects(userData: IUserDefects[], extraDraw: any = null) {
        this.userDefectsContainer.removeChildren();
        const radius = this.toPx(3); // Радиус в мм
        
        const gr = new Graphics();
        gr.beginFill(0x00aa00, 1);
        this.userDefectsContainer.addChild(gr);

        userData.forEach((data: IUserDefects) => {
            const point =  { 
                x: this.sampleX + this.toPx(data.x),
                y: this.sampleY + this.sampleHeight - this.toPx(data.y) 
            };
            
            // Доп рисование
            if (extraDraw) extraDraw(this, gr, point, radius, data);

            // Маркер
            gr.drawCircle(point.x, point.y, radius);

            // Текст метки
            const value = data.counter.toString();
            const fontStyle = new TextStyle({ fontSize: 14 });
            const textMain = new Text(value, fontStyle);
            textMain.anchor.set(0.5, 0.5);
            textMain.x = point.x;
            textMain.y = point.y;
            this.userDefectsContainer.addChild(textMain);
        });

        gr.endFill();
    }
}