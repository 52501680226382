import HTTPService from './HTTPService';

import { apiPaths } from 'Shared/Constants/Navigation/apiPaths';

import { ISampleDto, ITuningSampleDto } from 'Shared/Interfaces/Api/Samples';

class SamplesApiService extends HTTPService {
  constructor() {
    super(apiPaths.samples);
  }

  public getSamples(deviceTypeId: string): Promise<ISampleDto[]> {
    return this.GET(`?deviceTypeId=${deviceTypeId}`);
  }

  public getSampleById(sampleId: string): Promise<ISampleDto> {
    return this.GET(`/${sampleId}`);
  }

  public getTuningSamples(): Promise<ITuningSampleDto[]> {
    return this.GET(`/tuning`);
  }
}

export default new SamplesApiService();
