import { Theme, makeStyles } from '@material-ui/core';
import { colors, indents } from '../theme';

interface IProps {
  spaceBetweenSize?: number;
  inputWidthInPercents?: number;
}

const useStyles = makeStyles<Theme, IProps>((theme) => ({
  controlWrapper: {
    display: 'flex',
    position: 'relative',
  },
  validationNotice: {
    position: 'absolute',
    fontSize: 12,
    left: indents.four,
    top: -indents.sixteen,
    color: colors.statusColor.dangerRed,
    maxWidth: '80%',
    height: 20,
    overflow: 'hidden',
  },
  inputElemsInColumnSlipLeft: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputElemsInColumnCentered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inputElemsInColumnSlipRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  inputElemsInRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputWrapper: (props) => ({
    width: `${props.inputWidthInPercents}%` || 'auto',
    display: 'flex',
  }),
  inputLabelOnAbove: (props) => ({
    display: 'flex',
    marginBottom: props.spaceBetweenSize || '0px',
  }),
  inputLabelOnLeft: (props) => ({
    display: 'flex',
    marginRight: props.spaceBetweenSize || '0px',
  }),
  requiredSign: {
    color: colors.statusColor.dangerRed,
    marginLeft: indents.eight,
    fontSize: 16,
  },
}));

export default useStyles;
