import { makeStyles } from '@material-ui/core';

import { indents } from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  organizationFormWrapper: {
    paddingLeft: indents.fortyEight,
    paddingTop: indents.sixteen,
    paddingRight: indents.thirtyTwo,
    paddingBottom: indents.thirtyTwo,
  },
  organizationFormRow: {
    marginTop: indents.twentyFour,
  },
  passwordInput: {
    width: 500,
  },
  copyBtn: {
    marginLeft: indents.eight,
  },
  organizationFormInput: {
    width: 537,
  },
  organizationFormActionRow: {
    marginTop: indents.thirtyTwo,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default useStyles;
