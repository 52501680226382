import React, { useRef } from 'react';
import clsx from 'clsx';

import { TextField, InputAdornment } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { TextInputSize, VariantInput } from './types';

import useStyles from './styles';

interface IProps {
  autoFocus?: boolean;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  endAdornment?: JSX.Element;
  error?: boolean;
  height?: number;
  nativeLabel?: string;
  onChange: (value: number) => void;
  password?: boolean;
  size?: TextInputSize;
  startAdornment?: JSX.Element;
  step?: number;
  value: number;
  variant?: VariantInput;
}

const NumberInput = (props: IProps) => {
  const {
    autoFocus,
    className,
    defaultValue,
    endAdornment,
    disabled,
    error,
    height,
    nativeLabel,
    onChange,
    size,
    startAdornment,
    step,
    value,
    variant,
  } = props;

  const inputRef = useRef<HTMLInputElement | null>(null);

  const c = useStyles({ height });

  let sizedClass = c.mdTextInputClass;
  const mainClass = disabled ? c.inputWrapperDisabled : c.inputWrapper;

  switch (size) {
    case TextInputSize.Sm: {
      sizedClass = c.smTextInputClass;
      break;
    }
    case TextInputSize.Md: {
      sizedClass = c.mdTextInputClass;
      break;
    }
    case TextInputSize.Lg: {
      sizedClass = c.lgTextInputClass;
      break;
    }
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    try {
      if (e.code === 'Enter') {
        (document.activeElement as any).blur();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value) || 0;
    onChange(value);
  };

  const errorIcon = error ? (
    <InputAdornment position="end">
      <ErrorOutlineIcon className={c.errorIcon} />
    </InputAdornment>
  ) : null;

  const startPlaceContent = (
    <>
      {startAdornment}
      {errorIcon}
    </>
  );

  const endPlaceContent = (
    <>
      {endAdornment}
      {errorIcon}
    </>
  );

  const inputProps = {
    startAdornment: startAdornment ? startPlaceContent : null,
    endAdornment: endAdornment ? endPlaceContent : null,
  };

  return (
    <div className={clsx(mainClass, sizedClass, className || '')}>
      <TextField
        ref={inputRef}
        onKeyDown={onKeyDown}
        label={nativeLabel}
        value={value}
        disabled={disabled}
        type={'number'}
        inputProps={{ step: step || 1 }}
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        error={error}
        InputProps={inputProps}
        onChange={handleChange}
        variant={variant || VariantInput.Outlined}
      />
    </div>
  );
};

export default NumberInput;
