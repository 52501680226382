export enum LocalStorageParam {
  CourseId = 'courseId',
  GroupId = 'groupId',
  CurrentAdminTab = '',
  ModuleId = 'moduleId',
  ModuleStepIndex = 'moduleStepIndex',
  FlawDeviceParams = 'flawDeviceParams',
  DefectNotes = 'defectNotes',
  ToolsState = 'toolsState',
  ThickDeviceToolState = 'thickDeviceToolState',
}

export enum UrlParams {
  GroupId = 'groupId',
}

export enum UserRole {
  Admin = '00000000-0000-0000-0000-000000000001',
  Teacher = '00000000-0000-0000-0000-000000000002',
  Student = '00000000-0000-0000-0000-000000000003',
}

export enum CoursesId {
  // Толщинометрия
  ThicknessMeasurement = '00000000-0000-0000-0000-000000000001',
  // Дефектоскопия
  FlawDetection = '00000000-0000-0000-0000-000000000002',
}

export enum MaterialType {
  TypeStahl20 = '00000000-0000-0000-0000-000000000001',
  Type12X18H10T = '00000000-0000-0000-0000-000000000002',
  Type09G2C = '00000000-0000-0000-0000-000000000003',
}

export enum SampleType {
  Teller = '00000000-0000-0000-0000-000000000001',
  Tube = '00000000-0000-0000-0000-000000000002',
  TubeSegment = '00000000-0000-0000-0000-000000000003',
}

export enum CourseModulesIds {
  ThickIntroduce = '1',
  ThickWorkWithTool = '2',
  ThickExam = '3',
  FlawIntroduce = '4',
  FlawWorkWithTool = '5',
  FlawExam = '6',
}

export enum ModuleProgress {
  Locked = 'Модуль недоступен',
  Current = 'Текущий модуль',
  Started = 'Начат',
  Completed = 'Модуль пройден',
}

export enum VRHPointIndex {
  FirstPoint = '0',
  SecondPoint = '1',
}

export enum SampleMode {
  Empty = 0,
  Depth = 1,
  Defect = 2,
  DepthStudy = 3,
  DefectStudy = 4,
  DepthStep = 5,
  DefectCO2 = 6,
  DefectCO3 = 7,
  DefectCalibrationLevels = 8,
}

export enum StudyFocusEl {
  UnKnown = 'unknown',
  DeviceDisplay = 'Дисплей прибора',
  AZone = 'А зона параметры',
  BZone = 'Б зона параметры',
  Sensor = 'датчик',
  Sample = 'образец',
  InputAngle = 'угол ввода',
  Mode = 'Режим',
  Protector = 'Протектор',
  Frequency = 'Частота',
  Scan = 'Развёртка',
  Liquid = 'Контактная жидкость',
  SampleSurface = 'Поверхность образца',
  SampleSurfaceSegment = 'Часть поверхности образца',
  SO2Sample = 'Образец СО2',
  SO3Sample = 'Образец СО3',
  CalibrationLevelsSample = 'Образец калиборовки браковочных уровней',
  Gain = 'Усиление',
  RayLengthMode = 'Длина луча',
  Y = 'Y',
  VRHSwitcher = 'Вкл ВРЧ',
  VrhPointSelector = 'Выбор ВРЧ Точки',
  VrhPointGainAndPosition = 'Усиление и положение ВРЧ точки',
  Thickness = 'Толщина образца',
  CustomSample = 'Образец со швом',
  LevelOver = 'Превышение брак уровня',
  oXTableInput = 'oX',
  oYTableInput = 'oY',
  YTableInput = 'Y',
  DefectLengthTableInput = 'Протяжённость дефекта табличный ввод',
  DefectNoteTableNumber = 'Номер дефекта в таблице',
  ShortTableNote = 'Сокращённая запись дефекта',
  Normality = 'Оценка допустимости',
  VRHGraphSwitcher = 'Отображение графика врч',

  DeviceCommonTab = 'Основные',
  DeviceSensorTab = 'Датчик',
  DeviceAZoneTab = 'А зона',
  DeviceBZoneTab = 'Б зона',
  DeviceVRHTab = 'ВРЧ',

  AdditionalImage = 'Дополнительное изображение прибора',
  DeviceImage = 'Изображнеие толщиномера',
  SwitchOn = 'Включаетль',
  TopPanel = 'Верхняя панель',
  LiquidBottle = 'Ёмкость с контактной жидкостью',
  SampleImitation = 'Имитатор изделия',
  BackTable = 'Таблица на тыльной стороне толщиномтера',
  SensorImage = 'Изображение датчика',
  StepOne = 'Первая ступенька',
  StepTwo = 'Вторая ступенька',
  StepThree = 'Третья ступенька',
  StepFour = 'Четвёртая ступенька',
  StepFive = 'Пятая ступенька',
  TableDepthValue = 'Значение глубины в таблице',
  TableXValue = 'Значение x в таблице',
  TableYValue = 'Значение y в таблице',
  FreeSurface = 'Часть поверхности образца',
  TableNormalityValue = 'Оценка допустимости в таблице',
}

export enum SensorMode {
  Separated = 'Раздельный',
  Combined = 'Совмещённый',
}

export enum OptionsTabCode {
  common = 'Основные',
  sensor = 'Датчик',
  aZone = 'а-Зона',
  bZone = 'б-Зона',
  VRH = 'ВРЧ',
}

export enum AdmissibilityLevels {
  NotSet = 'Не установлен',
  Normal = 'Допустимый',
  NotNormal = 'Не допустимый',
}

export enum DefectTableFocusedCell {
  ShortName = 1,
  Y = 2,
  OX = 3,
  LevelOver = 4,
  DefectLength = 5,
  DefectDepth = 6,
}

export enum RayLength {
  S0a = 'S0-a',
  Sab = 'Sa-b',
  H = 'H, дБ',
}

export enum AdminMenuTabs {
  Users = 'Пользователи',
  Groups = 'Группы',
  Organizations = 'Организации',
  Messages = 'Сообщения',
  Exams = 'Экзамены',
  Archive = 'Архив',
}

export enum ValidationErrorTypes {
  MinLength = 'Минимальная длина поля - 2 символа',
  MaxLength = 'Максимальная длина поля',
  RequiredField = 'Это обязательное поле',
  NoError = '',
  DatesOrder = 'Дата начала курса не можеть быть позже даты окончания',
}

export enum ExceedLevel {
  OneOfThree = 1/3,
  HalfOfDisplay = 1 / 2,
  TwoOfThree = 2 / 3,
  RejectedLevelBottom = 1 / 2.2,
  RejectedLevelTop = 1 / 1.7,

}
