import { makeStyles } from '@material-ui/core';
import {bgColors, borderRadius, colors, FontWeights, indents, inputBorderRadius} from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  dragWrapper: {
    cursor: 'grab',
    position: 'absolute',
    zIndex: 13,
  },
  studyInfoWrapper: {
    minWidth: 414,
    width: 632,
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: colors.primary.mainBlue,
    borderRadius: inputBorderRadius,
    padding: indents.four,
  },
  mainWrapper: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: `solid ${colors.grayColor.strokeInputGray} 1px`,
    width: `calc(100% - ${indents.thirtyTwo}px)`,
    height: `calc(100% - ${indents.ninetySix}px)`,
    margin: indents.sixteen,
    paddingTop: indents.sixteen,
  },
  leftPart: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    minWidth: 'fit-content',
  },
  deviceAndToolsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: '50%',
    minWidth: 'fit-content',
    minHeight: 100,
    overflow: 'auto',
    padding: indents.eight,
  },
  deviceWrapper: {
    minHeight: 300,
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(100% - 170px)',
    minWidth: 'fit-content',
  },
  deviceImageWrapper: {
    position: 'relative',
    width: '10vw',
  },
  toolsWrapper: {
    marginLeft: indents.eight,
    minWidth: 150,
    maxWidth: 150,
    minHeight: 'fit-content',
  },
  measurementTableWrapper: {
    height: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  titleRow: {
    borderRadius: borderRadius,
    paddingLeft: indents.sixteen,
    display: 'flex',
    alignItems: 'center',
    height: '15%',
    backgroundColor: colors.grayColor.strokeLightGray,
    marginTop: indents.eight,
    marginBottom: indents.eight,
  },
  addRowBtn: {
    margin: indents.eight,
    width: 30,
    height: 30,
    color: colors.primary.mainBlue,
  },
  tableWrapper: {
    height: '85%',
  },
  canvasWrapper: {
    position: 'relative',
    borderRadius: borderRadius,
    backgroundColor: bgColors.thickBg,
    width: '50%',
    maxWidth: '50%',
    minWidth: 200,
    marginLeft: indents.eight,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    overflow: 'auto',
  },
}));

export default useStyles;
