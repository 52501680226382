import { CoursesId } from 'Shared/Enums';

export const courses = [
  { code: CoursesId.ThicknessMeasurement, name: 'УЗ толщинометрия' },
  { code: CoursesId.FlawDetection, name: 'УЗ дефектоскопия' },
];

export const studyItems = [
  { id: CoursesId.ThicknessMeasurement, name: 'Ультразвуковая толщинометрия' },
  { id: CoursesId.FlawDetection, name: 'Ультразвуковая дефектоскопия' },
];