import { makeStyles } from '@material-ui/core';

import { inputBorderRadius, colors, indents } from '../../../uiKitElems';

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    width: '100%',
    display: 'flex',
    marginTop: indents.eight,
    marginBottom: indents.sixteen,
    height: 69,
  },
  leftCell: {
    overflow: 'hidden',
    width: '12%',
    height: '100%',
    border: `solid ${colors.grayColor.strokeInputGray} 1px`,
    borderRight: 'none',
    borderRadius: `${inputBorderRadius}px ${0}px ${0}px ${inputBorderRadius}px`,
  },
  topOfCell: {
    height: '40%',
    display: 'flex',
    justifyContent: 'space-around',
    padding: indents.eight,
    backgroundColor: colors.grayColor.gray,
  },
  bottomOfCell: {
    height: '60%',
    display: 'flex',
    justifyContent: 'space-around',
    borderRadius: `${0}px ${inputBorderRadius}px ${inputBorderRadius}px ${inputBorderRadius}px`,
    padding: indents.eight,
    marginBottom: indents.four,
  },
  downLeveInput: {
    width: '70%',
  },
  middleCell: {
    overflow: 'hidden',
    height: '100%',
    width: '11%',
    border: `solid ${colors.grayColor.strokeInputGray} 1px`,
  },
  rightCell: {
    overflow: 'hidden',
    width: '11%',
    height: '100%',
    border: `solid ${colors.grayColor.strokeInputGray} 1px`,
    borderLeft: 'none',
    borderRadius: `${0}px ${inputBorderRadius}px ${inputBorderRadius}px ${0}px`,
  },
}));

export default useStyles;
