import { Theme, makeStyles } from '@material-ui/core';

import { SizeSpinner } from './Spinner';
import { colors } from '../theme';

interface IProps {
  size?: SizeSpinner;
  zIndex?: number;
}

const useStyles = makeStyles<Theme, IProps>((theme) => ({
  spinner: (props) => {
    let size = '';

    switch (props.size) {
      case SizeSpinner.Sm: {
        size = '20px';
        break;
      }
      case SizeSpinner.Md: {
        size = '30px';
        break;
      }
      case SizeSpinner.Lg: {
        size = '60px';
        break;
      }

      default: {
        size = '40px';
      }
    }

    return {
      '& .MuiCircularProgress-colorPrimary': {
        color: colors.primary.darkBlue,
        width: `${size} !important`,
        height: `${size} !important`,
      },
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backdropFilter: 'blur(1px)',
      zIndex: props.zIndex || 1,
    };
  },
}));

export default useStyles;
