import React from 'react';
import clsx from 'clsx';

import { TextField, InputAdornment } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { TextInputSize, VariantInput } from './types';

import useStyles from './styles';

interface IProps {
  autoFocus?: boolean;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  endAdornment?: JSX.Element;
  error?: boolean;
  height?: number;
  nativeLabel?: string;
  onChange: (value: string) => void;
  password?: boolean;
  rows?: number;
  size?: TextInputSize;
  startAdornment?: JSX.Element;
  value: string;
  variant?: VariantInput;
}

const TextInput = (props: IProps) => {
  const {
    autoFocus,
    className,
    defaultValue,
    endAdornment,
    disabled,
    error,
    height,
    nativeLabel,
    onChange,
    password,
    rows,
    size,
    startAdornment,
    value,
    variant,
  } = props;

  const c = useStyles({ height });

  let sizedClass = '';

  if (!rows) {
    switch (size) {
      case TextInputSize.Sm: {
        sizedClass = c.smTextInputClass;
        break;
      }
      case TextInputSize.Md: {
        sizedClass = c.mdTextInputClass;
        break;
      }
      case TextInputSize.Lg: {
        sizedClass = c.lgTextInputClass;
        break;
      }
      default: {
        sizedClass = c.mdTextInputClass
      }
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    try {
      if (e.code === 'Enter') {
        (document.activeElement as any).blur();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const errorIcon = error ? (
    <InputAdornment position="end">
      <ErrorOutlineIcon className={c.errorIcon} />
    </InputAdornment>
  ) : null;

  const startPlaceContent = (
    <>
      {startAdornment}
      {errorIcon}
    </>
  );

  const endPlaceContent = (
    <>
      {endAdornment}
      {errorIcon}
    </>
  );

  const inputProps = {
    startAdornment: startAdornment ? startPlaceContent : null,
    endAdornment: endAdornment ? endPlaceContent : null,
  };

  return (
    <div className={clsx(c.inputWrapper, sizedClass, className || '')}>
      <TextField
        onKeyDown={onKeyDown}
        label={nativeLabel}
        value={value}
        disabled={disabled}
        type={password ? 'password' : ''}
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        error={error}
        InputProps={inputProps}
        onChange={handleChange}
        multiline={!!rows}
        rows={rows || 1}
        variant={variant || VariantInput.Outlined}
      />
    </div>
  );
};

export default TextInput;
