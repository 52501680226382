// Образец калибровки браковочного уровня

import { SampleMode } from 'Shared/Enums';
import { ITuningSampleDto } from 'Shared/Interfaces/Api/Samples';
import { ISampleDefectData } from 'Shared/Interfaces/App';

export class TuningSample {
  public static getSampleFromData(dtos: ITuningSampleDto[]) {
    const tuningSamples: ISampleDefectData[] = [];
      
    dtos.forEach((item) =>
      tuningSamples.push({
        id: item.id,
        mode: SampleMode.DefectCalibrationLevels,
        name: item.name,
        width: 150,
        height: 75,
        depth: item.sampleDepth,
        defects: [
          { id: '1', x: 120, y: 35, length: 2, depth: 0, size: item.depth * item.width },
          { id: '2', x: 33, y: 35, length: 2, depth: item.sampleDepth, size: item.depth * item.width },
        ],
      })
    );

    return tuningSamples;
  }
}
