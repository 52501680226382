import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener/ClickAwayListener';
import { Delete, Edit } from '@material-ui/icons';

import CustomTable, { ITableColumn } from 'Components/Common/CustomTable';
import { Select } from 'Components/uiKitElems/Select';
import { ModalDialog } from 'Components/Common';
import { H5Text, IconButtonWrapper, NumberInput, TextInput } from 'Components/uiKitElems';

import { appStore, measurementStore, studyCoursesStore } from 'Stores';

import { DefectNote } from 'Shared/Models/ForkFlow/DefectNote';
import { AdmissibilityLevels, DefectTableFocusedCell } from 'Shared/Enums';

import useStyles from './styles';

const FlawReportTable = () => {
  const c = useStyles();
  const ref = useRef<HTMLElement | null>(null);

  const goTopLastRow = () => {
    if (!ref) return;

    ref.current?.scrollIntoView({ block: 'center' });
  };

  useEffect(() => {
    goTopLastRow();
  }, [measurementStore.defectNotes.length]);

  useEffect(() => {
    appStore.initDefectNotesToLocalStorage();
  }, []);

  useEffect(() => {
    if (
      studyCoursesStore.selectedCourse.selectedModule?.currentStep?.id! === 48 &&
      measurementStore.defectNotes.length === 0
    ) {
      measurementStore.addDefectNote();
    }

    if (
      studyCoursesStore.selectedCourse.selectedModule?.currentStep?.id! < 48 &&
      measurementStore.defectNotes.length > 0
    ) {
      measurementStore.removeDefectNotes();
    }
  }, [studyCoursesStore.selectedCourse?.selectedModule?.currentStep?.id]);

  const [selectedRowId, setSelectedRowId] = useState(1);

  const flawColumns: ITableColumn[] = [
    {
      key: 'sensorName',
      label: 'ПЭП',
      align: 'center',
      width: '5%',
      cell: (row: DefectNote) => <H5Text className={c.cellInput} noWrap={true} text={row.sensorName} />,
    },
    {
      key: 'defectNumber',
      label: '№ дефекта',
      align: 'center',
      width: '3%',
      cell: (row: DefectNote) => <>{row.defectNumber}</>,
    },
    {
      key: 'oY',
      label: 'oY',
      align: 'center',
      width: '5%',
      cell: (row: DefectNote) => {
        const isEditThisCell = selectedRowId === row.defectNumber && row.focused === DefectTableFocusedCell.OX;

        const cellEditRender = (
          <NumberInput className={c.cellInput} height={30} value={row.oX!} onChange={row.onOXChange} />
        );

        const cellInfoRender = row.oX?.toString() ? (
          <H5Text className={c.cellInput} text={row.oX?.toString()} />
        ) : (
          <IconButtonWrapper icon={<Edit className={c.editCellIcon} />} />
        );

        const onMouseDown = () => {
          row.setFocused(DefectTableFocusedCell.OX);
          setSelectedRowId(row.defectNumber!);
        };

        return (
          <div className={c.cellInput} onMouseDown={onMouseDown} onBlur={() => row.setFocused(null)}>
            {isEditThisCell ? cellEditRender : cellInfoRender}
          </div>
        );
      },
    },
    {
      key: 'y',
      label: 'Y',
      align: 'center',
      width: '5%',
      cell: (row: DefectNote) => {
        const isEditThisCell = selectedRowId === row.defectNumber && row.focused === DefectTableFocusedCell.Y;

        const cellEditRender = (
          <NumberInput className={c.cellInput} height={30} value={row.y!} onChange={row.onYChange} />
        );

        const cellInfoRender = row.y?.toString() ? (
          <H5Text className={c.cellInput} text={row.y?.toString()} />
        ) : (
          <IconButtonWrapper icon={<Edit className={c.editCellIcon} />} />
        );

        const onMouseDown = () => {
          row.setFocused(DefectTableFocusedCell.Y);
          setSelectedRowId(row.defectNumber!);
        };

        return (
          <div className={c.cellInput} onMouseDown={onMouseDown} onBlur={() => row.setFocused(null)}>
            {isEditThisCell ? cellEditRender : cellInfoRender}
          </div>
        );
      },
    },
    {
      key: 'defectLength',
      label: 'Протяжённость дефекта, мм',
      align: 'center',
      width: '10%',
      cell: (row: DefectNote) => {
        const isEditThisCell =
          selectedRowId === row.defectNumber && row.focused === DefectTableFocusedCell.DefectLength;

        const cellEditRender = (
          <NumberInput
            className={c.cellInput}
            height={30}
            value={row.defectLength!}
            onChange={row.onDefectLengthChange}
          />
        );

        const cellInfoRender = row.defectLength?.toString() ? (
          <H5Text className={c.cellInput} text={row.defectLength?.toString()} />
        ) : (
          <IconButtonWrapper icon={<Edit className={c.editCellIcon} />} />
        );

        const onMouseDown = () => {
          row.setFocused(DefectTableFocusedCell.DefectLength);
          setSelectedRowId(row.defectNumber!);
        };

        return (
          <div className={c.cellInput} onMouseDown={onMouseDown} onBlur={() => row.setFocused(null)}>
            {isEditThisCell ? cellEditRender : cellInfoRender}
          </div>
        );
      },
    },
    {
      key: 'levelOver',
      label: 'Превышение браковочного уровня, дБ',
      align: 'center',
      width: '10%',
      cell: (row: DefectNote) => {
        const isEditThisCell = selectedRowId === row.defectNumber && row.focused === DefectTableFocusedCell.LevelOver;

        const cellEditRender = (
          <NumberInput className={c.cellInput} height={30} value={row.levelOver!} onChange={row.onLevelOverChange} />
        );

        const cellInfoRender = row.levelOver?.toString() ? (
          <H5Text className={c.cellInput} text={row.levelOver.toString()} />
        ) : (
          <IconButtonWrapper icon={<Edit className={c.editCellIcon} />} />
        );

        const onMouseDown = () => {
          row.setFocused(DefectTableFocusedCell.LevelOver);
          setSelectedRowId(row.defectNumber!);
        };

        return (
          <div className={c.cellInput} onMouseDown={onMouseDown} onBlur={() => row.setFocused(null)}>
            <div className={c.cellWithCouple}>
              <Select
                className={c.selectCellInput}
                height={30}
                items={[
                  { id: '+', name: '+' },
                  { id: '-', name: '-' },
                ]}
                value={row.sign}
                onChange={row.onChangeSign}
              />
              {isEditThisCell ? cellEditRender : cellInfoRender}
            </div>
          </div>
        );
      },
    },
    {
      key: 'shortNote',
      label: 'Сокращенная запись дефекта',
      align: 'center',
      width: '10%',
      cell: (row: DefectNote) => {
        const isEditThisCell = selectedRowId === row.defectNumber && row.focused === DefectTableFocusedCell.ShortName;

        const cellEditRender = (
          <TextInput className={c.cellInput} height={30} value={row.shortNote} onChange={row.onShortNoteChange} />
        );

        const cellInfoRender = row.shortNote ? (
          <H5Text className={c.cellInput} text={row.shortNote} />
        ) : (
          <IconButtonWrapper icon={<Edit className={c.editCellIcon} />} />
        );

        const onMouseDown = () => {
          row.setFocused(DefectTableFocusedCell.ShortName);
          setSelectedRowId(row.defectNumber!);
        };

        return (
          <div className={c.cellInput} onMouseDown={onMouseDown} onBlur={() => row.setFocused(null)}>
            {isEditThisCell ? cellEditRender : cellInfoRender}
          </div>
        );
      },
    },
    {
      key: 'marking',
      label: 'Оценка допустимости',
      align: 'center',
      width: '5%',
      cell: (row: DefectNote) => {
        const onChangeNormal = (value: AdmissibilityLevels) => {
          row.onSetAdmissibility(value);
        };

        return (
          <div className={''}>
            <Select
              className={c.selectCellInput}
              height={30}
              items={[
                { id: AdmissibilityLevels.NotSet, name: '', iconSrc: 'icons/not-set.svg' },
                { id: AdmissibilityLevels.Normal, name: '', iconSrc: 'icons/normal.svg' },
                { id: AdmissibilityLevels.NotNormal, name: '', iconSrc: 'icons/not-normal.svg' },
              ]}
              value={row.admissibility}
              onChange={onChangeNormal}
            />
          </div>
        );
      },
    },
    {
      key: 'actions',
      label: '',
      align: 'center',
      width: '3%',
      cell: (row: DefectNote) => {
        const onRemoveDefectNote = () => {
          measurementStore.showDefectNoteRemoveDialog(row.defectNumber!);
        };

        return <IconButtonWrapper icon={<Delete />} onClick={onRemoveDefectNote} />;
      },
    },
  ];

  const thicknessColumns: ITableColumn[] = [
    {
      key: 'sensorName',
      label: 'ПЭП',
      align: 'center',
      width: '5%',
    },
    {
      key: 'defectNumber',
      label: '№ дефекта',
      align: 'center',
      width: '3%',
      cell: (row: DefectNote) => <>{row.defectNumber}</>,
    },
    {
      key: 'oY',
      label: 'X',
      align: 'center',
      width: '5%',
      cell: (row: DefectNote) => {
        const isEditThisCell = selectedRowId === row.defectNumber && row.focused === DefectTableFocusedCell.OX;

        const cellEditRender = (
          <NumberInput className={c.cellInput} height={30} value={row.oX!} onChange={row.onOXChange} />
        );

        const cellInfoRender = row.oX?.toString() ? (
          <H5Text className={c.cellInput} text={row.oX?.toString()} />
        ) : (
          <IconButtonWrapper icon={<Edit className={c.editCellIcon} />} />
        );

        const onMouseDown = () => {
          row.setFocused(DefectTableFocusedCell.OX);
          setSelectedRowId(row.defectNumber!);
        };

        return (
          <div className={c.cellInput} onMouseDown={onMouseDown} onBlur={() => row.setFocused(null)}>
            {isEditThisCell ? cellEditRender : cellInfoRender}
          </div>
        );
      },
    },
    {
      key: 'y',
      label: 'Y',
      align: 'center',
      width: '5%',
      cell: (row: DefectNote) => {
        const isEditThisCell = selectedRowId === row.defectNumber && row.focused === DefectTableFocusedCell.Y;

        const cellEditRender = (
          <NumberInput className={c.cellInput} height={30} value={row.y!} onChange={row.onYChange} />
        );

        const cellInfoRender = row.y?.toString() ? (
          <H5Text className={c.cellInput} text={row.y?.toString()} />
        ) : (
          <IconButtonWrapper icon={<Edit className={c.editCellIcon} />} />
        );

        const onMouseDown = () => {
          row.setFocused(DefectTableFocusedCell.Y);
          setSelectedRowId(row.defectNumber!);
        };

        return (
          <div className={c.cellInput} onMouseDown={onMouseDown} onBlur={() => row.setFocused(null)}>
            {isEditThisCell ? cellEditRender : cellInfoRender}
          </div>
        );
      },
    },
    {
      key: 'depth',
      label: 'Результат измерений',
      align: 'center',
      width: '5%',
      cell: (row: DefectNote) => {
        const isEditThisCell = selectedRowId === row.defectNumber && row.focused === DefectTableFocusedCell.Y;

        const cellEditRender = (
          <NumberInput className={c.cellInput} height={30} value={row.defectLength!} onChange={row.onDepthChange} />
        );

        const cellInfoRender = row.y?.toString() ? (
          <H5Text className={c.cellInput} text={row.defectDepth?.toString()} />
        ) : (
          <IconButtonWrapper icon={<Edit className={c.editCellIcon} />} />
        );

        const onMouseDown = () => {
          row.setFocused(DefectTableFocusedCell.Y);
          setSelectedRowId(row.defectNumber!);
        };

        return (
          <div className={c.cellInput} onMouseDown={onMouseDown} onBlur={() => row.setFocused(null)}>
            {isEditThisCell ? cellEditRender : cellInfoRender}
          </div>
        );
      },
    },
    {
      key: 'marking',
      label: 'Оценка допустимости',
      align: 'center',
      width: '5%',
      cell: (row: DefectNote) => {
        const onChangeNormal = (value: AdmissibilityLevels) => {
          row.onSetAdmissibility(value);
        };

        return (
          <div className={''}>
            <Select
              className={c.selectCellInput}
              height={30}
              items={[
                { id: AdmissibilityLevels.NotSet, name: '', iconSrc: 'icons/not-set.svg' },
                { id: AdmissibilityLevels.Normal, name: '', iconSrc: 'icons/normal.svg' },
                { id: AdmissibilityLevels.NotNormal, name: '', iconSrc: 'icons/not-normal.svg' },
              ]}
              value={row.admissibility}
              onChange={onChangeNormal}
            />
          </div>
        );
      },
    },
    {
      key: 'actions',
      label: '',
      align: 'center',
      width: '3%',
      cell: (row: DefectNote) => {
        const onRemoveDefectNote = () => {
          measurementStore.showDefectNoteRemoveDialog(row.defectNumber!);
        };

        return <IconButtonWrapper icon={<Delete />} onClick={onRemoveDefectNote} />;
      },
    },
  ];

  const onCloseRemoveDialog = () => {
    measurementStore.showDefectNoteRemoveDialog(null);
  };

  const onClickAway = () => {
    measurementStore.defectNotes.forEach((item) => item.setFocused(null));
  };

  return (
    <>
      <ModalDialog
        onClose={onCloseRemoveDialog}
        onConfirm={measurementStore.removeDefectNote}
        isOpen={!!measurementStore.defectNoteIdToRemove}
        title={
          measurementStore.defectNoteIdToRemove
            ? `Удалить записть о дефекте №${measurementStore.defectNoteIdToRemove}`
            : ''
        }
      />

      <ClickAwayListener onClickAway={onClickAway}>
        <div className={c.tableListenerWrapper}>
          <CustomTable
            lastRowRef={ref}
            className={c.tableWrapper}
            customClassCell={c.measurementInputCell}
            dataKey={'defectNumber'}
            columns={flawColumns}
            data={measurementStore.defectNotes}
          />
        </div>
      </ClickAwayListener>
    </>
  );
};

export default observer(FlawReportTable);
