import { makeStyles, Theme } from '@material-ui/core';
import { colors, indents, inputBorderRadius } from 'Components/uiKitElems';

interface IProps {
  isDeviceEnv: boolean;
  progressCellCount: number;
}

const useStyles = makeStyles<Theme, IProps>((theme) => ({
  progressBar: (props) => ({
    alignSelf: 'center',
    overflow: 'hidden',
    display: 'flex',
    width: 414,
    minWidth: 414,
    maxWidth: 414,
    height: 17,
    border: `solid ${colors.primary.mainBlue} 1px`,
    borderRadius: inputBorderRadius,
    marginTop: props.isDeviceEnv ? 0 : indents.sixteen,
  }),
  studyStepControlRow: {
    position: 'relative',
    marginTop: indents.thirtyTwo,
    marginLeft: indents.fortyEight,
    marginRight: indents.fortyEight,
    width: `calc(100% - ${indents.fortyEight * 2}px)`,
  },
  stepControlDeviceRow: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginTop: indents.four,
    marginLeft: indents.four,
    marginRight: indents.four,
    justifyContent: 'space-between',
    width: `calc(100% - ${indents.four * 2}px)`,
  },
  stepActions: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  exitOnlyWrapper: {
    justifyContent: 'flex-end',
  },
  wrapperLeftPart: {
    minWidth: 'fit-content',
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  completedStep: (props) => ({
    backgroundColor: colors.primary.mainBlue,
    width: 414 / props.progressCellCount,
    height: 17,
  }),
  prevStepActionWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: 'fit-content',
    overflow: 'hidden',
  },
  wrapperRightPart: {
    minWidth: 'fit-content',
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  nextStepWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    width: '20%',
    minWidth: 'fit-content',
    overflow: 'hidden',
    position: 'relative',
  },
  disabled: {
    color: `${colors.statusColor.dangerRed} !important`,
  },
  stepActionText: {
    color: colors.primary.mainBlue,
  },
  stepDescriptionText: {
    marginLeft: indents.sixteen,
    minWidth: 'fit-content',
    width: '30%',
    color: colors.primary.mainBlue,
  },
  successStep: {
    color: colors.statusColor.successGreen,
  },
}));

export default useStyles;
