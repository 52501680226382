import { IUserRowDto } from 'Shared/Interfaces/Api/Useres';
import { action, makeObservable, observable } from 'mobx';

interface IUserRowModel {
  id: string;
  organizationName: string;
  departmentNames: string;
  groupNames: string;
  isDeleted: boolean;
  fullName: string;
  checked: boolean;

  onCheck(checked: boolean): void;
}

class UserRowModel implements IUserRowModel {
  constructor(dto: IUserRowDto) {
    makeObservable(this, {
      checked: observable,

      onCheck: action.bound,
    });

    this.id = dto.id;
    this.fullName = dto.fullName;
    this.organizationName = dto.organizationName || '';
    this.departmentNames = dto.departmentNames || '';
    this.groupNames = dto.groupNames || '';
    this.isDeleted = dto.isDeleted;
  }

  public id = '';

  public fullName = '';

  public organizationName = '';

  public departmentNames = '';

  public groupNames = '';

  public isDeleted = false;

  public checked = false;

  public onCheck(checked: boolean) {
    this.checked = checked;
  }
}

export default UserRowModel;
