import React, { MouseEvent } from 'react';
import { observer } from 'mobx-react';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Filter from '../Filter';

import { ITableColumn } from '../../index';

import useStyles from './styles';

interface IProps {
  customCell?: { el: JSX.Element; columnKey: string };
  columns: ITableColumn[];
  className?: string;
  order?: 'asc' | 'desc';
  orderBy?: string;
  onSort?: (event: MouseEvent<unknown>, property: string | undefined) => void;
}

const EnhancedTableHeader = (props: IProps) => {
  const { columns, className, customCell, order, orderBy, onSort } = props;

  const c = useStyles();

  const renderHeader = (cols: ITableColumn[]) => {
    return cols.map((column) => {
      const isSortActive = orderBy === column.key;

      const handleSort = (event: MouseEvent<unknown>) => {
        if (!onSort) return;

        if (isSortActive) {
          onSort(event, order === 'asc' || !order ? column.key : undefined);
        } else {
          onSort(event, column.key);
        }
      };

      const getLabel = () => {
        if (column.sortable) {
          return (
            <TableSortLabel active={isSortActive} direction={isSortActive ? order : 'asc'} onClick={handleSort}>
              {column.label}
            </TableSortLabel>
          );
        }

        return column.label;
      };

      const defaultHeaderCell = (
        <TableCell
          variant="head"
          key={column.key}
          align={column.align}
          style={{ maxWidth: column.maxWidth, width: column.width }}
          sortDirection={isSortActive ? order : false}>
          {getLabel()}

          {column.filter && (
            <Filter
              className={c.filter}
              hasValue={column.hasFilterValue ? column.hasFilterValue() : false}
              onFilter={column.onSaveFilter}
              onClear={column.onClearFilter}>
              {column.filter()}
            </Filter>
          )}
        </TableCell>
      );

      const headerCell = (customCell && column.key === customCell.columnKey) ? customCell.el : defaultHeaderCell;

      return headerCell;
    });
  };

  return (
    <TableHead className={className}>
      <TableRow>{renderHeader(columns)}</TableRow>
    </TableHead>
  );
};

export default observer(EnhancedTableHeader);
