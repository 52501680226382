import { Graphics, Sprite, Text} from 'pixi.js';
import PipDefect from '../Pip/PipDefect';
import {IDefectDefectData, IPoint, ISampleDefectData, IUserDefectDefects} from 'Shared/Interfaces/App';
import SampleAbstract from "./SampleAbstract";
import {SampleMode} from "../../../Enums";

export default class SampleDefect extends SampleAbstract {
    /**
     * URL картинок образца
     */
    imageDefaultSample = 'images/sampleSurface/sample.jpg';
    pipDefectTuningImage = 'images/sampleSurface/defect-tuning.jpg';    
    
    /**
     * URL картинки шва
     */
    imageWeld = 'images/sampleSurface/sh3.png';

    /**
     * Данные образца
     */
    sampleData: ISampleDefectData;

    defectsGraphics: Graphics = new Graphics();
    
    constructor(view: HTMLCanvasElement, sampleData: ISampleDefectData, setCoord: any = null) {
        super(view, sampleData)

        this.sampleData = sampleData;

        const imageSampleUrl = sampleData.mode === SampleMode.DefectCalibrationLevels
            ? this.pipDefectTuningImage
            : this.imageDefaultSample
        this.setMaterial(imageSampleUrl);

        this.addChild(this.liquidGraphics);
        
        this.setWeld();
        if (this.isCustomRulerX) this.setCustomRulerX();
        if (this.isShowDefects) this.setDefects();
        
        this.addChild(this.userDefectsContainer);
        
        this.pip = new PipDefect(this, 20, 10, this.metricX, this.metricY, setCoord);
    }
    
    /**
     * Шов (если есть)
     */
    private setWeld() {
        if (!this.sampleData.weld) return;

        const weld = Sprite.from(this.imageWeld);
        weld.anchor.set(0.5, 0);
        weld.position.set(this.sampleX + this.toPx(this.sampleData.weld.x), this.sampleY);
        weld.width = this.toPx(this.sampleData.weld.width);
        weld.height = this.sampleHeight;
        this.addChild(weld);
    }

    /**
     * Кастомная линейка по Х
     * @private
     */
    private setCustomRulerX() {
        if (!this.sampleData.weld) return;
        
        const graphRuler = new Graphics();
        graphRuler.lineStyle(1, 0x000000, 1);

        const xRulerY = this.sampleY + this.sampleHeight;
        const centerX = this.sampleData.weld.x;

        const drawMajor = (i: number) => {
            const x = this.sampleX + this.toPx(i);
            if (x - this.sampleX < 0) return;
            
            graphRuler.moveTo(x, xRulerY);
            graphRuler.lineTo(x, xRulerY + this.majorTickRulerSize);

            const textRulerText = new Text(Math.abs(Math.round(i - centerX)).toString(), this.textRulerFontStyle);
            textRulerText.anchor.set(0.5, 0);
            textRulerText.x = x;
            textRulerText.y = xRulerY + this.majorTickRulerSize;
            this.addChild(textRulerText);
        }

        const drawMinor = (i: number) => {
            const x = this.sampleX + this.toPx(i);
            if (x - this.sampleX < 0) return;
            
            graphRuler.moveTo(x, xRulerY);
            graphRuler.lineTo(x, xRulerY + this.minorTickRulerSize);
        }
        
        // Мажор Х
        for (let i = centerX; i <= this.width; i += 10) {
            drawMajor(i);
            drawMajor(2 * centerX - i);
        }

        // Минор Х
        for (let i = centerX; i <= this.width; i += 5) {
            drawMinor(i);
            drawMinor(2 * centerX - i);
        }
        
        this.addChild(graphRuler);
    }

    /**
     * Нарисовать продолжительный дефект, вдоль Х (по шву)
     * @param sample - образец
     * @param gr - объект на котрпом рисуются дефекты
     * @param point - центр дефекта
     * @param radius - радиус точки дефекта
     * @param data - данные дефекта
     * @private
     */
    private static drawLengthDefect(sample: SampleAbstract, gr: Graphics, point: IPoint, radius: number, 
                                    data: IUserDefectDefects) {
        const { weld } = (<SampleDefect>sample).sampleData;
        if (!weld) return;
        
        point.x = sample.sampleX +sample.toPx(weld.x);

        if (data.length <= 0) return;
        const widthLengthK = 0.5;
        const xLength = point.x - (radius / 2) * widthLengthK;
        const yLength = point.y - sample.toPx(data.length);
        
        gr.drawRect(xLength, yLength, radius * widthLengthK, sample.toPx(data.length));
    }
    
    /**
     * Установка пользовательских данных дефектов
     * @param userData
     * @param extraDraw
     */
    public setUserDefects(userData: IUserDefectDefects[], extraDraw: any = null) {
        super.setUserDefects(userData, SampleDefect.drawLengthDefect);
    }

    /**
     * Жидкость
     * @param sampleId
     */
    public putLiquidOnSurface(sampleId: string) {
        if (this.isSurfaceWet) return;
        this.isSurfaceWet = true;

        const { weld } = this.sampleData;
        
        const offset = 5;
        this.liquidGraphics.lineStyle(0);
        this.liquidGraphics.beginFill(0xFFC107, 0.2);
        if (weld) {
            const widthLeft = this.toPx(weld.x - weld.width / 2); 
            const offsetRight = this.sampleX + this.toPx(weld.x + weld.width / 2); 
            const widthRight = this.sampleWidth - this.toPx(weld.x + weld.width / 2) - offset;
            this.liquidGraphics.drawRect(
                this.sampleX + offset, this.sampleY + offset,
                widthLeft, this.sampleHeight -  offset * 2
            );
            this.liquidGraphics.drawRect(
                offsetRight, this.sampleY + offset,
                widthRight, this.sampleHeight -  offset * 2
            );
        } else if (sampleId === 'НО-14-2.0x2.0') {
            const offsetMin = this.toPx(Math.min(this.sampleData.defects[0].x, this.sampleData.defects[1].x));
            const offsetMax = this.toPx(Math.max(this.sampleData.defects[0].x, this.sampleData.defects[1].x));
            this.liquidGraphics.drawRect(
                this.sampleX + offsetMin + offset, this.sampleY + offset,
                offsetMax - offsetMin - offset, this.sampleHeight -  offset * 2
            );
        } else {
            this.liquidGraphics.drawRect(
              this.sampleX + offset, this.sampleY + offset,
              this.sampleWidth -  offset * 2, this.sampleHeight -  offset * 2
            );
        }
        this.liquidGraphics.endFill();
    }

    /**
     * Дефекты (реальное положение)
     */
    public setDefects() {
        this.defectsGraphics.clear();

        const width = 8; // Ширина дефекта в пикс
        this.sampleData.defects.forEach((data: IDefectDefectData) => {
            this.defectsGraphics.beginFill(0xde3249, 0.8);
            const x = this.sampleX + this.toPx(data.x);
            const y = this.sampleY + this.sampleHeight - this.toPx(data.y);

            if (data.length > 0) this.defectsGraphics.drawRect(x - width / 2, y, width, this.toPx(-data.length));
            else this.defectsGraphics.drawCircle(x, y, 4);

            this.defectsGraphics.endFill();
        });

        this.addChild(this.defectsGraphics);
    }
}