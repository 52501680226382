import { IPoint, ILine } from "../../Interfaces/App";

/**
 * Параметры прямой
 */
interface IParamLine {
    k: number;
    b: number;
}

export default class MathDefectsUtils {
    private static eps: number = 1e-6;

    /**
     * Входит ли точка в область, ограниченной линией
     * @param line - Линия
     * @param point - Тестируемая точка
     * @returns {boolean}
     */
    public static isNodeInsideLine(line: ILine, point: IPoint) 
    {
        const { point1, point2 } = line;
        const isVert: boolean = point.y <= Math.max(point1.y, point2.y) && point.y >= Math.min(point1.y, point2.y);
        const isHor : boolean = point.x <= Math.max(point1.x, point2.x) && point.x >= Math.min(point1.x, point2.x);
        return isVert && isHor;
    }

    /**
     * Точка пересечения отрезка line и перпендикуляра на нее из точки point
     * @param line - Отрезок
     * @param point - Точка из которой опускается перпендикуляр
     * @returns {IPoint} - Точка пересечения
     */
    public static getCrossPoint(line: ILine, point: IPoint) 
    {
        const { point1, point2 } = line;
        
        const lineParams = this.lineParams(point1, point2);

        // Параметры прямой, перпендикулярной line
        const k: number = -1 / lineParams.k;
        const b: number = -k * point.x + point.y;

        let x: number;
        let y: number;

        // Параллельно Х
        if (k === Infinity || k === -Infinity) {
            x = point.x;
            y = point1.y;
        }
        
        // Параллельно Y
        else if (this.getEps(k)) {
            x = point1.x;
            y = point.y;
        }
        
        // Произвольный наклон
        else {
            x = (lineParams.b - b) / (k - lineParams.k);
            y = k * x + b;
        }
        
        return <IPoint> { x, y };
    }
    
    /**
     * Расстояние между двумя точками
     * @param point1 - Точка 1
     * @param point2 - Точка 2
     * @returns {number} - Длина отрезка
     */
    public static getLength(point1: IPoint, point2: IPoint) 
    {
        return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
    }

    /**
     * Уравнение прямой по двум точкам
     * @param x - Координата Х
     * @param point1 - Точка 1
     * @param point2 - Точка 2
     * @returns {number} - Координата Y
     */
    public static getLine(x: number, point1: IPoint, point2: IPoint): number 
    {
        if (point1.y === point2.y) return point1.y;
        if (point2.x === x) return point2.y;

        const point = ((x - point1.x) * (point2.y - point1.y)) / (point2.x - point1.x) + point1.y;

        return Math.abs(point);
    };

    /**
     * Функция Гаусса
     * @param x - Аргумент
     * @param amplitude - Амплитуда
     * @param offset - Смещение
     * @param width - Ширина пика
     */
    public static getGauss(x: number, amplitude: number, offset: number, width: number): number
    {
        return amplitude * Math.exp(-Math.pow(x - offset, 2) / (2 * width * width));
    };

    /**
     * Параметры прямой заданной двумя точками
     * @param point1 - Точка 1
     * @param point2 - Точка 2
     * @returns {IParamLine} - Параметры прямой
     */
    private static lineParams(point1: IPoint, point2: IPoint) 
    {
        const x1: number = point1.x;
        const y1: number = point1.y;
        const x2: number = point2.x;
        const y2: number = point2.y;

        const k: number = (y2 - y1) / (x2 - x1);
        const b: number = (x2 * y1 - y2 * x1) / (x2 - x1);
        return <IParamLine> { k, b };
    }

    /**
     * Попадание в окрестность
     * @param value - Значение
     * @returns {boolean} - Попадает в окрестность
     */
    private static getEps(value: number) 
    {
        return (value < this.eps) && (value > -this.eps);
    }
}