import { makeAutoObservable } from 'mobx';

import { appStore } from 'Stores';

import ModuleStep from './ModuleStep';
import { CourseModulesIds, LocalStorageParam, ModuleProgress } from 'Shared/Enums';
import { ICourseModule } from 'Shared/Interfaces/App';
import { ISensorPosition } from 'Shared/Interfaces/App/Simulation/ISensorPosition';

interface ICourseModuleDef {
  id: CourseModulesIds | null;
  name: string;
  description: string;
  welcomeText: string;
  state: ModuleProgress;
  currentStepIndex: number;
  isShowSuccess: boolean;

  onExit: any; // callback outside

  get lastCompletedStep(): number;
  get isCurrentStepFirst(): boolean;
  get isCurrentStepLast(): boolean;

  initCurrentStep(index: number): void;
  closeSuccessMessage(): void;
  onStart(): void;
  onStepForward(): void;
  onStepBackward(): void;
  unlockModule(): void;
  deInitModuleOnExit(): void;
}

class CourseModule implements ICourseModuleDef {
  constructor(moduleData: ICourseModule, onExit: (isCompleted: boolean) => void) {
    makeAutoObservable(this, undefined, { autoBind: true });

    this.id = moduleData.id;
    this.name = moduleData.name;
    this.state = moduleData.state;
    this.description = moduleData.description;
    this.welcomeText = moduleData.welcomeText!;
    this.successText = moduleData.successText!;

    this.onExit = onExit;

    if (!moduleData.steps) return;

    this.steps = moduleData.steps.map((step) => new ModuleStep(step));
    this.currentStep = this.steps[0];
  }

  public id: CourseModulesIds | null = null;

  public name = '';

  public description = '';

  public welcomeText = '';

  public state: ModuleProgress = ModuleProgress.Locked;

  public currentStepIndex = 0;

  public steps: ModuleStep[] = [];

  public currentStep: ModuleStep | null = null;

  public isShowSuccess = false;

  public successText = '';

  public onExit: any = null;

  public get isExam() {
    return this.id === CourseModulesIds.ThickExam || this.id === CourseModulesIds.FlawExam;
  }

  public get lastCompletedStep(): number {
    const stepsLength = this.steps.length;

    for (let i = 0; i < stepsLength; i++) {
      if (i === stepsLength - 1 && this.steps[i].isCompleted) {
        return this.steps[stepsLength - 1].id;
      }

      if (this.steps[i].isCompleted && !this.steps[i + 1].isCompleted) {
        return this.steps[i].id;
      }
    }

    return 1;
  }

  public get isCurrentStepFirst(): boolean {
    return this.currentStepIndex === 0;
  }

  public get isCurrentStepLast(): boolean {
    return this.currentStepIndex === this.steps.length - 1;
  }

  public initCurrentStep(index: number): void {
    this.currentStepIndex = index;
    if (this.steps.length > this.currentStepIndex) {
      for (let i = 0; i < this.steps.length; i++) {
        if (i >= index) break;

        this.steps[i].onComplete(true);
      }
      this.currentStep = this.steps[this.currentStepIndex];
    }
  }

  public closeSuccessMessage(): void {
    this.isShowSuccess = false;
  }

  public onStart(): void {
    if (this.steps.length < 1) return;

    this.state = ModuleProgress.Started;
    this.currentStep = this.steps[0];
  }

  public onStepForward(sensorPosition?: ISensorPosition): void {
    if (this.id === CourseModulesIds.FlawWorkWithTool && !this.currentStep?.isDone) return;

    if (!this.currentStep) return;

    this.currentStep.onComplete(true);

    if (this.id === CourseModulesIds.FlawWorkWithTool) {
      appStore.putDeviceEnvParamsToLocalStorage();
      appStore.putDeviceToolsStateToLocalStorage();
    }

    if (this.id === CourseModulesIds.ThickWorkWithTool) {
      appStore.saveThickEnvToLocalStorage();
    }

    if (this.steps.length > this.currentStepIndex) {
      this.currentStepIndex += 1;
    }

    if (this.currentStepIndex <= this.steps.length - 1) {
      this.currentStep = this.steps[this.currentStepIndex];
      this.currentStep?.updateLastSensorPosition(sensorPosition!);
    }

    if (this.currentStepIndex === this.steps.length - 1) {
      appStore.putDataToLocalStorage(LocalStorageParam.ModuleStepIndex, '0');
    } else {
      appStore.putDataToLocalStorage(LocalStorageParam.ModuleStepIndex, this.currentStepIndex.toString());
    }

    if (this.id === CourseModulesIds.FlawWorkWithTool) {
      this.currentStep?.check();
    }

    if (this.id === CourseModulesIds.ThickWorkWithTool) {
      this.currentStep?.checkThicknessStep();
    }

    if (this.currentStepIndex === this.steps.length) {
      this.isShowSuccess = true;
    }
  }

  public onStepBackward(sensorPosition?: ISensorPosition): void {
    this.currentStep?.updateLastSensorPosition(sensorPosition!);

    if (this.id === CourseModulesIds.FlawWorkWithTool) {
      this.currentStep?.check();
    }

    if (this.id === CourseModulesIds.ThickWorkWithTool) {
      this.currentStep?.checkThicknessStep();
    }

    if (this.currentStepIndex === 0) {
      this.onExit(this.isCurrentStepLast);

      return;
    }

    this.steps[this.currentStepIndex - 1].onComplete(false);

    if (!this.currentStep) return;

    this.currentStepIndex -= 1;
    this.currentStep = this.steps[this.currentStepIndex];

    appStore.putDataToLocalStorage(LocalStorageParam.ModuleStepIndex, this.currentStepIndex.toString());
  }

  public unlockModule(): void {
    if (this.state === ModuleProgress.Locked) {
      this.state = ModuleProgress.Current;
    }
  }

  public updateWelcomeText(text: string) {
    this.welcomeText = text;
  }

  public deInitModuleOnExit(): void {
    this.currentStepIndex = 0;

    if (this.id !== CourseModulesIds.ThickIntroduce && this.id !== CourseModulesIds.FlawIntroduce) {
      this.state = ModuleProgress.Locked;
    } else {
      this.state = ModuleProgress.Current;
    }

    this.currentStep = this.steps[0];
    this.steps.forEach((item) => item.onComplete(false));
    appStore.removeParamFromLocalStorage(LocalStorageParam.DefectNotes);
    appStore.removeParamFromLocalStorage(LocalStorageParam.ModuleStepIndex);
    appStore.removeParamFromLocalStorage(LocalStorageParam.FlawDeviceParams);
    appStore.removeParamFromLocalStorage(LocalStorageParam.ModuleId);
  }
}

export default CourseModule;
