import React, { MouseEvent } from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import { IconButtonWrapper } from 'Components/uiKitElems';

import useStyles from './styles';

interface IProps {
  onClick: () => void;
  hint?: string;
  icon: JSX.Element;
}

const TableAction = (props: IProps) => {
  const { onClick, hint, icon } = props;

  const c = useStyles();

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();

    onClick();
  };

  return <IconButtonWrapper icon={icon} hint={hint} className={c.btn} onClick={handleClick} />;
};

export default TableAction;
