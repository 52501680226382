import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { H4Text, H6Text, IconButtonWrapper, typoColors } from 'Components/uiKitElems';
import Tabs from 'Modules/StudentCabinet/Components/StudyModules/Components/Tabs';
import { ModalDialog } from 'Components/Common';

import { DefectCheckingTable, ThickCheckingTable, ModuleProgressRender } from './Components';
import WelcomePopup from 'Modules/StudentCabinet/Components/StudyModules/Components/WelcomePopup';

import {
  appStore,
  measurementStore,
  studentProgressStore,
  studentsInGroupStore,
  studyCoursesStore,
  usersStore
} from 'Stores';

import { CoursesId, LocalStorageParam, UserRole } from 'Shared/Enums';

import Utils from 'Shared/Utils';

import useStyles from './styles';

const StudentProgress = () => {
  const c = useStyles();

  useEffect(() => {
    usersStore.onChangeSelectedTab(UserRole.Teacher);
    usersStore.getUsers();

    measurementStore.getAllSensors();

    studyCoursesStore.init();
    studyCoursesStore.getProgressForTeacher(
      studentsInGroupStore.selectedGroupId,
      studentProgressStore.selectedStudent?.id!
    );

    measurementStore.getTuningSamples();
    studentProgressStore.getStudentExamData();

    return () => {
      studyCoursesStore.deInit();
      studentProgressStore.deInit();
      usersStore.deInit();
      onCloseBrief();
    };
  }, []);

  const thicknessProgress = (
    <div className={c.modulesWrapper}>
      {studyCoursesStore.thicknessCourse.modules.map((item) => {
        const color = Utils.getColorByStudyProgress(item.state);

        return (
          <ModuleProgressRender
            courseModuleId={item.id}
            name={item.name}
            state={item.state}
            color={color}
            specialTextForExam={
              item.isExam ? studentProgressStore.currentExamState : ''
            }
          />
        );
      })}
    </div>
  );

  const flawProgress = (
    <div className={c.modulesWrapper}>
      {studyCoursesStore.flawDetection.modules.map((item) => {
        const color = Utils.getColorByStudyProgress(item.state);

        return (
          <ModuleProgressRender
            courseModuleId={item.id}
            name={item.name}
            state={item.state}
            color={color}
            specialTextForExam={item.isExam ? studentProgressStore.currentExamState : ''}
          />
        );
      })}
    </div>
  );

  const onReturnToStudentsList = () => {
    if (studentProgressStore.isShowCheckTable) {
      studentProgressStore.closeCheckTable();
    } else {
      studentProgressStore.deInit();
      studentProgressStore.showStudentProgress(null);
    }
  };

  const studyItems = [
    { id: CoursesId.ThicknessMeasurement, name: 'Ультразвуковая толщинометрия' },
    { id: CoursesId.FlawDetection, name: 'Ультразвуковая дефектоскопия' },
  ];

  const onCourseSelect = (selectedCourseId: string) => {
    studyCoursesStore.onSelectCourse(selectedCourseId as CoursesId);
    appStore.putDataToLocalStorage(LocalStorageParam.CourseId, selectedCourseId);
    studyCoursesStore.getProgressForTeacher(
      studentsInGroupStore.selectedGroupId,
      studentProgressStore.selectedStudent?.id!
    );
  };

  const onCloseBrief = () => {
    studentProgressStore.onShowBrief(false)
  };

  return (
    <div className={c.wrapper}>
      <ModalDialog
        isOpen={studentProgressStore.isShowExamBrief}
        hideBackdrop={true}
        title={'Общая информация о модуле'}
        onClose={onCloseBrief}>
        <WelcomePopup
          buttonCaption={'Ок'}
          welcomeText={studentProgressStore.examBrief}
          onButtonClick={onCloseBrief}
        />
      </ModalDialog>

      <div className={c.tabMenuWrapper}>
        <div className={c.studentInfoWrapper}>
          <IconButtonWrapper
            className={c.returnBtn}
            mediumSize={true}
            icon={<ArrowBackIcon />}
            onClick={onReturnToStudentsList}
          />
        </div>

        <Tabs
          tabsWrapperClassName={c.tabMenuWrapper}
          enabledTabIds={[CoursesId.ThicknessMeasurement, CoursesId.FlawDetection].filter(
            (item) => item === studentsInGroupStore.deviceIdByGroup
          )}
          items={studyItems}
          selected={studyCoursesStore.selectedCourse.code!}
          onChange={onCourseSelect}
        />

        {studentProgressStore.isShowCheckTable && studentProgressStore.finishedAt && (
          <>
            <H6Text
              className={c.passDate}
              noWrap={true}
              color={typoColors.bodyGrey}
              text={studentProgressStore.passedAt}
            />
            <IconButtonWrapper
              onClick={() => studentProgressStore.onShowBrief(true)}
              className={c.icnBtn}
              icon={<img className={c.passDateCard} src={'icons/pass-date-card.svg'} />}
            />
          </>
        )}

        <H4Text
          className={c.studentFullName}
          noWrap={true}
          color={typoColors.bodyGrey}
          text={`${studentProgressStore.selectedStudent?.fullName || ''}`}
        />
      </div>

      <div className={c.progressContentWrapper}>
        {studyCoursesStore.selectedCourse.code === CoursesId.ThicknessMeasurement && thicknessProgress}
        {studyCoursesStore.selectedCourse.code === CoursesId.FlawDetection && flawProgress}

        {studentProgressStore.isShowThicknessCheckingTable && <ThickCheckingTable />}
        {studentProgressStore.isShowFlawCheckingTable && <DefectCheckingTable />}
      </div>
    </div>
  );
};

export default observer(StudentProgress);
