import { ReactNode } from 'react';

import Table from './CustomTable';

export interface ITableColumn {
  key: string;
  label: string;
  width?: string;
  maxWidth?: string;
  align: 'left' | 'right' | 'center';
  sortable?: boolean;
  cell?: (row: any) => ReactNode | null;
  filter?: () => ReactNode | null;
  onSaveFilter?: () => void;
  onClearFilter?: () => void;
  hasFilterValue?: () => boolean;
  comparator?: (a: any, b: any) => number;
}

export default Table;
