import { makeAutoObservable } from 'mobx';

import CourseModule from './CourseModule';
import { CourseModulesIds, CoursesId } from 'Shared/Enums';
import { ICourse } from 'Shared/Interfaces/App';

interface ICourseModelDef {
  isShowBrief: boolean;
  code: CoursesId | null;
  selectedModule: CourseModule | null;
  modules: CourseModule[];

  onExit(isLast: boolean): void;
  onModuleClick(moduleId: CourseModulesIds | null): void;
  unlockNextModule(completedModuleId: CourseModulesIds | null): void;
  closeConfirmDialog(): void;
}

class CourseModel implements ICourseModelDef {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isShowBrief = false;

  public isShowConfirm = false;

  public code: CoursesId | null = null;

  public selectedModule: CourseModule | null = null;

  public modules: CourseModule[] = [];

  public init(courseData: ICourse) {
    this.code = courseData.code;
    this.modules = courseData.modules.map((item) => new CourseModule(item, this.onExit));
  }

  public onExit(): void {
    this.isShowConfirm = true;
  }

  public briefHandle(): void {
    if (!this.selectedModule) return;

    this.isShowBrief = !this.isShowBrief;
  }

  public onModuleClick(moduleId: CourseModulesIds | null): void {
    if (!moduleId) this.selectedModule = null;

    const module = this.modules.find((item) => item.id === moduleId);
    if (!module) return;

    this.selectedModule = module;
  }

  public unlockNextModule(completedModuleId: CourseModulesIds | null): void {
    const completedModuleIndex = this.modules.findIndex((el) => el.id === completedModuleId);
    if (completedModuleIndex.toString() && completedModuleIndex + 1 <= this.modules.length) {
      this.modules[completedModuleIndex + 1]?.unlockModule();
    }
  }

  public closeConfirmDialog(): void {
    this.isShowConfirm = false;
  }

  public deInitCourseModel() {
    this.selectedModule = null;
    this.isShowBrief = false;
  }
}

export default CourseModel;
