import { makeStyles } from '@material-ui/core';
import { indents } from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  userFormWrapper: {
    paddingLeft: indents.fortyEight,
    paddingTop: indents.sixteen,
    paddingRight: indents.thirtyTwo,
    paddingBottom: indents.thirtyTwo,
  },
  userFormRow: {
    marginTop: indents.twentyFour,
  },
  userFormInput: {
    width: 537,
  },
  passwordInput: {
    width: 500,
  },
  copyBtn: {
    marginLeft: indents.eight,
  },
  userFormActionRow: {
    marginTop: indents.thirtyTwo,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default useStyles;
