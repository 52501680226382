import { makeAutoObservable } from 'mobx';

import { CourseModulesIds, CoursesId, UserRole } from 'Shared/Enums';
import { ApplicationRoutes } from 'Shared/Constants/Navigation';
import { IAuthDto, ICredentialsDto, IUserPostDto } from 'Shared/Interfaces/Api/Useres';
import { ILocalStorageAuthData } from 'Shared/Interfaces/App';
import { IGroupDto } from 'Shared/Interfaces/Api/Groups';
import { departments } from 'Shared/Constants/departments';

const AUTH_DATA_STR = 'userAuthData';

interface IUserAuthModelDef {
  id: string;
  isRememberMe: boolean;
  login: string;
  password: string;
  shortName: string;
  role: UserRole | null;
  currentModuleId: CourseModulesIds | null;
  isPasswordError: boolean;
  isEmailError: boolean;

  get isIntroducingTool(): boolean;
  get isWorkingWithTool(): boolean;
  get isInExam(): boolean;
  get isAuthError(): boolean;
  get apiToken(): string;

  setAuthState(isSuccess: boolean): void;
  getCredentialsDto(): ICredentialsDto;
  updateBySuccessAuth(dto: any, userData: IUserPostDto | null, organizationName: string): void;
  updateModelFromLocalStorage(dto: ILocalStorageAuthData): void;
  getModelForLocalStorage(): ILocalStorageAuthData;
  onChangeLogin(login: string): void;
  onChangePassword(password: string): void;
  setCurrentStudyModule(currentModuleId: CourseModulesIds | null): void;
  onRememberMe(selected: boolean): void;
  logOut(): void;
  clear(): void;
  clearBeforeAuth(): void;
}

export interface IUserGroup {
  groupId: string;
  deviceTypeId: string;
}

class UserAuthModel implements IUserAuthModelDef {
  public id = '';

  public isRememberMe = true;

  public login = '';

  public password = '';

  public isPasswordError = false;

  public isEmailError = false;

  public shortName = '';

  public surname = '';

  public firstName = '';

  public patronymic = '';

  public departments: string[] = [];

  public organization = '';

  public role: UserRole | null = null;

  public userGroups: IUserGroup[] = [];

  public currentModuleId: CourseModulesIds | null = null;

  private token = '';

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });

    const authDataStr = window.localStorage.getItem(AUTH_DATA_STR);
    if (!authDataStr) return;

    const userData = JSON.parse(authDataStr);
    this.updateModelFromLocalStorage(userData);
  }

  public get isIntroducingTool(): boolean {
    return (
      this.currentModuleId === CourseModulesIds.FlawIntroduce ||
      this.currentModuleId === CourseModulesIds.ThickIntroduce
    );
  }

  public get isWorkingWithTool(): boolean {
    return (
      this.currentModuleId === CourseModulesIds.ThickWorkWithTool ||
      this.currentModuleId === CourseModulesIds.FlawWorkWithTool
    );
  }

  public get isInExam(): boolean {
    return this.currentModuleId === CourseModulesIds.FlawExam || this.currentModuleId === CourseModulesIds.ThickExam;
  }

  public get isAuthError(): boolean {
    return this.isEmailError && this.isPasswordError;
  }

  public get apiToken(): string {
    return this.token;
  }

  public get defaultRouteByRole(): string {
    switch (this.role) {
      case UserRole.Admin: {
        return ApplicationRoutes.Cabinet;
      }

      case UserRole.Student: {
        return ApplicationRoutes.Cabinet;
      }

      case UserRole.Teacher: {
        return ApplicationRoutes.Cabinet;
      }

      case null: {
        return ApplicationRoutes.Auth;
      }

      default: {
        return ApplicationRoutes.Auth;
      }
    }
  }

  public getCredentialsDto(): ICredentialsDto {
    return { login: this.login, password: this.password };
  }

  public updateBySuccessAuth(dto: IAuthDto, userData: IUserPostDto | null, organizationName: string) {
    this.id = dto.id;
    this.shortName = dto.shortName;
    if (dto.roleId === UserRole.Admin) {
      this.shortName = 'Администратор';
    }
    this.role = dto.roleId;
    this.token = dto.token;

    this.organization = organizationName;

    if (userData) {
      this.surname = userData.surname;
      this.firstName = userData.firstName;
      this.patronymic = userData.patronymic;
      this.departments = departments
        .filter((item) => userData.departmentIds?.includes(item.id))
        .map((item) => item.name);
    }

    if (this.isRememberMe) {
      window.localStorage.setItem(AUTH_DATA_STR, JSON.stringify(this.getModelForLocalStorage()));
    }
  }

  public updateGroupAndDeviceType(userGroups: IGroupDto[]): void {
    userGroups.forEach((item) => this.userGroups.push({ groupId: item.id, deviceTypeId: item.deviceTypeId }));
  }

  public updateModelFromLocalStorage(dto: ILocalStorageAuthData) {
    this.id = dto.id;
    this.shortName = dto.shortName;
    this.role = dto.role;
    this.token = dto.token;
    this.userGroups = dto.userGroups;
    this.surname = dto.surname;
    this.firstName = dto.firstName;
    this.patronymic = dto.patronymic;
    this.departments = dto.departments;
    this.organization = dto.organization;
  }

  public getModelForLocalStorage(): ILocalStorageAuthData {
    return {
      id: this.id,
      shortName: this.shortName,
      role: this.role!,
      token: this.token,
      userGroups: this.userGroups,
      surname: this.surname,
      firstName: this.firstName,
      patronymic: this.patronymic,
      departments: this.departments,
      organization: this.organization,
    };
  }

  public getUnLockedCourses(courseIds: CoursesId[]): CoursesId[] {
    return courseIds.filter((courseId) => this.userGroups.find((userGroup) => userGroup.deviceTypeId === courseId));
  }

  public onChangeLogin(login: string) {
    this.login = login;
    this.isEmailError = false;
  }

  public onChangePassword(password: string) {
    this.password = password;
    this.isPasswordError = false;
  }

  public onRememberMe(selected: boolean) {
    this.isRememberMe = selected;
  }

  public setAuthState(isSuccess: boolean) {
    this.isEmailError = !isSuccess;
    this.isPasswordError = !isSuccess;
  }

  public setCurrentStudyModule(moduleId: CourseModulesIds | null) {
    this.currentModuleId = moduleId;
  }

  public logOut() {
    window.localStorage.removeItem(AUTH_DATA_STR);
    this.clear();
  }

  public clear() {
    this.id = '';
    this.isRememberMe = true;
    this.login = '';
    this.password = '';
    this.shortName = '';
    this.role = null;
    this.token = '';

    this.surname = '';
    this.firstName = '';
    this.patronymic = '';
    this.organization = '';
    this.departments = [];
  }

  public clearBeforeAuth() {
    this.shortName = '';
    this.role = null;
    this.token = '';
    this.userGroups = [];
  }
}

export default UserAuthModel;
