import { makeStyles } from '@material-ui/core';

import { colors, indents, inputBorderRadius, typoColors } from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  welcomeWrapper: {
    paddingLeft: indents.eight,
    paddingRight: indents.thirtyTwo,
    paddingBottom: indents.eight,
    marginTop: indents.twentyFour,
    width: '791px',
    height: '427px',
  },
  welcomeContent: {
    display: 'flex',
    height: '80%',
  },
  welcomeImageWrapper: {
    borderRadius: inputBorderRadius,
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '253px',
    height: '100%',
    overflow: 'hidden',
  },
  welcomeImage: {
    width: '450px',
    height: '450px',
  },
  welcomeTextWrapper: {
    height: '100%',
    width: '100%',
    color: typoColors.bodyGrey,
    marginLeft: indents.eight,
    border: `solid ${colors.primary.mainBlue} 1px`,
    borderRadius: inputBorderRadius,
    padding: indents.eight,
    minWidth: '200px',
    minHeight: '200px',
  },
  welcomeText: {
    height: '100%',
    overflow: 'auto',
  },
  welcomeAction: {
    marginTop: indents.thirtyTwo,
    display: 'flex',
    justifyContent: 'end',
  },
}));

export default useStyles;
