import HTTPService from './HTTPService';

import { apiPaths } from 'Shared/Constants/Navigation/apiPaths';

import { IExamGetDto, IExamPostDto, IExamRowDto } from 'Shared/Interfaces/Api/ExamTemplate';

class ExamsApiService extends HTTPService {
  constructor() {
    super(apiPaths.exams);
  }

  public getExams(): Promise<IExamRowDto[]> {
    return this.GET(``);
  }

  public postExam(exam: IExamPostDto): Promise<string> {
    return this.POST(``, exam);
  }

  public putExam(examId: string, exam: IExamPostDto): Promise<string> {
    return this.PUT(`${examId}`, exam);
  }

  public getExamById(examId: string): Promise<IExamGetDto> {
    return this.GET(`${examId}`);
  }

  public deleteExam(examId: string): Promise<string> {
    return this.DELETE(`/${examId}`);
  }
}

export default new ExamsApiService();
