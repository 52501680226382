import { action, makeObservable, observable } from 'mobx';

export default class PaginationParams {
  public totalCount = 0;

  public pageIndex = 0;

  public pageSize = 10;

  constructor(pageIndex?: number, pageSize?: number) {
    makeObservable(this, {
      totalCount: observable,
      pageIndex: observable,
      pageSize: observable,

      setTotalCount: action.bound,
      updatePageIndex: action.bound,
      updatePageSize: action.bound,
      deInit: action,
    });

    if (pageIndex) {
      this.pageIndex = pageIndex;
    }

    if (pageSize) {
      this.pageSize = pageSize;
    }
  }

  public setTotalCount(count: number) {
    this.totalCount = count;

    const lastPage = Math.trunc(this.totalCount / this.pageSize);
    if (lastPage < this.pageIndex) this.updatePageIndex(lastPage);
  }

  public updatePageIndex(pageIndex: number) {
    this.pageIndex = pageIndex;
  }

  public updatePageSize(pageSize: number) {
    this.pageSize = pageSize;
  }

  public deInit() {
    this.totalCount = 0;
    this.pageIndex = 0;
    this.pageSize = 0;
  }
}
