import { makeStyles } from '@material-ui/core';

import { colors, indents, inputBorderRadius } from 'Components/uiKitElems';

const useStyles = makeStyles((theme) => ({
  deviceIntroduceWrapper: {
    height: '100%',
    width: '100%',
  },
  introduceContent: {
    display: 'flex',
    height: '90%',
  },

  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '75vw',
    height: 'calc(100vh - 250px)',
    minHeight: 200,
    minWidth: 414,
    overflow: 'auto',
    overflowX: 'auto',
    marginRight: indents.thirtyTwo,
  },
  introduceImageWrapper: {
    minWidth: 414,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  thickDeviceImage: {
    width: '80%',
    height: 'auto',
  },
  flawDeviceImage: {
    width: '80%',
    minWidth: 250,
    height: 'fit-content',
  },
}));

export default useStyles;
