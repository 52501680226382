import { Theme, makeStyles } from '@material-ui/core';

import { bgColors, borderRadius, colors, indents, typoColors } from '../theme';

interface IProps {
  height?: number;
}

const useStyles = makeStyles<Theme, IProps>((theme) => ({
  switchWrapper: (props) => ({
    display: 'flex',
    height: props.height || '40px',
    minWidth: '60px',
    border: 'solid 1px',
    borderRadius: borderRadius,
    borderColor: bgColors.deviceBorders,
    '&:hover': {
      borderColor: bgColors.deviceBorderActive,
    },
  }),
  stateHighLight: {
    cursor: 'pointer',
    width: '50%',
    fontSize: 14,
    paddingLeft: indents.four,
    paddingRight: indents.four,
    backgroundColor: colors.statusColor.successGreen,
  },
  stateMain: {
    cursor: 'pointer',
    fontSize: 14,
    width: '50%',
    paddingLeft: indents.four,
    paddingRight: indents.four,
    color: typoColors.white,
  },
}));

export default useStyles;
