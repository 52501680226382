import React, { useEffect } from 'react';

import { observer } from 'mobx-react';

import { StudyTextWrapper } from 'Components/uiKitElems/StudyTextWrapper';
import StepNavigator from '../Components/StepNavigator';
import { ModalDialog } from 'Components/Common';
import WelcomePopup from '../../StudyModules/Components/WelcomePopup';

import { studyCoursesStore } from 'Stores';

import { CourseModule } from 'Shared/Models/Courses';
import { CourseModulesIds, ModuleProgress } from 'Shared/Enums';

import useStyles from './styles';

interface IProps {
  module: CourseModule | null;
}

const DeviceIntroduce = (props: IProps) => {
  const { module } = props;
  const c = useStyles();

  useEffect(() => {
    if (studyCoursesStore.selectedCourse.selectedModule?.state === ModuleProgress.Completed) {
      studyCoursesStore.selectedCourse.unlockNextModule(studyCoursesStore.selectedCourse.selectedModule?.id);
    }
  }, [studyCoursesStore.selectedCourse.selectedModule?.state]);

  useEffect(() => {
    const el = document.getElementById('edu_step_descriptor');
    if (!el || !module?.currentStep?.description!) return;

    el.innerHTML = module.currentStep.description;
  }, [studyCoursesStore.selectedCourse.selectedModule?.currentStep]);

  return (
    <div className={c.deviceIntroduceWrapper}>
      {module && (
        <StepNavigator
          isDeviceEnv={false}
          steps={module.steps}
          isCurrentStepFirst={module.isCurrentStepFirst}
          isCurrentStepLast={module.isCurrentStepLast}
          onStepBack={module.onStepBackward}
          onStepForward={module.onStepForward}
        />
      )}

      <ModalDialog
        isOpen={studyCoursesStore.selectedCourse.isShowBrief}
        hideBackdrop={true}
        title={'Общая информация о модуле'}
        onClose={studyCoursesStore.selectedCourse.briefHandle}>
        <WelcomePopup
          buttonCaption={'Ок'}
          welcomeText={studyCoursesStore.selectedCourse.selectedModule?.welcomeText!}
          onButtonClick={studyCoursesStore.selectedCourse.briefHandle}
        />
      </ModalDialog>

      {module && (
        <div className={c.introduceContent}>
          <StudyTextWrapper
            title={module.currentStep?.descriptionTitle!}
            subtitle={module.currentStep?.title!}
            htmlNodeId={'edu_step_descriptor'}
          />

          <div className={c.imageContainer}>
            <div className={c.introduceImageWrapper}>
              <img
                className={module.id === CourseModulesIds.ThickIntroduce ? c.thickDeviceImage : c.flawDeviceImage}
                src={module.currentStep?.imageUrl}
                alt={'image'}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(DeviceIntroduce);
