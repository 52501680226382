import React, { MouseEventHandler } from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

interface IProps {
  mediumSize?: boolean;
  icon: JSX.Element;
  disabled?: boolean;
  hint?: string;
  onClick?: MouseEventHandler;
  className?: string;
}

const IconButtonWrapper = (props: IProps) => {
  const { onClick, disabled, hint, className, mediumSize } = props;

  return (
    <Tooltip title={hint || ''}>
      <IconButton size={mediumSize ? 'medium' : 'small'} onClick={onClick} disabled={disabled} className={className}>
        {props.icon}
      </IconButton>
    </Tooltip>
  );
};

export default IconButtonWrapper;
